import React from "react";
import Clear from "@mui/icons-material/Clear";
import "styles/components/AttachedFileNameComponent.scss";

interface Props {
    fileName: string | null;
    onClick: () => void;
    onDelete: () => void;
}

export const AttachedFileNameComponent: React.FC<Props> = (props) => {
    // Utility.log("===== AttachedFileNameComponent");
    return (
        <div
            className={
                props.fileName == null
                    ? "component AttachedFileNameComponent hidden"
                    : "component AttachedFileNameComponent"
            }
        >
            <div className="filename" onClick={props.onClick}>
                {props.fileName}
            </div>
            <div className="delete-icon" onClick={props.onDelete}>
                <Clear />
            </div>
        </div>
    );
};
