import { JoinGroupModel } from "models/Models";

export class JoinGroupListResponse {
    rtnCd: number | null = null;
    lstJoinGroup: JoinGroupModel[] | null = null;
    lstUnderInvitation: JoinGroupModel[] | null = null;
    lstUnderApplication: JoinGroupModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstJoinGroup
        if (obj.hasOwnProperty("JoinedGroupList")) {
            if (this.lstJoinGroup == null) {
                this.lstJoinGroup = [];
            }
            for (let i in obj.JoinedGroupList) {
                let joinGroup = new JoinGroupModel(obj.JoinedGroupList[i]);
                this.lstJoinGroup.push(joinGroup);
            }
        }
        // lstUnderInvitation
        if (obj.hasOwnProperty("UserListUnderInvitation")) {
            if (this.lstUnderInvitation == null) {
                this.lstUnderInvitation = [];
            }
            for (let i in obj.UserListUnderInvitation) {
                let joinGroup = new JoinGroupModel(
                    obj.UserListUnderInvitation[i]
                );
                this.lstUnderInvitation.push(joinGroup);
            }
        }
        // lstUnderApplication
        if (obj.hasOwnProperty("UserListUnderApplication")) {
            if (this.lstUnderApplication == null) {
                this.lstUnderApplication = [];
            }
            for (let i in obj.UserListUnderApplication) {
                let joinGroup = new JoinGroupModel(
                    obj.UserListUnderApplication[i]
                );
                this.lstUnderApplication.push(joinGroup);
            }
        }
    }
}
