import { JoinGroupModel } from "models/Models";

export class GroupMemberListResponse {
    rtnCd: number | null = null;
    hasNext: boolean = false;
    lstJoinGroup: JoinGroupModel[] | null = null;
    readSize: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // hasNext
        if (obj.hasOwnProperty("IsNext")) {
            this.hasNext = obj.IsNext;
        }
        // lstJoinGroup
        if (obj.hasOwnProperty("JoinGroupList")) {
            if (this.lstJoinGroup == null) {
                this.lstJoinGroup = [];
            }
            for (let i in obj.JoinGroupList) {
                let joinGroup = new JoinGroupModel(obj.JoinGroupList[i]);
                this.lstJoinGroup.push(joinGroup);
            }
        }
        // readSize
        if (obj.hasOwnProperty("ReadSize")) {
            this.readSize = obj.ReadSize;
        }
    }
}
