import Utility from "utils/Utility";
import { UserModel } from "./UserModel";

export class TalkHistoryModel {
    id: number | null = null;
    senderId: number | null = null;
    receiverId: number | null = null;
    senderTalkType: number | null = null;
    receiverTalkType: number | null = null;
    fetchSkywayToken: number | null = null;
    createAndAttachTalkStream: number | null = null;
    joinSkywayRoom: number | null = null;
    startCasting: number | null = null;
    subscribeTalk: number | null = null;
    startTime: number | null = null;
    endTime: number | null = null;
    talkTime: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // senderId
        if (obj.hasOwnProperty("senderId")) {
            this.senderId = obj.senderId;
        } else if (obj.hasOwnProperty("SenderId")) {
            this.senderId = obj.SenderId;
        }
        // receiverId
        if (obj.hasOwnProperty("partreceiverIdnerId")) {
            this.receiverId = obj.receiverId;
        } else if (obj.hasOwnProperty("ReceiverId")) {
            this.receiverId = obj.ReceiverId;
        }
        // senderTalkType
        if (obj.hasOwnProperty("senderTalkType")) {
            this.senderTalkType = obj.senderTalkType;
        } else if (obj.hasOwnProperty("SenderTalkType")) {
            this.senderTalkType = obj.SenderTalkType;
        }
        // receiverTalkType
        if (obj.hasOwnProperty("receiverTalkType")) {
            this.receiverTalkType = obj.receiverTalkType;
        } else if (obj.hasOwnProperty("ReceiverTalkType")) {
            this.receiverTalkType = obj.ReceiverTalkType;
        }
        // fetchSkywayToken
        if (obj.hasOwnProperty("fetchSkywayToken")) {
            this.fetchSkywayToken = obj.fetchSkywayToken;
        } else if (obj.hasOwnProperty("FetchSkywayToken")) {
            this.fetchSkywayToken = obj.FetchSkywayToken;
        }
        // createAndAttachTalkStream
        if (obj.hasOwnProperty("createAndAttachTalkStream")) {
            this.createAndAttachTalkStream = obj.createAndAttachTalkStream;
        } else if (obj.hasOwnProperty("CreateAndAttachTalkStream")) {
            this.createAndAttachTalkStream = obj.CreateAndAttachTalkStream;
        }
        // joinSkywayRoom
        if (obj.hasOwnProperty("joinSkywayRoom")) {
            this.joinSkywayRoom = obj.joinSkywayRoom;
        } else if (obj.hasOwnProperty("JoinSkywayRoom")) {
            this.joinSkywayRoom = obj.JoinSkywayRoom;
        }
        // startCasting
        if (obj.hasOwnProperty("startCasting")) {
            this.startCasting = obj.startCasting;
        } else if (obj.hasOwnProperty("StartCasting")) {
            this.startCasting = obj.StartCasting;
        }
        // subscribeTalk
        if (obj.hasOwnProperty("subscribeTalk")) {
            this.subscribeTalk = obj.subscribeTalk;
        } else if (obj.hasOwnProperty("SubscribeTalk")) {
            this.subscribeTalk = obj.SubscribeTalk;
        }
        // startTime
        if (obj.hasOwnProperty("startTime")) {
            const strDt = obj.startTime;
            Utility.log("startTime:" + strDt);
            try {
                const unixTime = new Date(strDt).getTime()/1000;
                this.startTime = unixTime;
                Utility.log("startTime unixTime:" + unixTime);
            } catch (error) {
                Utility.log(error);
            }
        } else if (obj.hasOwnProperty("StartTime")) {
            const strDt = obj.StartTime;
            Utility.log("startTime:" + strDt);
            try {
                const unixTime = new Date(strDt).getTime()/1000;
                this.startTime = unixTime;
                Utility.log("startTime unixTime:" + unixTime);
            } catch (error) {
                Utility.log(error);
            }
        }
        // endTime
        if (obj.hasOwnProperty("endTime")) {
            const strDt = obj.endTime;
            Utility.log("endTime:" + strDt);
            try {
                const unixTime = new Date(strDt).getTime()/1000;
                this.startTime = unixTime;
                Utility.log("endTime unixTime:" + unixTime);
            } catch (error) {
                Utility.log(error);
            }
        } else if (obj.hasOwnProperty("EndTime")) {
            const strDt = obj.EndTime;
            Utility.log("endTime:" + strDt);
            try {
                const unixTime = new Date(strDt).getTime()/1000;
                this.startTime = unixTime;
                Utility.log("endTime unixTime:" + unixTime);
            } catch (error) {
                Utility.log(error);
            }
        }
        // talkTime
        if (obj.hasOwnProperty("talkTime")) {
            this.talkTime = obj.talkTime;
        } else if (obj.hasOwnProperty("TalkTime")) {
            this.talkTime = obj.TalkTime;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
    }
}
