import React from "react";
import { Box, Divider } from "@mui/material";
import { UserModel, ParentMailModel } from "models/Models";
import { AppConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";

type Props = {
    loginUser: UserModel;
    parentMail: ParentMailModel;
    selected: boolean;
    onClick: () => void;
    langCd: string;
    timeDifference: number;
};

export const SentParentMailListItem: React.FC<Props> = (props) => {
    // Utility.log("===== SentParentMailListItem");
    /***** 定数、変数 */

    /***** useState */
    const [title, setTitle] = React.useState<string>("");
    const [sender, setSender] = React.useState<UserModel>();
    const [receiver, setReceiver] = React.useState<UserModel>();
    const [updatedAt, setUpdatedAt] = React.useState<string>("");

    React.useEffect(() => {
        function reflectData() {
            if (
                props.loginUser != null &&
                props.parentMail != null &&
                props.parentMail.sender != null &&
                props.parentMail.receiver != null
            ) {
                if (props.loginUser.id === props.parentMail.receiverId) {
                    // 送信者セット
                    setSender(props.parentMail.receiver);
                    // 受信者セット
                    setReceiver(props.parentMail.sender);
                    // タイトルセット
                    if (props.parentMail.title != null) {
                        setTitle(AppConstants.MAIL_REPLY + props.parentMail.title);
                    }
                    // 更新日時セット
                    if (props.parentMail.updatedAtReceiverSend != null) {
                        const unixTime =
                            new Date(
                                props.parentMail.updatedAtReceiverSend
                            ).getTime() / 1000;
                        const updatedAt = Utility.getFormattedDateTime(
                            unixTime,
                            props.timeDifference,
                            props.langCd
                        );
                        setUpdatedAt(updatedAt);
                    }
                } else {
                    // 送信者セット
                    setSender(props.parentMail.sender);
                    // 受信者セット
                    setReceiver(props.parentMail.receiver);
                    // タイトルセット
                    if (props.parentMail.title != null) {
                        if (
                            props.parentMail.reply != null &&
                            props.parentMail.reply === 1
                        ) {
                            setTitle(
                                AppConstants.MAIL_REPLY + props.parentMail.title
                            );
                        } else {
                            setTitle(props.parentMail.title);
                        }
                    }
                    // 更新日時セット
                    if (props.parentMail.updatedAtSenderSend != null) {
                        const unixTime =
                            new Date(
                                props.parentMail.updatedAtSenderSend
                            ).getTime() / 1000;
                        const updatedAt = Utility.getFormattedDateTime(
                            unixTime,
                            props.timeDifference,
                            props.langCd
                        );
                        setUpdatedAt(updatedAt);
                    }
                }
            }
        }
        reflectData();
    }, [
        props.loginUser, 
        props.timeDifference,
        props.parentMail,
        props.langCd
    ]);


    /**
     * レンダー
     */
    return (
        <Box
            sx={{
                width: "100%",
                cursor: "pointer",
                backgroundColor: props.selected ? "#eeeeee" : "#ffffff",
            }}
            onClick={props.onClick}
        >
            {sender != null && receiver != null && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            minHeight: {
                                xs: "50px",
                                sm: "60px",
                            },
                            padding: {
                                xs: "5px 10px 0 10px",
                                sm: "5px 10px",
                            },
                        }}
                    >
                        {
                            // プロフィール画像
                        }
                        <Box
                            component="img"
                            sx={{
                                objectFit: "cover",
                                height: {
                                    xs: "40px",
                                    sm: "50px",
                                },
                                width: {
                                    xs: "40px",
                                    sm: "50px",
                                },
                                borderRadius: "50%",
                            }}
                            src={
                                receiver.iconName != null &&
                                receiver.iconName.length > 0
                                    ? UrlConstants.URL_S3_USER_ICON +
                                      receiver.iconName
                                    : "/images/no_image.png"
                            }
                        ></Box>
                        {
                            // 親メール内容
                        }
                        <Box
                            component="div"
                            sx={{
                                width: "100%",
                                height: "90%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                padding: {
                                    xs: "5px 0 5px 5px",
                                    sm: "5px",
                                },
                                minWidth: 0,
                            }}
                        >
                            {
                                // 受信者名
                            }
                            <Box
                                component="div"
                                sx={{
                                    fontSize: "0.9rem",
                                }}
                            >
                                {"To:" + receiver.name}
                            </Box>
                            {
                                // タイトル
                            }
                            <Box
                                component="div"
                                sx={{
                                    width: "100%",
                                    fontSize: "1.0rem",
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {title}
                            </Box>
                            {
                                // 日時
                            }
                            <Box
                                component="div"
                                sx={{
                                    width: "100%",
                                    textAlign: "right",
                                    color: AppConstants.COLOR_GRAY_DATE,
                                    fontSize: "0.75rem",
                                }}
                            >
                                {updatedAt}
                            </Box>
                        </Box>
                    </Box>
                    <Divider
                        sx={{
                            margin: {
                                xs: "5px 10px 0 10px",
                                sm: "0",
                            },
                            backgroundColor: "#000000",
                        }}
                    />
                </>
            )}
        </Box>
    );
};
