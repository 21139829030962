import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Menu, MenuItem, Switch } from "@mui/material";
import { TalkUserModel, UserModel } from "models/Models";
import { DbConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type Props = {
    talkUser: TalkUserModel;
    selected: boolean;
    permitted: boolean;
    partnerPermitted?: boolean;
    unreadCount: number;
    langCd: string;
    timeDifference: number;
    onClickUser?: () => void;
    onFollow: () => void;
    onBlock: () => void;    
    onChangePermission: (permitted: boolean) => void;
};

const _TalkUserListItem: React.FC<Props> = (props) => {
    // Utility.log("===== TalkUserListItem partnerName:" + props.talkUser.partner?.name + " talkPermitted:" + props.permitted + " partnerTalkPermitted:" + props.talkUser.partnerTalkPermitted);
    /***** 定数、変数 */

    /***** useState */
    const [partner, setPartner] = React.useState<UserModel>();
    const [following, setFollowing] = React.useState<boolean>(false);
    const [online, setOnline] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const [permitted, setPermitted] = React.useState<boolean>(false);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        if (props.talkUser.partner != null) {
            setPartner(props.talkUser.partner);
        }
    }, [props.talkUser.partner]);
    React.useEffect(() => {
        if (props.talkUser.partner != null) {
            if (props.talkUser.partner.following) {
                setFollowing(true);
            }
        }
    }, [
        props.talkUser.partner,
        props.talkUser.partner?.following
    ]);
    React.useEffect(() => {
        if (props.talkUser.partner != null) {
            if (props.talkUser.partner.online === 1) {
                setOnline(true);
            }
        }
    }, [
        props.talkUser.partner,
        props.talkUser.partner?.online
    ]);

    async function onChangeTalkPermission(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        event.stopPropagation();
        props.onChangePermission(event.target.checked)
    }

    async function onFollow() {
        props.onFollow();
        setAnchorEl(null);
    }

    async function onBlock() {
        props.onBlock();
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * フォローのHtml要素生成
     * @returns
     */
    function getFollowHtml() {
        if (following) {
            return (
                <Box
                    component="div"
                    className="followingLabel"
                    sx={{
                        backgroundColor: "#cccc00",
                        color: "#ffffff",
                        borderRadius: "9999px",
                        padding: {
                            xs: "1px 6px",
                            sm: "1px 6px",
                            md: "1px 9px",
                            lg: "1px 12px",
                            xl: "1px 12px",
                        },
                        marginLeft: "3px",
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                    }}
                >
                    <FormattedMessage id={"user_following"} />
                </Box>
            );
        } else {
            return <></>;
        }
    }
    /**
     * トーク許可のHtml要素生成
     * @returns
     */
    function getTalkPermissionHtml() {
        if (props.talkUser.pertnerPermitted) {
            return (
                <Box
                    component="div"
                    className="talkPermissionLabel"
                    sx={{
                        backgroundColor: "#0000cc",
                        color: "#ffffff",
                        borderRadius: "9999px",
                        padding: {
                            xs: "1px 6px",
                            sm: "1px 6px",
                            md: "1px 9px",
                            lg: "1px 12px",
                            xl: "1px 12px",
                        },
                        marginLeft: "3px",
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                    }}
                >
                    <FormattedMessage id={"talk_permission2"} />
                </Box>
            );
        } else {
            return <></>;
        }
    }
    /**
     * オンラインのHtml要素生成
     * @returns
     */
    function getOnlineHtml() {
        if (online) {
            return (
                <Box
                    component="div"
                    className="onlineLabel"
                    sx={{
                        backgroundColor: "#00cc00",
                        color: "#ffffff",
                        borderRadius: "9999px",
                        padding: {
                            xs: "1px 6px",
                            sm: "1px 6px",
                            md: "1px 9px",
                            lg: "1px 12px",
                            xl: "1px 12px",
                        },
                        marginLeft: "3px",
                        fontSize: "0.8rem",
                        whiteSpace: "nowrap",
                    }}
                >
                    <FormattedMessage id={"user_online"} />
                </Box>
            );
        } else {
            return <></>;
        }
    }
    /**
     * 属性のHtml要素生成
     * @param gender
     * @param attribute
     * @returns
     */
    function getAttributeHtml() {
        if (partner == null || partner.attribute == null) {
            return <span></span>;
        }
        let attributeLabel = Utility.getAttributeLabelKey(partner);
        if (attributeLabel.length > 0) {
            let bgColor = "#999999";
            if (partner.gender === DbConstants.GENDER_MALE) {
                bgColor = "#55AAFF";
            } else if (partner.gender === DbConstants.GENDER_FEMALE) {
                bgColor = "#FF55CC";
            }
            let label = Utility.getAttributeLabelKey(partner);
            if (label != null && label.length > 0) {
                return (
                    <Box
                        sx={{
                            backgroundColor: bgColor,
                            color: "#FFFFFF",
                            borderRadius: "9999px",
                            fontSize: "0.8rem",
                            padding: {
                                xs: "1px 6px",
                                sm: "2px 6px",
                            },
                        }}
                    >
                        <FormattedMessage id={label} />
                    </Box>
                );
            } else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }
    /**
     * 国旗のHtml要素生成
     * @returns
     */
    function getUserFlagHtml() {
        if (
            partner == null ||
            partner.countryCd == null ||
            partner.countryCd.length === 0
        ) {
            return <span></span>;
        }
        return (
            <Box
                component="img"
                src={
                    "/images/flag/" + partner.countryCd?.toUpperCase() + ".png"
                }
                sx={{
                    objectFit: "contain",
                    width: {
                        xs: "20px",
                        sm: "25px",
                    },
                    height: {
                        xs: "20px",
                        sm: "25px",
                    },
                    marginRight: {
                        xs: "5px",
                    },
                }}
            />
        );
    }
    /**
     * 年代のHtml要素生成
     * @returns
     */
    function getAgeHtml() {
        if (partner == null || partner.age == null || partner.age === 0) {
            return <span></span>;
        }
        let label = Utility.getAgeLabelKey(partner.age!);
        if (label != null && label.length > 0) {
            return (
                <Box sx={{ fontSize: "0.8rem" }}>
                    (
                    <FormattedMessage id={label} />)
                </Box>
            );
        } else {
            return <span></span>;
        }
    }

    function onItemMenuClicked(event: React.MouseEvent<any>) {
        setAnchorEl(event.currentTarget);
    }

    /**
     * レンダー
     */
    return (
        <Box
            className="TalkUserListItem"
            sx={{
                position: "relative",
                width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: {
                    xs: "5px",
                    sm: "10px",
                },
                color: "#ffffff",
                borderBottom: "1px solid rgba(255,255,255,0.3)",
            }}
        >
            {partner != null && (
                <>
                    {
                        // ユーザアイコン
                    }
                    <Box className="icon-area"
                        sx={{
                            position: "relative",
                        }}
                    >
                        <Link to={`/users/${Utility.getUserKey(partner)}/profile`}>
                            <Box
                                component="img"
                                sx={{
                                    height: {
                                        xs: "40px",
                                        sm: "50px",
                                    },
                                    width: {
                                        xs: "40px",
                                        sm: "50px",
                                    },
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                }}
                                src={
                                    partner.iconName != null &&
                                    partner.iconName.length > 0
                                        ? UrlConstants.URL_S3_USER_ICON +
                                        partner.iconName
                                        : "/images/no_image.png"
                                }
                            />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            position: "relative",
                            flexGrow: 1,
                            flexShrink: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            cursor: "default",
                            overflow: "hidden",
                        }}
                    >
                        {
                            // 上段
                        }
                        <Box
                            sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    flexWrap: "wrap",
                                }}
                                onClick={props.onClickUser}
                            >
                                {
                                    // 国旗
                                    getUserFlagHtml()
                                }
                                {
                                    // 地域
                                }
                                {partner.region != null && partner.region.length > 0 && (
                                    <Box
                                        component="div"
                                        sx={{ fontSize: "0.9rem" }}
                                    >
                                        {partner.region}
                                    </Box>
                                )}
                                {!props.selected && (
                                    // フォロー中ラベル
                                    getFollowHtml()
                                )}
                                {!props.selected && (
                                    // オンラインラベル
                                    getOnlineHtml()

                                )}
                                {
                                    // トーク許可ラベル
                                    getTalkPermissionHtml()
                                }
                            </Box>
                            {
                                // ３点メニュー
                            }
                            <Box 
                                className="item-menu"
                                sx={{
                                    position: "relative",
                                    flexGrow: 0,
                                    flexShrink: 0,
                                }}
                            >
                                {
                                    // 未読マーカー
                                }
                                {props.talkUser.unreadCount != null &&
                                    props.talkUser.unreadCount > 0 && (
                                    <Box
                                        sx={{
                                            width: "15px",
                                            height: "15px",
                                            position: "absolute",
                                            left: "-20px",
                                            top: "5px",
                                            borderRadius: "9999px",
                                            backgroundColor: "#ff0000",
                                        }}
                                    />
                                )}
                                <MoreHorizIcon
                                    onClick={(
                                        event: React.MouseEvent<any>
                                    ) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        onItemMenuClicked(event);
                                    }}
                                />
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {!following && (
                                        <MenuItem 
                                            onClick={onFollow}
                                        >
                                            <FormattedMessage id="btn_follow" />
                                        </MenuItem>
                                    )}
                                    <MenuItem 
                                        sx={{
                                            color: "#FF0000",
                                        }}
                                        onClick={onBlock}
                                    >
                                        <FormattedMessage id="btn_block" />
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                        {
                            // 中段
                        }
                        <Box
                            sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                columnGap: "3px",
                                paddingTop: "3px",
                            }}
                            onClick={props.onClickUser}
                        >
                            {
                                // 属性
                                getAttributeHtml()
                            }
                            {
                                // 名前
                            }
                            <Box
                                component="div"
                                sx={{
                                    fontWeight: "bold",
                                    overflow: "hidden",
                                }}
                            >
                                {partner.name}
                            </Box>
                            {
                                // 年代
                                getAgeHtml()
                            }
                        </Box>
                        {
                            // 下段
                        }
                        {partner.profileText != null && partner.profileText.length > 0 && (
                            <Box
                                sx={{
                                    position: "relative",
                                    textAlign: "left",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    paddingTop: "3px",
                                }}
                                onClick={props.onClickUser}
                            >
                                {
                                    // プロフィール
                                }
                                {partner.profileText}
                            </Box>
                        )}
                        {
                            // 最下段
                        }
                        <Box className="talk-permission-area"
                            sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingTop: "3px",
                            }}
                        >
                            <label onClick={props.onClickUser}
>
                                <FormattedMessage id="talk_permission"/>
                            </label>
                            <Switch 
                                sx={{
                                    position: "relative",
                                    height: "auto",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    "& span.MuiSwitch-root": {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    },
                                    "& span.MuiSwitch-switchBase": {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        top: 0,
                                        bottom: 0,
                                    },
                                    "& span.MuiSwitch-track": {
                                        height: "15px",
                                    }
                                }}
                                checked={props.permitted} 
                                onChange={onChangeTalkPermission} 
                                name="antoine" 
                            />
                            <Box component="label"
                                sx={{
                                    display: "block",
                                    height: "100%",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }} 
                                onClick={props.onClickUser}
                            >
                                &nbsp;
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export const TalkUserListItem = React.memo(
    _TalkUserListItem,
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.talkUser !== nextProps.talkUser) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser != null) {
            if (prevProps.talkUser.userId !== nextProps.talkUser.userId) {
                return false;
            }
            if (prevProps.talkUser.partnerId !== nextProps.talkUser.partnerId) {
                return false;
            }
            if (prevProps.talkUser.partner != null && nextProps.talkUser.partner != null) {
                if (prevProps.talkUser.partner.following !== nextProps.talkUser.partner.following) {
                    return false;
                }
            }
            if (prevProps.talkUser.pertnerPermitted !== nextProps.talkUser.pertnerPermitted) {
                return false;
            }
        }
        if (prevProps.selected !== nextProps.selected) {
            return false;
        }
        if (prevProps.unreadCount !== nextProps.unreadCount) {
            return false;
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }
        if (prevProps.onClickUser !== nextProps.onClickUser) {
            return false;
        }
        if (prevProps.permitted !== nextProps.permitted) {
            return false;
        }

        return true;
    }
);
