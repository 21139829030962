import { ParentMailModel } from "models/Models";

export class ReceivedParentMailListResponse {
    rtnCd?: number | null = null;
    hasNext?: boolean | null = null;
    lstParentMailReceive?: ParentMailModel[] | null = null;
    lstParentMailSend?: ParentMailModel[] | null = null;
    readSize?: number | null = null;
    limitUnixTime?: number | null = null;
    prevLimitUnixTime?: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // hasNext
        if (obj.hasOwnProperty("IsNext")) {
            this.hasNext = obj.IsNext;
        }
        // lstParentMailReceive
        if (obj.hasOwnProperty("ListReceiveParentMail")) {
            if (this.lstParentMailReceive == null) {
                this.lstParentMailReceive = [];
            }
            for (let i in obj.ListReceiveParentMail) {
                let parentMail = new ParentMailModel(
                    obj.ListReceiveParentMail[i]
                );
                this.lstParentMailReceive.push(parentMail);
            }
        }
        // lstParentMailSend
        if (obj.hasOwnProperty("ListSendParentMail")) {
            if (this.lstParentMailSend == null) {
                this.lstParentMailSend = [];
            }
            for (let i in obj.ListSendParentMail) {
                let parentMail = new ParentMailModel(obj.ListSendParentMail[i]);
                this.lstParentMailSend.push(parentMail);
            }
        }
        // readSize
        if (obj.hasOwnProperty("ReadSize")) {
            this.readSize = obj.ReadSize;
        }
        // limitUnixTime
        if (obj.hasOwnProperty("LIMIT_UNIXTIME")) {
            this.limitUnixTime = obj.LIMIT_UNIXTIME;
        }
        // prevLimitUnixTime
        if (obj.hasOwnProperty("PREV_LIMIT_UNIXTIME")) {
            this.prevLimitUnixTime = obj.PREV_LIMIT_UNIXTIME;
        }
    }
}
