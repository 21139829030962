import { TalkHistoryModel } from "models/Models";

export class TalkHistoryResponse {
    rtnCd: number | null = null;
    talkHistory: TalkHistoryModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // talkHistory
        if (obj.hasOwnProperty("TALK_HISTORY")) {
            this.talkHistory = new TalkHistoryModel(obj.TALK_HISTORY);
        }
    }
}
