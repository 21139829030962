import { GalleryModel } from "models/Models";

export class GalleryUploadResponse {
    rtnCd?: number | null = null;
    gallery?: GalleryModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // gallery
        if (obj.hasOwnProperty("Gallery")) {
            this.gallery = new GalleryModel(obj.Gallery);
        }
    }
}
