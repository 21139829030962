import { createSelector } from "reselect";

const unreadCountSelector = (state) => state.unreadCount;

export const getUnreadCountMail = createSelector(
    [unreadCountSelector],
    (state) => state.mail
);
export const getUnreadCountTalk = createSelector(
    [unreadCountSelector],
    (state) => state.talk
);
