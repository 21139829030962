import { TalkMessageModel } from "models/Models";

export class TalkMessageListResponse {
    rtnCd: number | null = null;
    hasNext: boolean = false;
    lstTalkMessage: TalkMessageModel[] | null = null;
    readSize: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // hasNext
        if (obj.hasOwnProperty("HasPrev")) {
            this.hasNext = obj.HasPrev;
        }
        // lstTalkMessage
        if (obj.hasOwnProperty("TalkMessageList")) {
            if (this.lstTalkMessage == null) {
                this.lstTalkMessage = [];
            }
            for (let i in obj.TalkMessageList) {
                let talkMessage = new TalkMessageModel(obj.TalkMessageList[i]);
                this.lstTalkMessage.push(talkMessage);
            }
        }
        // readSize
        if (obj.hasOwnProperty("ReadSize")) {
            this.readSize = obj.ReadSize;
        }
    }
}
