export class IsBlockedResponse {
    rtnCd: number | null = null;
    blocked: boolean | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // blocked
        if (obj.hasOwnProperty("BLOCKED")) {
            this.blocked = obj.BLOCKED;
        }
    }
}
