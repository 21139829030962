import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import {
    TalkPermissionResponse,
} from "api/responses/Responses";

export class TalkPermissionRequest {
    // トーク許可更新
    static async updatePermission(user: UserModel, partner: UserModel, permitted: number) {
        if (user == null || user.id == null || user.bearerToken == null || partner.id == null) {
            return;
        }
        let url = UrlConstants.URL_UPDATE_TALK_PERMISSION;
        url = url.replace(":user_id", String(user.id));
        url = url + "/" + String(partner.id);
        let formData = new FormData();
        formData.append("permitted", String(permitted));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkPermissionResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    // トーク許可取得
    static async getPermission(user: UserModel, partner: UserModel) {
        if (user == null || user.id == null || user.bearerToken == null || partner.id == null) {
            return;
        }
        let url = UrlConstants.URL_GET_TALK_PERMISSION;
        url = url.replace(":user_id", String(user.id));
        url = url + "/" + String(partner.id);
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkPermissionResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
