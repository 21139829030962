import { GroupModel } from "models/Models";

export class GroupDetailResponse {
    rtnCd: number | null = null;
    group: GroupModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // group
        if (obj.hasOwnProperty("Group")) {
            this.group = new GroupModel(obj.Group);
        }
    }
}
