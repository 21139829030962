import React from "react";
import Draggable from "react-draggable";
import { Box, Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import { PaperProps } from "@mui/material/Paper";
import { UserModel, GalleryModel, AttachedFileModel } from "models/Models";
import { AppConstants } from "constants/Constants";
import CloseButton from "assets/images/cross_mark.png";
import Utility from "utils/Utility";

type Props = {
    user: UserModel | null;
    gallery?: GalleryModel;
    attachedFile?: AttachedFileModel;
    partnerId?: number;
    file?: File;
    onClose: () => void;
    open: boolean;
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper
                {...props}
                style={{
                    margin: 0,
                    maxWidth: "none",
                }}
            />
        </Draggable>
    );
}

export const ImageComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== ImageComponent");
        // URL
        const [url, setUrl] = React.useState<string>();

        /**
         * useEffect
         */
        React.useEffect(() => {
            if (props.open) {
                setImageUrl();
            } else {
                handleClose();
            }
        }, [props.open]);

        /**
         * 本ダイアログを閉じる
         */
        const handleClose = () => {
            props.onClose();
        };
        /**
         * 画像URLをセット
         */
        async function setImageUrl() {
            const url = await getImageUrl();
            if (url != null) {
                setUrl(url);
            }
        }

        /**
         * 画像URL取得
         * @returns
         */
        async function getImageUrl() {
            const attachedFile = props.attachedFile;
            const gallery = props.gallery;
            const file = props.file;
            if (file != null) {
                return window.URL.createObjectURL(file);
            } else if (gallery !== undefined) {
                return Utility.getGalleryOriginalUrl(gallery);
            } else if (attachedFile !== undefined) {
                return Utility.getAttachedFileOriginalUrl(
                    attachedFile,
                    props.partnerId
                );
            }
        }
        /**
         * レンダー
         */
        return (
            <Box
                className="component imageComponent"
                sx={{
                    display: props.open ? "block" : "none",
                    position: "fixed",
                    top: AppConstants.HEADER_HEIGHT,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: {
                        xs: "100vw",
                        md: "70vw",
                    },
                    height: {
                        xs: `calc(100vh - ${AppConstants.HEADER_HEIGHT} - ${AppConstants.FOOTER_HEIGHT})`,
                        md: "50vw",
                    },
                    margin: {
                        xs: 0,
                        md: "auto",
                    },
                    zIndex: 2000,
                    overflow: "auto",
                }}
            >
                {((props.gallery != null && props.gallery.fileName != null) ||
                    (props.attachedFile != null &&
                        props.attachedFile.fileName != null) ||
                    (props.file != null && url != null)) && (
                    <>
                        {
                            // スマホ用
                        }
                        <Box
                            component="div"
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                display: {
                                    xs: "block",
                                    md: "none",
                                },
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    position: "fixed",
                                    top: AppConstants.HEADER_HEIGHT,
                                    left: 0,
                                    width: "100%",
                                    height: AppConstants.NAVBAR_HEIGHT,
                                    lineHeight: AppConstants.NAVBAR_HEIGHT,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "1.2rem",
                                    color: "#FFFFFF",
                                    backgroundColor: "#000000",
                                    paddinx: 0,
                                    overflow: "hidden",
                                    zIndex: 2010,
                                }}
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                CLOSE
                            </Box>
                            <Box
                                component="img"
                                src={url}
                                sx={{
                                    minWidth: "100%",
                                    minHeight: "100%",
                                    objectFit: "contain",
                                    position: "relative",
                                    marginTop: AppConstants.NAVBAR_HEIGHT,
                                    backgroundColor: "#000000",
                                }}
                            />
                        </Box>
                        {
                            // スマホ以外用
                        }
                        <Box
                            component="div"
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: {
                                    xs: "none",
                                    md: "block",
                                },
                            }}
                        >
                            <Dialog
                                className="fullscreen dialog"
                                fullScreen={true}
                                fullWidth={true}
                                open={props.open}
                                onClose={handleClose}
                                sx={{
                                    display: {
                                        xs: "none",
                                        md: "block",
                                    },
                                    zIndex: 2000,
                                }}
                            >
                                <DialogTitle
                                    className="dialog-title"
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        padding: 0,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={CloseButton}
                                        sx={{
                                            objectFit: "contain",
                                            width: "30px",
                                            height: "30px",
                                            marginRight: "10px",
                                            cursor: "default",
                                        }}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    />
                                </DialogTitle>
                                <DialogContent
                                    style={{
                                        padding: 0,
                                        position: "relative",
                                        lineHeight: 0,
                                        backgroundColor: "#cccccc",
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={url}
                                        sx={{
                                            width: "100%",
                                            minWidth: "100%",
                                            height: "100%",
                                            minHeight: "100%",
                                            objectFit: "contain",
                                        }}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.user !== nextProps.user) {
            return false;
        }
        if (prevProps.gallery !== nextProps.gallery) {
            return false;
        }
        if (prevProps.gallery != null && nextProps.gallery != null) {
            if (prevProps.gallery.id !== nextProps.gallery.id) {
                return false;
            }
        }
        if (prevProps.attachedFile !== nextProps.attachedFile) {
            return false;
        }
        if (prevProps.attachedFile != null && nextProps.attachedFile != null) {
            if (prevProps.attachedFile.id !== nextProps.attachedFile.id) {
                return false;
            }
        }
        if (prevProps.partnerId !== nextProps.partnerId) {
            return false;
        }
        if (prevProps.file !== nextProps.file) {
            return false;
        }
        if (prevProps.open !== nextProps.open) {
            return false;
        }
        if (prevProps.onClose !== nextProps.onClose) {
            return false;
        }

        return true;
    }
);
