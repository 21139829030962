import axios, { AxiosResponse } from "axios";
import { AppConstants } from "constants/AppConstants";
import { UrlConstants } from "constants/UrlConstants";
import { CountryCodeResponse } from "api/responses/Responses";

export class CountryCodeRequest {
    /**
     * アクションキー取得
     * @returns
     */
    static async getCountryCode(): Promise<CountryCodeResponse | null> {
        let url = UrlConstants.URL_GET_COUNTRY_CODE;
        let result = null;
        try {
            let res: AxiosResponse | null = null;
            res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${AppConstants.IPINFO_ACCESS_TOKEN}`,
                },
            });
            if (res != null) {
                result = new CountryCodeResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
