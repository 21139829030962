export class LinkInfoModel {
    screen: string | null = null;
    messageBoardId: number | null = null;
    tweetUserId: number | null = null;
    userId: number | null = null;
    groupId: number | null = null;
    groupName: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // screen
        if (obj.hasOwnProperty("screen")) {
            this.screen = obj.screen;
        } else if (obj.hasOwnProperty("Screen")) {
            this.screen = obj.Screen;
        }
        // messageBoardId
        if (obj.hasOwnProperty("messageBoardId")) {
            this.messageBoardId = obj.messageBoardId;
        } else if (obj.hasOwnProperty("MessageBoardId")) {
            this.messageBoardId = obj.MessageBoardId;
        }
        // tweetUserId
        if (obj.hasOwnProperty("tweetUserId")) {
            this.tweetUserId = obj.tweetUserId;
        } else if (obj.hasOwnProperty("TweetUserId")) {
            this.tweetUserId = obj.TweetUserId;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // groupId
        if (obj.hasOwnProperty("groupId")) {
            this.groupId = obj.groupId;
        } else if (obj.hasOwnProperty("GroupId")) {
            this.groupId = obj.GroupId;
        }
        // groupName
        if (obj.hasOwnProperty("groupName")) {
            this.groupName = obj.groupName;
        } else if (obj.hasOwnProperty("GroupName")) {
            this.groupName = obj.GroupName;
        }
    }
}
