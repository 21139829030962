export const RTC_INFO = "RTC_INFO";

export const RtcInfoAction = (rtcInfoState) => {
    let payload = {};
    if (Object.keys(rtcInfoState).indexOf("senderId") > -1) {
        payload["senderId"] = rtcInfoState.senderId;
    }
    if (Object.keys(rtcInfoState).indexOf("receiverId") > -1) {
        payload["receiverId"] = rtcInfoState.receiverId;
    }
    if (Object.keys(rtcInfoState).indexOf("senderTalkType") > -1) {
        payload["senderTalkType"] = rtcInfoState.senderTalkType;
    }
    if (Object.keys(rtcInfoState).indexOf("receiverTalkType") > -1) {
        payload["receiverTalkType"] = rtcInfoState.receiverTalkType;
    }

    return {
        type: RTC_INFO,
        payload: payload,
    };
};
