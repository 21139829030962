import { UserModel } from "models/Models";

export class HistoryUserList {
    index: number = 0;
    hasNext: boolean = true;
    list: UserModel[] = [];
    readSize: number = 100;
    scrollPos: number = 0;
    expiredTime: number = 0;
}
