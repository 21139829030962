import React from "react";
import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { UserModel, AttachedFileModel } from "models/Models";
import { ImageComponent, VideoComponent } from "components/Components";
import Utility from "utils/Utility";

import "styles/components/AttachedFileListForChatComponent.scss";

type Props = {
    loginUser: UserModel | null;
    lstAttachedFile: AttachedFileModel[];
    isPartner: boolean
    partnerId?: number | null;
};

export const AttachedFileListForChatComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== AttachedFileListForChatComponent");
        /***** useState */
        const [selectedAttachedFile, setSelectedAttachedFile] =
            React.useState<AttachedFileModel>();
        const [showImage, setShowImage] = React.useState<boolean>(false);
        const [showVideo, setShowVideo] = React.useState<boolean>(false);

        /**
         * 画像クリック時
         * @param attachedFile
         * @returns
         */
        function onClickImage(attachedFile: AttachedFileModel) {
            if (attachedFile.fileName == null) {
                return;
            }
            setSelectedAttachedFile(attachedFile);
            setShowImage(true);
        }
        /**
         * 動画サムネイルクリック時
         * @param attachedFile
         * @returns
         */
        function onClickVideo(attachedFile: AttachedFileModel) {
            if (attachedFile.fileName == null) {
                return;
            }
            setSelectedAttachedFile(attachedFile);
            setShowVideo(true);
        }
        /**
         * 添付ファイルの受信者ID取得
         * @param attachedFile
         * @returns
         */
        function getRceeiverId(
            attachedFile: AttachedFileModel
        ): number | undefined {
            if (props.loginUser == null || props.loginUser.id == null) {
                return undefined;
            }
            if (attachedFile.userId === props.loginUser.id) {
                if (props.partnerId == null) {
                    return undefined;
                } else {
                    return props.partnerId;
                }
            } else {
                return props.loginUser.id;
            }
        }
        /**
         * インナーコンポーネント取得
         * @returns
         */
        const InnerContents = () => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return <></>;
            }
            const loginUserId = props.loginUser.id;
            return (
                <>
                    {props.lstAttachedFile.map((attachedFile) => {
                        const fileName = attachedFile.fileName;
                        let isImage = true;
                        if (fileName != null) {
                            isImage = Utility.isImage(fileName);
                        }
                        let url = null;
                        if (attachedFile.userId === props.partnerId) {
                            url = Utility.getAttachedFileSampleUrl(
                                attachedFile,
                                loginUserId
                            );
                        } else {
                            url = Utility.getAttachedFileSampleUrl(
                                attachedFile,
                                props.partnerId
                            );
                        }
                        return (
                            <React.Fragment key={attachedFile.id}>
                                {fileName != null && url != null && (
                                    <>
                                        {
                                            // 画像の場合
                                        }
                                        {isImage && (
                                            <Box
                                                className="imageWrapper"
                                                onClick={() => {
                                                    onClickImage(attachedFile);
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    src={url}
                                                />
                                            </Box>
                                        )}
                                        {
                                            // 動画の場合
                                        }
                                        {!isImage && (
                                            <Box
                                                component="div"
                                                className="videoThumbnailWrapper"
                                                onClick={() => {
                                                    onClickVideo(attachedFile);
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    className="thumbnail"
                                                    src={url}
                                                />
                                                <Box
                                                    component="img"
                                                    className="play-icon"
                                                    src="/images/btn_play.png"
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {selectedAttachedFile != null &&
                        selectedAttachedFile.fileName != null &&
                        Utility.isImage(selectedAttachedFile.fileName) && (
                            <ImageComponent
                                user={props.loginUser}
                                attachedFile={selectedAttachedFile}
                                onClose={() => {
                                    setShowImage(false);
                                }}
                                open={showImage}
                                partnerId={getRceeiverId(selectedAttachedFile)}
                            />
                        )}
                    {selectedAttachedFile != null &&
                        selectedAttachedFile.fileName != null &&
                        !Utility.isImage(selectedAttachedFile.fileName) && (
                            <VideoComponent
                                user={props.loginUser}
                                attachedFile={selectedAttachedFile}
                                onClose={() => {
                                    setShowVideo(false);
                                }}
                                open={showVideo}
                                partnerId={getRceeiverId(selectedAttachedFile)}
                            />
                        )}
                </>
            );
        };
        /**
         * レンダー
         */
        return (
            <Box className={props.isPartner ? "component AttachedFileListForChatComponent partner" : "component AttachedFileListForChatComponent"}>
                <InnerContents />
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return true;
        }
        const prevAttachedFileList = prevProps.lstAttachedFile;
        const nextAttachedFileList = nextProps.lstAttachedFile;
        if (prevAttachedFileList != null && nextAttachedFileList != null) {
            if (prevAttachedFileList.length !== nextAttachedFileList.length) {
                return false;
            }
            for (let i = 0; i < prevAttachedFileList.length; i++) {
                for (let j = 0; j < nextAttachedFileList.length; j++) {
                    if (
                        prevAttachedFileList[i].id !==
                        nextAttachedFileList[j].id
                    ) {
                        return false;
                    }
                }
            }
        }
        if (prevProps.isPartner !== nextProps.isPartner) {
            return false;
        }
        if (prevProps.partnerId !== nextProps.partnerId) {
            return false;
        }
        return true;
    }
);
