export class CountryModel {
    id: number | null = null;
    code: string | null = null;
    nameJa: string | null = null;
    nameEn: string | null = null;
    nameCn: string | null = null;
    nameTw: string | null = null;
    nameEs: string | null = null;
    nameDe: string | null = null;
    nameIt: string | null = null;
    nameRu: string | null = null;
    selected: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // code
        if (obj.hasOwnProperty("code")) {
            this.code = obj.code;
        } else if (obj.hasOwnProperty("Code")) {
            this.code = obj.Code;
        }
        // nameJa
        if (obj.hasOwnProperty("nameJa")) {
            this.nameJa = obj.nameJa;
        } else if (obj.hasOwnProperty("NameJa")) {
            this.nameJa = obj.NameJa;
        }
        // nameEn
        if (obj.hasOwnProperty("nameEn")) {
            this.nameEn = obj.nameEn;
        } else if (obj.hasOwnProperty("NameEn")) {
            this.nameEn = obj.NameEn;
        }
        // nameCn
        if (obj.hasOwnProperty("nameCn")) {
            this.nameCn = obj.nameCn;
        } else if (obj.hasOwnProperty("NameCn")) {
            this.nameCn = obj.NameCn;
        }
        // nameTw
        if (obj.hasOwnProperty("nameTw")) {
            this.nameTw = obj.nameTw;
        } else if (obj.hasOwnProperty("NameTw")) {
            this.nameTw = obj.NameTw;
        }
        // nameEs
        if (obj.hasOwnProperty("nameEs")) {
            this.nameEs = obj.nameEs;
        } else if (obj.hasOwnProperty("NameEs")) {
            this.nameEs = obj.NameEs;
        }
        // nameDe
        if (obj.hasOwnProperty("nameDe")) {
            this.nameDe = obj.nameDe;
        } else if (obj.hasOwnProperty("NameDe")) {
            this.nameDe = obj.NameDe;
        }
        // nameIt
        if (obj.hasOwnProperty("nameIt")) {
            this.nameIt = obj.nameIt;
        } else if (obj.hasOwnProperty("NameIt")) {
            this.nameIt = obj.NameIt;
        }
        // nameRu
        if (obj.hasOwnProperty("nameRu")) {
            this.nameRu = obj.nameRu;
        } else if (obj.hasOwnProperty("NameRu")) {
            this.nameRu = obj.NameRu;
        }
    }
}
