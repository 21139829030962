import React from "react";
import { FormattedMessage } from "react-intl";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { UserModel } from "models/Models";
import { AppConstants } from "constants/AppConstants";
import { SelectTagComponent } from "components/Components";

interface Props {
    loginUser: UserModel | null;
    openDialog: boolean;
    onClose: () => void;
    onCancel: () => void;
    onOk: () => void;
}

export const SelectTagDialog: React.FC<Props> = (props) => {
    // Utility.log("===== SelectTagDialog");
    return (
        <Dialog
            className="dialog"
            open={props.openDialog}
            onClose={props.onClose}
            sx={{
                width: "100%",
                maxWidth: {
                    xs: "none",
                    sm: "800px",
                },
                margin: "auto",
            }}
            PaperProps={{
                sx: {
                    margin: "auto",
                    maxWidth: "none",
                    width: {
                        xs: "95%",
                        sm: "80%",
                    },
                },
            }}
        >
            <DialogTitle
                className="dialog-title"
                sx={{
                    textAlign: "center",
                    padding: "5px 0",
                }}
            >
                <FormattedMessage id={"interests"} />
            </DialogTitle>
            <DialogContent
                className="dialog-content"
                sx={{
                    padding: {
                        xs: "0 10px 10px 10px",
                        sm: "0 20px 10px 20px",
                    },
                    backgroundColor: AppConstants.BG_COLOR_DIALOG,
                }}
            >
                <SelectTagComponent
                    loginUser={props.loginUser}
                    onCancel={props.onCancel}
                    onOk={props.onOk}
                />
            </DialogContent>
        </Dialog>
    );
};
