import { ParentMailModel } from "models/Models";

export class HistorySentMailList {
    limitUnixTime: number = 0;
    prevLimitUnixTime: number = 0;
    hasNext: boolean = true;
    list: ParentMailModel[] = [];
    readSize: number = 100;
    scrollPos: number = 0;
    expiredTime: number = 0;
}
