export class AlbumTemplateModel {
    id: number | null = null;
    name: string | null = null;
    orderNo: number | null = null;
    plan: number | null = null;
    // selected: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // name
        if (obj.hasOwnProperty("name")) {
            this.name = obj.name;
        } else if (obj.hasOwnProperty("Name")) {
            this.name = obj.Name;
        }
        // orderNo
        if (obj.hasOwnProperty("orderNo")) {
            this.orderNo = obj.orderNo;
        } else if (obj.hasOwnProperty("OrderNo")) {
            this.orderNo = obj.OrderNo;
        }
        // plan
        if (obj.hasOwnProperty("plan")) {
            this.plan = obj.plan;
        } else if (obj.hasOwnProperty("Plan")) {
            this.plan = obj.Plan;
        }
    }
}
