export const HISTORY = "HISTORY";
export const HistoryAction = (historyState) => {
    let payload = {};
    if (historyState.hasOwnProperty("userListHistory")) {
        payload["userListHistory"] = historyState.userListHistory;
    }
    if (historyState.hasOwnProperty("receivedMailListHistory")) {
        payload["receivedMailListHistory"] =
            historyState.receivedMailListHistory;
    }
    if (historyState.hasOwnProperty("sentMailListHistory")) {
        payload["sentMailListHistory"] = historyState.sentMailListHistory;
    }

    return {
        type: HISTORY,
        payload: payload,
    };
};
