import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import {
    JoinGroupListResponse,
    JoinGroupResponse,
    JoinGroupApplyResponse,
    GroupInviteResponse,
    GroupLeaveResponse,
    JoinGroupAcceptInvitationResponse,
    JoinGroupDeclineInvitationResponse,
    JoinGroupWithdrawApplyingResponse,
    JoinGroupApproveApplicationResponse,
    JoinGroupDeclineApplicationResponse,
    JoinGroupWithdrawInvitingResponse,
    GroupPurgeResponse,
} from "api/responses/Responses";
import { UserModel } from "models/UserModel";

export class JoinGroupRequest {
    /**
     * グループ加入
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async join(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_JOIN_GROUP.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ加入申請
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async apply(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_APPLY_JOIN_GROUP.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupApplyResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 加入申請を取り下げる
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async withdrawApplying(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url =
            UrlConstants.URL_GROUP_CANCEL_JOIN_GROUP_APPLICATION.replace(
                ":id",
                String(group_id)
            );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null && message.length > 0) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupWithdrawApplyingResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ招待受諾
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async acceptInvitation(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_ACCEPT_INVITATION.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupAcceptInvitationResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ招待を拒否
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async declineInvitation(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_REJECT_INVITATION.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupDeclineInvitationResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 加入申請を承認
     * @param user
     * @param applicant_id
     * @param group_id
     * @param join_group_id
     * @returns
     */
    static async approveApplication(
        user: UserModel,
        applicant_id: number,
        group_id: number,
        join_group_id: number,
        message?: string | undefined | null
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_ACCEPT_APPLICATION.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        formData.append("applicant", String(applicant_id));
        formData.append("joinGroupId", String(join_group_id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupApproveApplicationResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 加入申請を拒否
     * @param user
     * @param applicant_id
     * @param group_id
     * @param join_group_id
     * @param message
     * @returns
     */
    static async declineApplication(
        user: UserModel,
        applicant_id: number,
        group_id: number,
        join_group_id: number,
        message?: string | undefined | null
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_REJECT_APPLICATION.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        formData.append("applicantId", String(applicant_id));
        formData.append("joinGroupId", String(join_group_id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupDeclineApplicationResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 招待を取り下げる
     * @param user
     * @param group_id
     * @param target_id
     * @returns
     */
    static async withdrawInviting(
        user: UserModel,
        group_id: number,
        target_id: number,
        message?: string | undefined | null
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url = UrlConstants.URL_GROUP_CANCEL_INVITATION.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("user_id", String(user.id));
        formData.append("target_id", String(target_id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupWithdrawInvitingResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 加入グループリスト取得(申請中、招待中含む)
     * @param user_id
     * @param targetId
     * @returns
     */
    static async getJoinGroupList(user: UserModel, targetId: number) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        const url =
            UrlConstants.URL_USER_JOIN_GROUP_LIST.replace(
                ":id",
                String(user.id)
            ) +
            "?target_id=" +
            String(targetId);
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new JoinGroupListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 招待
     * @param user
     * @param target_id
     * @param group_id_list
     * @param message
     * @returns
     */
    static async invite(
        user: UserModel,
        target_id: number,
        group_id_list: string[],
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_USER_INVITE_GROUP.replace(
            ":id",
            String(user.id)
        );
        url = url + "?group_ids=" + group_id_list.join(",");
        let formData = new FormData();
        formData.append("targetId", String(target_id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupInviteResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ追放
     * @param user
     * @param group_id
     * @param target_id
     * @param message
     * @returns
     */
    static async purge(
        user: UserModel,
        group_id: number,
        target_id: number,
        message?: string | undefined | null
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_GROUP_PURGE_MEMBER.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        formData.append("targetId", String(target_id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupPurgeResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ脱退
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async leave(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_USER_LEAVE_GROUP.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupLeaveResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
