import { UserModel } from "./UserModel";

export class TalkPermissionModel {
    id: number | null = null;
    userId: number | null = null;
    partnerId: number | null = null;
    permitted: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // partnerId
        if (obj.hasOwnProperty("partnerId")) {
            this.partnerId = obj.partnerId;
        } else if (obj.hasOwnProperty("PartnerId")) {
            this.partnerId = obj.PartnerId;
        }
        // permitted
        if (obj.hasOwnProperty("permitted")) {
            this.permitted = obj.permitted;
        } else if (obj.hasOwnProperty("Permitted")) {
            this.permitted = obj.Permitted;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
    }
}
