import * as Actions from "./actions";
import initialState from "../store/initialState";

export const CountryListReducer = (
    state = initialState.countryList,
    action
) => {
    switch (action.type) {
        case Actions.COUNTRY_LIST:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
