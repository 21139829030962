export class CountryCodeResponse {
    country?: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // country
        if (obj.hasOwnProperty("country")) {
            this.country = obj.country;
        }
    }
}
