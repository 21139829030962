export const NETWORK = "NETWORK";

export const NetworkAction = (networkState) => {
    return {
        type: NETWORK,
        payload: {
            connected: networkState.connected,
        },
    };
};
