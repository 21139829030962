import { createSelector } from "reselect";

const userSelector = (state) => state.user;

export const getLoginUser = createSelector(
    [userSelector],
    (state) => state.loginUser
);

export const getFollowUserList = createSelector(
    [userSelector],
    (state) => state.lstFollowUser
);

export const getBlockUserList = createSelector(
    [userSelector],
    (state) => state.lstBlockUser
);

export const getOwnGroupList = createSelector(
    [userSelector],
    (state) => state.lstOwnGroup
);

export const getUserTagList = createSelector(
    [userSelector],
    (state) => state.lstTag
);

export const getUserCustomTagList = createSelector(
    [userSelector],
    (state) => state.lstCustomTag
);

export const getUserInviteList = createSelector(
    [userSelector],
    (state) => state.lstInvite
);
