import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import {
    ResponseListResponse,
    ResponsePostResponse,
} from "api/responses/Responses";

export class ResponseRequest {
    /**
     * レスポンスリスト取得
     * @returns
     */
    static async getList(threadId: number) {
        let url = UrlConstants.URL_RESPONSE_LIST.replace(
            ":thread_id",
            String(threadId)
        );
        let result = null;
        try {
            const res = await axios.get(url, { withCredentials: true });
            if (res != null) {
                result = new ResponseListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * レスポンス投稿
     * @param threadId
     * @param user
     * @returns
     */
    static async post(threadId: number, body: string, user: UserModel) {
        let formData = new FormData();
        formData.append("user_id", String(user.id));
        formData.append("thread_id", String(threadId));
        formData.append("body", body);
        const bearerToken = user.bearerToken;
        const url = UrlConstants.URL_RESPONSE_POST.replace(
            ":thread_id",
            String(threadId)
        );

        let result = null;
        try {
            const res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            });
            if (res != null) {
                result = new ResponsePostResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
