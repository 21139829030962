import { createSelector } from "reselect";

const selectedItemSelector = (state) => state.selectedItem;

export const getSelectedUserId = createSelector(
    [selectedItemSelector],
    (state) => state.userId
);
export const getSelectedUser = createSelector(
    [selectedItemSelector],
    (state) => state.user
);
export const getSelectedGroup = createSelector(
    [selectedItemSelector],
    (state) => state.group
);
export const getSelectedParentMail = createSelector(
    [selectedItemSelector],
    (state) => state.parentMail
);
export const getSelectedChildMail = createSelector(
    [selectedItemSelector],
    (state) => state.childMail
);
export const getSelectedTalkUser = createSelector(
    [selectedItemSelector],
    (state) => state.talkUser
);
export const getSelectedAlbum = createSelector(
    [selectedItemSelector],
    (state) => state.album
);
export const getSelectedUserList = createSelector(
    [selectedItemSelector],
    (state) => state.lstUser
);
export const getSelectedGroupList = createSelector(
    [selectedItemSelector],
    (state) => state.lstGroup
);
export const getSelectedCountry = createSelector(
    [selectedItemSelector],
    (state) => state.country
);
export const getSelectedCategory = createSelector(
    [selectedItemSelector],
    (state) => state.category
);
