import { UserModel, UserProfileData } from "models/Models";

export class UserProfileResponse {
    rtnCd?: number | null = null;
    targetProfile?: UserProfileData | null = null;
    targetUser?: UserModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // targetProfile
        if (obj.hasOwnProperty("TargetProfile")) {
            this.targetProfile = new UserProfileData(obj.TargetProfile);
        }
        // targetUser
        if (obj.hasOwnProperty("TargetUser")) {
            this.targetUser = new UserModel(obj.TargetUser);
        }
    }
}
