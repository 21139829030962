export class GoogleAuthenticationResponse {
    rtnCd?: number | null = null;
    email?: string | null = null;
    familyName?: string | null = null;
    givenName?: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // email
        if (obj.hasOwnProperty("Email")) {
            this.email = obj.Email;
        }
        // familyName
        if (obj.hasOwnProperty("FamilyName")) {
            this.familyName = obj.FamilyName;
        }
        // givenName
        if (obj.hasOwnProperty("GivenName")) {
            this.givenName = obj.GivenName;
        }
    }
}
