import React from "react";
import interact from 'interactjs'

type Partial<T> = {
    [P in keyof T]?: T[P];
};

const initPosition = {
    x: 0,
    y: 0
}

export function useInteractJS(position?: Partial<typeof initPosition>) {
    const interactRef = React.useRef(null)    // <= HTML ELEMENTを取得
      // 引数で指定したpositionを初期値として、Stateを作る
    const [_position, setPosition] = React.useState({
        ...initPosition,
        ...position
    })

    // interactJSを有効化する
    const enable = React.useCallback(() => {
        if (interactRef == null || interactRef.current == null) {
            return;
        }
        let { x, y } = _position
        interact((interactRef.current as unknown) as HTMLElement)
        // ドラッグでコンポーネントを動かすための処理を追加
        .draggable({
            inertia: false
        })
        .on('dragmove', event => {
            x += event.dx
            y += event.dy
            // ドラッグ後の座標をstateに保存する
            setPosition({
                x,
                y
            })
        })
    }, [
        _position,
        interactRef,
    ]);

    // interactJSを無効化する
    const disable = () => {
        if (interactRef == null || interactRef.current == null) {
            return;
        }
        interact((interactRef.current as unknown) as HTMLElement).unset()
    }

    // マウント時にRefで取得した要素にinteractJSを作用させる。
    React.useEffect(() => {
        if (interactRef == null || interactRef.current == null) {
            return;
        }
        enable()
    }, [
        interactRef, 
        enable
    ]);
    // アンマウント時にはunsetする
    React.useEffect(() => {
        return disable
    }, []);

    return {
        ref: interactRef,
        // 返り値にCSSのスタイルを追加する。このスタイルを動かしたいコンポーネントに適用することで、コンポーネントが実際に動くようになる
        style: {
            transform: `translate3D(${_position.x}px, ${_position.y}px, 0)`,
        },
        enable: enable,
    }
}
