export const TIME_DIFFERENCE = "TIME_DIFFERENCE";

export const TimeDifferenceAction = (timeDifferenceState) => {
    return {
        type: TIME_DIFFERENCE,
        payload: {
            difference: timeDifferenceState.difference,
        },
    };
};
