export const WORKING_ITEM = "WORKING_ITEM";

export const WorkingItemAction = (workingItemState) => {
    let payload = {};
    if (Object.keys(workingItemState).indexOf("register") > -1) {
        payload["register"] = workingItemState.register;
    }
    if (Object.keys(workingItemState).indexOf("album") > -1) {
        payload["album"] = workingItemState.album;
    }
    if (Object.keys(workingItemState).indexOf("profile") > -1) {
        payload["profile"] = workingItemState.profile;
    }
    if (Object.keys(workingItemState).indexOf("mail") > -1) {
        payload["mail"] = workingItemState.mail;
    }

    return {
        type: WORKING_ITEM,
        payload: payload,
    };
};
