import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import {
    GroupListResponse,
    GroupDetailResponse,
    GroupChatHistoryResponse,
    GroupCreateResponse,
    GroupSaveResponse,
    GroupMemberListResponse,
    GroupMemberListAllResponse,
    GroupDisbandResponse,
} from "api/responses/Responses";
import { UserModel } from "models/UserModel";
import { SearchGroupCondition } from "models/SearchGroupCondition";
import { GroupModel } from "models/GroupModel";

export class GroupRequest {
    /**
     * グループ作成
     * @param user
     * @param group
     */
    static async create(user: UserModel, group: GroupModel) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        if (group.name == null) {
            return;
        }
        const url = UrlConstants.URL_USER_CREATE_GROUP.replace(
            ":id",
            String(user.id)
        );
        let formData = new FormData();
        formData.append("name", group.name);
        if (group.profile != null) {
            formData.append("profile", group.profile);
        }
        formData.append("public", String(group.open));
        formData.append("autoJoin", String(group.autoJoin));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupCreateResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ保存
     * @param user
     * @param group
     */
    static async save(user: UserModel, group: GroupModel) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        if (group.name == null) {
            return;
        }
        let url = UrlConstants.URL_GROUP_UPDATE.replace(
            ":id",
            String(group.id)
        );
        url = url + "?user_id=" + String(user.id);
        if (group.name != null) {
            url = url + "&name=" + group.name;
        }
        if (group.open != null) {
            url = url + "&public=" + String(group.open);
        }
        if (group.autoJoin != null) {
            url = url + "&autoJoin=" + String(group.autoJoin);
        }
        if (group.profile != null) {
            url = url + "&profile=" + group.profile;
        }
        let result = null;
        try {
            let res = await axios.put(url, null, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupSaveResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループリスト取得
     * @returns
     */
    static async getGroupList(
        user_id: number = 0,
        country_cd: string = "99",
        index: number
    ) {
        let url = UrlConstants.URL_PUBLIC_GROUP_LIST;
        url = url + "?country_cd=" + country_cd;
        url = url + "&user_id=" + String(user_id);
        url = url + "&index=" + String(index);
        let result = null;
        try {
            let res = await axios.get(url, { withCredentials: true });
            if (res != null) {
                result = new GroupListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループリスト検索
     * @returns
     */
    static async search(
        user_id: number = 0,
        condition: SearchGroupCondition,
        index: number
    ) {
        let url = UrlConstants.URL_GROUP_SEARCH;
        url = url + "?CountryCd=" + condition.countryCd;
        url = url + "&user_id=" + String(user_id);
        url = url + "&index=" + String(index);
        let formData = new FormData();
        if (condition.groupName == null || condition.groupName.length === 0) {
            formData.append("group_name", "");
        } else {
            formData.append("group_name", condition.groupName);
        }
        if (
            condition.groupOwnerName == null ||
            condition.groupOwnerName.length === 0
        ) {
            formData.append("owner_name", "");
        } else {
            formData.append("owner_name", condition.groupOwnerName);
        }

        let result = null;
        try {
            let res = await axios.put(url, formData, { withCredentials: true });
            if (res != null) {
                result = new GroupListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ詳細取得
     * @param group_id
     * @returns
     */
    static async getDetail(group_id: number) {
        const url = UrlConstants.URL_GROUP_DETAIL.replace(
            ":id",
            String(group_id)
        );
        let result = null;
        try {
            let res = await axios.get(url, { withCredentials: true });
            if (res != null) {
                result = new GroupDetailResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * チャット履歴取得
     * @param user
     * @param group_id
     * @param timestamp
     * @returns
     */
    static async getChatHistory(
        user: UserModel,
        group_id: number,
        limitUnixTime: number,
        prevLimitUnixTime: number
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.V3_URL_GROUP_CHAT_MESSAGES.replace(
            ":id",
            String(group_id)
        );
        url = url + "?limit_unixtime=" + String(limitUnixTime);
        url = url + "&prev_limit_unixtime=" + String(prevLimitUnixTime);
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupChatHistoryResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループメンバーリスト取得
     * @param groupId
     * @param index
     * @returns
     */
    static async getGroupMemberList(groupId: number, index: number) {
        const url =
            UrlConstants.URL_GROUP_MEMBER_LIST.replace(
                ":groupId",
                String(groupId)
            ) +
            "?index=" +
            String(index);
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
            });
            if (res != null) {
                result = new GroupMemberListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    static async getGroupMemberListAll(groupId: number) {
        const url = UrlConstants.URL_GROUP_MEMBER_LIST_ALL.replace(
            ":groupId",
            String(groupId)
        );
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
            });
            if (res != null) {
                result = new GroupMemberListAllResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * グループ解散
     * @param user
     * @param group_id
     * @param message
     * @returns
     */
    static async disband(
        user: UserModel,
        group_id: number,
        message?: string | null | undefined
    ) {
        if (user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_USER_DISMISS_GROUP.replace(
            ":id",
            String(group_id)
        );
        let formData = new FormData();
        formData.append("userId", String(user.id));
        if (message != null) {
            formData.append("message", message);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new GroupDisbandResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
