import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Card, CardMedia, CardContent, Button } from "@mui/material";
import { UserModel, GroupModel } from "models/Models";
import { DbConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";
import "styles/components/listItems/GroupMemberListItem.scss";

type Props = {
    loginUser: UserModel | null | undefined;
    user: UserModel;
    group: GroupModel | undefined;
    langCd: string;
    timeDifference: number;
    onClickPurge: () => void;
};

export const GroupMemberListItem: React.FC<Props> = (props) => {
    // Utility.log("===== GroupMemberListItem");
    /***** 定数、変数 */

    /**
     * テーマ
     */
    const theme = createTheme({
        components: {
            // Name of the component
            MuiCard: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        fontSize: "1rem",
                    },
                },
            },
        },
    });

    /**
     * オーナーのHtml要素生成
     * @returns
     */
    function getOwnerHtml() {
        return (
            <div className="owner">
                <FormattedMessage id={"group_owner"} />
            </div>
        );
    }
    /**
     * フォローのHtml要素生成
     * @returns
     */
    function getFollowHtml() {
        if (props.user.following) {
            return (
                <div className="follow">
                    <FormattedMessage id={"user_following"} />
                </div>
            );
        } else {
            return <></>;
        }
    }
    /**
     * 属性のHtml要素生成
     * @param gender
     * @param attribute
     * @returns
     */
    function getAttributeHtml() {
        let attributeLabel = Utility.getAttributeLabelKey(props.user);
        if (attributeLabel.length > 0) {
            let className = "attribute";
            if (props.user.gender === DbConstants.GENDER_MALE) {
                className = className + " male";
            } else if (props.user.gender === DbConstants.GENDER_FEMALE) {
                className = className + " female";
            } else {
                className = className + " other";
            }
            let label = Utility.getAttributeLabelKey(props.user);
            if (label != null && label.length > 0) {
                return (
                    <div className={className}>
                        <FormattedMessage id={label} />
                    </div>
                );
            } else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }
    /**
     * 国旗のHtml要素生成
     * @returns
     */
    function getUserFlagHtml() {
        if (props.user.countryCd != null && props.user.countryCd.length > 0) {
            return (
                <img
                    className="flag-image"
                    src={
                        "/images/flag/" +
                        props.user.countryCd?.toUpperCase() +
                        ".png"
                    }
                    alt="Country Flag"
                />
            );
        } else {
            return <span></span>;
        }
    }

    /**
     * レンダー
     */
    return (
        <ThemeProvider theme={theme}>
            <Card className="GroupMemberListItem">
                {
                    // ユーザアイコン
                }
                <CardMedia
                    className="card-image"
                    component="img"
                    image={
                        props.user.iconName != null &&
                        props.user.iconName.length > 0
                            ? UrlConstants.URL_S3_USER_ICON +
                              props.user.iconName
                            : "/images/no_image.png"
                    }
                />
                <CardContent className="card-content">
                    <div className="info-wrapper-1">
                        {
                            // 国旗
                            getUserFlagHtml()
                        }
                        {
                            // 属性
                            getAttributeHtml()
                        }
                    </div>
                    {
                        // 名前
                    }
                    <div className="info-wrapper-2">{props.user.name}</div>
                    {props.group != null &&
                        props.loginUser != null &&
                        props.group.ownerId != null &&
                        props.group.ownerId === props.loginUser.id &&
                        props.loginUser.id !== props.user.id && (
                            <div className="button-area">
                                <Button
                                    className="btn-purge"
                                    color="primary"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        props.onClickPurge();
                                    }}
                                >
                                    <FormattedMessage id={"btn_purge"} />
                                </Button>
                            </div>
                        )}
                </CardContent>
                <div className="user-label">
                    {props.group != null &&
                        props.group.ownerId != null &&
                        props.group.ownerId === props.user.id &&
                        // オーナーラベル
                        getOwnerHtml()}

                    {
                        // オンラインラベル
                        // getOnlineHtml()
                    }
                    {
                        // フォローラベル
                        getFollowHtml()
                    }
                </div>
                {props.user.selected && <div className="selected-layer" />}
            </Card>
        </ThemeProvider>
    );
};
