import { CategoryModel, ThreadModel } from "models/Models";

export class ThreadListResponse {
    rtnCd?: number | null = null;
    category?: CategoryModel | null = null;
    lstThread?: ThreadModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // category
        if (obj.hasOwnProperty("Category")) {
            this.category = new CategoryModel(obj.Category);
        }
        // lstThread
        if (obj.hasOwnProperty("ThreadList")) {
            if (this.lstThread == null) {
                this.lstThread = [];
            }
            for (let i in obj.ThreadList) {
                let thread = new ThreadModel(obj.ThreadList[i]);
                this.lstThread.push(thread);
            }
        }
    }
}
