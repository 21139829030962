import axios, { AxiosResponse } from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { SystemInfoResponse } from "api/responses/Responses";
import Utility from "utils/Utility";

export class SystemInfoRequest {

    /**
     * システム情報取得
     * @returns
     */
    static async getSystemInfo() {
        let url = UrlConstants.V2_URL_GET_SYSTEM_INFO;
        url = url + "?os=web";
        Utility.log("getSystemInfo url:" + url);
        let result = null;
        try {
            let res: AxiosResponse | null = null;
            res = await axios.get(url);
            if (res != null) {
                result = new SystemInfoResponse(res.data);
            }
        } catch (error) {
            alert(error);
            console.error(error);
        }
        return result;
    }
}
