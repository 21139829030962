import { MailModel } from "models/MailModel";
import { ParentMailModel } from "models/ParentMailModel";

export class MailSendResponse {
    rtnCd: number | null = null;
    parentMail: ParentMailModel | null = null;
    childMail: MailModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // parentMail
        if (obj.hasOwnProperty("PARENT_MAIL")) {
            this.parentMail = new ParentMailModel(obj.PARENT_MAIL);
        }
        // childMail
        if (obj.hasOwnProperty("CHILD_MAIL")) {
            this.childMail = new MailModel(obj.CHILD_MAIL);
        }
    }
}
