import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getReceivedMailListHistory } from "redux/Selectors";
import { HistoryAction } from "redux/Actions";

import { UserModel, MailModel, HistoryReceivedMailList } from "models/Models";
import { ReceivedChildMailComponent } from "components/Components";

type Props = {
    loginUser: UserModel | null;
};

const ReceivedChildMail: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("ReceivedChildMail IN");
        /***** 定数、変数 */
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { parentId } = useParams<{ parentId: string }>();
        const history = useSelector((state) => getReceivedMailListHistory(state) as HistoryReceivedMailList);

        /***** useState */
        const [selectedParentId, setSelectedParentId] =
            React.useState<string>("");

        /**
         * useEffect
         */
        React.useEffect(() => {
            if (parentId != null) {
                setSelectedParentId(parentId);
            }
        }, [parentId]);

        /**
         * 子メール既読
         * @returns
         */
        const readMail = React.useCallback(() => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return;
            }
            if (parentId == null) {
                return;
            }
            if (history == null || history.list == null) {
                return;
            }
            let lstParentMail = history.list;
            for (let i = 0; i < lstParentMail.length; i++) {
                let parentMail = lstParentMail[i];
                if (parentMail.id === parseInt(parentId)) {
                    if (props.loginUser.id === parentMail.receiverId) {
                        if (
                            parentMail.receiverUnreadCnt != null &&
                            parentMail.receiverUnreadCnt > 0
                        ) {
                            parentMail.receiverUnreadCnt -= 1;
                        }
                        if (parentMail.receiverUnreadCnt === 0) {
                            parentMail.read = true;
                        }
                    } else if (props.loginUser.id === parentMail.senderId) {
                        if (
                            parentMail.senderUnreadCnt != null &&
                            parentMail.senderUnreadCnt > 0
                        ) {
                            parentMail.senderUnreadCnt -= 1;
                        }
                        if (parentMail.senderUnreadCnt === 0) {
                            parentMail.read = true;
                        }
                    }
                }
            }
            dispatch(HistoryAction({ receivedMailListHistory: history }));
        }, [
            props.loginUser,
            parentId,
            history,
            dispatch,
        ]);

        /**
         * 子メール削除
         * @returns
         */
        const deleteMail = React.useCallback((child_mail: MailModel) => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return;
            }
            if (parentId == null) {
                return;
            }
            if (child_mail.receiverId == null || child_mail.senderId == null) {
                return;
            }
            if (history == null || history.list == null) {
                return;
            }
            let lstParentMail = history.list;
            let goBack = false;
            for (let i = 0; i < lstParentMail.length; i++) {
                let parentMail = lstParentMail[i];
                if (parentMail.id === parseInt(parentId)) {
                    if (props.loginUser.id === parentMail.receiverId) {
                        if (props.loginUser.id === child_mail.receiverId) {
                            if (
                                parentMail.receiverReceiveCnt != null &&
                                parentMail.receiverReceiveCnt > 0
                            ) {
                                parentMail.receiverReceiveCnt -= 1;
                            }
                            if (parentMail.receiverReceiveCnt === 0) {
                                parentMail.hidden = true;
                                goBack = true;
                            }
                            if (child_mail.read === 0) {
                                if (
                                    parentMail.receiverUnreadCnt != null &&
                                    parentMail.receiverUnreadCnt > 0
                                ) {
                                    parentMail.receiverUnreadCnt -= 1;
                                }
                                if (parentMail.receiverUnreadCnt === 0) {
                                    parentMail.read = true;
                                }
                            }
                        }
                    } else if (props.loginUser.id === parentMail.senderId) {
                        if (props.loginUser.id === child_mail.receiverId) {
                            if (
                                parentMail.senderReceiveCnt != null &&
                                parentMail.senderReceiveCnt > 0
                            ) {
                                parentMail.senderReceiveCnt -= 1;
                            }
                            if (parentMail.senderReceiveCnt === 0) {
                                parentMail.hidden = true;
                                goBack = true;
                            }
                            if (child_mail.read === 0) {
                                if (
                                    parentMail.senderUnreadCnt != null &&
                                    parentMail.senderUnreadCnt > 0
                                ) {
                                    parentMail.senderUnreadCnt -= 1;
                                }
                                if (parentMail.senderUnreadCnt === 0) {
                                    parentMail.read = true;
                                }
                            }
                        }
                    }
                }
            }
            dispatch(HistoryAction({ receivedMailListHistory: history }));
            if (goBack) {
                window.setTimeout(() => {
                    navigate(-1);
                });
            }
        }, [
            props.loginUser,
            parentId,
            history,
            dispatch,
            navigate,
        ]);
        /**
         * レンダリング
         */
        return (
            <>
                {props.loginUser != null && parentId != null && (
                    <ReceivedChildMailComponent
                        loginUser={props.loginUser}
                        parentId={selectedParentId}
                        onRead={readMail}
                        onDelete={(childMail: MailModel) =>
                            deleteMail(childMail)
                        }
                    />
                )}
            </>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default ReceivedChildMail;
