export const USER = "USER";

export const UserAction = (userState) => {
    let payload = {};
    if (userState.hasOwnProperty("loginUser")) {
        payload["loginUser"] = userState.loginUser;
    }
    if (userState.hasOwnProperty("lstOwnGroup")) {
        payload["lstOwnGroup"] = userState.lstOwnGroup;
    }
    if (userState.hasOwnProperty("lstBlockUser")) {
        payload["lstBlockUser"] = userState.lstBlockUser;
    }
    if (userState.hasOwnProperty("lstFollowUser")) {
        payload["lstFollowUser"] = userState.lstFollowUser;
    }
    if (userState.hasOwnProperty("lstTag")) {
        payload["lstTag"] = userState.lstTag;
    }
    if (userState.hasOwnProperty("lstCustomTag")) {
        payload["lstCustomTag"] = userState.lstCustomTag;
    }
    if (userState.hasOwnProperty("lstInvite")) {
        payload["lstInvite"] = userState.lstInvite;
    }
    return {
        type: USER,
        payload: payload,
    };
};
