import { ResponseModel } from "models/Models";

export class ResponseListResponse {
    rtnCd?: number | null = null;
    lstResponse?: ResponseModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstResponse
        if (obj.hasOwnProperty("ResponseList")) {
            if (this.lstResponse == null) {
                this.lstResponse = [];
            }
            for (let i in obj.ResponseList) {
                let response = new ResponseModel(obj.ResponseList[i]);
                this.lstResponse.push(response);
            }
        }
    }
}
