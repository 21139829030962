export class SystemInfoModel {
    id: number = 0;
    deviceType: number = 0;
    maintenance: number = 0;
    majorVersion: number = 0;
    minorVersion: number = 0;
    buildNo: number = 0;
    existNews: number = 0;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // deviceType
        if (obj.hasOwnProperty("deviceType")) {
            this.deviceType = obj.deviceType;
        } else if (obj.hasOwnProperty("DeviceType")) {
            this.deviceType = obj.DeviceType;
        }
        // maintenance
        if (obj.hasOwnProperty("maintenance")) {
            this.maintenance = obj.maintenance;
        } else if (obj.hasOwnProperty("Maintenance")) {
            this.maintenance = obj.Maintenance;
        }
        // majorVersion
        if (obj.hasOwnProperty("majorVersion")) {
            this.majorVersion = obj.majorVersion;
        } else if (obj.hasOwnProperty("MajorVersion")) {
            this.majorVersion = obj.MajorVersion;
        }
        // minorVersion
        if (obj.hasOwnProperty("minorVersion")) {
            this.minorVersion = obj.minorVersion;
        } else if (obj.hasOwnProperty("MinorVersion")) {
            this.minorVersion = obj.MinorVersion;
        }
        // buildNo
        if (obj.hasOwnProperty("buildNo")) {
            this.buildNo = obj.buildNo;
        } else if (obj.hasOwnProperty("BuildNo")) {
            this.buildNo = obj.BuildNo;
        }
        // existNews
        if (obj.hasOwnProperty("existNews")) {
            this.existNews = obj.existNews;
        } else if (obj.hasOwnProperty("ExistNews")) {
            this.existNews = obj.ExistNews;
        }
    }
}
