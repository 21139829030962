import { AlbumTemplateModel } from "models/Models";

export class AlbumTemplateResponse {
    rtnCd?: number | null = null;
    lstAlbumTemplate?: AlbumTemplateModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstAlbumTemplate
        if (obj.hasOwnProperty("AlbumTemplateList")) {
            if (this.lstAlbumTemplate == null) {
                this.lstAlbumTemplate = [];
            }
            for (let i in obj.AlbumTemplateList) {
                let template = new AlbumTemplateModel(obj.AlbumTemplateList[i]);
                this.lstAlbumTemplate.push(template);
            }
        }
    }
}
