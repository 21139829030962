import { AlbumModel, GalleryModel } from "models/Models";

export class GalleryListResponse {
    rtnCd?: number | null = null;
    album?: AlbumModel | null = null;
    lstGallery?: GalleryModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // album
        if (obj.hasOwnProperty("Album")) {
            this.album = new AlbumModel(obj.Album);
        }
        // lstGallery
        if (obj.hasOwnProperty("GalleryList")) {
            if (this.lstGallery == null) {
                this.lstGallery = [];
            }
            for (let i in obj.GalleryList) {
                let gallery = new GalleryModel(obj.GalleryList[i]);
                this.lstGallery.push(gallery);
            }
        }
    }
}
