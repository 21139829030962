import { UserModel } from "./UserModel";

export class BlockUserModel {
    id: number | null = null;
    userId: number | null = null;
    targetUserId: number | null = null;
    targetUser: UserModel | null = null;
    createdAt: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // targetUserId
        if (obj.hasOwnProperty("targetUserId")) {
            this.targetUserId = obj.targetUserId;
        } else if (obj.hasOwnProperty("TargetUserId")) {
            this.targetUserId = obj.TargetUserId;
        }
        // targetUser
        if (obj.hasOwnProperty("targetUser")) {
            this.targetUser = new UserModel(obj.targetUser);
        } else if (obj.hasOwnProperty("TargetUser")) {
            this.targetUser = new UserModel(obj.TargetUser);
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
    }
}
