import {
    BlockUserModel,
    CustomTagModel,
    GroupModel,
    TagModel,
    UserModel,
    FollowModel,
} from "models/Models";

export class LoginResponse {
    rtnCd?: number | null = null;
    user?: UserModel | null = null;
    lstTag?: TagModel[] | null = null;
    lstCustomTag?: CustomTagModel[] | null = null;
    lstOwnGroup?: GroupModel[] | null = null;
    lstBlockUser?: UserModel[] | null = null;
    lstFollowUser?: UserModel[] | null = null;
    bearerToken?: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // user
        if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // lstTag
        if (obj.hasOwnProperty("LIST_TAG")) {
            if (this.lstTag == null) {
                this.lstTag = [];
            }
            for (let i in obj.LIST_TAG) {
                let tag = new TagModel(obj.LIST_TAG[i]);
                this.lstTag.push(tag);
            }
        }
        // lstCustomTag
        if (obj.hasOwnProperty("LIST_CUSTOM_TAG")) {
            if (this.lstCustomTag == null) {
                this.lstCustomTag = [];
            }
            for (let i in obj.LIST_CUSTOM_TAG) {
                let customTag = new CustomTagModel(obj.LIST_CUSTOM_TAG[i]);
                this.lstCustomTag.push(customTag);
            }
        }
        // lstOwnGroup
        if (obj.hasOwnProperty("LIST_OWN_GROUP")) {
            if (this.lstOwnGroup == null) {
                this.lstOwnGroup = [];
            }
            for (let i in obj.LIST_OWN_GROUP) {
                let group = new GroupModel(obj.LIST_OWN_GROUP[i]);
                this.lstOwnGroup.push(group);
            }
        }
        // lstBlockUser
        if (obj.hasOwnProperty("LIST_BLOCK_USER")) {
            if (this.lstBlockUser == null) {
                this.lstBlockUser = [];
            }
            for (let i in obj.LIST_BLOCK_USER) {
                let blockUser = new BlockUserModel(obj.LIST_BLOCK_USER[i]);
                if (blockUser.targetUser != null) {
                    this.lstBlockUser.push(blockUser.targetUser);
                }
            }
        }
        // lstFollowUser
        if (obj.hasOwnProperty("LIST_FOLLOW_USER")) {
            if (this.lstFollowUser == null) {
                this.lstFollowUser = [];
            }
            for (let i in obj.LIST_FOLLOW_USER) {
                let followUser = new FollowModel(obj.LIST_FOLLOW_USER[i]);
                if (followUser.target != null) {
                    this.lstFollowUser.push(followUser.target);
                }
            }
        }
        // bearerToken
        if (obj.hasOwnProperty("Token")) {
            this.bearerToken = obj.Token;
        }
    }
}
