import React from "react";
import { FormattedMessage } from "react-intl";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { UserModel } from "models/Models";
import { AppConstants } from "constants/AppConstants";
import { ProfileComponent } from "components/Components";

interface Props {
    loginUser?: UserModel;
    openDialog: boolean;
    onClose: () => void;
    onCancel: () => void;
    onOk: (profileHtml: string, profile: string) => void;
}

export const ProfileDialog: React.FC<Props> = React.memo(
    (props) => {
        // export const ProfileDialog: React.FC<Props> = (props) => {
        // Utility.log("===== ProfileDialog");
        return (
            <Dialog
                className="dialog"
                open={props.openDialog}
                onClose={props.onClose}
                sx={{
                    width: "100%",
                    maxWidth: {
                        xs: "none",
                        sm: "800px",
                    },
                    margin: "auto",
                }}
                PaperProps={{
                    sx: {
                        margin: "auto",
                        maxWidth: "none",
                        width: {
                            xs: "95%",
                            sm: "80%",
                        },
                    },
                }}
            >
                <DialogTitle
                    className="dialog-title"
                    sx={{
                        textAlign: "center",
                        padding: "5px 0",
                    }}
                >
                    <FormattedMessage id={"profile"} />
                </DialogTitle>
                <DialogContent
                    className="dialog-content"
                    sx={{
                        padding: {
                            xs: "0 10px 10px 10px",
                            sm: "0 20px 10px 20px",
                        },
                        backgroundColor: AppConstants.BG_COLOR_DIALOG,
                    }}
                >
                    <ProfileComponent
                        loginUser={props.loginUser}
                        onCancel={props.onCancel}
                        onOk={props.onOk}
                    />
                </DialogContent>
            </Dialog>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevLoginUser = prevProps.loginUser;
        const nextLoginUser = nextProps.loginUser;
        if (prevLoginUser !== nextLoginUser) {
            return false;
        }
        if (prevLoginUser != null && nextLoginUser != null) {
            if (prevLoginUser.id !== nextLoginUser.id) {
                return false;
            }
        }
        const prevOpenDialog = prevProps.openDialog;
        const nextOpenDialog = nextProps.openDialog;
        if (prevOpenDialog !== nextOpenDialog) {
            return false;
        }
        return true;
    }
);
