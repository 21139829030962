import { createSelector } from "reselect";

const workingItemSelector = (state) => state.workingItem;

export const getWorkingRegister = createSelector(
    [workingItemSelector],
    (state) => state.register
);
export const getWorkingAlbum = createSelector(
    [workingItemSelector],
    (state) => state.album
);
export const getWorkingProfile = createSelector(
    [workingItemSelector],
    (state) => state.profile
);
export const getWorkingMail = createSelector(
    [workingItemSelector],
    (state) => state.mail
);
