import {
    UserModel,
    AttachedFileModel,
    LinkInfoModel,
    LinkInfo2Model,
} from "./Models";

export class MailModel {
    id: number | null = null;
    parentMailId: number | null = null;
    senderId: number | null = null;
    sender: UserModel | null = null;
    receiverId: number | null = null;
    receiver: UserModel | null = null;
    title: string | null = null;
    message: string | null = null;
    read: number | null = null;
    reply: number | null = null;
    existsAttachedFiles: number | null = null;
    lstAttachedFile: AttachedFileModel[] | null = null;
    senderReceiveDelete: number | null = null;
    senderSendDelete: number | null = null;
    receiverReceiveDelete: number | null = null;
    receiverSendDelete: number | null = null;
    linkInfo: LinkInfoModel | null = null;
    linkInfo2: LinkInfo2Model | null = null;
    invalid: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    deletedAt: string | null = null;

    hidden: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // parentMailId
        if (obj.hasOwnProperty("parentMailId")) {
            this.parentMailId = obj.parentMailId;
        } else if (obj.hasOwnProperty("ParentMailId")) {
            this.parentMailId = obj.ParentMailId;
        }
        // senderId
        if (obj.hasOwnProperty("senderId")) {
            this.senderId = obj.senderId;
        } else if (obj.hasOwnProperty("SenderId")) {
            this.senderId = obj.SenderId;
        }
        // sender
        if (obj.hasOwnProperty("sender")) {
            this.sender = new UserModel(obj.sender);
        } else if (obj.hasOwnProperty("Sender")) {
            this.sender = new UserModel(obj.Sender);
        }
        // receiverId
        if (obj.hasOwnProperty("receiverId")) {
            this.receiverId = obj.receiverId;
        } else if (obj.hasOwnProperty("ReceiverId")) {
            this.receiverId = obj.ReceiverId;
        }
        // receiver
        if (obj.hasOwnProperty("receiver")) {
            this.receiver = new UserModel(obj.receiver);
        } else if (obj.hasOwnProperty("Receiver")) {
            this.receiver = new UserModel(obj.Receiver);
        }
        // title
        if (obj.hasOwnProperty("title")) {
            this.title = obj.title;
        } else if (obj.hasOwnProperty("Title")) {
            this.title = obj.Title;
        }
        // message
        if (obj.hasOwnProperty("message")) {
            this.message = obj.message;
        } else if (obj.hasOwnProperty("Message")) {
            this.message = obj.Message;
        }
        // read
        if (obj.hasOwnProperty("read")) {
            this.read = obj.read;
        } else if (obj.hasOwnProperty("Read")) {
            this.read = obj.Read;
        }
        // reply
        if (obj.hasOwnProperty("reply")) {
            this.reply = obj.reply;
        } else if (obj.hasOwnProperty("Reply")) {
            this.reply = obj.Reply;
        }
        // existsAttachedFiles
        if (obj.hasOwnProperty("existsAttachedFiles")) {
            this.existsAttachedFiles = obj.existsAttachedFiles;
        } else if (obj.hasOwnProperty("ExistsAttachedFiles")) {
            this.existsAttachedFiles = obj.ExistsAttachedFiles;
        }
        // attachedFile
        if (obj.hasOwnProperty("attachedFile")) {
            this.lstAttachedFile = [];
            for (let i in obj.attachedFile) {
                let attachedFile = new AttachedFileModel(obj.attachedFile[i]);
                this.lstAttachedFile.push(attachedFile);
            }
        } else if (obj.hasOwnProperty("AttachedFile")) {
            this.lstAttachedFile = [];
            for (let i in obj.AttachedFile) {
                let attachedFile = new AttachedFileModel(obj.AttachedFile[i]);
                this.lstAttachedFile.push(attachedFile);
            }
        }
        // senderReceiveDelete
        if (obj.hasOwnProperty("senderReceiveDelete")) {
            this.senderReceiveDelete = obj.senderReceiveDelete;
        } else if (obj.hasOwnProperty("SenderReceiveDelete")) {
            this.senderReceiveDelete = obj.SenderReceiveDelete;
        }
        // senderSendDelete
        if (obj.hasOwnProperty("senderSendDelete")) {
            this.senderSendDelete = obj.senderSendDelete;
        } else if (obj.hasOwnProperty("SenderSendDelete")) {
            this.senderSendDelete = obj.SenderSendDelete;
        }
        // receiverReceiveDelete
        if (obj.hasOwnProperty("receiverReceiveDelete")) {
            this.receiverReceiveDelete = obj.receiverReceiveDelete;
        } else if (obj.hasOwnProperty("ReceiverReceiveDelete")) {
            this.receiverReceiveDelete = obj.ReceiverReceiveDelete;
        }
        // receiverSendDelete
        if (obj.hasOwnProperty("receiverSendDelete")) {
            this.receiverSendDelete = obj.receiverSendDelete;
        } else if (obj.hasOwnProperty("ReceiverSendDelete")) {
            this.receiverSendDelete = obj.ReceiverSendDelete;
        }
        // linkInfo
        if (obj.hasOwnProperty("linkInfo") && obj.linkInfo.length > 0) {
            const json = JSON.parse(obj.linkInfo);
            this.linkInfo = new LinkInfoModel(json);
        } else if (obj.hasOwnProperty("LinkInfo") && obj.LinkInfo.length > 0) {
            const json = JSON.parse(obj.LinkInfo);
            this.linkInfo = new LinkInfoModel(json);
        }
        // linkInfo2
        if (obj.hasOwnProperty("linkInfo2") && obj.linkInfo2.length > 0) {
            const json = JSON.parse(obj.linkInfo2);
            this.linkInfo2 = new LinkInfo2Model(json);
        } else if (
            obj.hasOwnProperty("LinkInfo2") &&
            obj.LinkInfo2.length > 0
        ) {
            const json = JSON.parse(obj.LinkInfo2);
            this.linkInfo2 = new LinkInfo2Model(json);
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
    }
}
