export class DbConstants {
    // 未選択
    static NO_SELECTION = 0;
    // 認証タイプ
    static AUTH_TYPE_APP = 0;
    static AUTH_TYPE_GOOGLE = 1;
    // 性別
    static GENDER_NONE = 0;
    static GENDER_MALE = 1;
    static GENDER_FEMALE = 2;
    static GENDER_OTHER = 3;
    // 年代
    static AGE_NONE = 0;
    static AGE_10 = 1;
    static AGE_20 = 2;
    static AGE_30 = 3;
    static AGE_40 = 4;
    static AGE_50 = 5;
    static AGE_60 = 6;
    // 属性
    static ATTRIBUTE_NONE = 0;
    static ATTRIBUTE_SADO = 1;
    static ATTRIBUTE_MASO = 2;
    // デバイスタイプ
    static DEVICE_TYPE_ANDROID = 1;
    static DEVICE_TYPE_IOS = 2;
    static DEVICE_TYPE_BROWSER = 3;
    // プラン
    static PLAN_FREE = 0;
    static PLAN_STANDARD = 1;
    static PLAN_PREMIUM = 2;
    // 通知
    static NOTIFICATION_OFF = 0;
    static NOTIFICATION_ON = 1;
    // 広告
    static ADS_OFF = 1;
    static ADS_ON = 0;
    // 位置情報提供
    static PROVIDE_LOCATION_REJECT = 0;
    static PROVIDE_LOCATION_CONFIRM = 1;
    // グループ公開設定
    static GROUP_PUBLIC = 1;
    static GROUP_PRIVATE = 0;
    // グループ加入方式
    static GROUP_AUTO_JOIN = 1;
    static GROUP_INDIVIDUAL_APPROVAL = 0;
    // ファイルタイプ
    static FILE_TYPE_IMAGE = 1;
    static FILE_TYPE_VIDEO = 2;
    // 運営管理者ID
    static SMMATE_STAFF_ID = 1;
    // 国コード
    static COUNTRY_CODE_99 = "99";
    static COUNTRY_CODE_AE = "AE";
    static COUNTRY_CODE_AT = "AT";
    static COUNTRY_CODE_AU = "AU";
    static COUNTRY_CODE_BE = "BE";
    static COUNTRY_CODE_BG = "BG";
    static COUNTRY_CODE_BR = "BR";
    static COUNTRY_CODE_CA = "CA";
    static COUNTRY_CODE_CH = "CH";
    static COUNTRY_CODE_CN = "CN";
    static COUNTRY_CODE_DE = "DE";
    static COUNTRY_CODE_DK = "DK";
    static COUNTRY_CODE_ES = "ES";
    static COUNTRY_CODE_EG = "EG";
    static COUNTRY_CODE_FI = "FI";
    static COUNTRY_CODE_FR = "FR";
    static COUNTRY_CODE_GB = "GB";
    static COUNTRY_CODE_GR = "GR";
    static COUNTRY_CODE_HK = "HK";
    static COUNTRY_CODE_HR = "HR";
    static COUNTRY_CODE_HU = "HU";
    static COUNTRY_CODE_ID = "ID";
    static COUNTRY_CODE_IE = "IE";
    static COUNTRY_CODE_IL = "IL";
    static COUNTRY_CODE_IN = "IN";
    static COUNTRY_CODE_IT = "IT";
    static COUNTRY_CODE_IQ = "IQ";
    static COUNTRY_CODE_JO = "JO";
    static COUNTRY_CODE_JP = "JP";
    static COUNTRY_CODE_KR = "KR";
    static COUNTRY_CODE_LB = "LB";
    static COUNTRY_CODE_MA = "MA";
    static COUNTRY_CODE_MY = "MY";
    static COUNTRY_CODE_NL = "NL";
    static COUNTRY_CODE_NO = "NO";
    static COUNTRY_CODE_NZ = "NZ";
    static COUNTRY_CODE_PH = "PH";
    static COUNTRY_CODE_PS = "PS";
    static COUNTRY_CODE_PT = "PT";
    static COUNTRY_CODE_RO = "RO";
    static COUNTRY_CODE_SE = "SE";
    static COUNTRY_CODE_SG = "SG";
    static COUNTRY_CODE_SY = "SY";
    static COUNTRY_CODE_TH = "TH";
    static COUNTRY_CODE_TR = "TR";
    static COUNTRY_CODE_TW = "TW";
    static COUNTRY_CODE_US = "US";
    static COUNTRY_CODE_VN = "VN";
    static COUNTRY_CODE_YE = "YE";

    static getCssGenderLabel(gender: number | null): string {
        switch (gender) {
            case DbConstants.GENDER_MALE:
                return "male";
            case DbConstants.GENDER_FEMALE:
                return "female";
            default:
                return "other";
        }
    }
}
