import React from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Alert,
    Switch,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { UserModel, GroupModel } from "models/Models";
import { getOwnGroupList } from "redux/Selectors";
import { NavBar } from "components/Components";
import { JoinGroupRequest, UserRequest } from "api/requests/Requests";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/group/GroupInvite.scss";
import { NetworkAction } from "redux/Actions";

type Props = {
    loginUser: UserModel | null;
};

const GroupInvite: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupInvite");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { mark } = useParams<{ mark: string }>();
        const tmpOwnGroupList = useSelector((state) => getOwnGroupList(state));
        /***** useRef */
        // ターゲットID
        const targetId = React.useRef<number>(0);

        /***** useState */
        // ターゲットユーザ
        const [target, setTarget] = React.useState<UserModel>();
        // 自分のグループリスト
        const [lstOwnGroup, setOwnGroupList] = React.useState<GroupModel[]>();
        // 招待メッセージ
        const [inviteMessage, setInviteMessage] = React.useState<string>();
        // 招待可能グループ有無
        const [existInvitableGroup, setExistInvitableGroup] =
            React.useState<boolean>(false);
        // 選択グループ名リスト
        const [lstGroupName, setGroupNameList] = React.useState<string[]>();
        // データロード完了フラグ
        const [initialDataLoaded, setInitialDataLoaded] =
            React.useState<boolean>(false);
        // 待確認ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>();
        // 招待確認ダイアログ表示フラグ
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        // エラーメッセージ
        const [errMessage, setErrMessage] = React.useState<string>("");

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループ招待",
                path: window.location.pathname,
            })            
        }, []);
        React.useEffect(() => {
            if (props.loginUser != null) {
                initialProcess();
            }
        }, [props.loginUser, target]);

        /**
         * 初期処理
         */
        async function initialProcess() {
            if (target == null) {
                const paramArray = mark?.split("-");
                if (paramArray?.length === 3) {
                    targetId.current = Number(paramArray[2]);
                }
                if (
                    targetId != null &&
                    targetId.current != null &&
                    targetId.current > 0
                ) {
                    await fetchUser(targetId.current);
                }
            } else {
                await getTargetJoinGroupList();
                setInitialDataLoaded(true);
            }
        }

        /**
         * 招待ボタンクリック時
         */
        function onClickInvite() {
            if (target == null || target.name == null) {
                return;
            }
            if (validate()) {
                let dialogMessage = intl.formatMessage({
                    id: "confirm_invite_user",
                });
                const lstGroupName = getSelectedGroupNameList();
                if (dialogMessage != null && lstGroupName != null) {
                    setGroupNameList(lstGroupName);
                    dialogMessage = dialogMessage.replace(
                        "[USER]",
                        "[" + target.name + "]"
                    );
                    setDialogMessage(dialogMessage);
                    setOpenConfirmDialog(true);
                    setErrMessage("");
                }
            } else {
                let errMessage = intl.formatMessage({
                    id: "err_no_group_selected",
                });
                if (errMessage != null) {
                    setErrMessage(errMessage);
                }
            }
        }

        /**
         * 選択状態変更時
         * @param event
         * @param group
         */
        function onChangeSelected(
            event: React.ChangeEvent<HTMLInputElement>,
            group: GroupModel
        ) {
            if (lstOwnGroup == null) {
                return;
            }
            for (let i = 0; i < lstOwnGroup.length; i++) {
                const ownGroup = lstOwnGroup[i];
                if (ownGroup.id === group.id) {
                    ownGroup.selected = event.target.checked;
                    break;
                }
            }
            setOwnGroupList([...lstOwnGroup]);
        }

        /**
         * 選択されてるグループ名リスト取得
         * @returns
         */
        function getSelectedGroupNameList(): string[] | null {
            let lstGroupName: string[] | null = null;
            const lstSelectedGroup = getSelectedGroup();
            if (lstSelectedGroup != null) {
                for (let i = 0; i < lstSelectedGroup.length; i++) {
                    const groupName = lstSelectedGroup[i].name;
                    if (groupName != null) {
                        if (lstGroupName == null) {
                            lstGroupName = [groupName];
                        } else {
                            lstGroupName.push(groupName);
                        }
                    }
                }
            }
            return lstGroupName;
        }
        /**
         * 選択されてるグループIDリスト取得
         * @returns
         */
        function getSelectedGroupIdList(): string[] | null {
            let lstGroupId: string[] | null = null;
            const lstSelectedGroup = getSelectedGroup();
            if (lstSelectedGroup != null) {
                for (let i = 0; i < lstSelectedGroup.length; i++) {
                    if (lstSelectedGroup[i].id != null) {
                        if (lstGroupId == null) {
                            lstGroupId = [String(lstSelectedGroup[i].id)];
                        } else {
                            lstGroupId.push(String(lstSelectedGroup[i].id));
                        }
                    }
                }
            }
            return lstGroupId;
        }
        /**
         * 選択されてるグループリスト取得
         * @returns
         */
        function getSelectedGroup(): GroupModel[] | null {
            let lstSelectedGroup: GroupModel[] | null = null;
            if (lstOwnGroup != null) {
                for (let i = 0; i < lstOwnGroup.length; i++) {
                    if (lstOwnGroup[i].selected) {
                        if (lstSelectedGroup == null) {
                            lstSelectedGroup = [lstOwnGroup[i]];
                        } else {
                            lstSelectedGroup.push(lstOwnGroup[i]);
                        }
                    }
                }
            }
            return lstSelectedGroup;
        }
        /**
         * バリデーション
         */
        function validate(): boolean {
            if (getSelectedGroup() == null) {
                return false;
            } else {
                return true;
            }
        }

        /**
         * 招待実行
         */
        async function executeInvite() {
            try {
                if (
                    props.loginUser == null ||
                    target == null ||
                    target.id == null
                ) {
                    return;
                }
                const lstGroupId = getSelectedGroupIdList();
                if (lstGroupId == null) {
                    return;
                }
                const result = await JoinGroupRequest.invite(
                    props.loginUser,
                    target.id,
                    lstGroupId,
                    inviteMessage
                );
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }

                if (result.rtnCd == null) {
                    const errMessage = intl.formatMessage({ id: "err_common" });
                    if (errMessage != null) {
                        setErrMessage(errMessage);
                    }
                    return;
                }
                if (result.rtnCd === 0) {
                    setInviteMessage("");
                    await getTargetJoinGroupList();
                } else if (result.rtnCd === -9) {
                    const errMessage = intl.formatMessage({
                        id: "err_blocked",
                    });
                    if (errMessage != null) {
                        setErrMessage(errMessage);
                    }
                    return;
                } else {
                    const errMessage = intl.formatMessage({ id: "err_common" });
                    if (errMessage != null) {
                        setErrMessage(errMessage);
                    }
                    return;
                }
            } finally {
                setOpenConfirmDialog(false);
            }
        }

        /**
         * ユーザ取得
         * @param userId
         * @returns
         */
        async function fetchUser(userId: number) {
            const result = await UserRequest.getUser(userId);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.user == null
            ) {
                return;
            }
            setTarget(result.user);
        }

        /**
         * ターゲットユーザのグループ加入状況を取得
         * @returns
         */
        async function getTargetJoinGroupList() {
            if (
                props.loginUser == null ||
                target == null ||
                target.id == null
            ) {
                return;
            }
            const result = await JoinGroupRequest.getJoinGroupList(
                props.loginUser,
                target.id
            );
            setExistInvitableGroup(false);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            let wkOwnGroupList: GroupModel[] | null = null;
            if (tmpOwnGroupList != null) {
                wkOwnGroupList = [];
                for (let i = 0; i < tmpOwnGroupList.length; i++) {
                    const group = new GroupModel(tmpOwnGroupList[i]);
                    group.selected = false;
                    wkOwnGroupList.push(group);
                }
                for (let i = 0; i < wkOwnGroupList.length; i++) {
                    const ownGroup = wkOwnGroupList[i];
                    ownGroup.selected = false;
                    let existJoinGroup = false;
                    if (result.lstJoinGroup != null) {
                        for (let j = 0; j < result.lstJoinGroup.length; j++) {
                            const joinGroup = result.lstJoinGroup[j];
                            if (ownGroup.id === joinGroup.groupId) {
                                existJoinGroup = true;
                                if (joinGroup.underApplication === 1) {
                                    ownGroup.underApplication = true;
                                } else if (joinGroup.underInvitation === 1) {
                                    ownGroup.inviting = true;
                                } else {
                                    ownGroup.join = true;
                                }
                            }
                        }
                    }
                    if (!existJoinGroup) {
                        setExistInvitableGroup(true);
                    }
                }
                setOwnGroupList(wkOwnGroupList);
                // if (lstOwnGroup != null) {
                //     for (let i = 0; i < lstOwnGroup.length; i++) {
                //         lstOwnGroup[i].selected = false;
                //     }
                //     setOwnGroupList([...lstOwnGroup]);
                // }
            }
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper GroupInvite">
                {initialDataLoaded && (
                    <>
                        <NavBar
                            showPC={true}
                            showBack={true}
                            title={
                                target != null && target.name != null
                                    ? target.name
                                    : intl.formatMessage({
                                          id: "title_invite_user",
                                      })
                            }
                        />

                        {
                            // エラーメッセージエリア
                        }
                        <Alert
                            className={
                                errMessage.length > 0
                                    ? "error-message"
                                    : "d-none"
                            }
                            severity="error"
                            onClose={() => {
                                setErrMessage("");
                            }}
                        >
                            {errMessage}
                        </Alert>
                        <div className="contents-wrapper">
                            {lstOwnGroup == null ||
                                (lstOwnGroup.length === 0 && (
                                    <div className="no-group">
                                        <FormattedMessage id="no_invite_group" />
                                    </div>
                                ))}
                            {lstOwnGroup != null && lstOwnGroup.length > 0 && (
                                <>
                                    <h1 className="page-title">
                                        <FormattedMessage id="select_group_to_invite" />
                                    </h1>
                                    <ul className="list-wrapper">
                                        {lstOwnGroup.map(
                                            (
                                                ownGroup: GroupModel,
                                                index: number
                                            ) => {
                                                if (ownGroup.name != null) {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="item-wrapper"
                                                        >
                                                            <div
                                                                className={
                                                                    ownGroup.selected
                                                                        ? "group-name selected"
                                                                        : "grou-name"
                                                                }
                                                            >
                                                                {ownGroup.name}
                                                            </div>
                                                            {ownGroup.join && (
                                                                <div className="join">
                                                                    <FormattedMessage id="now_joined" />
                                                                </div>
                                                            )}
                                                            {ownGroup.underApplication && (
                                                                <div className="under-application">
                                                                    <FormattedMessage id="under_application" />
                                                                </div>
                                                            )}
                                                            {!ownGroup.underApplication &&
                                                                ownGroup.inviting && (
                                                                    <div className="inviting">
                                                                        <FormattedMessage id="now_inviting" />
                                                                    </div>
                                                                )}
                                                            {!ownGroup.join &&
                                                                !ownGroup.underApplication &&
                                                                !ownGroup.inviting && (
                                                                    <Switch
                                                                        className="switch"
                                                                        checked={
                                                                            ownGroup.selected
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            onChangeSelected(
                                                                                event,
                                                                                ownGroup
                                                                            );
                                                                        }}
                                                                        color="primary"
                                                                        name="checkedB"
                                                                        inputProps={{
                                                                            "aria-label":
                                                                                "primary checkbox",
                                                                        }}
                                                                    />
                                                                )}
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <React.Fragment
                                                            key={index}
                                                        ></React.Fragment>
                                                    );
                                                }
                                            }
                                        )}
                                    </ul>
                                    {
                                        // メッセージ
                                    }
                                    {existInvitableGroup && (
                                        <div className="message-area">
                                            <div className="label">
                                                <FormattedMessage
                                                    id={"invite_message"}
                                                />
                                            </div>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                sx={{
                                                    backgroundColor: "#ffffff",
                                                    fontSize: "1rem",
                                                    borderRadius: "4px",
                                                    "& .MuiInputBase-root": {
                                                        padding: "5px",
                                                    },
                                                }}
                                                label=""
                                                type="text"
                                                multiline={true}
                                                maxRows={30}
                                                minRows={8}
                                                inputProps={{
                                                    maxLength: 500,
                                                }}
                                                value={inviteMessage}
                                                onChange={(e) => {
                                                    setInviteMessage(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}

                                    {
                                        // ボタンエリア
                                    }
                                    {existInvitableGroup &&
                                        props.loginUser != null && (
                                            <div className="button-area">
                                                {
                                                    // 招待ボタン
                                                }
                                                <Button
                                                    className="app-button button"
                                                    variant="text"
                                                    onClick={onClickInvite}
                                                >
                                                    <FormattedMessage
                                                        id={"btn_invite"}
                                                    />
                                                </Button>
                                            </div>
                                        )}
                                </>
                            )}
                        </div>
                        <Dialog
                            className="dialog"
                            open={openConfirmDialog}
                            onClose={() => {
                                setOpenConfirmDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                className="dialog-title"
                                id="alert-dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent className="dialog-content2">
                                <DialogContentText id="alert-dialog-description">
                                    {dialogMessage}
                                </DialogContentText>
                                {lstGroupName != null && (
                                    <ul className="selected-group-list">
                                        {lstGroupName.map(
                                            (
                                                groupName: string,
                                                index: number
                                            ) => {
                                                return (
                                                    <li key={index}>
                                                        {groupName}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    className="dialog-button"
                                    onClick={executeInvite} 
                                    color="primary">
                                    <FormattedMessage id={"btn_yes2"} />
                                </Button>
                                <Button
                                    className="dialog-button"
                                    onClick={() => {
                                        setOpenConfirmDialog(false);
                                    }}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);
export default GroupInvite;
