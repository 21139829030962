import { UserModel } from "./UserModel";

export class GalleryModel {
    id: number | null = null;
    type: number | null = null;
    userId: number | null = null;
    user: UserModel | null = null;
    albumId: number | null = null;
    fileName: string | null = null;
    thumbnailName: string | null = null;
    orderNo: number | null = null;
    visionResult: number | null = null;
    uploadedOriginal: number | null = null;
    uploadedResizedImage: number | null = null;
    uploadedVideoThumbnail: number | null = null;
    uploadedVideoHls360: number | null = null;
    invalid: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    selected: boolean = false;
    file: File | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // type
        if (obj.hasOwnProperty("type")) {
            this.type = obj.type;
        } else if (obj.hasOwnProperty("Type")) {
            this.type = obj.Type;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // user
        if (obj.hasOwnProperty("user")) {
            this.user = new UserModel(obj.user);
        } else if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // albumId
        if (obj.hasOwnProperty("albumId")) {
            this.albumId = obj.albumId;
        } else if (obj.hasOwnProperty("AlbumId")) {
            this.albumId = obj.AlbumId;
        }
        // fileName
        if (obj.hasOwnProperty("fileName")) {
            this.fileName = obj.fileName;
        } else if (obj.hasOwnProperty("FileName")) {
            this.fileName = obj.FileName;
        }
        // thumbnailName
        if (obj.hasOwnProperty("thumbnailName")) {
            this.thumbnailName = obj.thumbnailName;
        } else if (obj.hasOwnProperty("ThumbnailName")) {
            this.thumbnailName = obj.ThumbnailName;
        }
        // orderNo
        if (obj.hasOwnProperty("orderNo")) {
            this.orderNo = obj.orderNo;
        } else if (obj.hasOwnProperty("OrderNo")) {
            this.orderNo = obj.OrderNo;
        }
        // visionResult
        if (obj.hasOwnProperty("visionResult")) {
            this.visionResult = obj.visionResult;
        } else if (obj.hasOwnProperty("VisionResult")) {
            this.visionResult = obj.VisionResult;
        }
        // uploadedOriginal
        if (obj.hasOwnProperty("uploadedOriginal")) {
            this.uploadedOriginal = obj.uploadedOriginal;
        } else if (obj.hasOwnProperty("UploadedOriginal")) {
            this.uploadedOriginal = obj.UploadedOriginal;
        }
        // uploadedResizedImage
        if (obj.hasOwnProperty("uploadedResizedImage")) {
            this.uploadedResizedImage = obj.uploadedResizedImage;
        } else if (obj.hasOwnProperty("UploadedResizedImage")) {
            this.uploadedResizedImage = obj.UploadedResizedImage;
        }
        // uploadedVideoThumbnail
        if (obj.hasOwnProperty("uploadedVideoThumbnail")) {
            this.uploadedVideoThumbnail = obj.uploadedVideoThumbnail;
        } else if (obj.hasOwnProperty("UploadedVideoThumbnail")) {
            this.uploadedVideoThumbnail = obj.UploadedVideoThumbnail;
        }
        // uploadedVideoHls360
        if (obj.hasOwnProperty("uploadedVideoHls360")) {
            this.uploadedVideoHls360 = obj.uploadedVideoHls360;
        } else if (obj.hasOwnProperty("UploadedVideoHls360")) {
            this.uploadedVideoHls360 = obj.UploadedVideoHls360;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
    }
}
