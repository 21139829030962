import { UserModel, MailModel } from "./Models";

export class ParentMailModel {
    id: number | null = null;
    lstMail: MailModel[] | null = null;
    receiverId: number | null = null;
    receiver: UserModel | null = null;
    senderId: number | null = null;
    sender: UserModel | null = null;
    title: string | null = null;
    message: string | null = null;
    receiverUnreadCnt: number | null = null;
    receiverReceiveCnt: number | null = null;
    receiverSendCnt: number | null = null;
    senderUnreadCnt: number | null = null;
    senderReceiveCnt: number | null = null;
    senderSendCnt: number | null = null;
    reply: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    updatedAtSenderSend: string | null = null;
    updatedAtSenderReceive: string | null = null;
    updatedAtReceiverSend: string | null = null;
    updatedAtReceiverReceive: string | null = null;
    deletedAt: string | null = null;

    hidden: boolean = false;
    read: boolean = false;
    sortDate: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // lstMail
        if (obj.hasOwnProperty("lstMail")) {
            this.lstMail = [];
            for (let i in obj.lstMail) {
                let mail = new MailModel(obj.lstMail[i]);
                this.lstMail.push(mail);
            }
        } else if (obj.hasOwnProperty("LstMail")) {
            this.lstMail = [];
            for (let i in obj.LstMail) {
                let mail = new MailModel(obj.LstMail[i]);
                this.lstMail.push(mail);
            }
        }
        // receiverId
        if (obj.hasOwnProperty("receiverId")) {
            this.receiverId = obj.receiverId;
        } else if (obj.hasOwnProperty("ReceiverId")) {
            this.receiverId = obj.ReceiverId;
        }
        // receiver
        if (obj.hasOwnProperty("receiver")) {
            this.receiver = new UserModel(obj.receiver);
        } else if (obj.hasOwnProperty("Receiver")) {
            this.receiver = new UserModel(obj.Receiver);
        }
        // senderId
        if (obj.hasOwnProperty("senderId")) {
            this.senderId = obj.senderId;
        } else if (obj.hasOwnProperty("SenderId")) {
            this.senderId = obj.SenderId;
        }
        // sender
        if (obj.hasOwnProperty("sender")) {
            this.sender = new UserModel(obj.sender);
        } else if (obj.hasOwnProperty("Sender")) {
            this.sender = new UserModel(obj.Sender);
        }
        // title
        if (obj.hasOwnProperty("title")) {
            this.title = obj.title;
        } else if (obj.hasOwnProperty("Title")) {
            this.title = obj.Title;
        }
        // message
        if (obj.hasOwnProperty("message")) {
            this.message = obj.message;
        } else if (obj.hasOwnProperty("Message")) {
            this.message = obj.Message;
        }
        // receiverUnreadCnt
        if (obj.hasOwnProperty("receiverUnreadCnt")) {
            this.receiverUnreadCnt = obj.receiverUnreadCnt;
        } else if (obj.hasOwnProperty("ReceiverUnreadCnt")) {
            this.receiverUnreadCnt = obj.ReceiverUnreadCnt;
        }
        // receiverReceiveCnt
        if (obj.hasOwnProperty("receiverReceiveCnt")) {
            this.receiverReceiveCnt = obj.receiverReceiveCnt;
        } else if (obj.hasOwnProperty("ReceiverReceiveCnt")) {
            this.receiverReceiveCnt = obj.ReceiverReceiveCnt;
        }
        // receiverSendCnt
        if (obj.hasOwnProperty("receiverSendCnt")) {
            this.receiverSendCnt = obj.receiverSendCnt;
        } else if (obj.hasOwnProperty("ReceiverSendCnt")) {
            this.receiverSendCnt = obj.ReceiverSendCnt;
        }
        // senderUnreadCnt
        if (obj.hasOwnProperty("senderUnreadCnt")) {
            this.senderUnreadCnt = obj.senderUnreadCnt;
        } else if (obj.hasOwnProperty("SenderUnreadCnt")) {
            this.senderUnreadCnt = obj.SenderUnreadCnt;
        }
        // senderReceiveCnt
        if (obj.hasOwnProperty("senderReceiveCnt")) {
            this.senderReceiveCnt = obj.senderReceiveCnt;
        } else if (obj.hasOwnProperty("SenderReceiveCnt")) {
            this.senderReceiveCnt = obj.SenderReceiveCnt;
        }
        // senderSendCnt
        if (obj.hasOwnProperty("senderSendCnt")) {
            this.senderSendCnt = obj.senderSendCnt;
        } else if (obj.hasOwnProperty("SenderSendCnt")) {
            this.senderSendCnt = obj.SenderSendCnt;
        }
        // reply
        if (obj.hasOwnProperty("reply")) {
            this.reply = obj.reply;
        } else if (obj.hasOwnProperty("Reply")) {
            this.reply = obj.Reply;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // updatedAtSenderSend
        if (obj.hasOwnProperty("updatedAtSenderSend")) {
            this.updatedAtSenderSend = obj.updatedAtSenderSend;
        } else if (obj.hasOwnProperty("UpdatedAtSenderSend")) {
            this.updatedAtSenderSend = obj.UpdatedAtSenderSend;
        }
        // updatedAtSenderReceive
        if (obj.hasOwnProperty("updatedAtSenderReceive")) {
            this.updatedAtSenderReceive = obj.updatedAtSenderReceive;
        } else if (obj.hasOwnProperty("UpdatedAtSenderReceive")) {
            this.updatedAtSenderReceive = obj.UpdatedAtSenderReceive;
        }
        // updatedAtReceiverSend
        if (obj.hasOwnProperty("updatedAtReceiverSend")) {
            this.updatedAtReceiverSend = obj.updatedAtReceiverSend;
        } else if (obj.hasOwnProperty("UpdatedAtReceiverSend")) {
            this.updatedAtReceiverSend = obj.UpdatedAtReceiverSend;
        }
        // updatedAtReceiverReceive
        if (obj.hasOwnProperty("updatedAtReceiverReceive")) {
            this.updatedAtReceiverReceive = obj.updatedAtReceiverReceive;
        } else if (obj.hasOwnProperty("UpdatedAtReceiverReceive")) {
            this.updatedAtReceiverReceive = obj.UpdatedAtReceiverReceive;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
    }
}
