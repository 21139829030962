/**
 * Google認証スクリプトのロード
 * @returns
 */
export async function loadGoogleAuthenticationScript() {
    const id = "google-js";
    const src = "https://accounts.google.com/gsi/client";

    //prevent script from loading twice
    if (document.getElementById(id)) {
        return;
    }
    const js = document.createElement("script");
    js.id = id;
    js.src = src;
    js.onload = onGoogleScriptLoaded;

    //we have at least one script (React)
    const firstJs = document.getElementsByTagName("script")[0];
    if (firstJs == null) {
        return;
    }

    const parentNode = firstJs.parentNode;
    if (parentNode == null) {
        return;
    }
    parentNode.insertBefore(js, firstJs);
}
/**
 * Google認証スクリプトのアンロード
 * @returns
 */
export async function unloadGoogleAuthenticationScript() {
    const id = "google-js";
    const elm = document.getElementById(id);
    if (elm) {
        elm.remove();
    }
}

function onGoogleScriptLoaded() {
    const elm = document.getElementById("google-oauth-area");
    if (elm) {
        window.setTimeout(function () {
            elm.classList.add("loaded");
        }, 2000);
    }
}
