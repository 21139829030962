export const TAG_LIST = "TAG_LIST";

export const TagListAction = (tagListState) => {
    let payload = {};
    if (Object.keys(tagListState).indexOf("list") > -1) {
        payload["list"] = tagListState.list;
    }
    return {
        type: TAG_LIST,
        payload: payload,
    };
};
