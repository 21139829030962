import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { UserModel, CategoryModel } from "models/Models";
import { CategoryRequest } from "api/requests/Requests";
import {
    NavBar,
    CategoryListComponent,
    ThreadListComponent,
    ThreadPostComponent,
    ResponseListComponent,
} from "components/Components";
import { SelectedItemAction, CategoryListAction, NetworkAction } from "redux/Actions";
import {
    getCategoryList,
    getLanguageCode,
    getTimeDifference,
} from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/board/Board.scss";

type Props = {
    loginUser: UserModel | null;
};

const Board: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ Board IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const { categoryId } = useParams<{ categoryId: string }>();
        const { threadId } = useParams<{ threadId: string }>();
        const pathname = window.location.pathname;
        const langCd = useSelector((state) => getLanguageCode(state));
        // 時差
        const timeDifference = useSelector((state) => getTimeDifference(state));
        const lstCategory = useSelector((state) => getCategoryList(state));

        /***** useRef */

        /***** useState */
        // 読み込み完了フラグ
        const [loaded, setLoaded] = React.useState<boolean>(false);
        // 選択カテゴリー
        const [selectedCategory, setSelectedCategory] =
            React.useState<CategoryModel>();
        // ナブバータイトル
        const [navBarTitle, setNavBarTitle] = React.useState<string>("");

        const [showCategoryList, setShowCategoryList] =
            React.useState<boolean>(false);
        const [showThreadList, setShowThreadList] =
            React.useState<boolean>(false);
        const [showThreadPost, setShowThreadPost] =
            React.useState<boolean>(false);
        const [showResponseList, setShowResponseList] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        // React.useEffect(() => {
        // }, [lstCategory]);
        // React.useEffect(() => {
        //     if (categoryId != null) {
        //         if (lstCategory == null) {
        //             return;
        //         }
        //         for (let i = 0; i < lstCategory.length; i++) {
        //             const tmpCategory = lstCategory[i];
        //             if (tmpCategory.id === Number(categoryId)) {
        //                 setSelectedCategory(tmpCategory);
        //                 break;
        //             }
        //         }
        //     }
        // }, [categoryId]);
        React.useEffect(() => {
            function getNavigationBarTitle(
                pathname: string,
                langCd: string,
                _selectedCategory: CategoryModel | undefined
            ): string {
                let title: string | null = intl.formatMessage({ id: "title_message_board" });
    
                const regexp1 = /^\/board\/([0-9]+)\/thread\/list$/;
                const regexp2 = /^\/board\/([0-9]+)\/thread\/[0-9]+\/list$/;
                const regexp3 = /^\/board\/([0-9]+)\/thread\/new$/;
                const match1 = regexp1.exec(pathname);
                const match2 = regexp2.exec(pathname);
                const match3 = regexp3.exec(pathname);
                let wkCategoryId: string | null = null;
                if (match1) {
                    wkCategoryId = match1[1];
                } else if (match2) {
                    wkCategoryId = match2[1];
                } else if (match3) {
                    wkCategoryId = match3[1];
                }
                if (wkCategoryId != null) {
                    if (lstCategory != null && lstCategory.length > 0) {
                        const categoryId = parseInt(wkCategoryId);
                        for (let i=0; i<lstCategory.length; i++) {
                            const category = lstCategory[i];
                            if (category.id === categoryId) {
                                setSelectedCategory(category);
                                _selectedCategory = category;
                                break;
                            }
                        }
                    }
                }
                if (pathname.indexOf("/thread/new") > -1) {
                    title = intl.formatMessage({ id: "title_thread_post" });
                } else {
                    if (_selectedCategory) {
                        switch (langCd) {
                            case "ja":
                                title = _selectedCategory.name;
                                break;
                            case "cn":
                                title = _selectedCategory.nameCn;
                                break;
                            case "tw":
                                title = _selectedCategory.nameTw;
                                break;
                            case "es":
                                title = _selectedCategory.nameEs;
                                break;
                            case "de":
                                title = _selectedCategory.nameDe;
                                break;
                            case "it":
                                title = _selectedCategory.nameIt;
                                break;
                            case "ru":
                                title = _selectedCategory.nameRu;
                                break;
                            default:
                                title = _selectedCategory.nameEn;
                        }    
                    }
                }
                return title == null ? "" : title;
            }
    
            if (lstCategory == null) {
                fetchCategoryList();
                return;
            }
            if (langCd == null) {
                return;
            }
            init();
            if (categoryId != null) {
                for (let i = 0; i < lstCategory.length; i++) {
                    const tmpCategory = lstCategory[i];
                    if (tmpCategory.id === Number(categoryId)) {
                        setSelectedCategory(tmpCategory);
                        break;
                    }
                }
            }
            setLoaded(true);

            let title = getNavigationBarTitle(
                pathname,
                langCd,
                selectedCategory
            );
            setNavBarTitle(title);
        }, [
            categoryId,
            pathname,
            selectedCategory,
            lstCategory, 
            langCd,
            intl,
        ]);

        function init() {
            setShowCategoryList(false);
            setShowThreadList(false);
            setShowThreadPost(false);
            setShowResponseList(false);

            if (!isSizeXS) {
                setShowCategoryList(true);
            }
            if (pathname === "/board") {
                setSelectedCategory(undefined);
                if (isSizeXS) {
                    setShowCategoryList(true);
                } else {
                    navigate(`/board/1/thread/list`, { replace: true });
                    setShowThreadList(true);
                }
            } else if (pathname.indexOf("/thread/list") > -1) {
                setShowThreadList(true);
            } else if (pathname.indexOf("/thread/new") > -1) {
                setShowThreadPost(true);
            } else if (pathname.indexOf("/list") > -1) {
                if (categoryId != null && threadId != null) {
                    setShowResponseList(true);
                }
            }
        }


        /**
         * カテゴリークリック時
         * @param category
         */
        async function onClickCategory(category: CategoryModel) {
            if (category.id != null) {
                if (isSizeXS) {
                    dispatch(
                        SelectedItemAction({ category: selectedCategory })
                    );
                    window.setTimeout(function () {
                        navigate(`/board/${category.id}/thread/list`);
                    });
                } else {
                    dispatch(
                        SelectedItemAction({ category: selectedCategory })
                    );
                    window.setTimeout(function () {
                        navigate(`/board/${category.id}/thread/list`);
                    });
                }
            }
        }

        /**
         * カテゴリーリスト取得実行
         * @returns
         */
        async function fetchCategoryList() {
            const result = await CategoryRequest.getList();
            try {
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                        throw new Error();
                    }
                    return;
                }
                if (result.rtnCd == null || result.rtnCd < 0) {
                }
                if (result.lstCategory == null) {
                    return;
                }
                const lstCategory = result.lstCategory;
                if (lstCategory.length > 0) {
                    setSelectedCategory(lstCategory[0]);
                    dispatch(
                        CategoryListAction({
                            list: lstCategory,
                        })
                    );
                }
            } catch (e) {
            }
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper Board">
                {/* ページタイトル */}
                <NavBar
                    showBack={selectedCategory ? true : false}
                    title={navBarTitle}
                />
                {loaded && (
                    <div className="contents-wrapper">
                        {
                            // カテゴリー一覧
                        }
                        {langCd != null &&
                            showCategoryList &&
                            lstCategory != null && (
                                <div className="category-list-wrapper">
                                    <CategoryListComponent
                                        lstCategory={lstCategory}
                                        selectedCategory={selectedCategory}
                                        langCd={langCd}
                                        onClick={onClickCategory}
                                    />
                                </div>
                            )}
                        {
                            // スレッドリスト
                        }
                        {langCd != null &&
                            showThreadList &&
                            selectedCategory != null &&
                            selectedCategory.id != null && (
                                <div className="thread-list-wrapper">
                                    <ThreadListComponent
                                        loginUser={props.loginUser}
                                        category={selectedCategory}
                                        langCd={langCd}
                                    />
                                </div>
                            )}
                        {
                            // スレッド投稿
                        }
                        {langCd != null &&
                            showThreadPost &&
                            selectedCategory != null &&
                            selectedCategory.id != null && (
                                <div className="thread-post-wrapper">
                                    <ThreadPostComponent
                                        loginUser={props.loginUser}
                                        category={selectedCategory}
                                        langCd={langCd}
                                    />
                                </div>
                            )}
                        {
                            // レスポンスリスト
                        }
                        {langCd != null &&
                            timeDifference != null &&
                            showResponseList &&
                            selectedCategory != null &&
                            selectedCategory.id != null && (
                                <div className="response-list-wrapper">
                                    <ResponseListComponent
                                        loginUser={props.loginUser}
                                        category={selectedCategory}
                                        langCd={langCd}
                                        timeDifference={timeDifference}
                                    />
                                </div>
                            )}
                    </div>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevLoginUser = prevProps.loginUser;
        const nextLoginUser = nextProps.loginUser;
        if (prevLoginUser !== nextLoginUser) {
            return false;
        }
        return true;
    }
);

export default Board;
