import { GroupModel } from "./GroupModel";

export class AlbumGroupModel {
    id: number | null = null;
    albumId: number | null = null;
    groupId: number | null = null;
    group: GroupModel | null = null;
    orderNo: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // albumId
        if (obj.hasOwnProperty("id")) {
            this.albumId = obj.albumId;
        } else if (obj.hasOwnProperty("AlbumId")) {
            this.albumId = obj.AlbumId;
        }
        // groupId
        if (obj.hasOwnProperty("groupId")) {
            this.groupId = obj.groupId;
        } else if (obj.hasOwnProperty("GroupId")) {
            this.groupId = obj.GroupId;
        }
        // group
        if (obj.hasOwnProperty("group")) {
            this.group = new GroupModel(obj.group);
        } else if (obj.hasOwnProperty("Group")) {
            this.group = new GroupModel(obj.Group);
        }
        // orderNo
        if (obj.hasOwnProperty("orderNo")) {
            this.orderNo = obj.orderNo;
        } else if (obj.hasOwnProperty("OrderNo")) {
            this.orderNo = obj.OrderNo;
        }
    }
}
