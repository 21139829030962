import axios, { AxiosResponse } from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import { TagListResponse } from "api/responses/Responses";

export class TagRequest {
    /**
     * タグリスト取得
     * @returns
     */
    static async getList(user: UserModel | null = null) {
        let bearerToken = null;
        let url = UrlConstants.URL_TAG_LIST;
        if (user != null && user.id != null && user.id > 0) {
            url = UrlConstants.V2_URL_TAG_LIST + "?user_id=" + String(user.id);
            if (user.bearerToken != null) {
                bearerToken = user.bearerToken;
            }
        }
        let result = null;
        try {
            let res: AxiosResponse | null = null;
            if (bearerToken == null) {
                res = await axios.get(url, { withCredentials: true });
            } else {
                res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    withCredentials: true,
                });
            }
            if (res != null) {
                result = new TagListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
