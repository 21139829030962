import { DbConstants } from "constants/DbConstants";

export class RegisterData {
    actionKey: string = "";
    authType: number = 0;
    email: string = "";
    confirmEmail: string = "";
    password: string = "";
    confirmPassword: string = "";
    userName: string = "";
    gender: number = DbConstants.GENDER_NONE;
    age: number = DbConstants.AGE_NONE;
    attribute: number = DbConstants.ATTRIBUTE_NONE;
    region: string | null = null;
    regionLatitude: number | null = null;
    regionLongitude: number | null = null;
    lstTag: string[] | null = null;
    lstCustomTag: string[] | null = null;
    profileImage: File | null = null;
    previewImagePath: string | null = null;
    profile: string | null = null;
    countryCode: string | null = null;
    languageCode: string | null = null;
}
