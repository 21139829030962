export class SearchUserCondition {
    userId: number | null = null;
    index: number | null = null;
    name: string | null = null;
    region: string | null = null;
    latitude: number | null = null;
    longitude: number | null = null;
    gender: number | null = null;
    age: number | null = null;
    attribute: number | null = null;
    lstTagId: number[] | null = null;
    countryCd: string | null = null;
    changed: boolean = false;
}
