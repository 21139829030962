import React from "react";
import { FormattedMessage } from "react-intl";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/Maintenance.scss";

const Maintenance: React.FC = () => {
    return (
        <div className="pageWrapper Maintenance">
            <FormattedMessage id={"under_maintenance"} />
        </div>
    );
};

export default Maintenance;
