import React from "react";
import reactStringReplace from "react-string-replace";
import { Box } from "@mui/material";
import { UserModel, GroupChatMessageData, GroupModel } from "models/Models";
import { AppConstants, DbConstants } from "constants/Constants";
import Utility from "utils/Utility";

type Props = {
    loginUser: UserModel;
    group: GroupModel;
    chatMessage: GroupChatMessageData;
    timeDifference: number;
    langCd: string;
};

export const GroupChatListItem: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== GroupChatListItem");
        /***** 定数、変数 */
        const regExp = /(https?:\/\/\S+)/g;

        /**
         * 文字色取得
         * @param gender
         * @returns
         */
        function getColor(gender: number) {
            if (gender === DbConstants.GENDER_MALE) {
                return AppConstants.BG_COLOR_GENDER_MALE;
            } else if (gender === DbConstants.GENDER_FEMALE) {
                return AppConstants.BG_COLOR_GENDER_MALE;
            } else {
                return AppConstants.BG_COLOR_GENDER_OTHER;
            }
        }

        /**
         * ローカライズ時差取得
         * @param createdAt
         * @returns
         */
        // function getFormattedCreatedAt(createdAt: string): string {
        //     const unixTime = new Date(createdAt).getTime() / 1000;
        //     const formattedCreatedAt = Utility.getFormattedDateTime(
        //         unixTime,
        //         props.timeDifference,
        //         props.langCd
        //     );
        //     return formattedCreatedAt;
        // }

        /**
         * 入室情報コンポーネント
         * @param chatMessage
         */
        const EnterInfo = (props: { chatMessage: GroupChatMessageData }) => {
            return (
                <>
                    {props.chatMessage.message != null &&
                        props.chatMessage.displayTime != null && (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    color: AppConstants.COLOR_GRAY_DATE,
                                    fontSize: "0.8rem",
                                }}
                            >
                                {
                                    // 入室メッセージ
                                }
                                <Box>{props.chatMessage.message}</Box>
                                {
                                    // 時刻
                                }
                                <Box
                                    sx={{
                                        fontSize: "0.7rem",
                                        color: AppConstants.COLOR_GRAY_DATE,
                                    }}
                                >
                                    {props.chatMessage.displayTime}
                                </Box>
                            </Box>
                        )}
                </>
            );
        };
        /**
         * 退室情報コンポーネント
         * @param chatMessage
         */
        const LeaveInfo = (props: { chatMessage: GroupChatMessageData }) => {
            return (
                <>
                    {props.chatMessage.message != null &&
                        props.chatMessage.displayTime != null && (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    color: AppConstants.COLOR_GRAY_DATE,
                                    fontSize: "0.8rem",
                                }}
                            >
                                {
                                    // 入室メッセージ
                                }
                                <Box>{props.chatMessage.message}</Box>
                                {
                                    // 時刻
                                }
                                <Box
                                    sx={{
                                        fontSize: "0.7rem",
                                        color: AppConstants.COLOR_GRAY_DATE,
                                    }}
                                >
                                    {props.chatMessage.displayTime}
                                </Box>
                            </Box>
                        )}
                </>
            );
        };
        /**
         * チャットメッセージコンポーネント
         * @param chatMessage
         */
        const ChatMessage = (props: { chatMessage: GroupChatMessageData }) => {
            if (
                props.chatMessage.sender == null ||
                props.chatMessage.sender.gender == null ||
                props.chatMessage.sender.name == null ||
                props.chatMessage.message == null ||
                props.chatMessage.displayTime == null
            ) {
                return <></>;
            }
            const gender = props.chatMessage.sender.gender;
            const name = props.chatMessage.sender.name;
            const message = props.chatMessage.message;
            const displayTime = props.chatMessage.displayTime;
            return (
                <React.Fragment>
                    {gender != null &&
                        name != null &&
                        message != null &&
                        displayTime != null && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {
                                        // ユーザ名
                                    }
                                    <Box
                                        sx={{
                                            color: getColor(gender),
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {props.chatMessage.sender != null && (
                                            <a
                                                target="_blank"
                                                href={`/users/${Utility.getUserKey2(
                                                    props.chatMessage.sender
                                                )}/profile`}
                                                style={{
                                                    color: "inherit",
                                                }}
                                                rel="noreferrer"
                                            >
                                                {name}
                                            </a>
                                        )}
                                        {props.chatMessage.sender == null && (
                                            <>{name}</>
                                        )}
                                    </Box>
                                    {
                                        // 時刻
                                    }
                                    <Box
                                        sx={{
                                            fontSize: "0.7rem",
                                            color: AppConstants.COLOR_GRAY_DATE,
                                        }}
                                    >
                                        {displayTime}
                                    </Box>
                                </Box>
                                {
                                    // メッセージ
                                }
                                <>
                                    {message.split("\n").map((text, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "flex-start",
                                                }}
                                            >
                                                {reactStringReplace(
                                                    text,
                                                    regExp,
                                                    (match, i) => (
                                                        <React.Fragment
                                                            key={`${index}_${i}`}
                                                        >
                                                            <a
                                                                target="_blank"
                                                                href={match}
                                                                rel="noreferrer"
                                                            >
                                                                {match}
                                                            </a>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </Box>
                                        );
                                    })}
                                </>
                            </Box>
                        )}
                </React.Fragment>
            );
        };
        /**
         * レンダー
         */
        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.loginUser.name != null && (
                    <>
                        {
                            // 入室情報の場合
                        }
                        {props.chatMessage.enter && (
                            <EnterInfo chatMessage={props.chatMessage} />
                        )}
                        {
                            // 退室情報の場合
                        }
                        {props.chatMessage.leave && (
                            <LeaveInfo chatMessage={props.chatMessage} />
                        )}
                        {
                            // チャットメッセージの場合
                        }
                        {!props.chatMessage.enter &&
                            !props.chatMessage.leave && (
                                <ChatMessage chatMessage={props.chatMessage} />
                            )}
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.group !== nextProps.group) {
            return false;
        }
        if (prevProps.group != null && nextProps.group != null) {
            if (prevProps.group.id !== nextProps.group.id) {
                return false;
            }
        }
        if (prevProps.chatMessage !== nextProps.chatMessage) {
            return false;
        }
        if (prevProps.chatMessage != null && nextProps.chatMessage != null) {
            if (prevProps.chatMessage.id !== nextProps.chatMessage.id) {
                return false;
            }
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }

        return true;
    }
);
