import { AttachedFileModel } from "./AttachedFileModel";
import { UserModel } from "./UserModel";

export class TalkMessageModel {
    id: number | null = null;
    senderId: number | null = null;
    sender: UserModel | null = null;
    receiverId: number | null = null;
    receiver: UserModel | null = null;
    message: string | null = null;
    existsAttachedFiles: number | null = null;
    read: number | null = null;
    invalid: number | null = null;
    lstAttachedFileData: AttachedFileModel[] | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    deletedAt: string | null = null;
    selfReturn: boolean | null = null;

    displayTime: string | null = null;
    isHeader: boolean = false;
    displayDate: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // senderId
        if (obj.hasOwnProperty("senderId")) {
            this.senderId = obj.senderId;
        } else if (obj.hasOwnProperty("SenderId")) {
            this.senderId = obj.SenderId;
        }
        // sender
        if (obj.hasOwnProperty("sender")) {
            this.sender = new UserModel(obj.sender);
        } else if (obj.hasOwnProperty("Sender")) {
            this.sender = new UserModel(obj.Sender);
        }
        // receiverId
        if (obj.hasOwnProperty("receiverId")) {
            this.receiverId = obj.receiverId;
        } else if (obj.hasOwnProperty("ReceiverId")) {
            this.receiverId = obj.ReceiverId;
        }
        // receiver
        if (obj.hasOwnProperty("receiver")) {
            this.receiver = new UserModel(obj.receiver);
        } else if (obj.hasOwnProperty("Receiver")) {
            this.receiver = new UserModel(obj.Receiver);
        }
        // message
        if (obj.hasOwnProperty("message")) {
            this.message = obj.message;
        } else if (obj.hasOwnProperty("Message")) {
            this.message = obj.Message;
        }
        // existsAttachedFiles
        if (obj.hasOwnProperty("existsAttachedFiles")) {
            this.existsAttachedFiles = obj.existsAttachedFiles;
        } else if (obj.hasOwnProperty("ExistsAttachedFiles")) {
            this.existsAttachedFiles = obj.ExistsAttachedFiles;
        }
        // read
        if (obj.hasOwnProperty("read")) {
            this.read = obj.read;
        } else if (obj.hasOwnProperty("Read")) {
            this.read = obj.Read;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // lstAttachedFileData
        if (obj.hasOwnProperty("lstAttachedFileData")) {
            this.lstAttachedFileData = [];
            for (let i in obj.lstAttachedFileData) {
                let attachedFile = new AttachedFileModel(
                    obj.lstAttachedFileData[i]
                );
                this.lstAttachedFileData.push(attachedFile);
            }
        } else if (obj.hasOwnProperty("LstAttachedFileData")) {
            this.lstAttachedFileData = [];
            for (let i in obj.LstAttachedFileData) {
                let attachedFile = new AttachedFileModel(
                    obj.LstAttachedFileData[i]
                );
                this.lstAttachedFileData.push(attachedFile);
            }
        } else if (obj.hasOwnProperty("AttachedFile")) {
            this.lstAttachedFileData = [];
            for (let i in obj.AttachedFile) {
                let attachedFile = new AttachedFileModel(obj.AttachedFile[i]);
                this.lstAttachedFileData.push(attachedFile);
            }
        } else if (obj.hasOwnProperty("attachedFile")) {
            this.lstAttachedFileData = [];
            for (let i in obj.attachedFile) {
                let attachedFile = new AttachedFileModel(obj.AttachedFile[i]);
                this.lstAttachedFileData.push(attachedFile);
            }
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
        // selfReturn
        if (obj.hasOwnProperty("selfReturn")) {
            this.selfReturn = obj.selfReturn;
        } else if (obj.hasOwnProperty("SelfReturn")) {
            this.selfReturn = obj.SelfReturn;
        }
    }
}
