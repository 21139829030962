import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import {
    ReceivedParentMailListResponse,
    ChildMailListResponse,
    MailReadResponse,
    MailDeleteResponse,
    UnreadMailResponse,
    MailSendResponse,
    MailReplyResponse,
} from "api/responses/Responses";

export class MailRequest {
    /**
     * 受信親メールリスト取得
     * @param user
     * @param index_receive
     * @param index_send
     * @returns
     */
    static async getReceivedParentMailList(
        user: UserModel,
        limit_unixtime: number,
        prev_limit_unixtime?: number
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.V3_URL_RECEIVE_PARENT_MAIL_LIST;
        url = url.replace(":id", String(user.id));
        url = url + `?limit_unixtime=${limit_unixtime}`;
        if (prev_limit_unixtime != null && prev_limit_unixtime > 0) {
            url = url + `&prev_limit_unixtime=${prev_limit_unixtime}`;
        }
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new ReceivedParentMailListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 送信親メールリスト取得
     * @param user
     * @param index_receive
     * @param index_send
     * @returns
     */
    static async getSentParentMailList(
        user: UserModel,
        limit_unixtime: number,
        prev_limit_unixtime?: number
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.V3_URL_SEND_PARENT_MAIL_LIST;
        url = url.replace(":id", String(user.id));
        url = url + `?limit_unixtime=${limit_unixtime}`;
        if (prev_limit_unixtime != null && prev_limit_unixtime > 0) {
            url = url + `&prev_limit_unixtime=${prev_limit_unixtime}`;
        }
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new ReceivedParentMailListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 子メールリスト取得
     * @param user
     * @param parent_mail_id
     * @returns
     */
    static async getChildMailList(user: UserModel, parent_mail_id: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.V3_URL_CHILD_MAIL_LIST;
        url = url.replace(":id", String(user.id));
        url = url + "?parent_mail_id=" + String(parent_mail_id);
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new ChildMailListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 未読件数取得
     * @param user
     * @returns
     */
    static async getUnreadCount(user: UserModel) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_UNREAD_MAIL_COUNT;
        url = url.replace(":id", String(user.id));
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new UnreadMailResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 送信
     * @param user
     * @returns
     */
    static async send(
        reply: number,
        parent_reply: number,
        user: UserModel,
        target: UserModel,
        title: string,
        body: string,
        file_list: File[] | undefined
    ) {
        if (
            user == null ||
            user.id == null ||
            user.bearerToken == null ||
            target.id == null
        ) {
            return;
        }
        let url = UrlConstants.URL_USER_MAIL_SEND;
        url = url.replace(":id", String(user.id));
        let formData = new FormData();
        formData.append("receiver_id", String(target.id));
        formData.append("title", title);
        formData.append("message", body);
        formData.append("reply", String(reply));
        formData.append("parent_mail_reply", String(parent_reply));
        if (file_list != null && file_list.length > 0) {
            file_list.forEach((file) => {
                formData.append("attachedImageFiles", file);
            });
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new MailSendResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 返信
     * @param user
     * @returns
     */
    static async reply(
        parent_id: number,
        user: UserModel,
        target: UserModel,
        title: string,
        body: string,
        file_list: File[] | undefined
    ) {
        if (
            user == null ||
            user.id == null ||
            user.bearerToken == null ||
            target.id == null
        ) {
            return;
        }
        let url = UrlConstants.URL_USER_MAIL_REPLY;
        url = url.replace(":id", String(user.id));
        let formData = new FormData();
        formData.append("parent_id", String(parent_id));
        formData.append("receiver_id", String(target.id));
        formData.append("title", title);
        formData.append("message", body);
        if (file_list != null && file_list.length > 0) {
            file_list.forEach((file) => {
                formData.append("attachedImageFiles", file);
            });
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new MailReplyResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 既読セット
     * @param user
     * @param mail_id
     * @returns
     */
    static async read(user: UserModel, mail_id: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_USER_MAIL_READ;
        url = url.replace(":id", String(user.id));
        let formData = new FormData();
        formData.append("mail_id", String(mail_id));

        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new MailReadResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 削除
     * @param user
     * @param mail_id_list
     * @param parent_mail_id
     * @param send_delete
     * @param receive_delete
     * @returns
     */
    static async delete(
        user: UserModel,
        mail_id_list: number[],
        parent_mail_id: number,
        send_delete: number,
        receive_delete: number
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_USER_MAIL_DELETE;
        url = url.replace(":id", String(user.id));
        let formData = new FormData();
        const mailIdList = mail_id_list.join(",");
        formData.append("mailIds", mailIdList);
        formData.append("parent_mail_id", String(parent_mail_id));
        formData.append("sendDelete", String(send_delete));
        formData.append("receiveDelete", String(receive_delete));

        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new MailDeleteResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
