import { createSelector } from "reselect";

const processSelector = (state) => state.process;

export const isProcessing = createSelector(
    [processSelector],
    (state) => state.processing
);
export const getProcessingMessage = createSelector(
    [processSelector],
    (state) => state.message
);
