import { TalkMessageModel } from "models/TalkMessageModel";

export class TalkMessageDeleteResponse {
    rtnCd: number | null = null;
    talkMessage: TalkMessageModel | null = null;
    decrementedUnreadTalkCount: boolean | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // talkMessage
        if (obj.hasOwnProperty("TALK_MESSAGE")) {
            this.talkMessage = new TalkMessageModel(obj.TALK_MESSAGE);
        }
        // decrementedUnreadTalkCount
        if (obj.hasOwnProperty("DECREMENTED_UNREAD_TALK_COUNT")) {
            this.decrementedUnreadTalkCount = obj.DECREMENTED_UNREAD_TALK_COUNT;
        }
    }
}
