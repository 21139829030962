import { SystemInfoModel, UrlInfoModel } from "models/Models";

export class SystemInfoResponse {
    systemInfo?: SystemInfoModel | null = null;
    time?: number | null = null;
    urlInfo?: UrlInfoModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // systemInfo
        if (obj.hasOwnProperty("SystemInfo")) {
            this.systemInfo = new SystemInfoModel(obj.SystemInfo);
        }
        // time
        if (obj.hasOwnProperty("Time")) {
            this.time = obj.Time;
        }
        // urlInfo
        if (obj.hasOwnProperty("UrlInfo")) {
            this.urlInfo = new UrlInfoModel(obj.UrlInfo);
        }
    }
}
