import React from "react";
import { Box } from "@mui/material";
import { CategoryModel } from "models/Models";
import { pushDataLayer } from "gtm/gtm"

import "styles/components/CategoryListComponent.scss";

type Props = {
    lstCategory: CategoryModel[] | null;
    selectedCategory: CategoryModel | undefined;
    langCd: string;
    onClick: (category: CategoryModel) => void;
};

export const CategoryListComponent: React.FC<Props> = React.memo(
    (props) => {
        /***** 定数、変数 */

        /***** useEffect */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "カテゴリー一覧(掲示板)",
                path: window.location.pathname,
            });
        }, []);

        /**
         * レンダリング
         */
        return (
            <Box className="component CategoryListComponent">
                {props.lstCategory != null && (
                    <ul className="category-list">
                        {props.lstCategory.map((category: CategoryModel) => {
                            let categoryName = null;
                            switch (props.langCd) {
                                case "ja":
                                    categoryName = category.name;
                                    break;
                                case "cn":
                                    categoryName = category.nameCn;
                                    break;
                                case "tw":
                                    categoryName = category.nameTw;
                                    break;
                                case "es":
                                    categoryName = category.nameEs;
                                    break;
                                case "de":
                                    categoryName = category.nameDe;
                                    break;
                                case "it":
                                    categoryName = category.nameIt;
                                    break;
                                case "ru":
                                    categoryName = category.nameRu;
                                    break;
                            }
                            if (categoryName == null) {
                                categoryName = category.nameEn;
                            }
                            
                            return (
                                <li
                                    key={category.id}
                                    className={
                                        props.selectedCategory != null &&
                                        props.selectedCategory.id ===
                                            category.id
                                            ? "category-item selected"
                                            : "category-item"
                                    }
                                    onClick={() => {
                                        props.onClick(category);
                                    }}
                                >
                                    {categoryName}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevCategoryList = prevProps.lstCategory;
        const nextCategoryList = nextProps.lstCategory;
        if (prevCategoryList !== nextCategoryList) {
            return false;
        }
        const prevSelectedCategory = prevProps.selectedCategory;
        const nextSelectedCategory = nextProps.selectedCategory;
        if (prevSelectedCategory !== nextSelectedCategory) {
            return false;
        }
        if (prevSelectedCategory != null && nextSelectedCategory != null) {
            if (prevSelectedCategory.id !== nextSelectedCategory.id) {
                return false;
            }
        }
        const prevLangCd = prevProps.langCd;
        const nextLangCd = nextProps.langCd;
        if (prevLangCd !== nextLangCd) {
            return false;
        }
        if (prevProps.onClick !== nextProps.onClick) {
            return false;
        }

        return true;
    }
);
