import { UserModel } from "models/Models";

export class AccessTokenResponse {
    rtnCd?: number | null = null;
    accessToken?: string | null = null;
    user?: UserModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // accessToken
        if (obj.hasOwnProperty("BEARER_TOKEN")) {
            this.accessToken = obj.BEARER_TOKEN;
        }
        // user
        if (obj.hasOwnProperty("USER")) {
            this.user = new UserModel(obj.USER);
        }
    }
}
