import React from "react";
import { GalleryModel } from "models/Models";
import { UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";
import "styles/components/listItems/GalleryListItem.scss";

type Props = {
    gallery: GalleryModel;
    selected: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
};

export const GalleryListItem: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== GalleryListItem");
        /**
         * レンダー
         */
        return (
            <div className="GalleryListItem">
                {props.gallery.userId != null &&
                    props.gallery.albumId != null &&
                    props.gallery.fileName != null && (
                        <>
                            {Utility.isImage(props.gallery.fileName) && (
                                <img
                                    className="image"
                                    src={`${UrlConstants.URL_USER_CONTENTS_URL}/gallery_sample/${props.gallery.userId}/${props.gallery.albumId}/${props.gallery.fileName}`}
                                    alt="Profile Content"
                                    onClick={(e) => {
                                        if (props.onClick != null) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props.onClick();
                                        }
                                    }}
                                    onDoubleClick={(e) => {
                                        if (props.onDoubleClick != null) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props.onDoubleClick();
                                        }
                                    }}
                                />
                            )}
                            {Utility.isVideo(props.gallery.fileName) && (
                                <div
                                    className="video-thumbnail-wrapper"
                                    onClick={(e) => {
                                        if (props.onClick) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props.onClick();
                                        }
                                    }}
                                    onDoubleClick={(e) => {
                                        if (props.onDoubleClick != null) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props.onDoubleClick();
                                        }
                                    }}
                                >
                                    <img
                                        className="thumbnail"
                                        src={`${
                                            UrlConstants.URL_USER_CONTENTS_URL
                                        }/gallery/${props.gallery.userId}/${
                                            props.gallery.albumId
                                        }/${Utility.getThumbnailFileName(
                                            props.gallery.fileName ?? ""
                                        )}`}
                                        alt="Profile Content"
                                    />
                                    <img
                                        className="play"
                                        src="/images/btn_play.png"
                                        alt="Play Video Button"
                                    />
                                </div>
                            )}
                        </>
                    )}
                <div
                    className={
                        props.gallery.selected ? "select-wrapper" : "d-none"
                    }
                    onClick={props.onClick}
                    onDoubleClick={props.onDoubleClick}
                ></div>
            </div>
        );
    },

    (prevProps: Props, nextProps: Props) => {
        if (prevProps.gallery !== nextProps.gallery) {
            return false;
        }
        if (prevProps.gallery != null && nextProps.gallery != null) {
            if (prevProps.gallery.id !== nextProps.gallery.id) {
                return false;
            }
        }
        if (prevProps.selected !== nextProps.selected) {
            return false;
        }
        if (prevProps.onClick !== nextProps.onClick) {
            return false;
        }
        if (prevProps.onDoubleClick !== nextProps.onDoubleClick) {
            return false;
        }

        return true;
    }
);
