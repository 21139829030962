import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { CategoryListResponse } from "api/responses/Responses";

export class CategoryRequest {
    /**
     * カテゴリーリスト取得
     * @returns
     */
    static async getList() {
        let url = UrlConstants.URL_CATEGORY_LIST;
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
            });
            if (res != null) {
                result = new CategoryListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
