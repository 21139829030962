import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { ReportResponse } from "api/responses/Responses";

export class ReportRequest {
    /**
     * レポート送信
     * @param user_id
     * @param title
     * @param message
     * @returns
     */
    static async send(user_id: number, title: string, message: string) {
        let formData = new FormData();
        formData.append("title", title);
        formData.append("message", message);
        const url = UrlConstants.URL_REPORT.replace(":id", String(user_id));

        let result = null;
        try {
            const res = await axios.put(url, formData, {
                withCredentials: true,
            });
            if (res != null) {
                result = new ReportResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
