import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

interface Props {
    open: boolean;
    title: string;
    message: string;
    onClose: () => void;
    onOk: () => void;
}

export const OkDialog: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== OkDialog");
        return (
            <Dialog
                className="dialog"
                open={props.open}
                onClose={() => {
                    props.onClose();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent className="dialog-content2">
                    <DialogContentText id="alert-dialog-description">
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="dialog-button"
                        onClick={() => {
                            props.onOk();
                        }}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.open !== nextProps.open) {
            return false;
        }
        if (prevProps.title !== nextProps.title) {
            return false;
        }
        if (prevProps.message !== nextProps.message) {
            return false;
        }
        return true;
    }
);
