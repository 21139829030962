export class AttachedFileModel {
    id: number | null = null;
    type: number | null = null;
    userId: number | null = null;
    tweetId: number | null = null;
    messageBoardId: number | null = null;
    mailId: number | null = null;
    chatMessageId: number | null = null;
    talkMessageId: number | null = null;
    fileName: string | null = null;
    thumbnailName: string | null = null;
    visionResult: number | null = null;
    uploadedOriginal: number | null = null;
    uploadedResizedImage: number | null = null;
    uploadedVideoThumbnail: number | null = null;
    uploadedVideoHls360: number | null = null;
    invalid: number | null = null;
    createdAt: string | null = null;
    deletedAt: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // type
        if (obj.hasOwnProperty("type")) {
            this.type = obj.type;
        } else if (obj.hasOwnProperty("Type")) {
            this.type = obj.Type;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // tweetId
        if (obj.hasOwnProperty("tweetId")) {
            this.tweetId = obj.tweetId;
        } else if (obj.hasOwnProperty("TweetId")) {
            this.tweetId = obj.TweetId;
        }
        // messageBoardId
        if (obj.hasOwnProperty("messageBoardId")) {
            this.messageBoardId = obj.messageBoardId;
        } else if (obj.hasOwnProperty("MessageBoardId")) {
            this.messageBoardId = obj.MessageBoardId;
        }
        // mailId
        if (obj.hasOwnProperty("mailId")) {
            this.mailId = obj.mailId;
        } else if (obj.hasOwnProperty("MailId")) {
            this.mailId = obj.MailId;
        }
        // chatMessageId
        if (obj.hasOwnProperty("chatMessageId")) {
            this.chatMessageId = obj.chatMessageId;
        } else if (obj.hasOwnProperty("ChatMessageId")) {
            this.chatMessageId = obj.ChatMessageId;
        }
        // talkMessageId
        if (obj.hasOwnProperty("talkMessageId")) {
            this.talkMessageId = obj.talkMessageId;
        } else if (obj.hasOwnProperty("TalkMessageId")) {
            this.talkMessageId = obj.TalkMessageId;
        }
        // fileName
        if (obj.hasOwnProperty("fileName")) {
            this.fileName = obj.fileName;
        } else if (obj.hasOwnProperty("FileName")) {
            this.fileName = obj.FileName;
        }
        // thumbnailName
        if (obj.hasOwnProperty("thumbnailName")) {
            this.thumbnailName = obj.thumbnailName;
        } else if (obj.hasOwnProperty("ThumbnailName")) {
            this.thumbnailName = obj.ThumbnailName;
        }
        // visionResult
        if (obj.hasOwnProperty("visionResult")) {
            this.visionResult = obj.visionResult;
        } else if (obj.hasOwnProperty("VisionResult")) {
            this.visionResult = obj.VisionResult;
        }
        // uploadedOriginal
        if (obj.hasOwnProperty("uploadedOriginal")) {
            this.uploadedOriginal = obj.uploadedOriginal;
        } else if (obj.hasOwnProperty("UploadedOriginal")) {
            this.uploadedOriginal = obj.UploadedOriginal;
        }
        // uploadedResizedImage
        if (obj.hasOwnProperty("uploadedResizedImage")) {
            this.uploadedResizedImage = obj.uploadedResizedImage;
        } else if (obj.hasOwnProperty("UploadedResizedImage")) {
            this.uploadedResizedImage = obj.UploadedResizedImage;
        }
        // uploadedVideoThumbnail
        if (obj.hasOwnProperty("uploadedVideoThumbnail")) {
            this.uploadedVideoThumbnail = obj.uploadedVideoThumbnail;
        } else if (obj.hasOwnProperty("UploadedVideoThumbnail")) {
            this.uploadedVideoThumbnail = obj.UploadedVideoThumbnail;
        }
        // uploadedVideoHls360
        if (obj.hasOwnProperty("uploadedVideoHls360")) {
            this.uploadedVideoHls360 = obj.uploadedVideoHls360;
        } else if (obj.hasOwnProperty("UploadedVideoHls360")) {
            this.uploadedVideoHls360 = obj.UploadedVideoHls360;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
    }
}
