import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Card, CardMedia, CardContent } from "@mui/material";
import { UserModel } from "models/Models";
import { DbConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";
import "styles/components/listItems/UserListItem.scss";

type Props = {
    user: UserModel;
    langCd: string;
    timeDifference: number;
    profImageUrlParam: string | null;
};

export const UserListItem: React.FC<Props> = (props) => {
    Utility.log("===== UserListItem IN");

    /**
     * テーマ
     */
    const theme = createTheme({
        components: {
            // Name of the component
            MuiCard: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        fontSize: "1rem",
                    },
                },
            },
        },
    });

    /**
     * フォローのHtml要素生成
     * @returns
     */
    function getFollowHtml() {
        if (props.user.following) {
            return (
                <div className="follow">
                    <FormattedMessage id={"user_following"} />
                </div>
            );
        } else {
            return <></>;
        }
    }
    /**
     * オンラインのHtml要素生成
     * @returns
     */
    // function getOnlineHtml() {
    //     if (props.user.online) {
    //         return (
    //             <div className="online">
    //                 <FormattedMessage id={"user_online"} />
    //             </div>
    //         );
    //     } else {
    //         return <></>;
    //     }
    // }
    /**
     * 属性のHtml要素生成
     * @param gender
     * @param attribute
     * @returns
     */
    function getAttributeHtml() {
        let attributeLabel = Utility.getAttributeLabelKey(props.user);
        if (attributeLabel.length > 0) {
            let className = "attribute";
            if (props.user.gender === DbConstants.GENDER_MALE) {
                className = className + " male";
            } else if (props.user.gender === DbConstants.GENDER_FEMALE) {
                className = className + " female";
            } else {
                className = className + " other";
            }
            let label = Utility.getAttributeLabelKey(props.user);
            if (label != null && label.length > 0) {
                return (
                    <div className={className}>
                        <FormattedMessage id={label} />
                    </div>
                );
            } else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }
    /**
     * 国旗のHtml要素生成
     * @returns
     */
    function getUserFlagHtml() {
        if (props.user.countryCd != null && props.user.countryCd.length > 0) {
            return (
                <img
                    className="flag-image"
                    src={
                        "/images/flag/" +
                        props.user.countryCd?.toUpperCase() +
                        ".png"
                    }
                    alt="Country Flag"
                />
            );
        } else {
            return <span></span>;
        }
    }
    /**
     * 年代のHtml要素生成
     * @returns
     */
    // function getAgeHtml() {
    //     if (props.user.age != null) {
    //         let label = Utility.getAgeLabelKey(props.user.age!);
    //         if (label != null && label.length > 0) {
    //             return (
    //                 <div className="age">
    //                     <FormattedMessage id={label} />
    //                 </div>
    //             );
    //         } else {
    //             return <span></span>;
    //         }
    //     } else {
    //         return <span></span>;
    //     }
    // }
    /**
     * 性別のHtml生成
     * @returns
     */
    // function getGenderHtml() {
    //     if (props.user.gender != null) {
    //         let label = Utility.getGenderLabelKey(props.user.gender);
    //         if (label != null && label.length > 0) {
    //             return (
    //                 <div className=".gender">
    //                     (
    //                     <FormattedMessage id={label} />)
    //                 </div>
    //             );
    //         } else {
    //             return <span></span>;
    //         }
    //     } else {
    //         return <span></span>;
    //     }
    // }
    /**
     * レンダー
     */
    return (
        <ThemeProvider theme={theme}>
            <Card className="UserListItem">
                {
                    // ユーザアイコン
                }
                <CardMedia
                    className="card-image"
                    component="img"
                    image={
                        props.user.iconName != null &&
                        props.user.iconName.length > 0
                            ? props.user.getIconUrl(props.profImageUrlParam ? props.profImageUrlParam : null)
                            : "/images/no_image.png"
                    }
                />
                <CardContent className="card-content">
                    <div className="info-wrapper-1">
                        {
                            // 国旗
                            getUserFlagHtml()
                        }
                        {
                            // 属性
                            getAttributeHtml()
                        }
                    </div>
                    {
                        // 名前
                    }
                    <div className="info-wrapper-2">{props.user.name}</div>
                </CardContent>
                <div className="user-label">
                    {
                        // オンラインラベル
                        // getOnlineHtml()
                    }
                    {
                        // フォローラベル
                        getFollowHtml()
                    }
                </div>
                {props.user.selected && <div className="selected-layer" />}
            </Card>
        </ThemeProvider>
    );
};
