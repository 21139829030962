import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
    Box,
    Button,
    OutlinedInput,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
} from "@mui/material";
import { UserModel, GroupModel, JoinGroupModel } from "models/Models";
import { NetworkAction, UserAction } from "redux/Actions";
import { getOwnGroupList } from "redux/Selectors";
import { NavBar } from "components/Components";
import { GroupRequest, JoinGroupRequest } from "api/requests/Requests";
import { DbConstants, GROUP_JOIN_STATUS } from "constants/Constants";
import { JOIN_GROUP_ACTION } from "constants/Constants";
import Utility from "utils/Utility";
import EditIcon from "assets/images/edit.svg";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/group/GroupDetail.scss";

type Props = {
    loginUser: UserModel | null;
};

const GroupDetail: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupDetail");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { groupId } = useParams<{ groupId: string }>();
        const lstOwnGroup = useSelector((state) => getOwnGroupList(state));
        // 加入申請取り消しボタンラベル
        const BUTTON_LABEL_WITHDRAW_APPLYING = intl.formatMessage({
            id: "btn_withdraw_application",
        });
        // 招待受諾ボタンラベル
        const BUTTON_LABEL_ACCEPT_INVITATION = intl.formatMessage({
            id: "btn_accept",
        });
        // 招待拒否ボタンラベル
        const BUTTON_LABEL_DECLINE_INVITATION = intl.formatMessage({
            id: "btn_decline",
        });
        // グループ解散ボタンラベル
        const BUTTON_LABEL_DISBAND_GROUP = intl.formatMessage({
            id: "btn_group_disband",
        });
        // グループ脱退ボタンラベル
        const BUTTON_LABEL_LEAVE_GROUP = intl.formatMessage({
            id: "btn_group_leave",
        });
        // グループ加入ボタンラベル
        const BUTTON_LABEL_JOIN_GROUP = intl.formatMessage({
            id: "btn_group_join",
        });
        // グループ加入申請ボタンラベル
        const BUTTON_LABEL_APPLY = intl.formatMessage({
            id: "btn_group_apply",
        });

        /***** useRef */
        const processing = React.useRef(false);

        /***** useState */
        // グループ
        const [group, setGroup] = React.useState<GroupModel | null>();
        // ボタンラベル
        const [buttonLabel1, setButtonLabel1] = React.useState<string>();
        const [buttonLabel2, setButtonLabel2] = React.useState<string>();
        // ダイアログ
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        const [dialogMessage, setDialogMessage] = React.useState<string>();
        const [message, setMessage] = React.useState<string>();
        // 処理結果メッセージ
        const [completeMessage, setCompleteMessage] =
            React.useState<string>("");
        const [errMessage, setErrorMessage] = React.useState<string>("");
        // アクション
        const [action, setAction] = React.useState<JOIN_GROUP_ACTION>();
        // 初期データロード完了フラグ
        const [initialDataLoaded, setInitialDataLoaded] =
            React.useState<boolean>(false);
        // 解散済みフラグ
        const [disbanded, setDisbanded] = React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループ詳細",
                path: window.location.pathname,
            })            
        }, []);
        React.useEffect(() => {
            (async () => {
                if (!initialDataLoaded) {
                    await getGroupInfo();
                    setInitialDataLoaded(true);
                }
            })();
        }, [props.loginUser, group]);

        /**
         * 編集ボタン押下時
         */
        function onClickEdit() {
            if (group == null || group.id == null) {
                return;
            }
            navigate(`/groups/${group.id}/edit`);
        }

        /**
         * ボタン１クリック時
         */
        function onClickButton1() {
            if (group == null || group.name == null) {
                return;
            }
            let confirmMessage = null;
            // 加入申請の取り下げ
            if (group.applying) {
                setAction(JOIN_GROUP_ACTION.WITHDRAW_APPLYING);
                confirmMessage = intl.formatMessage({
                    id: "confirm_withdraw_application2",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[@@@@@]",
                    "[" + group.name + "]"
                );
            }
            // 招待を受諾
            else if (group.underInvitation) {
                setAction(JOIN_GROUP_ACTION.ACCEPT_INVITATION);
                confirmMessage = intl.formatMessage({
                    id: "confirm_accept_invitation",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[GROUPNAME]",
                    "[" + group.name + "]"
                );
            }
            // グループ解散
            else if (group.isOwner) {
                setAction(JOIN_GROUP_ACTION.DISBAND_GROUP);
                confirmMessage = intl.formatMessage({
                    id: "confirm_disband_group",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[@@@@@]",
                    "[" + group.name + "]"
                );
            }
            // グループ脱退
            else if (group.join) {
                setAction(JOIN_GROUP_ACTION.LEAVE_GROUP);
                confirmMessage = intl.formatMessage({
                    id: "confirm_leave_group",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[@@@@@]",
                    "[" + group.name + "]"
                );
            }
            // グループ加入
            else if (group.autoJoin === DbConstants.GROUP_AUTO_JOIN) {
                setAction(JOIN_GROUP_ACTION.JOIN);
                confirmMessage = intl.formatMessage({
                    id: "confirm_join_group",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[@@@@@]",
                    "[" + group.name + "]"
                );
            }
            // グループ加入申請
            else {
                setAction(JOIN_GROUP_ACTION.APPLY_FOR_MEMBERSHIP);
                confirmMessage = intl.formatMessage({
                    id: "confirm_apply_for_membership",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[@@@@@]",
                    "[" + group.name + "]"
                );
            }
            if (confirmMessage != null) {
                setDialogMessage(confirmMessage);
                setOpenConfirmDialog(true);
            }
        }
        /**
         * ボタン２クリック時
         */
        async function onClickButton2() {
            if (group == null || group.name == null) {
                return;
            }
            // 招待拒否ボタン押下時
            if (buttonLabel2 === BUTTON_LABEL_DECLINE_INVITATION) {
                setAction(JOIN_GROUP_ACTION.DECLINE_INVITATION);
                let confirmMessage = intl.formatMessage({
                    id: "confirm_decline_invitation",
                });
                if (confirmMessage == null) {
                    return;
                }
                confirmMessage = confirmMessage.replace(
                    "[GROUPNAME]",
                    "[" + group.name + "]"
                );
                setDialogMessage(confirmMessage);
                setOpenConfirmDialog(true);
            }
        }
        async function onClickConfirmDialogYes() {
            if (processing.current) {
                return;
            }
            processing.current = true;
            switch (action) {
                // 加入申請の取り下げ
                case JOIN_GROUP_ACTION.WITHDRAW_APPLYING:
                    await withdrawApplying();
                    break;
                // 招待を受諾
                case JOIN_GROUP_ACTION.ACCEPT_INVITATION:
                    await acceptInvitation();
                    break;
                // グループ解散
                case JOIN_GROUP_ACTION.DISBAND_GROUP:
                    await disbandGroup();
                    break;
                // グループ脱退
                case JOIN_GROUP_ACTION.LEAVE_GROUP:
                    await leaveGroup();
                    break;
                // グループ加入
                case JOIN_GROUP_ACTION.JOIN:
                    await join();
                    break;
                // グループ加入申請
                case JOIN_GROUP_ACTION.APPLY_FOR_MEMBERSHIP:
                    await apply();
                    break;
                // 招待拒否
                case JOIN_GROUP_ACTION.DECLINE_INVITATION:
                    await declineInvitation();
                    break;
                default:
                    return;
            }
            setOpenConfirmDialog(false);
            processing.current = false;
        }

        /**
         * 加入
         * @returns
         */
        async function join() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await JoinGroupRequest.join(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_join",
            });
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
        }
        /**
         * 加入申請
         * @returns
         */
        async function apply() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await JoinGroupRequest.apply(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_applying",
            });
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
        }
        /**
         * 招待を受諾
         */
        async function acceptInvitation() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await JoinGroupRequest.acceptInvitation(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_join",
            });
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
        }
        /**
         * 招待を拒否
         */
        async function declineInvitation() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await JoinGroupRequest.declineInvitation(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            setOpenConfirmDialog(false);
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_decline_invitation",
            });
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
        }
        /**
         * 加入申請を取り下げ
         */
        async function withdrawApplying() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await JoinGroupRequest.withdrawApplying(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_withdraw_applying",
            });
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
        }
        /**
         * グループ解散
         */
        async function disbandGroup() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await GroupRequest.disband(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            setOpenConfirmDialog(false);
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_disband",
            });
            if (lstOwnGroup != null) {
                let lstOwnGroupNew = lstOwnGroup.filter(
                    (ownGroup: GroupModel) => {
                        return group.id !== ownGroup.id;
                    }
                );
                dispatch(
                    UserAction({
                        lstOwnGroup: lstOwnGroupNew,
                    })
                );
            }
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
            setDisbanded(true);
        }
        /**
         * グループ脱退
         */
        async function leaveGroup() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            // if (processing.current) {
            //     return;
            // }
            // processing.current = true;
            const result = await JoinGroupRequest.leave(
                props.loginUser,
                group.id,
                message
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const wkMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrorMessage(wkMessage);
                return;
            }
            setOpenConfirmDialog(false);
            // navigate(-1);
            setMessage("");
            setButtonLabel1(undefined);
            setButtonLabel2(undefined);
            setErrorMessage("");
            let wkMessage = intl.formatMessage({
                id: "msg_complete_group_leave",
            });
            if (wkMessage != null && group != null && group.name != null) {
                wkMessage = wkMessage.replace(
                    "[GROUP]",
                    "[" + group.name + "]"
                );
                setCompleteMessage(wkMessage);
            }
        }
        /**
         * グループ情報取得
         */
        async function getGroupInfo() {
            let wkGroup = await getGroup();
            if (wkGroup == null) {
                return;
            }
            const lstJoinGroup = await getJoinGroupList();

            let wkJoinGroup: JoinGroupModel | null = null;
            if (lstJoinGroup != null) {
                for (let i = 0; i < lstJoinGroup.length; i++) {
                    const joinGroup = lstJoinGroup[i];
                    if (joinGroup.groupId == null || joinGroup.userId == null) {
                        return;
                    }
                    // 加入グループ(申請中、被招待中含む)内にマッチするグループがある場合
                    if (
                        props.loginUser != null &&
                        joinGroup.groupId === wkGroup.id &&
                        joinGroup.userId === props.loginUser.id
                    ) {
                        wkJoinGroup = joinGroup;
                        break;
                    }
                }
            }
            if (wkJoinGroup != null) {
                if (wkJoinGroup.underApplication === 1) {
                    wkGroup.applying = true;
                } else if (wkJoinGroup.underInvitation === 1) {
                    wkGroup.underInvitation = true;
                } else {
                    const tmpGroup = wkJoinGroup.group;
                    if (
                        props.loginUser != null &&
                        tmpGroup != null &&
                        tmpGroup.ownerId === props.loginUser.id
                    ) {
                        wkGroup.isOwner = true;
                    } else {
                        wkGroup.join = true;
                    }
                }
            }
            setGroup(wkGroup);
            setButtonLabel(wkGroup);
        }

        /**
         * ボタンラベル設定
         * @param group
         */
        function setButtonLabel(group: GroupModel) {
            if (props.loginUser == null) {
                return;
            }
            if (group.applying) {
                setButtonLabel1(BUTTON_LABEL_WITHDRAW_APPLYING);
            } else if (group.underInvitation) {
                setButtonLabel1(BUTTON_LABEL_ACCEPT_INVITATION);
                setButtonLabel2(BUTTON_LABEL_DECLINE_INVITATION);
            } else if (group.isOwner) {
                setButtonLabel1(BUTTON_LABEL_DISBAND_GROUP);
            } else if (group.join) {
                setButtonLabel1(BUTTON_LABEL_LEAVE_GROUP);
            } else if (group.autoJoin === DbConstants.GROUP_AUTO_JOIN) {
                setButtonLabel1(BUTTON_LABEL_JOIN_GROUP);
            } else {
                setButtonLabel1(BUTTON_LABEL_APPLY);
            }
        }

        /**
         * グループリスト取得
         * @returns
         */
        async function getJoinGroupList() {
            if (props.loginUser == null || props.loginUser.id == null) {
                return null;
            }
            const result = await JoinGroupRequest.getJoinGroupList(
                props.loginUser,
                props.loginUser.id
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
            return result.lstJoinGroup;
        }

        /**
         * グループ詳細取得
         */
        async function getGroup() {
            if (groupId != null) {
                const result = await GroupRequest.getDetail(Number(groupId));
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }
                if (
                    result.rtnCd != null &&
                    result.rtnCd === 0 &&
                    result.group != null
                ) {
                    return result.group;
                }
            }
        }

        return (
            <div className="pageWrapper GroupDetail">
                {/* ページタイトル */}
                <NavBar
                    showBack={true}
                    title={
                        group == null || group.name == null
                            ? intl.formatMessage({ id: "title_group_detail" })
                            : group.name
                    }
                />
                {
                    // 処理結果表示エリア
                }
                {errMessage.length > 0 && (
                    <Alert
                        severity="error"
                        onClose={() => {
                            setErrorMessage("");
                        }}
                    >
                        {errMessage}
                    </Alert>
                )}
                {completeMessage.length > 0 && (
                    <Alert
                        severity="success"
                        onClose={() => {
                            setCompleteMessage("");
                        }}
                    >
                        {completeMessage}
                    </Alert>
                )}
                {initialDataLoaded && group == null && (
                    <div className="not-exist">
                        <FormattedMessage id={"msg_no_group"} />
                    </div>
                )}
                {initialDataLoaded && group != null && (
                    <>
                        <Box
                            component="h1"
                            className="page-title"
                            display={{
                                xs: "none",
                                sm: "block",
                            }}
                        >
                            {group.name}
                        </Box>
                        <div className="contents-wrapper">
                            {props.loginUser != null &&
                            group.ownerId === props.loginUser.id &&
                            !disbanded && (
                                <div className="icon-edit-wrapper">
                                    <img
                                        className="edit-group"
                                        src={EditIcon}
                                        alt="Edit Group Button"
                                        onClick={onClickEdit}
                                    />
                                </div>
                            )}
                            <div className="info-area">
                                {
                                    // ステータス
                                }
                                {group.applying && (
                                    <div className="group-status-area">
                                        <div className="label">
                                            <FormattedMessage
                                                id={"status"}
                                            />
                                        </div>
                                        <div className="value">
                                            <FormattedMessage
                                                id={"group_under_application"}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!group.applying && group.underInvitation && (
                                    <div className="group-status-area">
                                        <div className="label">
                                            <FormattedMessage
                                                id={"status"}
                                            />
                                        </div>
                                        <div className="value">
                                            <FormattedMessage
                                                id={"group_under_invitation"}
                                            />
                                        </div>
                                    </div>
                                )}
                                {
                                    // オーナー
                                }
                                {group.owner != null &&
                                group.owner.name != null && (
                                    <div className="group-owner-area">
                                        <div className="label">
                                            <FormattedMessage
                                                id={"group_owner"}
                                            />
                                        </div>
                                        <div className="value">
                                            <Link
                                                to={`/users/${Utility.getUserKey(
                                                    group.owner
                                                )}/profile`}
                                            >
                                                {group.owner.name}
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                {
                                    // グループ人数
                                }
                                <div className="group-member-area">
                                    <div className="label">
                                        <FormattedMessage
                                            id={"number_of_group_member"}
                                        />
                                    </div>
                                    <div className="value">
                                        {(group == null ||
                                            group.id == null ||
                                            group.cntMember == null) && <></>}
                                        {group != null &&
                                            group.id != null &&
                                            group.cntMember != null && (
                                                <Link
                                                    to={`/groups/${group.id}/member_list`}
                                                >
                                                    {group.cntMember}
                                                </Link>
                                            )}
                                    </div>
                                </div>
                                {
                                    // 公開設定
                                }
                                <div className="public-setting-area">
                                    <div className="label">
                                        <FormattedMessage
                                            id={"group_public_setting"}
                                        />
                                    </div>
                                    <div className="value">
                                        {group.open != null &&
                                            group.open === 1 && (
                                                <FormattedMessage
                                                    id={"group_public"}
                                                />
                                            )}
                                        {group.open != null &&
                                            group.open === 0 && (
                                                <FormattedMessage
                                                    id={"group_private"}
                                                />
                                            )}
                                    </div>
                                </div>
                                {
                                    // 加入方式
                                }
                                <div className="join-system-area">
                                    <div className="label">
                                        <FormattedMessage
                                            id={"group_join_system"}
                                        />
                                    </div>
                                    <div className="value">
                                        {group.autoJoin != null &&
                                            group.autoJoin === 1 && (
                                                <FormattedMessage
                                                    id={"join_auto_approval"}
                                                />
                                            )}
                                        {group.autoJoin != null &&
                                            group.autoJoin === 0 && (
                                                <FormattedMessage
                                                    id={
                                                        "join_individual_approval"
                                                    }
                                                />
                                            )}
                                    </div>
                                </div>
                                {
                                    // グループプロフィール
                                }
                                <div className="profile-area">
                                    <div className="value">
                                        {group.profile != null &&
                                            group.profile.length > 0 &&
                                            group.profile}
                                    </div>
                                </div>
                            </div>
                            {
                                // ダミー
                                <div className="dummy" />
                            }
                            {
                                // ボタンエリア
                            }
                            {props.loginUser != null && (
                                <div className="button-area">
                                    {buttonLabel1 != null &&
                                        buttonLabel2 != null && (
                                            <>
                                                <Button
                                                    className={
                                                        buttonLabel1 ===
                                                            BUTTON_LABEL_DISBAND_GROUP ||
                                                        buttonLabel1 ===
                                                            BUTTON_LABEL_LEAVE_GROUP
                                                            ? "button red two"
                                                            : "app-button button two"
                                                    }
                                                    variant="text"
                                                    onClick={onClickButton1}
                                                >
                                                    {buttonLabel1}
                                                </Button>
                                                <Button
                                                    className="button red two"
                                                    variant="text"
                                                    onClick={onClickButton2}
                                                >
                                                    {buttonLabel2}
                                                </Button>
                                            </>
                                        )}
                                    {buttonLabel1 != null &&
                                        buttonLabel2 == null && (
                                            <Button
                                                className={
                                                    buttonLabel1 ===
                                                        BUTTON_LABEL_DISBAND_GROUP ||
                                                    buttonLabel1 ===
                                                        BUTTON_LABEL_LEAVE_GROUP
                                                        ? "button red"
                                                        : "app-button button"
                                                }
                                                variant="text"
                                                onClick={onClickButton1}
                                            >
                                                {buttonLabel1}
                                            </Button>
                                        )}
                                    {buttonLabel1 == null &&
                                        buttonLabel2 != null && (
                                            <Button
                                                className="button red"
                                                variant="text"
                                                onClick={onClickButton2}
                                            >
                                                {buttonLabel2}
                                            </Button>
                                        )}
                                </div>
                            )}
                            <Dialog
                                className="dialog"
                                open={openConfirmDialog}
                                onClose={() => {
                                    setOpenConfirmDialog(false);
                                }}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle
                                    className="dialog-title"
                                    id="alert-dialog-title"
                                >
                                    <FormattedMessage
                                        id={"dlg_title_message"}
                                    />
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {dialogMessage}
                                    </DialogContentText>
                                    <div className="message-label">
                                        <FormattedMessage id="message" />
                                    </div>
                                    <OutlinedInput
                                        className="comment"
                                        fullWidth
                                        label=""
                                        type="text"
                                        multiline={true}
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                        rows={5}
                                        value={message}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        className="dialog-button"
                                        onClick={onClickConfirmDialogYes}
                                        color="primary"
                                    >
                                        <FormattedMessage id={"btn_yes2"} />
                                    </Button>
                                    <Button
                                        className="dialog-button"
                                        onClick={() => {
                                            setOpenConfirmDialog(false);
                                        }}
                                        color="primary"
                                    >
                                        <FormattedMessage id={"btn_cancel"} />
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </>
                )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);
export default GroupDetail;
