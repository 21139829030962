import { FollowModel } from "models/Models";

export class FollowerListResponse {
    rtnCd: number | null = null;
    lstFollow: FollowModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstFollow
        if (obj.hasOwnProperty("FollowerList")) {
            if (this.lstFollow == null) {
                this.lstFollow = [];
            }
            for (let i in obj.FollowerList) {
                let follower = new FollowModel(obj.FollowerList[i]);
                this.lstFollow.push(follower);
            }
        }
    }
}
