import { AppConstants } from "constants/AppConstants";
import { SHOOT_TYPE } from "constants/Enum";
import { ShootBackgroundModel } from "models/ShootBackgroundModel";
import { ShootDecorationModel } from "models/ShootDecorationModel";
import Util from "utils/Utility";

export default class IndexedDb {
    static async initialize() {
        Util.log("IndexedDb initialize IN");
        try {
            const openReq: IDBOpenDBRequest = window.indexedDB.open(AppConstants.DB_NAME);
            openReq.onupgradeneeded = await function(event){
                Util.log("openDb onupgradeneeded");
                const db = (event.target as IDBOpenDBRequest).result;
                // db.createObjectStore(AppConstants.STORE_SHOT_ITEM, {keyPath : 'id'})
                // db.createObjectStore(AppConstants.STORE_TRYON_ITEM, {keyPath : 'id'})
                db.createObjectStore(AppConstants.STORE_NAME_SHOOT_RESULT, {keyPath : 'id'})
                db.createObjectStore(AppConstants.STORE_NAME_SHOOT_BACKGROUND, {keyPath : 'id'})
                db.createObjectStore(AppConstants.STORE_NAME_SHOOT_DECORATION, {keyPath : 'id'})
            }
            openReq.onsuccess = await function(event) {
                Util.log("openDb onsuccess");
            }
            openReq.onerror = await function(event) {
                Util.log("openDb onerror");
            }
        } catch (error) {
            Util.error(error);
        }
    }
    private static async openDb(): Promise<IDBDatabase | null> {
        // Util.log("IndexedDb openDb IN")
        return new Promise((resolve, reject) => {
            try {
                const openReq: IDBOpenDBRequest = window.indexedDB.open(AppConstants.DB_NAME);
                openReq.onsuccess = function(event: Event) {
                    // Util.log("openDb onsuccess");
                    try {
                        const db = (event.target as IDBOpenDBRequest).result;
                        resolve(db);
                        return;
                    } catch (error) {
                        Util.error(error)
                        reject(null);
                        return;
                    }
                }
                openReq.onerror = function(event: Event) {
                    Util.log("openDb onerror");
                    reject(null);
                    return;
                }
            } catch (error) {
                Util.error(error);
            }
        });
    }
    static async findPhotoById(id: number): Promise<string | null> {
        // Util.log("IndexedDb findById IN");
        return new Promise(async (resolve, reject) => {
            const tempdb = await IndexedDb.openDb();
            if (tempdb == null) {
                reject(null);
                return;
            }
            const db = tempdb as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_RESULT;
                const trans: IDBTransaction = db.transaction(dbStore);
                const store: IDBObjectStore = trans.objectStore(dbStore);
                const req = store.get(id);
                req.onsuccess = function(event) {
                    const result = (event.target as IDBRequest).result;
                    if (result == null) {
                        reject(null);
                        return;
                    }
                    if (!Object.prototype.hasOwnProperty.call(result, "photo")) {
                        reject(null);
                        return;
                    }
                    
                    resolve(result as string);
                    return;
                }
                req.onerror = function(event) {
                    Util.error("onerror")
                    reject(null);
                    return;
                }
            } catch (error) {
                Util.error(error)
                reject(null);
            } finally {
                db.close();
            }
        });
    }
    static async findVideoById(id: number): Promise<Blob | null> {
        // Util.log("IndexedDb findById IN");
        return new Promise(async (resolve, reject) => {
            const tempdb = await IndexedDb.openDb();
            if (tempdb == null) {
                reject(null);
                return;
            }
            const db = tempdb as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_RESULT;
                const trans: IDBTransaction = db.transaction(dbStore);
                const store: IDBObjectStore = trans.objectStore(dbStore);
                const req = store.get(id);
                req.onsuccess = function(event) {
                    const result = (event.target as IDBRequest).result;
                    if (result == null) {
                        reject(null);
                        return;
                    }
                    if (!Object.prototype.hasOwnProperty.call(result, "video")) {
                        reject(null);
                        return;
                    }

                    resolve(result as Blob);
                    return;
                }
                req.onerror = function(event) {
                    Util.error("onerror")
                    reject(null);
                    return;
                }
            } catch (error) {
                Util.error(error)
                reject(null);
            } finally {
                db.close();
            }
        });
    }
    static async findAllShootBackground(): Promise<ShootBackgroundModel[] | null> {
        Util.log("IndexedDb findAllShootBackground IN");
        return new Promise(async (resolve, reject) => {
            const tempdb = await IndexedDb.openDb();
            if (tempdb == null) {
                reject(null);
                return;
            }
            const db = tempdb as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_BACKGROUND;
                const trans: IDBTransaction = db.transaction(dbStore);
                const store: IDBObjectStore = trans.objectStore(dbStore);
                const req = store.getAll();
                req.onsuccess = function(event) {
                    const result = (event.target as IDBRequest).result;
                    if (result == null) {
                        reject(null);
                        return;
                    }
                    const lstItem: ShootBackgroundModel[] = [];
                    for (let i=0; i<result.length; i++) {
                        if (!Object.prototype.hasOwnProperty.call(result[i], "id")) {
                            continue;
                        }
                        if (!Object.prototype.hasOwnProperty.call(result[i], "url")) {
                            continue;
                        }
                        const item = new ShootBackgroundModel(result[i]);
                        lstItem.push(item);
                    }

                    resolve(lstItem);
                    return;
                }
                req.onerror = function(event) {
                    Util.error("onerror")
                    reject(null);
                    return;
                }
            } catch (error) {
                Util.error(error)
                reject(null);
            } finally {
                db.close();
            }
        });
    }
    static async findAllShootDecoration(): Promise<ShootDecorationModel[] | null> {
        // Util.log("IndexedDb findById IN");
        return new Promise(async (resolve, reject) => {
            const tempdb = await IndexedDb.openDb();
            if (tempdb == null) {
                reject(null);
                return;
            }
            const db = tempdb as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_DECORATION;
                const trans: IDBTransaction = db.transaction(dbStore);
                const store: IDBObjectStore = trans.objectStore(dbStore);
                const req = store.getAll();
                req.onsuccess = function(event) {
                    const result = (event.target as IDBRequest).result;
                    if (result == null) {
                        reject(null);
                        return;
                    }
                    const lstItem: ShootDecorationModel[] = [];
                    for (let i=0; i<result.length; i++) {
                        if (!Object.prototype.hasOwnProperty.call(result[i], "id")) {
                            continue;
                        }
                        if (!Object.prototype.hasOwnProperty.call(result[i], "url")) {
                            continue;
                        }
                        const item = new ShootDecorationModel(result[i]);
                        lstItem.push(item);
                    }

                    resolve(lstItem);
                    return;
                }
                req.onerror = function(event) {
                    Util.error("onerror")
                    reject(null);
                    return;
                }
            } catch (error) {
                Util.error(error)
                reject(null);
            } finally {
                db.close();
            }
        });
    }

    static async writePhoto(
        photo: string
    ): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const result = await IndexedDb.openDb();
            if (result == null) {
                reject(-1);
                return;
            }
            const db = result as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_RESULT;
                const trans: IDBTransaction = db.transaction(dbStore, "readwrite");
                const store: IDBObjectStore = trans.objectStore(dbStore);
                const id = new Date().getTime();
                const putReq = store.put({
                    id: id,
                    photo: photo
                });
                putReq.onsuccess = function() {
                    resolve(id);
                    return;
                }
                putReq.onerror = function() {
                    reject(-1);
                    return;
                }
                trans.oncomplete = function() {
                }
            } catch (error) {
                Util.error(error)
                reject(-1);
                return;
            } finally {
                db.close();
            }
        });
    }
    static async writeVideo(
        video: Blob
    ): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const result = await IndexedDb.openDb();
            if (result == null) {
                reject(-1);
                return;
            }
            const db = result as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_RESULT;
                const trans: IDBTransaction = db.transaction(dbStore, "readwrite");
                const store: IDBObjectStore = trans.objectStore(dbStore);
                const id = new Date().getTime();
                const putReq = store.put({
                    id: id,
                    video: video
                });
                putReq.onsuccess = function() {
                    resolve(id);
                    return;
                }
                putReq.onerror = function() {
                    reject(-1);
                    return;
                }
                trans.oncomplete = function() {
                }
            } catch (error) {
                Util.error(error)
                reject(-1);
                return;
            } finally {
                db.close();
            }
        });
    }
    static async deleteShootResult(id: number): Promise<boolean>
    {
        return new Promise(async (resolve, reject) => {
            const tempdb = await IndexedDb.openDb();
            if (tempdb == null) {
                reject(null);
                return;
            }
            const db = tempdb as IDBDatabase;
            try {
                let dbStore = AppConstants.STORE_NAME_SHOOT_RESULT;
                const trans: IDBTransaction = db.transaction(dbStore, "readwrite");
                const store: IDBObjectStore = trans.objectStore(dbStore);
                var req = store.delete(id);
                req.onsuccess = function(event) {
                    resolve(true);
                };
                req.onerror = function(event) {
                    reject(false);
                };
            } catch (error) {
                reject(false);
                Util.error(error);
            }
        })
    }
}