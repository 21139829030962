export const SELECTED_ITEM = "SELECTED_ITEM";

export const SelectedItemAction = (selectedItemState) => {
    let payload = {};
    if (Object.keys(selectedItemState).indexOf("userId") > -1) {
        payload["userId"] = selectedItemState.userId;
    }
    if (Object.keys(selectedItemState).indexOf("user") > -1) {
        payload["user"] = selectedItemState.user;
    }
    if (Object.keys(selectedItemState).indexOf("group") > -1) {
        payload["group"] = selectedItemState.group;
    }
    if (Object.keys(selectedItemState).indexOf("parentMail") > -1) {
        payload["parentMail"] = selectedItemState.parentMail;
    }
    if (Object.keys(selectedItemState).indexOf("childMail") > -1) {
        payload["childMail"] = selectedItemState.childMail;
    }
    if (Object.keys(selectedItemState).indexOf("talkUser") > -1) {
        payload["talkUser"] = selectedItemState.talkUser;
    }
    if (Object.keys(selectedItemState).indexOf("album") > -1) {
        payload["album"] = selectedItemState.album;
    }
    if (Object.keys(selectedItemState).indexOf("lstUser") > -1) {
        payload["lstUser"] = selectedItemState.lstUser;
    }
    if (Object.keys(selectedItemState).indexOf("lstGroup") > -1) {
        payload["lstGroup"] = selectedItemState.lstGroup;
    }
    if (Object.keys(selectedItemState).indexOf("country") > -1) {
        payload["country"] = selectedItemState.country;
    }
    if (Object.keys(selectedItemState).indexOf("category") > -1) {
        payload["category"] = selectedItemState.category;
    }

    return {
        type: SELECTED_ITEM,
        payload: payload,
    };
};
