import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import {
    TalkUserListResponse,
    TalkUserResponse,
    CreateTalkUserResponse,
    FavoriteTalkUserListResponse
} from "api/responses/Responses";

export class TalkUserRequest {
    /**
     * トークユーザリスト取得
     * @param user
     * @param index
     * @returns
     */
    static async getTalkUserList(user: UserModel, index: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_USER_LIST;
        url = url.replace(":user_id", String(user.id));
        url = url + "?index=" + String(index);
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkUserListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * トークユーザ検索取得
     * @param user
     * @param index
     * @returns
     */
    static async searchTalkUser(user: UserModel, name: string, index: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_USER_SEARCH;
        url = url.replace(":user_id", String(user.id));
        url = url + "?index=" + String(index);
        url = url + "&Name=" + name;
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkUserListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * お気に入りトークユーザ一覧取得
     * @param user 
     * @returns 
     */
    static async getFavoriteTalkUserList(user: UserModel) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_FAVORITE_TALK_USER_LIST;
        url = url.replace(":user_id", String(user.id));
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new FavoriteTalkUserListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * トークユーザ取得
     * @param user
     * @param partner_id
     * @returns
     */
    static async getTalkUser(user: UserModel, partner_id: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_USER;
        url = url.replace(":user_id", String(user.id));
        url = url.replace(":partner_id", String(partner_id));
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkUserResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * トークユーザ作成
     * @param user
     * @param partner_id
     * @returns
     */
    static async createTalkUser(user: UserModel, partner_id: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_USER_CREATE;
        url = url.replace(":user_id", String(user.id));
        let formData = new FormData();
        formData.append("partner_id", String(partner_id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new CreateTalkUserResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
