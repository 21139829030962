import { MailModel, ParentMailModel } from "models/Models";

export class ChildMailListResponse {
    rtnCd?: number | null = null;
    parentMail?: ParentMailModel | null = null;
    lstChildMail?: MailModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // parentMail
        if (obj.hasOwnProperty("ParentMail")) {
            this.parentMail = new ParentMailModel(obj.ParentMail);
        }
        // lstChildMail
        if (obj.hasOwnProperty("ListChildMail")) {
            if (this.lstChildMail == null) {
                this.lstChildMail = [];
            }
            for (let i in obj.ListChildMail) {
                let mail = new MailModel(obj.ListChildMail[i]);
                this.lstChildMail.push(mail);
            }
        }
    }
}
