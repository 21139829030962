export class ShootBackgroundModel {
    id: number | null = null;
    url: string | null = null;
    data: string | null = null;
    selected: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        }
        // url
        if (obj.hasOwnProperty("url")) {
            this.url = obj.url;
        }
        // data
        if (obj.hasOwnProperty("data")) {
            this.data = obj.data;
        }
    }
}
