import { UserModel } from "./UserModel";
import { GroupModel } from "./GroupModel";

export class JoinGroupModel {
    id: number | null = null;
    groupId: number | null = null;
    group: GroupModel | null = null;
    userId: number | null = null;
    user: UserModel | null = null;
    underApplication: number | null = null;
    underInvitation: number | null = null;
    createdAt: string | null = null;
    deletedAt: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // groupId
        if (obj.hasOwnProperty("groupId")) {
            this.groupId = obj.groupId;
        } else if (obj.hasOwnProperty("GroupId")) {
            this.groupId = obj.GroupId;
        }
        // group
        if (obj.hasOwnProperty("group")) {
            this.group = new GroupModel(obj.group);
        } else if (obj.hasOwnProperty("Group")) {
            this.group = new GroupModel(obj.Group);
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // user
        if (obj.hasOwnProperty("user")) {
            this.user = new UserModel(obj.user);
        } else if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // underApplication
        if (obj.hasOwnProperty("underApplication")) {
            this.underApplication = obj.underApplication;
        } else if (obj.hasOwnProperty("UnderApplication")) {
            this.underApplication = obj.UnderApplication;
        }
        // underInvitation
        if (obj.hasOwnProperty("underInvitation")) {
            this.underInvitation = obj.underInvitation;
        } else if (obj.hasOwnProperty("UnderInvitation")) {
            this.underInvitation = obj.UnderInvitation;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
    }
}
