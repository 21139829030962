import { createSelector } from "reselect";

const historySelector = (state) => state.history;

export const getUserListHistory = createSelector(
    [historySelector],
    (state) => state.userListHistory
);
export const getReceivedMailListHistory = createSelector(
    [historySelector],
    (state) => state.receivedMailListHistory
);
export const getSentMailListHistory = createSelector(
    [historySelector],
    (state) => state.sentMailListHistory
);
