import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Utility from "utils/Utility";
import "styles/components/AdContentsLeftComponent.scss";
import { AppConstants } from "constants/AppConstants";

export const AdContentsLeftComponent: React.FC<any> = React.memo(
    (props) => {
        // Utility.log("@@@@@ AdContentsLeftComponent IN");
        /***** 定数、変数 */

        /**
         * レンダリング
         */
        return (
            <div className="ad-area left">
                <div className="dummy-area" />
                <div className="ad-contents left">
                    <div className="slider-wrapper">
                        <Splide
                            className="slider-container"
                            options={ {
                                type: 'loop',
                                role: 'group',
                                autoplay: true,
                                interval: AppConstants.AD_INTERVAL_LEFT,
                                width: '320px',
                                fixedWidth: '320px',
                                height: '100%',
                                arrows: false,
                                pagination: false,
                                padding: 0,
                            } }
                        >
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // 大阪王将公式通販(A8)
                                    }
                                    <div className="ad-item">
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IWZ+28YZTM+598S+5ZMCH" rel="nofollow">
                                            <img width="300" height="250" alt="" src="https://www24.a8.net/svt/bgt?aid=240209603136&wid=002&eno=01&mid=s00000024526001006000&mc=1" />
                                        </a>
                                        <img width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z0IWZ+28YZTM+598S+5ZMCH" alt="" />
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IWZ+28YZTM+598S+5YRHE" rel="nofollow">大阪王将公式通販</a>
                                        <img width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3Z0IWZ+28YZTM+598S+5YRHE" alt="" />
                                    </div>
                                    {
                                        // ハウス食品公式オンラインショップ(楽天)
                                    }
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    <div style={{
                                                        border:"1px solid #95A5A6",
                                                        borderRadius:".75rem",
                                                        backgroundColor:"#FFFFFF",
                                                        width:"310px",
                                                        margin:"0px",
                                                        padding:"5px 0",
                                                        textAlign:"center",
                                                        overflow:"hidden"
                                                    }}>
                                                    <a href="https://hb.afl.rakuten.co.jp/ichiba/393de2fb.25de8c28.393de2fc.6bac6fb6/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fhousefoods-official%2F10000061%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                        target="_blank" 
                                                        rel="nofollow sponsored noopener" 
                                                        style={{wordWrap:"break-word"}}
                                                    >
                                                    <img src="https://hbb.afl.rakuten.co.jp/hgb/393de2fb.25de8c28.393de2fc.6bac6fb6/?me_id=1401923&item_id=10000074&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Fhousefoods-official%2Fcabinet%2Fitem%2Fgekikara%2Fgekikara_7shu_01.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                        style={{margin:"2px"}} 
                                                        alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                        title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" />
                                                    </a>
                                                    <p style={{
                                                        fontSize:"12px",
                                                        lineHeight:"1.4em",
                                                        textAlign:"left",
                                                        margin:"0px",
                                                        padding:"2px 6px",
                                                        wordWrap:"break-word"
                                                    }}>
                                                    <a href="https://hb.afl.rakuten.co.jp/ichiba/393de2fb.25de8c28.393de2fc.6bac6fb6/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fhousefoods-official%2F10000061%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                        target="_blank" 
                                                        rel="nofollow sponsored noopener" 
                                                        style={{wordWrap:"break-word"}}
                                                    >
                                                        【ポイント20倍 2/4 20:00～2/10 1:59】【公式】ハウス食品 しあわせの激辛7種セット(チキンカレー・麻婆豆腐・辛ボナーラ・ラーメン 5食パック・辛ボロネーゼ・炒飯の素 1人前×2食入り・麻辣鍋の素 1人前×2食入り)旨さと強烈な辛さが両立した激辛詰合わせ【WEB限定】
                                                    </a>
                                                    <br />
                                                    <span >価格：4,356円（税込、送料無料)
                                                    </span> 
                                                    <span style={{color:"#BBB"}}>
                                                        (2024/2/9時点)
                                                    </span>
                                                    </p>
                                                    <div style={{margin:"15px"}}>
                                                    <a href="https://hb.afl.rakuten.co.jp/ichiba/393de2fb.25de8c28.393de2fc.6bac6fb6/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fhousefoods-official%2F10000061%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                        target="_blank" 
                                                        rel="nofollow sponsored noopener" 
                                                        style={{wordWrap:"break-word"}}
                                                    >
                                                        <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg" 
                                                            style={{
                                                                float:"left",
                                                                maxHeight:"27px",
                                                                width:"auto",
                                                                marginTop:"5px"}}
                                                        />
                                                    </a>
                                                    <a href="https://hb.afl.rakuten.co.jp/ichiba/393de2fb.25de8c28.393de2fc.6bac6fb6/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fhousefoods-official%2F10000061%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                        target="_blank" 
                                                        rel="nofollow sponsored noopener" 
                                                        style={{wordWrap:"break-word"}}
                                                    >
                                                        <div style={{
                                                            float:"right",
                                                            width:"50%",
                                                            height:"32px",
                                                            backgroundColor:"#bf0000",
                                                            color:"#ffffff",
                                                            fontSize:"14px",
                                                            fontWeight:"500",
                                                            lineHeight:"32px",
                                                            marginLeft:"1px",
                                                            padding: "0 12px",
                                                            borderRadius:"16px",
                                                            cursor:"pointer",
                                                            textAlign:"center",
                                                        }}>
                                                            楽天で購入 
                                                        </div>
                                                    </a>
                                                    </div>
                                                    </div>
                                                    <br />
                                                    <p style={{
                                                        color:"#000000",
                                                        fontSize:"12px",
                                                        lineHeight:"1.4em",
                                                        margin:"5px",
                                                        wordWrap:"break-word"}}
                                                    >
                                                    </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // 毎日“考える・買う・作る"を時短に 【食材宅配「ショクブン」】(21-1224)
                                    }
                                    <div className="ad-item">
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6P4LJU+4X5I+5ZMCH" rel="nofollow">
                                            <img width="300" height="250" alt="" src="https://www25.a8.net/svt/bgt?aid=240210624405&wid=002&eno=01&mid=s00000022959001006000&mc=1"/>
                                        </a>
                                        <img width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3Z0JPC+6P4LJU+4X5I+5ZMCH" alt=""/>
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6P4LJU+4X5I+5YJRM" rel="nofollow">毎日“考える・買う・作る"を時短に 【食材宅配「ショクブン」】</a>
                                        <img width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3Z0JPC+6P4LJU+4X5I+5YJRM" alt=""></img>
                                    </div>
                                    {
                                        // 黄さんの手作りキムチ　高麗食品
                                    }
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{
                                                            border:"1px solid #95A5A6",
                                                            borderRadius:".75rem",
                                                            backgroundColor:"#FFFFFF",
                                                            width:"310px",
                                                            margin:"0px",
                                                            padding:"5px 0",
                                                            textAlign:"center",
                                                            overflow:"hidden"
                                                        }}>
                                                            <a href="https://hb.afl.rakuten.co.jp/ichiba/3952d434.f1f54d56.3952d435.4ef412e1/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkimchi88%2Ffan-ramen%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9"
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}
                                                            >
                                                                <img src="https://hbb.afl.rakuten.co.jp/hgb/3952d434.f1f54d56.3952d435.4ef412e1/?me_id=1265409&item_id=10000420&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Fkimchi88%2Fcabinet%2Framen1217.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                                    style={{margin:"2px"}} 
                                                                    alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                                    title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]"/>
                                                            </a>
                                                            <p style={{
                                                                fontSize:"12px",
                                                                lineHeight:"1.4em",
                                                                textAlign:"left",
                                                                margin:"0px",
                                                                padding:"2px 6px",
                                                                wordWrap:"break-word"
                                                            }}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/3952d434.f1f54d56.3952d435.4ef412e1/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkimchi88%2Ffan-ramen%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}>
                                                                    炎の黄ラーメン（4食セット）辛さは甘辛味と激辛味の2段階から選べます。辛いものが苦手なら甘辛味がお勧め。生麺使用の本格韓国ラーメン【キムチ 韓国料理 お漬物 本場 本格 キムチ鍋 キムチチゲ ケジャン 激辛 冷麺 チャンジャ 辛い 訳あり 食品】【メール便】【送料無料】
                                                                </a>
                                                                <br/>
                                                                <span >価格：1,080円（税込、送料無料)</span>
                                                                <span style={{color:"#BBB"}}>(2024/2/13時点)</span>
                                                            </p>
                                                            <div style={{margin:"15px"}}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/3952d434.f1f54d56.3952d435.4ef412e1/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkimchi88%2Ffan-ramen%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}>
                                                                    <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg"
                                                                        style={{
                                                                            float:"left",
                                                                            maxHeight:"27px",
                                                                            width:"auto",
                                                                            marginTop:"5px"
                                                                        }} 
                                                                    />
                                                                </a>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/3952d434.f1f54d56.3952d435.4ef412e1/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkimchi88%2Ffan-ramen%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <div style={{
                                                                        float:"right",
                                                                        width:"50%",
                                                                        height:"32px",
                                                                        backgroundColor:"#bf0000",
                                                                        color:"#fff",
                                                                        fontSize:"14px",
                                                                        fontWeight:"500",
                                                                        lineHeight:"32px",
                                                                        marginLeft:"1px",
                                                                        padding: "0 12px",
                                                                        borderRadius:"16px",
                                                                        cursor:"pointer",
                                                                        textAlign:"center"
                                                                    }}>
                                                                        楽天で購入 
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p style={{
                                                            color:"#000000",
                                                            fontSize:"12px",
                                                            lineHeight:"1.4em",
                                                            margin:"5px",
                                                            wordWrap:"break-word"
                                                            }}
                                                        >
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // ★YOSHIKEI★４つの選べるミールキットお試し5days　＼全国で好評販売中／(15-0825)
                                    }
                                    <div className="ad-item">
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6KD4PM+1QM6+I17WX" rel="nofollow">
                                            <img width="300" height="250" alt="" src="https://www20.a8.net/svt/bgt?aid=240210624397&wid=002&eno=01&mid=s00000008115003029000&mc=1"/>
                                        </a>
                                        <img width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3Z0JPC+6KD4PM+1QM6+I17WX" alt=""/>
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6KD4PM+1QM6+HV7V6" rel="nofollow">食材宅配のヨシケイ</a>
                                        <img width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3Z0JPC+6KD4PM+1QM6+HV7V6" alt=""/>
                                    </div>
                                    {
                                        // お肉 ベーコン ハム スターゼン
                                    }
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{
                                                            border:"1px solid #95A5A6",
                                                            borderRadius:".75rem",
                                                            backgroundColor:"#FFFFFF",
                                                            width:"310px",
                                                            margin:"0px",
                                                            padding:"5px 0",
                                                            textAlign:"center",
                                                            overflow:"hidden"
                                                        }}>
                                                            <a href="https://hb.afl.rakuten.co.jp/ichiba/395d8b22.1e55c87e.395d8b23.69dc8691/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fstarzen-k%2F176%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}
                                                            >
                                                                <img src="https://hbb.afl.rakuten.co.jp/hgb/395d8b22.1e55c87e.395d8b23.69dc8691/?me_id=1277929&item_id=10000407&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Fstarzen-k%2Fcabinet%2Fkakou%2Fwiner%2F176sin.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                                    style={{margin:"2px"}} 
                                                                    alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                                    title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]"/>
                                                            </a>
                                                            <p style={{
                                                                fontSize:"12px",
                                                                lineHeight:"1.4em",
                                                                textAlign:"left",
                                                                margin:"0px",
                                                                padding:"2px 6px",
                                                                wordWrap:"break-word"
                                                            }}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395d8b22.1e55c87e.395d8b23.69dc8691/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fstarzen-k%2F176%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    旨辛 1.5kg セット チキンスティック スパイシー 1kg 旨激辛 チョリソー 500g あらびき ウインナー 国産鶏肉 送料無料 ギフト 業務用 冷凍食品 大容量 詰合せ おつまみ お弁当 おかず 唐揚げ からあげ 鶏肉 豚肉 骨付き肉 骨付き鶏 ソーセージ BBQ ギフト 贈り物 オードブル
                                                                </a>
                                                                <br/>
                                                                <span >価格：3,480円（税込、送料無料)</span> 
                                                                <span style={{color:"#BBB"}}>(2024/2/13時点)</span>
                                                            </p>
                                                            <div style={{margin:"15px"}}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395d8b22.1e55c87e.395d8b23.69dc8691/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fstarzen-k%2F176%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg" 
                                                                        style={{
                                                                            float:"left",
                                                                            maxHeight:"27px",
                                                                            width:"auto",
                                                                            marginTop:"5px"
                                                                        }}
                                                                    />
                                                                </a>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395d8b22.1e55c87e.395d8b23.69dc8691/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fstarzen-k%2F176%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <div style={{
                                                                        float:"right",
                                                                        width:"50%",
                                                                        height:"32px",
                                                                        backgroundColor:"#bf0000",
                                                                        color:"#fff",
                                                                        fontSize:"14px",
                                                                        fontWeight:"500",
                                                                        lineHeight:"32px",
                                                                        marginLeft:"1px",
                                                                        padding: "0 12px",
                                                                        borderRadius:"16px",
                                                                        cursor:"pointer",
                                                                        textAlign:"center"
                                                                    }}>
                                                                        楽天で購入 
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p style={{
                                                            color:"#000000",
                                                            fontSize:"12px",
                                                            lineHeight:"1.4em",
                                                            margin:"5px",
                                                            wordWrap:"break-word"
                                                        }}></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // 年間来店者26万人超！有名アスリート、著名人が通う【筋肉食堂】が提供する宅食サービス(21-0520)
                                    }
                                        <div className="ad-item">
                                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+5YXIXM+4R6I+601S1" rel="nofollow">
                                                <img width="300" height="250" alt="" src="https://www27.a8.net/svt/bgt?aid=240210624361&wid=002&eno=01&mid=s00000022185001008000&mc=1"/>
                                            </a>
                                            <img width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3Z0JPC+5YXIXM+4R6I+601S1" alt=""/>
                                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+5YXIXM+4R6I+61RIA" rel="nofollow">今日の食事が明日の自分のカラダを創る【筋肉食堂DELI】</a>
                                            <img width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3Z0JPC+5YXIXM+4R6I+61RIA" alt=""/>
                                    </div>
                                    {
                                        // 激辛壺ニラ調味料 4個セット(花月嵐)
                                    }
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{
                                                            border:"1px solid #95A5A6",
                                                            borderRadius:".75rem",
                                                            backgroundColor:"#FFFFFF",
                                                            width:"310px",
                                                            margin:"0px",
                                                            padding:"5px 0",
                                                            textAlign:"center",
                                                            overflow:"hidden"
                                                        }}>
                                                            <a href="https://hb.afl.rakuten.co.jp/ichiba/395dbb6c.a2d290db.395dbb6d.cb9e4048/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkagetsuarashi%2F101%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}
                                                            >
                                                                <img src="https://hbb.afl.rakuten.co.jp/hgb/395dbb6c.a2d290db.395dbb6d.cb9e4048/?me_id=1403799&item_id=10000031&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Fkagetsuarashi%2Fcabinet%2F08817879%2Fimgrc0130344128.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                                    style={{margin:"2px"}} 
                                                                    alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                                    title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]"/>
                                                            </a>
                                                            <p style={{
                                                                fontSize:"12px",
                                                                lineHeight:"1.4em",
                                                                textAlign:"left",
                                                                margin:"0px",
                                                                padding:"2px 6px",
                                                                wordWrap:"break-word"
                                                            }}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395dbb6c.a2d290db.395dbb6d.cb9e4048/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkagetsuarashi%2F101%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}>
                                                                    激辛壺ニラ調味料 4個セット らあめん花月嵐　|壺ニラ 壺にら にら ニラ 調味料 激辛 唐辛子 卓上調味料 トッピング ラーメン チャーハン 餃子 炒め物 冷奴 お鍋 瓶詰調味料 花月嵐 ギフト 瓶詰 ご飯のお供 ごはんのおとも 有名店
                                                                </a>
                                                                <br/>
                                                                <span >価格：2,140円（税込、送料別)</span> 
                                                                <span style={{color:"#BBB"}}>(2024/2/13時点)</span>
                                                            </p>
                                                            <div style={{margin:"15px"}}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395dbb6c.a2d290db.395dbb6d.cb9e4048/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkagetsuarashi%2F101%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg" 
                                                                        style={{
                                                                            float:"left",
                                                                            maxHeight:"27px",
                                                                            width:"auto",
                                                                            marginTop:"5px"
                                                                        }}
                                                                    />
                                                                </a>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395dbb6c.a2d290db.395dbb6d.cb9e4048/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fkagetsuarashi%2F101%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <div style={{
                                                                        float:"right",
                                                                        width:"50%",
                                                                        height:"32px",
                                                                        backgroundColor:"#bf0000",
                                                                        color:"#fff",
                                                                        fontSize:"14px",
                                                                        fontWeight:"500",
                                                                        lineHeight:"32px",
                                                                        marginLeft:"1px",
                                                                        padding: "0 12px",
                                                                        borderRadius:"16px",
                                                                        cursor:"pointer",
                                                                        textAlign:"center"
                                                                    }}>
                                                                        楽天で購入 
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p style={{
                                                            color:"#000000",
                                                            fontSize:"12px",
                                                            lineHeight:"1.4em",
                                                            margin:"5px",
                                                            wordWrap:"break-word"
                                                        }}></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
            </div>
        );
    },
    () => {
        return true;
    }
);
