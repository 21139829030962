import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardHeader, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { UserModel } from "models/Models";
import { DbConstants } from "constants/Constants";
import Utility from "utils/Utility";
import "styles/components/listItems/ResponseListItem.scss";

type Props = {
    index: number;
    user: UserModel | null;
    body: string | null;
    createdAt: string | null;
    langCd: string;
    timeDifference: number;
};

export const ResponseListItem: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== ResponseListItem");
        /***** 定数、変数 */
        const regExp = /(https?:\/\/\S+)/g;

        /**
         * ユーザ名取得
         * @param user
         * @returns
         */
        function getUserName(user: UserModel | null) {
            if (user == null || user.name == null || user.name.length === 0) {
                return "";
            } else {
                return user.name;
            }
        }

        /**
         * 国旗のHtml要素生成
         * @returns
         */
        function getUserFlagHtml(user: UserModel | null) {
            if (user == null) {
                return <></>;
            }
            if (user.countryCd != null && user.countryCd.length > 0) {
                return (
                    <img
                        className="img-flag"
                        src={
                            "/images/flag/" +
                            user.countryCd?.toUpperCase() +
                            ".png"
                        }
                        alt="Country Flag"
                    />
                );
            } else {
                return <></>;
            }
        }
        /**
         * 属性のHtml要素生成
         * @param gender
         * @param attribute
         * @returns
         */
        function getAttributeHtml(user: UserModel | null) {
            if (user == null) {
                return <></>;
            }
            let attributeLabel = Utility.getAttributeLabelKey(user);
            if (attributeLabel.length > 0) {
                let className = "other";
                if (user.gender === DbConstants.GENDER_MALE) {
                    className = "male";
                } else if (user.gender === DbConstants.GENDER_FEMALE) {
                    className = "female";
                }
                let label = Utility.getAttributeLabelKey(user);
                if (label != null && label.length > 0) {
                    return (
                        <span className={"attribute " + className}>
                            <FormattedMessage id={label} />
                        </span>
                    );
                } else {
                    return <></>;
                }
            } else {
                return <></>;
            }
        }
        /**
         * フォーマット済み作成日時取得
         * @param createdAt
         * @returns
         */
        function getFormattedCreatedAt(createdAt: string | null): string {
            if (createdAt == null) {
                return "";
            }
            const unixTime = new Date(createdAt).getTime() / 1000;

            return Utility.getFormattedDateTimeWithDay(
                unixTime,
                props.timeDifference,
                props.langCd
            );
        }
        /**
         * カードヘッダー取得
         * @param user
         * @returns
         */
        function getCardHeaderContents(user: UserModel | null) {
            return (
                <div className="header-wrapper">
                    <span className="index">{`${props.index}.`}&nbsp;</span>
                    {
                        // 国旗
                    }
                    {getUserFlagHtml(user)}
                    {
                        // ユーザ名
                    }
                    {getUserName(user).length === 0 && (
                        <span className="user-name">
                            <FormattedMessage id="name_anonymous" />
                        </span>
                    )}
                    {getUserName(user).length > 0 && (
                        <span className="user-name">
                            <Link
                                to={`/users/${Utility.getUserKey(
                                    user
                                )}/profile`}
                            >
                                {getUserName(user)}
                            </Link>
                        </span>
                    )}
                    {
                        // 年代
                    }
                    {user != null &&
                        user.age != null &&
                        Utility.getAgeLabelKey(user.age).length > 0 && (
                            <div className="age-area">
                                <span>(</span>
                                <FormattedMessage
                                    id={Utility.getAgeLabelKey(user.age!)}
                                />
                                <span>)</span>
                            </div>
                        )}
                    {
                        // 属性
                    }
                    <div className="attribute-area">
                        {getAttributeHtml(user)}
                    </div>
                    {
                        // 投稿日時
                    }
                    <span className="posted-date">
                        {getFormattedCreatedAt(props.createdAt)}
                    </span>
                </div>
            );
        }
        /**
         * カードボディ部分Html生成
         * @param parentIndex
         * @param body
         * @returns
         */
        function getBody(parentIndex: number, body: string | null) {
            if (body == null) {
                return "";
            }
            const texts = body.split("\n").map((text, index) => {
                return (
                    <div
                        className="body-wrapper"
                        key={`${parentIndex}_${index}`}
                    >
                        {reactStringReplace(text, regExp, (match, i) => (
                            <React.Fragment
                                key={`${parentIndex}_${index}_${i}`}
                            >
                                <a
                                    target="_blank"
                                    href={match}
                                    rel="noreferrer"
                                >
                                    {match}
                                </a>
                            </React.Fragment>
                        ))}
                    </div>
                );
            });
            return <>{texts}</>;
        }
        /**
         * レンダー
         */
        return (
            <>
                {props.user != null && (
                    <Card className="ResponseListItem" raised={true}>
                        <CardHeader
                            className="card-header"
                            title={getCardHeaderContents(props.user)}
                        ></CardHeader>
                        <CardContent className="card-content">
                            {getBody(props.index, props.body)}
                        </CardContent>
                    </Card>
                )}
            </>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.index !== nextProps.index) {
            return false;
        }
        if (prevProps.user !== nextProps.user) {
            return false;
        }
        if (prevProps.user != null && nextProps.user != null) {
            if (prevProps.user.id !== nextProps.user.id) {
                return false;
            }
        }
        if (prevProps.body !== nextProps.body) {
            return false;
        }
        if (prevProps.createdAt !== nextProps.createdAt) {
            return false;
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }

        return true;
    }
);
