import { UserModel } from "models/Models";
import { UserRequest } from "api/requests/Requests";

export async function follow(
    loginUser: UserModel,
    target: UserModel
): Promise<number | null> {
    if (target.id == null) {
        return null;
    }
    const result = await UserRequest.follow(loginUser, target.id);
    if (result == null || result.rtnCd == null) {
        return null;
    }
    return result.rtnCd;
}
/**
 * フォロー解除処理
 * @param target
 * @returns
 */
export async function unfollow(
    loginUser: UserModel,
    target: UserModel
): Promise<number | null> {
    if (target.id == null) {
        return null;
    }
    const result = await UserRequest.unfollow(loginUser, target.id);
    if (result == null || result.rtnCd == null) {
        return null;
    }
    return result.rtnCd;
}
