export class UrlInfoModel {
    id: number | null = null;
    socketAppHost: string | null = null;
    socketAppPort: number | null = null;
    socketGroupHost: string | null = null;
    socketGroupPort: number | null = null;
    userContentsUrl: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // socketAppHost
        if (obj.hasOwnProperty("socketAppHost")) {
            this.socketAppHost = obj.socketAppHost;
        } else if (obj.hasOwnProperty("SocketAppHost")) {
            this.socketAppHost = obj.SocketAppHost;
        }
        // socketAppPort
        if (obj.hasOwnProperty("socketAppPort")) {
            this.socketAppPort = obj.socketAppPort;
        } else if (obj.hasOwnProperty("SocketAppPort")) {
            this.socketAppPort = obj.SocketAppPort;
        }
        // socketGroupHost
        if (obj.hasOwnProperty("socketGroupHost")) {
            this.socketGroupHost = obj.socketGroupHost;
        } else if (obj.hasOwnProperty("SocketGroupHost")) {
            this.socketGroupHost = obj.SocketGroupHost;
        }
        // socketGroupPort
        if (obj.hasOwnProperty("socketGroupPort")) {
            this.socketGroupPort = obj.socketGroupPort;
        } else if (obj.hasOwnProperty("SocketGroupPort")) {
            this.socketGroupPort = obj.SocketGroupPort;
        }
        // userContentsUrl
        if (obj.hasOwnProperty("userContentsUrl")) {
            this.userContentsUrl = obj.userContentsUrl;
        } else if (obj.hasOwnProperty("UserContentsUrl")) {
            this.userContentsUrl = obj.UserContentsUrl;
        }
    }
}
