export class ActionKeyResponse {
    rtnCd?: number | null = null;
    actionKey?: string | null = null;
    date?: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // actionKey
        if (obj.hasOwnProperty("ACTION_KEY")) {
            this.actionKey = obj.ACTION_KEY;
        }
        // date
        if (obj.hasOwnProperty("DATE")) {
            this.date = obj.DATE;
        }
    }
}
