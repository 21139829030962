import { UserModel } from "models/Models";

export class SkyWayTokenResponse {
    rtnCd?: number | null = null;
    skywayToken?: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // skywayToken
        if (obj.hasOwnProperty("SKYWAY_TOKEN")) {
            this.skywayToken = obj.SKYWAY_TOKEN;
        }
    }
}
