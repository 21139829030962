import { TalkUserModel, TalkPermissionModel } from "models/Models";

export class TalkUserListResponse {
    rtnCd: number | null = null;
    hasNext: boolean = false;
    lstFavoriteTalkUser: TalkUserModel[] | null = null;
    lstTalkUser: TalkUserModel[] | null = null;
    lstTalkPermission: TalkPermissionModel[] | null = null;
    readSize: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // hasNext
        if (obj.hasOwnProperty("IsNext")) {
            this.hasNext = obj.IsNext;
        }
        // lstFavoriteTalkUser
        if (obj.hasOwnProperty("FavoriteTalkUserList")) {
            if (this.lstFavoriteTalkUser == null) {
                this.lstFavoriteTalkUser = [];
            }
            for (let i in obj.FavoriteTalkUserList) {
                let favoriteTalkUser = new TalkUserModel(
                    obj.FavoriteTalkUserList[i]
                );
                this.lstFavoriteTalkUser.push(favoriteTalkUser);
            }
        }
        // lstTalkUser
        if (obj.hasOwnProperty("TalkUserList")) {
            if (this.lstTalkUser == null) {
                this.lstTalkUser = [];
            }
            for (let i in obj.TalkUserList) {
                let talkUser = new TalkUserModel(obj.TalkUserList[i]);
                this.lstTalkUser.push(talkUser);
            }
        }
        // lstTalkPermission
        if (obj.hasOwnProperty("TalkPermissionList")) {
            if (this.lstTalkPermission == null) {
                this.lstTalkPermission = [];
            }
            for (let i in obj.TalkPermissionList) {
                let talkPermission = new TalkPermissionModel(obj.TalkPermissionList[i]);
                this.lstTalkPermission.push(talkPermission);
            }
        }
        // readSize
        if (obj.hasOwnProperty("ReadSize")) {
            this.readSize = obj.ReadSize;
        }
    }
}
