import { GalleryModel } from "models/Models";

export class GalleryData {
    title: string | null = null;
    target: string | null = null;
    galleryList: GalleryModel[] | null = null;
    amari: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // title
        if (obj.hasOwnProperty("title")) {
            this.title = obj.title;
        } else if (obj.hasOwnProperty("Title")) {
            this.title = obj.Title;
        }
        // target
        if (obj.hasOwnProperty("target")) {
            this.target = obj.target;
        } else if (obj.hasOwnProperty("Target")) {
            this.target = obj.Target;
        }
        // galleryList
        if (obj.hasOwnProperty("galleryList")) {
            this.galleryList = [];
            for (let i in obj.galleryList) {
                let gallery = new GalleryModel(obj.galleryList[i]);
                this.galleryList.push(gallery);
            }
        } else if (obj.hasOwnProperty("GalleryList")) {
            this.galleryList = [];
            for (let i in obj.GalleryList) {
                let gallery = new GalleryModel(obj.GalleryList[i]);
                this.galleryList.push(gallery);
            }
        }
        // amari
        if (obj.hasOwnProperty("amari")) {
            this.amari = obj.amari;
        } else if (obj.hasOwnProperty("amari")) {
            this.amari = obj.amari;
        }
    }
}
