import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { SelectedItemAction } from "redux/Actions";

import { UserModel } from "models/Models";
import { NavBar, SelectUserComponent } from "components/Components";

type Props = {
    loginUser: UserModel | undefined;
};

const SelectUser: React.FC<Props> = React.memo((props) => {
    // Utility.log("@@@@@ SelectUser");
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function onCancel() {
        navigate(-1);
    }
    function onOk(lstSelectedUser: UserModel[] | null) {
        dispatch(SelectedItemAction({ lstUser: lstSelectedUser }));

        navigate(-1);
    }
    /**
     * レンダリング
     */
    return (
        <Box
            className="pageWrapper SelectUser"
            sx={{
                margin: "auto",
                height: "100%",
                maxHeight: "100%",
                overflow: "hidden !important",
            }}
        >
            {
                // ページタイトル
            }
            <NavBar
                showBack={true}
                title={intl.formatMessage({ id: "title_select_user" })}
            />

            {props.loginUser != null && (
                <SelectUserComponent
                    loginUser={props.loginUser}
                    onCancel={onCancel}
                    onOk={onOk}
                />
            )}
        </Box>
    );
});

export default SelectUser;
