import { GroupModel } from "models/Models";

export class GroupCreateResponse {
    rtnCd: number | null = null;
    lstOwnGroup: GroupModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstOwnGroup
        if (obj.hasOwnProperty("LIST_OWN_GROUP")) {
            if (this.lstOwnGroup == null) {
                this.lstOwnGroup = [];
            }
            for (let i in obj.LIST_OWN_GROUP) {
                let group = new GroupModel(obj.LIST_OWN_GROUP[i]);
                this.lstOwnGroup.push(group);
            }
        }
    }
}
