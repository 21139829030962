export const IS_MAINTENANCE = "IS_MAINTENANCE";

export const MaintenanceAction = (maintenanceState) => {
    return {
        type: IS_MAINTENANCE,
        payload: {
            isMaintenance: maintenanceState.isMaintenance,
        },
    };
};
