export class TalkMessageReadResponse {
    rtnCd: number | null = null;
    decrementedUnreadTalkCount: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // decrementedUnreadTalkCount
        if (obj.hasOwnProperty("DECREMENTED_UNREAD_TALK_COUNT")) {
            this.decrementedUnreadTalkCount = obj.DECREMENTED_UNREAD_TALK_COUNT;
        }
    }
}
