import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Draggable from "react-draggable";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControlLabel,
    RadioGroup,
    Radio,
    Paper,
} from "@mui/material";
import { PaperProps } from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { UserModel } from "models/Models";
import { AppConstants, DbConstants, UrlConstants } from "constants/Constants";
import { TalkUserComponent } from "./Components";
import CloseButton from "assets/images/cross_mark.png";
import CallingImage from "assets/images/calling.gif";
import Utility from "utils/Utility";
import { TALK_TYPE, REQUEST_TALK_RESPONSE } from "constants/Enum";

type Props = {
    loginUser: UserModel;
    show: boolean;
    talkRequestUser: UserModel;
    talkTypeRequest: TALK_TYPE;
    onRejectTalkRequest: () => void;
    onAcceptTalkRequest: (talkType: TALK_TYPE) => void
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper
                {...props}
                style={{
                    margin: 0,
                    maxWidth: "none",
                    width: "70vw",
                }}
            />
        </Draggable>
    );
}

export const TalkCallingDialog: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("===== TalkCallingDialog");

        /***** 定数、変数 */
        const intl = useIntl();

        /***** useState */
        // トークタイプ(返信側)
        const [talkTypeResponse, setTalkTypeResponse] =
        React.useState<TALK_TYPE>(TALK_TYPE.VIDEO);

        /***** useEffect */
        React.useEffect(() => {
            if (props.talkTypeRequest === TALK_TYPE.VOICE) {
                setTalkTypeResponse(TALK_TYPE.VOICE);
            }
        }, [])

        /**
         * レンダー
         */
        return (
            <div className="component TalkCallingDialog">
                <Dialog
                    className="dialog"
                    open={props.show}
                    onClose={() => {
                        props.onRejectTalkRequest();
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage
                            id={"dlg_title_message"}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <div className="message-area">
                            {props.talkTypeRequest === TALK_TYPE.VIDEO && (
                                <FormattedMessage id="msg_talk_request_received_video" />
                            )}
                            {props.talkTypeRequest === TALK_TYPE.VOICE && (
                                <FormattedMessage id="msg_talk_request_received_voice" />
                            )}
                        </div>
                        <div className="partner-area">
                            <TalkUserComponent user={props.talkRequestUser} />
                        </div>
                        <Box className="call-type-area"
                            sx={{
                                paddingTop: "20px",
                            }}
                        >
                            <RadioGroup
                                className="radio-group"
                                name="rdo-call-type"
                                value={talkTypeResponse}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    let value = TALK_TYPE.VOICE;
                                    if (
                                        e.target.value ===
                                        String(TALK_TYPE.VIDEO)
                                    ) {
                                        value = TALK_TYPE.VIDEO;
                                    }
                                    setTalkTypeResponse(value);
                                }}
                            >
                                {
                                    // ビデオ通話
                                }
                                <FormControlLabel
                                    className="video-call"
                                    control={
                                        <Radio
                                            value={
                                                TALK_TYPE.VIDEO
                                            }
                                        />
                                    }
                                    label={intl.formatMessage({
                                        id: "talk_video",
                                    })}
                                />
                                {
                                    // 音声通話
                                }
                                <FormControlLabel
                                    className="voice-call"
                                    control={
                                        <Radio
                                            value={
                                                TALK_TYPE.VOICE
                                            }
                                        />
                                    }
                                    label={intl.formatMessage({
                                        id: "talk_voice",
                                    })}
                                />
                            </RadioGroup>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            color="primary"
                            onClick={() => {
                                props.onRejectTalkRequest();
                            }}
                        >
                            <FormattedMessage id={"btn_cancel"} />
                        </Button>
                        <Button
                            className="dialog-button"
                            color="primary"
                            onClick={() => {
                                props.onAcceptTalkRequest(talkTypeResponse);
                            }}
                        >
                            <FormattedMessage id={"btn_call"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.show !== nextProps.show) {
            return false;
        }
        if (prevProps.talkRequestUser !== nextProps.talkRequestUser) {
            return false;
        }
        if (prevProps.talkRequestUser != null && nextProps.talkRequestUser != null) {
            if (prevProps.talkRequestUser.id !== nextProps.talkRequestUser.id) {
                return false;
            }
        }

        return true;
    }
);
