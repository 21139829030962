import { TalkUserModel } from "models/Models";

export class UnreadTalkResponse {
    rtnCd?: number | null = null;
    unreadCount?: number | null = null;
    lstHiddenTalkUser?: TalkUserModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // unreadCount
        if (obj.hasOwnProperty("UNREAD_TALK_MESSAGE_COUNT")) {
            this.unreadCount = obj.UNREAD_TALK_MESSAGE_COUNT;
        }
        // lstHiddenTalkUser
        if (obj.hasOwnProperty("HIDDEN_TALK_USER_LIST")) {
            if (this.lstHiddenTalkUser == null) {
                this.lstHiddenTalkUser = [];
            }
            for (let i in obj.HIDDEN_TALK_USER_LIST) {
                let talkUser = new TalkUserModel(obj.HIDDEN_TALK_USER_LIST[i]);
                this.lstHiddenTalkUser.push(talkUser);
            }
        }
    }
}
