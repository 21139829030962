import { FollowModel } from "models/Models";

export class FollowUserListResponse {
    rtnCd: number | null = null;
    lstFollow: FollowModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstFollow
        if (obj.hasOwnProperty("FollowUserList")) {
            if (this.lstFollow == null) {
                this.lstFollow = [];
            }
            for (let i in obj.FollowUserList) {
                let followUser = new FollowModel(obj.FollowUserList[i]);
                this.lstFollow.push(followUser);
            }
        }
    }
}
