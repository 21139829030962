import axios, { AxiosResponse } from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import { ActionKeyResponse } from "api/responses/Responses";

export class ActionKeyRequest {
    /**
     * アクションキー取得
     * @returns
     */
    static async getActionKey(user?: UserModel) {
        let url = UrlConstants.URL_GET_ACTION_KEY;
        const formData = new FormData();
        if (
            user != null &&
            user.id != null &&
            user.id > 0 &&
            user.createdAt != null &&
            user.createdAt.length > 0
        ) {
            formData.append("UserId", String(user.id));
            formData.append("ActionSecret", user.createdAt);
        } else {
            formData.append("UserId", "UserId");
            formData.append("ActionSecret", "temp_secret");
        }

        let result = null;
        try {
            let res: AxiosResponse | null = null;
            res = await axios.put(url, formData, {
                withCredentials: true,
            });
            if (res != null) {
                result = new ActionKeyResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
