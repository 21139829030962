import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import {
    ThreadListResponse,
    ThreadResponse,
    ThreadPostResponse,
} from "api/responses/Responses";

export class ThreadRequest {
    /**
     * スレッドリスト取得
     * @returns
     */
    static async getList(categoryId: number) {
        let url = UrlConstants.URL_THREAD_LIST.replace(
            ":category_id",
            String(categoryId)
        );
        let result = null;
        try {
            const res = await axios.get(url, { withCredentials: true });
            if (res != null) {
                result = new ThreadListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * スレッド取得
     * @param threadId
     * @returns
     */
    static async getThread(threadId: number) {
        let url = UrlConstants.URL_THREAD.replace(":id", String(threadId));
        let result = null;
        try {
            const res = await axios.get(url, { withCredentials: true });
            if (res != null) {
                result = new ThreadResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * スレッド作成
     * @returns
     */
    static async create(
        category_id: number,
        title: string,
        body: string,
        user: UserModel
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return null;
        }
        let formData = new FormData();
        formData.append("category_id", String(category_id));
        formData.append("user_id", String(user.id));
        formData.append("title", title);
        formData.append("body", body);
        const bearerToken = user.bearerToken;
        const url = UrlConstants.URL_THREAD_CREATE;
        let result = null;
        try {
            const res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            });
            if (res != null) {
                result = new ThreadPostResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
