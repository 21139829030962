import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { UserModel } from "models/Models";
import { NavBar, SelectCountryComponent } from "components/Components";

type Props = {
    loginUser: UserModel | null;
};

const SelectCountry: React.FC<Props> = React.memo((props) => {
    // Utility.log("@@@@@ SelectCountry");
    /***** 定数、変数 */
    const intl = useIntl();
    const navigate = useNavigate();

    function onCancel() {
        navigate(-1);
    }
    function onOk() {
        navigate(-1);
    }
    /**
     * レンダリング
     */
    return (
        <Box
            className="pageWrapper SelectCountry"
            sx={{
                margin: "auto",
                height: "100%",
                maxHeight: "100%",
            }}
        >
            {
                // ページタイトル
            }
            <NavBar
                showBack={true}
                title={intl.formatMessage({ id: "title_select_country" })}
            />

            {props.loginUser != null && (
                <SelectCountryComponent
                    loginUser={props.loginUser}
                    onCancel={onCancel}
                    onOk={onOk}
                />
            )}
        </Box>
    );
});

export default SelectCountry;
