import { createSelector } from "reselect";

const shootSelector = (state) => state.shoot;

export const getPhotoId = createSelector(
    [shootSelector],
    (state) => state.shoot.photoId
);
export const getVideoId = createSelector(
    [shootSelector],
    (state) => state.shoot.videoId
);
