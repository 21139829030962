import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserModel } from "models/Models";
import { UserRequest } from "api/requests/Requests";
import { NetworkAction, UserAction, WorkingItemAction } from "redux/Actions";
import { getWorkingProfile } from "redux/Selectors";
import Trash from "assets/images/trash.svg";
import { pushDataLayer } from "gtm/gtm"

import "styles/components/ProfileComponent.scss";

interface Props {
    loginUser: UserModel | null | undefined;
    onCancel: () => void;
    onOk: (profileHtml: string, profile: string) => void;
}

export const ProfileComponent: React.FC<Props> = (props) => {
    // Utility.log("===== ProfileComponent");
    /***** 定数、変数 */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const STYLE_COLOR = 1;
    const STYLE_SIZE_LARGER = 2;
    const STYLE_SIZE_SMALLER = 3;
    const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
    let fontColor = "#000000";
    let profileHtml = useSelector((state) => getWorkingProfile(state));

    /***** useRef */
    const iframe_doc = React.useRef<Document>();
    const iframe_body = React.useRef<HTMLBodyElement | null>();
    const targetElement = React.useRef<HTMLElement>();
    const fontLargerCount = React.useRef<number>(0);
    const fontSmallerCount = React.useRef<number>(0);

    const createStyleElement = React.useCallback((style: number) => {
        if (iframe_doc == null || iframe_doc.current == null) {
            return;
        }
        try {
            let selection = iframe_doc.current.getSelection();
            if (selection == null) {
                return;
            }
            let range = iframe_doc.current.createRange();
            selection.addRange(range);
            if (selection.anchorNode == null) {
                return;
            }
            range.selectNodeContents(selection.anchorNode);
            range.setStart(selection.anchorNode, selection.anchorOffset);
            let newNode = iframe_doc.current.createElement("span");
            if (targetElement == null || targetElement.current == null) {
                return;
            }
            targetElement.current.appendChild(newNode);
            newNode.className = "dummy";
            switch (style) {
                case STYLE_COLOR:
                    newNode.classList.add("color");
                    newNode.style.color = fontColor;
                    break;
                case STYLE_SIZE_LARGER:
                    newNode.classList.add("fontSize");
                    newNode.style.fontSize = "larger";
                    break;
                case STYLE_SIZE_SMALLER:
                    newNode.classList.add("fontSize");
                    newNode.style.fontSize = "smaller";
                    break;
            }
            range.insertNode(newNode);
            let textNode = iframe_doc.current.createTextNode("");
            textNode.data = "\u200b";
            newNode.appendChild(textNode);
            selection.removeAllRanges();
            range.setStart(textNode, 1);
            range.setEnd(textNode, 1);
            selection.addRange(range);
        } catch (e) {
            console.error(e);
        }
    }, [
        fontColor
    ]);

    const mouseUp = React.useCallback(() => {
        for (var i = 0; i < fontLargerCount.current; i++) {
            createStyleElement(STYLE_SIZE_LARGER);
        }
        for (var j = 0; j < fontSmallerCount.current; j++) {
            createStyleElement(STYLE_SIZE_SMALLER);
        }
        fontLargerCount.current = 0;
        fontSmallerCount.current = 0;
        createStyleElement(STYLE_COLOR);
    }, [
        createStyleElement,
    ]);

    const getRange = React.useCallback(() => {
        if (iframe_doc == null || iframe_doc.current == null) {
            return;
        }
        var selection = iframe_doc.current.getSelection();
        if (!selection || selection.isCollapsed) {
            return null;
        }
        // if (!selection) return iframe_doc.createRange();
        return selection.getRangeAt
            ? selection.getRangeAt(0)
            : iframe_doc.current.createRange();
    }, []);

    const removeUnnecessaryElements = React.useCallback(() => {
        if (iframe_doc == null || iframe_doc.current == null) {
            return;
        }
        var loop = false;
        var cnt = 0;
        while (true) {
            cnt++;
            const lstElement = iframe_doc.current.querySelectorAll("span.dummuy.color");
            if (lstElement == null) {
                return;
            }
            for (let idx = 0; idx < lstElement.length; idx++) {
                const element = lstElement[idx];
                var child = element.children;
                var text1 = "";
                if (element.textContent != null) {
                    text1 = trimZeroWidthCharacter(element.textContent);
                }
                if (child.length === 0) {
                    if (text1.length === 0) {
                        loop = true;
                        element.remove();
                    }
                } else if (child.length === 1) {
                    if (child[0].textContent == null) {
                        return;
                    }
                    var text2 = trimZeroWidthCharacter(child[0].textContent);
                    const hasClass = child[0].classList.contains("color");
                    if (hasClass) {
                        if (text1 === text2) {
                            loop = true;
                            element.replaceWith(child[0]);
                        }
                    }
                }
            }
            if (!loop) {
                break;
            }
            if (cnt > 10) {
                break;
            }
            loop = false;
        }
    }, []);

    const bodyMouseUp = React.useCallback((e: MouseEvent) => {
        targetElement.current = e.target as HTMLElement;
        var range = getRange();
        if (!range || range.collapsed) {
            mouseUp();
        }
        removeUnnecessaryElements();
    }, [
        getRange,
        mouseUp,
        removeUnnecessaryElements,
    ]);

    const initialProcess = React.useCallback(() => {
        const iframe = document.getElementById(
            "profile-iframe"
        ) as HTMLIFrameElement;
        if (iframe == null) {
            window.setTimeout(function () {
                initialProcess();
            }, 1000);
            return;
        }
        iframe_doc.current =
            (iframe as HTMLIFrameElement).contentDocument ||
            (iframe as HTMLIFrameElement).contentWindow?.document;
        if (iframe_doc == null || iframe_doc.current == null) {
            return
        }
        iframe_body.current = iframe_doc.current.querySelector("body");

        if (iframe_body == null || iframe_body.current == null) {
            return;
        }
        iframe_body.current.setAttribute("contenteditable", "true");
        iframe_body.current.style.fontSize = "1.3rem";
        iframe_body.current.style.wordBreak = "break-all";
        iframe_body.current.style.overflowWrap = "break-word";
        iframe_body.current.style.whiteSpace = "pre-wrap";

        if (profileHtml != null && profileHtml.length > 0) {
            iframe_body.current.innerHTML = profileHtml;
        }
        iframe_body.current.addEventListener("mouseup", bodyMouseUp, false);
    }, [
        profileHtml,
        bodyMouseUp,
    ]);

    /**
     * useEffect
     */
    React.useEffect(() => {
        pushDataLayer({
            event: 'page_view',
            screen: "プロフィール",
            path: window.location.pathname,
        });
    }, []);
    
    React.useEffect(() => {
        initialProcess();
        return () => {
            if (iframe_body != null && iframe_body.current != null) {
                iframe_body.current.removeEventListener("mouseup", bodyMouseUp);
            }
        };
    }, [
        props.loginUser,
        profileHtml,
        initialProcess,
        bodyMouseUp,
    ]);

    /**
     * 色変更時
     * @param e
     */
    function onChangeColor(e: React.ChangeEvent<HTMLInputElement>) {
        fontColor = e.target.value;
        reflectFontColor(fontColor);
    }
    /**
     * 「大きく」ボタン押下時
     */
    function onClickBigger() {
        setFontSizeLarger();
    }
    /**
     * 「小さく」ボタン押下時
     */
    function onClickSmaller() {
        setFontSizeSmaller();
    }
    /**
     * 「消去」ボタン押下時
     * @returns
     */
    function onClickDelete() {
        if (iframe_body == null || iframe_body.current == null) {
            return;
        }
        iframe_body.current.innerHTML = "";
    }
    /**
     * 「キャンセル」ボタン押下時
     */
    function onCancel() {
        props.onCancel();
    }
    /**
     * 「OK」ボタン押下時
     */
    async function onOk() {
        if (iframe_body == null || iframe_body.current == null) {
            return;
        }
        removeUnnecessaryElements();
        const profile = iframe_body.current.innerHTML;
        let profileText = profileHtml.replace(/<span.*?>/g, "");
        profileText = profileText.replace(/<\/span.*?>/g, "");
        profileText = profileText.replace(/<div.*?>/g, "");
        profileText = profileText.replace(/<\/div.*?>/g, "");
        profileText = profileText.replace(/<br>/g, "\n");

        if (props.loginUser != null) {
            // 保存リクエスト
            await save(profile, profileText);
        }

        // ディスパッチ
        dispatch(
            WorkingItemAction({
                profile: profile,
            })
        );

        props.onOk(profile, profileText);
    }

    /**
     * 保存
     * @param profileHtml
     * @param profileText
     * @returns
     */
    async function save(profileHtml: string, profileText: string) {
        if (props.loginUser == null) {
            return;
        }
        let updateUser = new UserModel();
        updateUser.id = props.loginUser.id;
        updateUser.createdAt = props.loginUser.createdAt;
        updateUser.bearerToken = props.loginUser.bearerToken;
        updateUser.profile = profileHtml;
        updateUser.profileText = profileText;
        const result = await UserRequest.save(updateUser);
        if (result == null) {
            if (window.navigator.onLine) {
                navigate("/maintenance");
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return;
        }
        if (result.rtnCd != null && result.rtnCd === 0) {
            if (result.user != null && result.bearerToken != null) {
                result.user.bearerToken = result.bearerToken;
                dispatch(UserAction({ loginUser: result.user }));
            }
        }
    }


    function setStyleForSelectedElement(type: number, range: Range) {
        if (iframe_doc == null || iframe_doc.current == null) {
            return;
        }
        let span = null;
        switch (type) {
            case STYLE_COLOR:
                span = iframe_doc.current.createElement("span");
                span.style.color = fontColor;
                // span = $("<span>").css("color", fontColor)[0];
                break;
            case STYLE_SIZE_LARGER:
                span = iframe_doc.current.createElement("span");
                span.style.fontSize = "larger";
                // span = $("<span>").css("font-size", "larger")[0];
                break;
            case STYLE_SIZE_SMALLER:
                span = iframe_doc.current.createElement("span");
                span.style.fontSize = "smaller";
                // span = $("<span>").css("font-size", "smaller")[0];
                break;
        }
        if (span != null) {
            try {
                span.appendChild(range.extractContents());
                range.insertNode(span);
                // range.surroundContents(span);
            } catch (e) {
                console.error(e);
            }
        }
    }
    function reflectFontColor(color: string) {
        var range = getRange();
        if (range && !range.collapsed) {
            setStyleForSelectedElement(STYLE_COLOR, range);
        } else {
            mouseUp();
        }
    }

    function setFontSizeLarger() {
        var range = getRange();
        if (range && !range.collapsed) {
            setStyleForSelectedElement(STYLE_SIZE_LARGER, range);
            fontLargerCount.current = 0;
            return;
        } else {
            fontLargerCount.current++;
            mouseUp();
        }
    }

    function setFontSizeSmaller() {
        var range = getRange();
        if (range && !range.collapsed) {
            setStyleForSelectedElement(STYLE_SIZE_SMALLER, range);
            fontSmallerCount.current = 0;
            return;
        } else {
            fontSmallerCount.current++;
            mouseUp();
        }
    }

    // function escape_html(text: string) {
    //     if (typeof text !== "string") {
    //         return text;
    //     }
    //     text = text.replace(/&/g, "&amp;");
    //     text = text.replace(/'/g, "&#x27;");
    //     text = text.replace(/`/g, "&#x60;");
    //     text = text.replace(/"/g, "&quot;");
    //     text = text.replace(/</g, "&lt;");
    //     text = text.replace(/>/g, "&gt;");
    //     return text;
    // }
    // function unescape_html(text: string) {
    //     if (typeof text !== "string" || text == null || text.length == 0) {
    //         return text;
    //     }
    //     text = text.replace(/&amp;/g, "&");
    //     text = text.replace(/&#x27;/g, "'");
    //     text = text.replace(/&#x60;/g, "`");
    //     text = text.replace(/&quot;/g, '"');
    //     text = text.replace(/&lt;/g, "<");
    //     text = text.replace(/&gt;/g, ">");
    //     return text;
    // }

    function trimZeroWidthCharacter(txtBefore: string) {
        var spStr = [
            8203, // ゼロ幅スペース
        ];
        var txtAfter = "";
        for (var i = 0; i < txtBefore.length; i++) {
            var chr = txtBefore.charCodeAt(i);
            if (spStr.indexOf(chr) === -1) {
                txtAfter += String.fromCharCode(chr);
            }
        }
        return txtAfter;
    }

    /**
     * レンダリング
     */
    return (
        <div className="component Profile">
            <div className="operation-area">
                <input
                    id="btnColor"
                    className="btn-color"
                    type="color"
                    onChange={onChangeColor}
                />
                <Button
                    id="btnBigger"
                    className="btn-bigger"
                    variant="text"
                    onClick={onClickBigger}
                >
                    <FormattedMessage id={"larger"} />
                </Button>
                <Button
                    id="btnSmaller"
                    className="btn-smaller"
                    variant="text"
                    onClick={onClickSmaller}
                >
                    <FormattedMessage id={"smaller"} />
                </Button>
                <div style={{ flexShrink: 1, flexGrow: 1 }} />
                <img
                    id="btnDelete"
                    className="btn-delete"
                    src={Trash}
                    alt="Delete Profile"
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={onClickDelete}
                />
            </div>
            <div className="iframe-wrapper">
                <iframe
                    id="profile-iframe"
                    width="100%"
                    height="100%"
                    ref={iframeRef}
                    title="Profile"
                ></iframe>
            </div>
            <div className="button-area">
                <Button
                    className="button-ok dialog-button"
                    variant="text"
                    onClick={onCancel}
                >
                    <FormattedMessage id={"btn_cancel"} />
                </Button>
                <Button
                    className="button-cancel dialog-button"
                    variant="text"
                    onClick={onOk}
                >
                    <FormattedMessage id={"btn_ok"} />
                </Button>
            </div>
        </div>
    );
};
