import React from "react";
import { FormattedMessage } from "react-intl";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/NotFoundPage.scss";

const NotFoundPage: React.FC = () => {
    return (
        <div className="pageWrapper NotFoundPage">
            <FormattedMessage id={"page_not_found"} />
        </div>
    );
};

export default NotFoundPage;
