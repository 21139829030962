import { UrlConstants } from "constants/UrlConstants";
import {
    UserModel,
    GroupChatMemberData,
    GroupChatMessageData,
} from "models/Models";
import io from "socket.io-client";

export class GroupChatServer {
    /***** 定数、変数 */
    public socket: SocketIOClient.Socket | null = null;
    // socket: Socket | null = null;
    connectCallback: (() => void) | null = null;
    eventCallback: ((obj: object) => void) | null = null;
    disconnectCallback: (() => void) | null = null;
    user: UserModel | null = null;
    groupId: number | null = null;

    connect(
        user: UserModel,
        group_id: number,
        connect_callback: () => void,
        event_callback: (obj: object) => void,
        disconnect_callback: () => void
    ) {
        const url =
            UrlConstants.SOCKET_GROUP_CONNECTION_HOST +
            ":" +
            UrlConstants.SOCKET_GROUP_CONNECTION_PORT;
        this.socket = io(url);
        this.connectCallback = connect_callback;
        this.eventCallback = event_callback;
        this.disconnectCallback = disconnect_callback;
        this.user = user;
        this.groupId = group_id;
        // イベント登録
        this.socket.on("connect", this.connectCallback);
        this.socket.on("disconnect", this.disconnectCallback);
        this.socket.on("group_" + String(group_id), this.eventCallback);
    }
    sendMessage(data: GroupChatMessageData) {
        if (this.socket != null && this.socket.connected) {
            this.socket.emit("send_message", data);
        }
    }
    sendEnterInfo(data: GroupChatMemberData) {
        if (this.socket != null && this.socket.connected) {
            this.socket.emit("enter", data);
        }
    }

    disconnect() {
        if (this.socket == null || this.groupId == null) {
            return;
        }
        if (this.connectCallback != null) {
            this.socket.off("connect", this.connectCallback);
        }
        if (this.eventCallback != null) {
            this.socket.off(
                "group_" + String(this.groupId),
                this.eventCallback
            );
        }
        if (this.disconnectCallback != null) {
            this.socket.off("disconnect", this.disconnectCallback);
        }
        this.socket.disconnect();
        this.socket = null;
    }
}
