import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardContent } from "@mui/material";
import { GroupModel } from "models/Models";
import { DbConstants } from "constants/Constants";
import "styles/components/listItems/GroupListItem.scss";

type Props = {
    group: GroupModel;
    selected: boolean;
    onClick?: () => void;
};

export const GroupListItem: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== GroupListItem");

        /**
         * レンダー
         */
        return (
            <Card
                className={
                    props.group.selected
                        ? "GroupListItem selected"
                        : "GroupListItem"
                }
                onClick={props.onClick}
            >
                <CardContent className="item-content">
                    {
                        // ステータス
                    }
                    <div className="status">
                        {props.group.isOwner && (
                            // オーナー
                            <span className="owner">
                                <FormattedMessage id={"group_owner"} />
                            </span>
                        )}
                        {props.group.applying && (
                            // 加入申請中
                            <span className="applying">
                                <FormattedMessage id={"under_application"} />
                            </span>
                        )}
                        {props.group.underInvitation && (
                            // 被招待中
                            <span className="under-invited">
                                <FormattedMessage
                                    id={"group_under_invitation"}
                                />
                            </span>
                        )}
                        {!props.group.isOwner && props.group.join && (
                            // 加入中
                            <span className="member">
                                <FormattedMessage id={"group_member"} />
                            </span>
                        )}
                        {props.group.open !== 1 && (
                            // プライベート
                            <span className="private">
                                <FormattedMessage id={"group_private"} />
                            </span>
                        )}
                    </div>
                    {
                        // グループ情報
                    }
                    <div className="group-info">
                        {
                            // オーナー名
                        }
                        {props.group.owner != null &&
                            props.group.ownerId !== 1 &&
                            props.group.owner.name != null &&
                            props.group.owner.name.length > 0 && (
                                <div className="owner-name-area">
                                    <span className="owner-name-label">
                                        <FormattedMessage id={"group_owner2"} />
                                    </span>
                                    {props.group.owner.gender ===
                                        DbConstants.GENDER_MALE && (
                                        <span className="male owner-name">
                                            {props.group.owner.name}
                                        </span>
                                    )}
                                    {props.group.owner.gender ===
                                        DbConstants.GENDER_FEMALE && (
                                        <span className="female owner-name">
                                            {props.group.owner.name}
                                        </span>
                                    )}
                                    {props.group.owner.gender ===
                                        DbConstants.GENDER_OTHER && (
                                        <span className="other owner-name">
                                            {props.group.owner.name}
                                        </span>
                                    )}
                                </div>
                            )}
                        {
                            // グループ名
                        }
                        <div className="group-name">{props.group.name}</div>
                    </div>
                </CardContent>
            </Card>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.group !== nextProps.group) {
            return false;
        }
        if (prevProps.group != null && nextProps.group != null) {
            if (prevProps.group.id !== nextProps.group.id) {
                return false;
            }
        }
        if (prevProps.selected !== nextProps.selected) {
            return false;
        }
        if (prevProps.onClick !== nextProps.onClick) {
            return false;
        }

        return true;
    }
);
