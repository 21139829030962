export const GET_UNREAD_COUNT = "GET_UNREAD_COUNT";
export const DECREMENT_MAIL_UNREAD_COUNT = "DECREMENT_MAIL_UNREAD_COUNT";
export const DECREMENT_TALK_UNREAD_COUNT = "DECREMENT_TALK_UNREAD_COUNT";
export const INCREMENT_TALK_UNREAD_COUNT = "INCREMENT_TALK_UNREAD_COUNT";

export const UnreadCountAction = (unreadCountState) => {
    let payload = {};
    if (Object.keys(unreadCountState).indexOf("mail") > -1) {
        payload["mail"] = unreadCountState.mail;
    }
    if (Object.keys(unreadCountState).indexOf("talk") > -1) {
        payload["talk"] = unreadCountState.talk;
    }
    return {
        type: GET_UNREAD_COUNT,
        payload: payload,
    };
};
export const DecrementMailUnreadCountAction = () => {
    return {
        type: DECREMENT_MAIL_UNREAD_COUNT,
    };
};
export const DecrementTalkUnreadCountAction = () => {
    return {
        type: DECREMENT_TALK_UNREAD_COUNT,
    };
};
export const IncrementTalkUnreadCountAction = () => {
    return {
        type: INCREMENT_TALK_UNREAD_COUNT,
    };
};
