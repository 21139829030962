import { UserModel } from "./UserModel";

export class ResponseModel {
    id: number | null = null;
    threadId: number | null = null;
    countryCd: string | null = null;
    languageCd: string | null = null;
    userId: number | null = null;
    user: UserModel | null = null;
    title: string | null = null;
    body: string | null = null;
    invalid: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    deletedAt: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // threadId
        if (obj.hasOwnProperty("threadId")) {
            this.threadId = obj.threadId;
        } else if (obj.hasOwnProperty("ThreadId")) {
            this.threadId = obj.ThreadId;
        }
        // countryCd
        if (obj.hasOwnProperty("countryCd")) {
            this.countryCd = obj.countryCd;
        } else if (obj.hasOwnProperty("CountryCd")) {
            this.countryCd = obj.CountryCd;
        }
        // languageCd
        if (obj.hasOwnProperty("languageCd")) {
            this.languageCd = obj.languageCd;
        } else if (obj.hasOwnProperty("LanguageCd")) {
            this.languageCd = obj.LanguageCd;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // user
        if (obj.hasOwnProperty("user")) {
            this.user = new UserModel(obj.user);
        } else if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // title
        if (obj.hasOwnProperty("title")) {
            this.title = obj.title;
        } else if (obj.hasOwnProperty("Title")) {
            this.title = obj.Title;
        }
        // body
        if (obj.hasOwnProperty("body")) {
            this.body = obj.body;
        } else if (obj.hasOwnProperty("Body")) {
            this.body = obj.Body;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
    }
}
