import { TalkPermissionModel } from "models/Models";

export class TalkPermissionResponse {
    rtnCd: number | null = null;
    talkPermission: TalkPermissionModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // talkPermission
        if (obj.hasOwnProperty("TALK_PERMISSION")) {
            this.talkPermission = new TalkPermissionModel(obj.TALK_PERMISSION);
        }
    }
}
