import * as Actions from "./actions";
import initialState from "../store/initialState";

export const UnreadCountReducer = (
    state = initialState.unreadCount,
    action
) => {
    switch (action.type) {
        case Actions.GET_UNREAD_COUNT:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.DECREMENT_MAIL_UNREAD_COUNT:
            return {
                ...state,
                mail: state.mail > 0 ? state.mail - 1 : state.mail,
            };
        case Actions.DECREMENT_TALK_UNREAD_COUNT:
            return {
                ...state,
                talk: state.talk > 0 ? state.talk - 1 : state.talk,
            };
        case Actions.INCREMENT_TALK_UNREAD_COUNT:
            return {
                ...state,
                talk: state.talk + 1,
            };
        default:
            return state;
    }
};
