import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedParentMail, getSelectedUser } from "redux/Selectors";
import { HistoryAction, SelectedItemAction } from "redux/Actions";

import { UserModel, ParentMailModel } from "models/Models";
import { NavBar, SendMessageComponent } from "components/Components";

type Props = {
    loginUser: UserModel | null;
};

const SendMessage: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ SendMessage");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const parentMail = useSelector((state) => getSelectedParentMail(state));
        const selectedUser = useSelector((state) => getSelectedUser(state));

        /***** useState */

        React.useEffect(() => {
            dispatch(
                SelectedItemAction({
                    parentMail: null,
                    user: null,
                })
            );    
            return () => {
                (async () => {
                    dispatch(SelectedItemAction({ childMail: null }));
                })();
            };
        }, []);
        /**
         * メッセージ送信完了コールバック
         */
        function onSendCompleted() {
            dispatch(HistoryAction({ sentMailListHistory: null }));
            window.setTimeout(() => {
                navigate(-1);
            });
        }

        /**
         * レンダリング
         */
        return (
            <Box
                className="pageWrapper SendMessageSP"
                sx={{
                    margin: "auto",
                    height: "100%",
                    maxHeight: "100%",
                }}
            >
                {
                    // ページタイトル
                }
                <NavBar
                    showBack={true}
                    title={
                        parentMail == null
                            ? intl.formatMessage({ id: "title_send_message" })
                            : intl.formatMessage({
                                  id: "btn_reply_message2",
                              })
                    }
                />

                {props.loginUser != null && (
                    <SendMessageComponent
                        loginUser={props.loginUser}
                        // parentMail={parentMail}
                        target={selectedUser}
                        onSendCompleted={onSendCompleted}
                    />
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default SendMessage;
