export const CATEGORY_LIST = "CATEGORY_LIST";

export const CategoryListAction = (categoryListState) => {
    let payload = {};
    if (Object.keys(categoryListState).indexOf("list") > -1) {
        payload["list"] = categoryListState.list;
    }
    return {
        type: CATEGORY_LIST,
        payload: payload,
    };
};
