import {
    UserModel,
    AlbumTemplateModel,
    AlbumGroupModel,
    AlbumUserModel,
    GalleryModel,
} from "models/Models";

export class AlbumModel {
    id: number | null = null;
    userId: number | null = null;
    user: UserModel | null = null;
    name: string | null = null;
    albumTemplateId: number | null = null;
    albumTemplate: AlbumTemplateModel | null = null;
    open: number | null = null;
    openGroup: number | null = null;
    lstAlbumGroup: AlbumGroupModel[] | null = null;
    openIndividual: number | null = null;
    lstAlbumUser: AlbumUserModel[] | null = null;
    openFollower: number | null = null;
    lstFollower: UserModel[] | null = null;
    orderNo: number | null = null;
    invalid: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    lstGallery: GalleryModel[] | null = null;

    lstOpenGroupIdList: number[] | null = null;
    lstOpenUserIdList: number[] | null = null;
    selected: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // user
        if (obj.hasOwnProperty("user")) {
            this.user = new UserModel(obj.user);
        } else if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // name
        if (obj.hasOwnProperty("name")) {
            this.name = obj.name;
        } else if (obj.hasOwnProperty("Name")) {
            this.name = obj.Name;
        }
        // albumTemplateId
        if (obj.hasOwnProperty("albumTemplateId")) {
            this.albumTemplateId = obj.albumTemplateId;
        } else if (obj.hasOwnProperty("AlbumTemplateId")) {
            this.albumTemplateId = obj.AlbumTemplateId;
        }
        // albumTemplate
        if (obj.hasOwnProperty("albumTemplate")) {
            this.albumTemplate = obj.albumTemplate;
        } else if (obj.hasOwnProperty("AlbumTemplate")) {
            this.albumTemplate = obj.AlbumTemplate;
        }
        // open
        if (obj.hasOwnProperty("open")) {
            this.open = obj.open;
        } else if (obj.hasOwnProperty("Open")) {
            this.open = obj.Open;
        }
        // openGroup
        if (obj.hasOwnProperty("openGroup")) {
            this.openGroup = obj.openGroup;
        } else if (obj.hasOwnProperty("OpenGroup")) {
            this.openGroup = obj.OpenGroup;
        }
        // lstAlbumGroup
        if (obj.hasOwnProperty("AlbumGroupList")) {
            this.lstAlbumGroup = [];
            for (let i in obj.AlbumGroupList) {
                let albumGroup = new AlbumGroupModel(obj.AlbumGroupList[i]);
                if (
                    albumGroup.group != null &&
                    albumGroup.group.id != null &&
                    albumGroup.group.id > 0
                ) {
                    this.lstAlbumGroup.push(albumGroup);
                }
            }
        }
        // openIndividual
        if (obj.hasOwnProperty("openIndividual")) {
            this.openIndividual = obj.openIndividual;
        } else if (obj.hasOwnProperty("OpenIndividual")) {
            this.openIndividual = obj.OpenIndividual;
        }
        // lstAlbumUser
        if (obj.hasOwnProperty("AlbumUserList")) {
            this.lstAlbumUser = [];
            for (let i in obj.AlbumUserList) {
                let albumUser = new AlbumUserModel(obj.AlbumUserList[i]);
                if (
                    albumUser.user != null &&
                    albumUser.user.id != null &&
                    albumUser.user.id > 0
                ) {
                    this.lstAlbumUser.push(albumUser);
                }
            }
        }
        // openFollower
        if (obj.hasOwnProperty("openFollower")) {
            this.openFollower = obj.openFollower;
        } else if (obj.hasOwnProperty("OpenFollower")) {
            this.openFollower = obj.OpenFollower;
        }
        // lstFollower
        if (obj.hasOwnProperty("FollowerList")) {
            this.lstFollower = [];
            for (let i in obj.FollowerList) {
                let follower = new UserModel(obj.FollowerList[i]);
                this.lstFollower.push(follower);
            }
        }
        // orderNo
        if (obj.hasOwnProperty("orderNo")) {
            this.orderNo = obj.orderNo;
        } else if (obj.hasOwnProperty("OrderNo")) {
            this.orderNo = obj.OrderNo;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // lstGallery
        if (obj.hasOwnProperty("lstGallery")) {
            this.lstGallery = [];
            for (let i in obj.lstGallery) {
                let gallery = new GalleryModel(obj.lstGallery[i]);
                this.lstGallery.push(gallery);
            }
        } else if (obj.hasOwnProperty("LstGallery")) {
            this.lstGallery = [];
            for (let i in obj.LstGallery) {
                let gallery = new GalleryModel(obj.LstGallery[i]);
                this.lstGallery.push(gallery);
            }
        }
    }
}
