import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Divider, Button } from "@mui/material";
import { UserModel, MailModel, ParentMailModel } from "models/Models";
import { AppConstants } from "constants/Constants";
import { AttachedFileListComponent } from "components/Components";
import Utility from "utils/Utility";
import "styles/components/listItems/ReceivedChildMailListItem.scss";

type Props = {
    loginUser: UserModel | null;
    parentMail: ParentMailModel | undefined;
    mail: MailModel;
    sender: UserModel | undefined;
    receiver: UserModel | undefined;
    timeDifference: number;
    langCd: string;
    onRead: () => void;
    onDelete: () => void;
};

export const ReceivedChildMailListItem: React.FC<Props> = React.memo((props) => {
    Utility.log("===== ReceivedChildMailListItem IN");
    /***** 定数、変数 */

    /***** useState */
    const [createdAt, setCreatedAt] = React.useState<string>("");

    React.useEffect(() => {
        if (
            props.mail != null &&
            props.mail.createdAt != null &&
            props.timeDifference != null &&
            props.langCd != null
        ) {
            const unixTime = new Date(props.mail.createdAt).getTime() / 1000;
            const wkCreatedAt = Utility.getFormattedDateTime2(
                unixTime,
                props.timeDifference,
                props.langCd
            );
            setCreatedAt(wkCreatedAt);
        }
    }, [
        props.langCd,
        props.mail,
        props.timeDifference,
    ]);

    /**
     * メールメッセージ組み立て
     * @returns
     */
    function getMailMessage(): string {
        if (props.mail == null || props.mail.message == null) {
            return "";
        }
        let message = Utility.escape(props.mail.message).replaceAll(
            "\n",
            "<br />"
        );
        const linkInfo = props.mail.linkInfo2;
        if (linkInfo == null) {
            return message;
        } else {
            const userId = linkInfo.userId;
            const userName = linkInfo.userName;
            const groupId = linkInfo.groupId;
            const groupName = linkInfo.groupName;
            const urlList = linkInfo.urlList;

            // ユーザ名置換
            if (
                userName != null &&
                userName.length > 0 &&
                userId != null &&
                userId > 0
            ) {
                message = message.replaceAll(
                    "[" + userName + "]",
                    `[<a target="_blank" href="/users/012-39111508-${userId}/profile">${userName}</a>]`
                );
            }
            // グループ名置換
            if (
                groupName != null &&
                groupName.length > 0 &&
                groupId != null &&
                groupId > 0
            ) {
                message = message.replaceAll(
                    "[" + groupName + "]",
                    `[<a target="_blank" href="/groups/${groupId}">${groupName}</a>]`
                );
            }
            // URL置換
            if (urlList != null && urlList.length > 0) {
                const lstUrl = urlList.split(",");
                for (let i = 0; i < lstUrl.length; i++) {
                    let url = lstUrl[i];
                    message = message.replaceAll(
                        url,
                        `<a href="${url}" target="_blank">${url}</a>`
                    );
                }
            }
            return message;
        }
    }

    function getClassName() {
        if (!props.loginUser) {
            return;
        }
        let className = "component ReceivedChildMailListItem";
        if (props.loginUser.id !== props.mail.receiverId) {
            className = className + " sent-mail";
        } else {
            if (props.mail.read !== 1) {
                className = className + " unread";
            }
        }
        return className;
    }
    /**
     * レンダー
     */
    return (
        <>
            {props.loginUser != null &&
                props.parentMail != null &&
                props.mail.sender != null &&
                props.mail.receiver != null && (
                    <Box
                        className={getClassName()}
                    >
                        <Box className="wrapper">
                            {
                                // 送信者名、作成日時
                            }
                            <Box className="row-1">
                                {props.sender != null && (
                                    <>
                                        <Box className="receiver">
                                            {props.sender.id !== 1 && (
                                                <>
                                                    <span>From:&nbsp;</span>
                                                    <Link to={`/users/${Utility.getUserKey(props.sender)}/profile`}>
                                                        {props.mail.sender.name}
                                                    </Link>
                                                </>
                                            )}
                                            {props.sender.id === 1 && (
                                                <>
                                                    <span>From:&nbsp;</span>
                                                    {props.sender.name}
                                                </>
                                            )}
                                        </Box>
                                        <Box className="date">
                                            {createdAt}
                                        </Box>
                                    </>
                                )}
                            </Box>
                            {
                                // 受信者名
                            }
                            <Box className="row-2">
                                To:{" " + props.mail.receiver.name}
                            </Box>
                            {
                                // タイトル
                            }
                            <Box className="row-3">
                                {props.mail.title}
                            </Box>
                            <Divider className="divider"/>
                            {
                                // 本文
                            }
                            <Box className="row-4"
                                dangerouslySetInnerHTML={{
                                    __html: getMailMessage(),
                                }}
                            ></Box>
                            {
                                // 添付ファイル
                            }
                            {props.mail.existsAttachedFiles === 1 &&
                                props.mail.lstAttachedFile != null && (
                                    <AttachedFileListComponent
                                        loginUser={props.loginUser}
                                        lstAttachedFile={
                                            props.mail.lstAttachedFile
                                        }
                                    />
                                )}
                            {
                                // ボタンエリア
                            }
                            {props.loginUser.id === props.mail.receiverId && (
                                <Box className="button-area">
                                    <Button className="btn-delete"
                                        variant="text"
                                        onClick={props.onDelete}
                                    >
                                        <FormattedMessage id={"btn_delete"} />
                                    </Button>
                                    {props.loginUser.id ===
                                        props.mail.receiverId &&
                                        props.mail.read !== 1 && (
                                            <Button className="btn-read"
                                                variant="text"
                                                onClick={props.onRead}
                                            >
                                                <FormattedMessage
                                                    id={"btn_read"}
                                                />
                                            </Button>
                                        )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
        </>
    );
},
(prevProps: Props, nextProps: Props) => {
    if (prevProps.loginUser !== nextProps.loginUser) {
        return false;
    }
    if (prevProps.parentMail !== nextProps.parentMail) {
        return false;
    }
    if (prevProps.parentMail != null && nextProps.parentMail != null) {
        if (prevProps.parentMail.id !== nextProps.parentMail.id) {
            return false;
        }
    }
    if (prevProps.mail !== nextProps.mail) {
        return false;
    }
    if (prevProps.mail != null && nextProps.mail != null) {
        if (prevProps.mail.id !== nextProps.mail.id) {
            return false;
        }
    }
    if (prevProps.sender != null && nextProps.sender != null) {
        if (prevProps.sender.id !== nextProps.sender.id) {
            return false;
        }
    }
    if (prevProps.receiver != null && nextProps.receiver != null) {
        if (prevProps.receiver.id !== nextProps.receiver.id) {
            return false;
        }
    }
    if (prevProps.langCd !== nextProps.langCd) {
        return false;
    }
    if (prevProps.onRead !== nextProps.onRead) {
        return false;
    }
    if (prevProps.onDelete !== nextProps.onDelete) {
        return false;
    }

    return true;
});
