import React from "react";
import { FormattedMessage } from "react-intl";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { UserModel } from "models/Models";
import { AppConstants } from "constants/AppConstants";
import { SelectCountryComponent } from "components/Components";

interface Props {
    loginUser: UserModel | undefined;
    openDialog: boolean;
    onClose: () => void;
    onCancel: () => void;
    onOk: () => void;
}
export const SelectCountryDialog: React.FC<Props> = (props) => {
    // Utility.log("===== SelectCountryDialog");
    return (
        <Dialog
            className="dialog"
            open={props.openDialog}
            onClose={props.onClose}
            sx={{
                width: "100%",
                maxWidth: {
                    xs: "none",
                    sm: "1000px",
                },
                margin: "auto",
            }}
            PaperProps={{
                sx: {
                    margin: "auto",
                    maxWidth: "none",
                    width: {
                        xs: "95%",
                        sm: "80%",
                    },
                    height: {
                        xs: "100%",
                        sm: "80%",
                    },
                },
            }}
        >
            <DialogTitle
                className="dialog-title"
                sx={{
                    textAlign: "center",
                    padding: "5px 0",
                }}
            >
                <FormattedMessage id={"dlg_title_select_country"} />
            </DialogTitle>
            <DialogContent
                className="dialog-content"
                sx={{
                    position: "relative",
                    padding: 0,
                    overflow: "hidden",
                }}
            >
                <SelectCountryComponent
                    loginUser={props.loginUser}
                    onCancel={props.onCancel}
                    onOk={props.onOk}
                />
            </DialogContent>
        </Dialog>
    );
};
