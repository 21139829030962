import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Card, CardMedia, CardContent, Button } from "@mui/material";
import { UserModel } from "models/Models";
import { DbConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";
import "styles/components/TalkUserComponent.scss";

type Props = {
    user: UserModel;
};

export const TalkUserComponent: React.FC<Props> = React.memo((props) => {
    // Utility.log("===== TalkUserComponent");

    /**
     * 属性のクラス名取得
     * @param gender
     * @param attribute
     * @returns
     */
    function getAttributeClassName() {
        let className = "attribute";
        let attributeLabel = Utility.getAttributeLabelKey(props.user);
        if (attributeLabel.length > 0) {
            if (props.user.gender === DbConstants.GENDER_MALE) {
                className = className + " male";
            } else if (props.user.gender === DbConstants.GENDER_FEMALE) {
                className = className + " female";
            } else {
                className = className + " other";
            }
        }
        return className;
    }

    /**
     * フォローのHtml要素生成
     * @returns
     */
    function getFollowHtml() {
        if (props.user.following) {
            return (
                <div className="follow">
                    <FormattedMessage id={"user_following"} />
                </div>
            );
        } else {
            return <></>;
        }
    }
    
    /**
     * レンダー
     */
    return (
        <div className="component TalkUserComponent">
            <Card className="wrapper">
                {
                    // ユーザアイコン
                }
                <CardMedia
                    className="card-image"
                    component="img"
                    image={
                        props.user.iconName != null &&
                        props.user.iconName.length > 0
                            ? UrlConstants.URL_S3_USER_ICON +
                              props.user.iconName
                            : "/images/no_image.png"
                    }
                />
                <CardContent className="card-content">
                    <div className="info-wrapper-1">
                        {props.user.countryCd != null &&
                            props.user.countryCd.length > 0 && (
                                <img
                                    className="flag-image"
                                    src={
                                        "/images/flag/" +
                                        props.user.countryCd.toUpperCase() +
                                        ".png"
                                    }
                                    alt="Country Flag"
                                />
                            )}
                        {
                            // 属性
                        }
                        <div className={getAttributeClassName()}>
                            {Utility.getAttributeLabelKey(props.user) != null &&
                                Utility.getAttributeLabelKey(props.user)
                                    .length > 0 && (
                                    <FormattedMessage
                                        id={Utility.getAttributeLabelKey(
                                            props.user
                                        )}
                                    />
                                )}
                        </div>
                    </div>
                    {
                        // 名前
                    }
                    <div className="info-wrapper-2">
                        <div className="name-area">
                            {props.user.name}
                        </div>
                        {
                            // フォローラベル
                            getFollowHtml()
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
},
(prevProps: Props, nextProps: Props) => {
    if (prevProps.user !== nextProps.user) {
        return false;
    }
    if (prevProps.user != null && nextProps.user != null) {
        if (prevProps.user.id !== nextProps.user.id) {
            return false;
        }
    }

    return true;
}
);
