import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { TalkHistoryModel, UserModel } from "models/Models";
import {
    TalkHistoryResponse,
} from "api/responses/Responses";
import Utility from "utils/Utility";

export class TalkHistoryRequest {
    // トーク許可更新
    static async create(user: UserModel, history: TalkHistoryModel) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_HISTORY;
        url = url.replace(":user_id", String(user.id));
        Utility.log("TalkHistory create history:" + JSON.stringify(history))
        let formData = new FormData();
        if (history.senderId != null) {
            formData.append("sender_id", String(history.senderId));
        }
        if (history.receiverId != null) {
            formData.append("receiver_id", String(history.receiverId));
        }
        if (history.senderTalkType != null) {
            formData.append("sender_talk_type", String(history.senderTalkType));
        }
        if (history.receiverTalkType != null) {
            formData.append("receiver_talk_type", String(history.receiverTalkType));
        }
        if (history.fetchSkywayToken != null) {
            formData.append("fetch_skyway_token", String(history.fetchSkywayToken));
        }
        if (history.createAndAttachTalkStream != null) {
            formData.append("create_and_attach_talk_stream", String(history.createAndAttachTalkStream));
        }
        if (history.joinSkywayRoom != null) {
            formData.append("join_skyway_room", String(history.joinSkywayRoom));
        }
        if (history.startCasting != null) {
            formData.append("start_casting", String(history.startCasting));
        }
        if (history.subscribeTalk != null) {
            formData.append("subscribe_talk", String(history.subscribeTalk));
        }
        if (history.startTime != null) {
            formData.append("start_time", String(history.startTime));
        }
        if (history.endTime != null) {
            formData.append("end_time", String(history.endTime));
        }
        if (history.talkTime != null) {
            formData.append("talk_time", String(history.talkTime));
        }
        let result = null;
        try {
            Utility.log("TalkHistory create formData:" + JSON.stringify(formData))
            let res = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkHistoryResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    
    // トーク許可更新
    static async update(user: UserModel, history: TalkHistoryModel) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        Utility.log("TalkHistory update history:" + JSON.stringify(history))
        let url = UrlConstants.URL_TALK_HISTORY;
        url = url.replace(":user_id", String(user.id));
        let formData = new FormData();
        if (history.id != null) {
            formData.append("talk_history_id", String(history.id));
        }
        if (history.senderId != null) {
            formData.append("sender_id", String(history.senderId));
        }
        if (history.receiverId != null) {
            formData.append("receiver_id", String(history.receiverId));
        }
        if (history.senderTalkType != null) {
            formData.append("sender_talk_type", String(history.senderTalkType));
        }
        if (history.receiverTalkType != null) {
            formData.append("receiver_talk_type", String(history.receiverTalkType));
        }
        if (history.fetchSkywayToken != null) {
            formData.append("fetch_skyway_token", String(history.fetchSkywayToken));
        }
        if (history.createAndAttachTalkStream != null) {
            formData.append("create_and_attach_talk_stream", String(history.createAndAttachTalkStream));
        }
        if (history.joinSkywayRoom != null) {
            formData.append("join_skyway_room", String(history.joinSkywayRoom));
        }
        if (history.startCasting != null) {
            formData.append("start_casting", String(history.startCasting));
        }
        if (history.subscribeTalk != null) {
            formData.append("subscribe_talk", String(history.subscribeTalk));
        }
        if (history.startTime != null) {
            formData.append("start_time", String(history.startTime));
        }
        if (history.endTime != null) {
            formData.append("end_time", String(history.endTime));
        }
        if (history.talkTime != null) {
            formData.append("talk_time", String(history.talkTime));
        }
        let result = null;
        try {
            Utility.log("TalkHistory update formData:" + JSON.stringify(formData))
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkHistoryResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
