import { UserModel } from "./UserModel";

export class GroupModel {
    id: number | null = null;
    countryCd: string | null = null;
    languageCd: string | null = null;
    ownerId: number | null = null;
    owner: UserModel | null = null;
    name: string | null = null;
    profile: string | null = null;
    cntMember: number | null = null;
    open: number | null = null;
    autoJoin: number | null = null;
    createdAt: string | null = null;
    deletedAt: string | null = null;
    index: number | null = null;

    skip: boolean = false;
    underApplication: boolean = false;
    underInvitation: boolean = false;
    applying: boolean = false;
    inviting: boolean = false;
    selected: boolean = false;
    join: boolean = false;
    isOwner: boolean = false;
    tmpSelected: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // countryCd
        if (obj.hasOwnProperty("countryCd")) {
            this.countryCd = obj.countryCd;
        } else if (obj.hasOwnProperty("CountryCd")) {
            this.countryCd = obj.CountryCd;
        }
        // languageCd
        if (obj.hasOwnProperty("languageCd")) {
            this.languageCd = obj.languageCd;
        } else if (obj.hasOwnProperty("LanguageCd")) {
            this.languageCd = obj.LanguageCd;
        }
        // ownerId
        if (obj.hasOwnProperty("ownerId")) {
            this.ownerId = obj.ownerId;
        } else if (obj.hasOwnProperty("OwnerId")) {
            this.ownerId = obj.OwnerId;
        }
        // owner
        if (obj.hasOwnProperty("owner")) {
            this.owner = new UserModel(obj.owner);
        } else if (obj.hasOwnProperty("Owner")) {
            this.owner = new UserModel(obj.Owner);
        }
        // name
        if (obj.hasOwnProperty("name")) {
            this.name = obj.name;
        } else if (obj.hasOwnProperty("Name")) {
            this.name = obj.Name;
        }
        // profile
        if (obj.hasOwnProperty("profile")) {
            this.profile = obj.profile;
        } else if (obj.hasOwnProperty("Profile")) {
            this.profile = obj.Profile;
        }
        // cntMember
        if (obj.hasOwnProperty("cntMember")) {
            this.cntMember = obj.cntMember;
        } else if (obj.hasOwnProperty("CntMember")) {
            this.cntMember = obj.CntMember;
        }
        // open
        if (obj.hasOwnProperty("public")) {
            this.open = obj.public;
        } else if (obj.hasOwnProperty("Public")) {
            this.open = obj.Public;
        }
        // autoJoin
        if (obj.hasOwnProperty("autoJoin")) {
            this.autoJoin = obj.autoJoin;
        } else if (obj.hasOwnProperty("AutoJoin")) {
            this.autoJoin = obj.AutoJoin;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // deletedAt
        if (obj.hasOwnProperty("deletedAt")) {
            this.deletedAt = obj.deletedAt;
        } else if (obj.hasOwnProperty("DeletedAt")) {
            this.deletedAt = obj.DeletedAt;
        }
    }
}
