import {
    UserModel,
    FollowModel,
    BlockUserModel,
    GroupModel,
    JoinGroupModel,
} from "models/Models";

export class UserListResponse {
    hasNext: boolean = false;
    users: UserModel[] | null = null;
    followUserList: FollowModel[] | null = null;
    blockUserList: BlockUserModel[] | null = null;
    readSize: number | null = null;
    lstOwnGroup: GroupModel[] | null = null;
    lstInvite: JoinGroupModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // hasNext
        if (obj.hasOwnProperty("IsNext")) {
            this.hasNext = obj.IsNext;
        }
        // users
        if (obj.hasOwnProperty("Users")) {
            if (this.users == null) {
                this.users = [];
            }
            for (let i in obj.Users) {
                let user = new UserModel(obj.Users[i]);
                this.users.push(user);
            }
        }
        // followUserList
        if (obj.hasOwnProperty("FollowUserList")) {
            if (this.followUserList == null) {
                this.followUserList = [];
            }
            for (let i in obj.FollowUserList) {
                let followUser = new FollowModel(obj.FollowUserList[i]);
                this.followUserList.push(followUser);
            }
        }
        // blockUserList
        if (obj.hasOwnProperty("BlockUserList")) {
            if (this.blockUserList == null) {
                this.blockUserList = [];
            }
            for (let i in obj.BlockUserList) {
                let blockUser = new BlockUserModel(obj.BlockUserList[i]);
                this.blockUserList.push(blockUser);
            }
        }
        // readSize
        if (obj.hasOwnProperty("ReadSize")) {
            this.readSize = obj.ReadSize;
        }
        // lstOwnGroup
        if (obj.hasOwnProperty("LIST_OWN_GROUP")) {
            if (this.lstOwnGroup == null) {
                this.lstOwnGroup = [];
            }
            for (let i in obj.LIST_OWN_GROUP) {
                let group = new GroupModel(obj.LIST_OWN_GROUP[i]);
                this.lstOwnGroup.push(group);
            }
        }
        // lstInvite
        if (obj.hasOwnProperty("LIST_INVITE")) {
            if (this.lstInvite == null) {
                this.lstInvite = [];
            }
            for (let i in obj.LIST_INVITE) {
                let joinGroup = new JoinGroupModel(obj.LIST_INVITE[i]);
                this.lstInvite.push(joinGroup);
            }
        }
    }
}
