import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { UserModel, ParentMailModel, MailModel } from "models/Models";
import { MailRequest } from "api/requests/Requests";
import {
    ReceivedChildMailListItem,
    NavBar,
    SendMessageDialog,
} from "components/Components";
import {
    UnreadCountAction,
    DecrementMailUnreadCountAction,
    SelectedItemAction,
    NetworkAction,
} from "redux/Actions";
import { getTimeDifference, getLanguageCode } from "redux/Selectors";
import Utility from "utils/Utility";
import { DbConstants } from "constants/Constants";
import ReplyIcon from "@mui/icons-material/Reply";
import "styles/components/ReceivedChildMailComponent.scss";

type Props = {
    loginUser: UserModel | null;
    parentId: string;
    refreshTime?: number;
    onRead: () => void;
    onDelete: (childMail: MailModel, count: number) => void;
};

export const ReceivedChildMailComponent: React.FC<Props> = React.memo((props) => {
    Utility.log("===== ReceivedChildMailComponent parentId:" + props.parentId);
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
    const langCd = useSelector((state) => getLanguageCode(state));
    // 時差
    const timeDifference = useSelector((state) => getTimeDifference(state));

    /***** useRef */
    // 通信中フラグ
    const isUnderProcess = React.useRef(false);

    /***** useState */
    // 親メール
    const [parentMail, setParentMail] = React.useState<ParentMailModel>();
    // 子メールリスト
    const [lstChildMail, setChildMailList] = React.useState<MailModel[]>();
    // 送信者
    const [sender, setSender] = React.useState<UserModel>();
    // 受信者
    const [receiver, setReceiver] = React.useState<UserModel>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] =
        React.useState<boolean>(false);
    const [selectedChildMail, setSelectedChildMail] =
        React.useState<MailModel>();
    // メッセージ送信ダイアログ表示フラグ
    const [openSendMessageDialog, setOpenSendMessageDialog] =
        React.useState<boolean>(false);

    /**
     * useEffect
     */
    React.useEffect(() => {
        if (props.loginUser == null) {
            return;
        }
        if (props.parentId == null || props.parentId.length === 0) {
            return;
        }
        getChildMailList();
    }, [
        props.loginUser,
        props.parentId,
        props.refreshTime,
    ]);

    /**
     * 返信ボタン押下時
     */
    async function onClickReply() {
        if (
            props.loginUser == null ||
            parentMail == null ||
            parentMail.senderId === DbConstants.SMMATE_STAFF_ID
        ) {
            return;
        }
        let sender = parentMail.sender;
        if (parentMail.senderId === props.loginUser.id) {
            sender = parentMail.receiver;
        }
        dispatch(
            SelectedItemAction({
                parentMail: parentMail,
                user: sender,
            })
        );

        window.setTimeout(() => {
            if (isSizeXS) {
                window.setTimeout(() => {
                    navigate("/mail/send");
                }, 500);
            } else {
                setOpenSendMessageDialog(true);
            }
        }, 500);
    }

    /**
     * 既読
     * @param mail
     * @returns
     */
    const readMail = React.useCallback(async (mail: MailModel) => {
        if (mail.id == null || props.loginUser == null) {
            return;
        }
        if (isUnderProcess.current) {
            return;
        }
        isUnderProcess.current = true;
        try {
            // 既読リクエスト
            const result = await MailRequest.read(props.loginUser, mail.id);
            if (result == null) {
                if (window.navigator.onLine) {
                    throw new Error();
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
    
            if (result.rtnCd == null || result.rtnCd < 0) {
                throw new Error();
            }
            if (lstChildMail != null) {
                for (let i = 0; i < lstChildMail.length; i++) {
                    let childMail = lstChildMail[i];
                    if (childMail.id === mail.id) {
                        childMail.read = 1;
                        break;
                    }
                }
                setChildMailList([...lstChildMail]);
            }
            dispatch(DecrementMailUnreadCountAction());
            props.onRead();
        } catch (error) {
            console.error(error);
        } finally {
            isUnderProcess.current = false;
        }
    }, [
        props,
        lstChildMail,
        dispatch,
    ]);

    /**
     * メール削除
     * @param mail
     * @returns
     */
    const deleteMail = React.useCallback(async (mail: MailModel) => {
        if (
            props.loginUser == null ||
            props.parentId == null ||
            mail.id == null ||
            lstChildMail == null
        ) {
            return;
        }
        if (isUnderProcess.current) {
            return;
        }
        isUnderProcess.current = true;
        try {
            // 削除メールのインデックス取得
            let idxRemove = -1;
            for (let i = 0; i < lstChildMail.length; i++) {
                let childMail = lstChildMail[i];
                if (childMail.id === mail.id) {
                    idxRemove = i;
                    break;
                }
            }
            if (idxRemove === -1) {
                throw new Error();
            }
            // 削除メールIDのリスト組み立て
            let lstRemoveId: number[] = [];
            lstRemoveId.push(mail.id);
            if (lstChildMail.length >= idxRemove + 2) {
                let prevMailIsMine = true;
                if (idxRemove > 0) {
                    const prevChildMail = lstChildMail[idxRemove - 1];
                    if (prevChildMail.receiverId === props.loginUser.id) {
                        if (prevChildMail.hidden === false) {
                            prevMailIsMine = false;
                        }
                    }
                }
                if (prevMailIsMine) {
                    for (let i = idxRemove + 1; i < lstChildMail.length; i++) {
                        const childMail = lstChildMail[i];
                        if (childMail.hidden === true) {
                            continue;
                        }
                        if (childMail.receiverId === props.loginUser.id) {
                            break;
                        } else {
                            if (childMail.id != null) {
                                lstRemoveId.push(childMail.id);
                            }
                        }
                    }
                }
            }
            // メール削除リクエスト
            const result = await MailRequest.delete(
                props.loginUser,
                lstRemoveId,
                Number(props.parentId),
                0,
                1
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
    
            if (result.rtnCd == null || result.rtnCd < 0) {
                throw new Error();
            }
            if (lstChildMail != null) {
                // 削除メールの非表示設定
                for (let i = 0; i < lstChildMail.length; i++) {
                    let childMail = lstChildMail[i];
                    for (let j = 0; j < lstRemoveId.length; j++) {
                        if (childMail.id === lstRemoveId[j]) {
                            childMail.hidden = true;
                        }
                    }
                }
                setChildMailList([...lstChildMail]);
            }
            const unreadMailCount = await Utility.getUnreadMailCount(
                props.loginUser
            );
            dispatch(
                UnreadCountAction({
                    mail: unreadMailCount != null ? unreadMailCount : 0,
                })
            );
            let count = 0;
            if (lstChildMail != null && lstChildMail.length > 0) {
                lstChildMail.forEach((childMail) => {
                    if (!childMail.hidden) {
                        count++;
                    }
                });
            }
            props.onDelete(mail, count);
        } catch (error) {
            console.error(error);
        } finally {
            isUnderProcess.current = false;
        }
    }, [
        props,
        lstChildMail,
        dispatch,
        navigate,
    ]);

    /**
     * 子メールリスト取得
     * @returns
     */
    async function getChildMailList() {
        if (props.loginUser == null) {
            return;
        }
        if (props.parentId == null) {
            return;
        }
        if (isUnderProcess.current) {
            return;
        }
        isUnderProcess.current = true;
        // 子メールリスト取得
        const result = await MailRequest.getChildMailList(
            props.loginUser,
            Number(props.parentId)
        );
        try {
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;    
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                throw new Error();
            }
            // parentMail
            const parentMail = result.parentMail;
            if (parentMail != null) {
                setParentMail(parentMail);
                if (parentMail.sender != null && parentMail.receiver != null) {
                    if (props.loginUser.id === parentMail.receiverId) {
                        // 送信者セット
                        setSender(parentMail.sender);
                        // 受信者セット
                        setReceiver(parentMail.receiver);
                    } else {
                        // 送信者セット
                        setSender(parentMail.receiver);
                        // 受信者セット
                        setReceiver(parentMail.sender);
                    }
                }
            }
            // lstChildMail
            if (result.lstChildMail != null && parentMail != null) {
                const wkChildMailList: MailModel[] = [];
                let firstSendRec = false;
                for (let i = 0; i < result.lstChildMail.length; i++) {
                    const childMail = result.lstChildMail[i];
                    if (props.loginUser.id === parentMail.receiverId) {
                        if (childMail.receiverReceiveDelete === 1) {
                            continue;
                        }
                    } else if (props.loginUser.id === parentMail.senderId) {
                        if (childMail.senderReceiveDelete === 1) {
                            continue;
                        }
                    }
                    if (!firstSendRec) {
                        if (props.loginUser.id !== childMail.receiverId) {
                            continue;
                        }
                    }
                    firstSendRec = true;
                    wkChildMailList.push(childMail);
                }
                setChildMailList(wkChildMailList);
            }
            setLoaded(true);
        } catch (e) {
        } finally {
            isUnderProcess.current = false;
        }
    }

    /**
     * メール削除
     */
    async function handleConfirmDialogYes() {
        if (selectedChildMail != null) {
            await deleteMail(selectedChildMail);
        }
        setOpenConfirmDialog(false);
    }

    /**
     * 削除確認ダイアログ閉じる
     */
    function handleConfirmDialogClose() {
        setOpenConfirmDialog(false);
    }

    /**
     * レンダリング
     */
    return (
        <div className="component ReceivedChildMailComponent">
            {props.loginUser != null &&
                lstChildMail != null &&
                sender != null &&
                receiver != null &&
                loaded && (
                    <>
                        <NavBar
                            showBack={true}
                            title={
                                intl.formatMessage({ id: "inbox" }) +
                                `[${sender.name}]`
                            }
                        />
                        <div className="list-wrapper">
                            {lstChildMail.map(
                                (childMail: MailModel, index: number) => {
                                    let insertBottomMargin = false;
                                    if (index > 0) {
                                        const prevChildMail =
                                            lstChildMail[index - 1];
                                        if (
                                            props.loginUser != null &&
                                            props.loginUser.id ===
                                                childMail.receiverId &&
                                            prevChildMail.hidden === false &&
                                            !(
                                                prevChildMail != null &&
                                                prevChildMail.reply === 1 &&
                                                props.loginUser.id ===
                                                    prevChildMail.receiverId
                                            )
                                        ) {
                                            insertBottomMargin = true;
                                        }
                                    }
                                    return (
                                        <React.Fragment key={childMail.id}>
                                            {!childMail.hidden && (
                                                <>
                                                    {insertBottomMargin && (
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                height: "20px",
                                                            }}
                                                        />
                                                    )}
                                                    {!insertBottomMargin && (
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                height: "1px",
                                                            }}
                                                        />
                                                    )}
                                                    {timeDifference != null &&
                                                        langCd != null && (
                                                            <ReceivedChildMailListItem
                                                                loginUser={
                                                                    props.loginUser
                                                                }
                                                                parentMail={
                                                                    parentMail
                                                                }
                                                                mail={childMail}
                                                                sender={sender}
                                                                receiver={
                                                                    receiver
                                                                }
                                                                timeDifference={
                                                                    timeDifference
                                                                }
                                                                langCd={langCd}
                                                                onRead={() =>
                                                                    readMail(
                                                                        childMail
                                                                    )
                                                                }
                                                                onDelete={() => {
                                                                    setSelectedChildMail(
                                                                        childMail
                                                                    );
                                                                    setOpenConfirmDialog(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </div>
                        {lstChildMail != null && lstChildMail.length > 0 && (
                            <div
                                className={
                                    parentMail == null ||
                                    parentMail.senderId ===
                                        DbConstants.SMMATE_STAFF_ID
                                        ? "disabled icon-reply-wrapper"
                                        : "icon-reply-wrapper"
                                }
                            >
                                <ReplyIcon
                                    style={{
                                        width: "80%",
                                        height: "80%",
                                        color:
                                            parentMail == null ||
                                            parentMail.senderId ===
                                                DbConstants.SMMATE_STAFF_ID
                                                ? "#aaaaaa"
                                                : "#0978f6",
                                    }}
                                    onClick={onClickReply}
                                />
                            </div>
                        )}
                        <SendMessageDialog
                            open={openSendMessageDialog}
                            close={async () => {
                                dispatch(
                                    SelectedItemAction({
                                        childMail: null,
                                        lstUser: null,
                                    })
                                );
                                setOpenSendMessageDialog(false);
                            }}
                            onSendCompleted={() => {
                                dispatch(
                                    SelectedItemAction({
                                        childMail: null,
                                        lstUser: null,
                                    })
                                );
                                setOpenSendMessageDialog(false);
                            }}
                            loginUser={props.loginUser}
                            parentMail={parentMail}
                        />
                        <Dialog
                            className="dialog"
                            open={openConfirmDialog}
                            onClose={handleConfirmDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent className="dialog-content2">
                                <DialogContentText id="alert-dialog-description">
                                    <FormattedMessage id={"confirm_delete"} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    onClick={handleConfirmDialogYes}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_yes2"} />
                                </Button>
                                <Button
                                    className="dialog-button"
                                    onClick={handleConfirmDialogClose}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
        </div>
    );
},
(prevProps: Props, nextProps: Props) => {
    if (prevProps.loginUser !== nextProps.loginUser) {
        return false;
    }
    if (prevProps.parentId !== nextProps.parentId) {
        return false;
    }
    if (prevProps.refreshTime !== nextProps.refreshTime) {
        return false;
    }
    if (prevProps.onRead !== nextProps.onRead) {
        return false;
    }
    if (prevProps.onDelete !== nextProps.onDelete) {
        return false;
    }
    return true;
});
