import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { AlbumModel, GalleryModel, UserModel } from "models/Models";
import {
    AlbumResponse,
    AlbumTemplateResponse,
    AlbumCreateResponse,
    AlbumSaveResponse,
    AlbumReorderResponse,
    GalleryListResponse,
    GalleryUploadResponse,
    GalleryDeleteResponse,
    GallerySaveOrderResponse,
    AlbumDeleteResponse,
} from "api/responses/Responses";

export class AlbumRequest {
    static async createAlbum(loginUser: UserModel, album: AlbumModel) {
        let formData = new FormData();
        if (
            loginUser.id == null ||
            album.name == null ||
            album.albumTemplateId == null ||
            album.open == null ||
            album.openGroup == null ||
            album.openFollower == null ||
            album.openIndividual == null
        ) {
            return null;
        }
        // アルバム名
        formData.append("AlbumName", album.name);
        // テンプレートID
        formData.append("AlbumTemplateId", String(album.albumTemplateId));
        // 一般公開
        formData.append("Open", String(album.open));
        // グループ公開
        formData.append("OpenGroup", String(album.openGroup));
        // フォロワー公開
        formData.append("OpenFollower", String(album.openFollower));
        // 特定ユーザ公開
        formData.append("OpenIndividual", String(album.openIndividual));

        // グループ公開の場合
        if (album.openGroup === 1) {
            if (album.lstOpenGroupIdList == null) {
                return null;
            }
            const lstGroupId = album.lstOpenGroupIdList.join(",");
            formData.append("GroupIdList", lstGroupId);
        }
        // 特定ユーザ公開の場合
        if (album.openIndividual === 1) {
            if (album.lstOpenUserIdList == null) {
                return null;
            }
            const lstUserId = album.lstOpenUserIdList.join(",");
            formData.append("UserIdList", lstUserId);
        }

        let url = UrlConstants.URL_CREATE_ALBUM;
        url = url.replace(":id", String(loginUser.id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumCreateResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * アルバム保存
     * @param loginUser
     * @param album
     * @returns
     */
    static async saveAlbum(loginUser: UserModel, album: AlbumModel) {
        let formData = new FormData();
        if (
            loginUser.id == null ||
            album.name == null ||
            album.albumTemplateId == null ||
            album.open == null ||
            album.openGroup == null ||
            album.openFollower == null ||
            album.openIndividual == null
        ) {
            return null;
        }
        // アルバムID
        formData.append("AlbumId", String(album.id));
        // アルバム名
        formData.append("AlbumName", album.name);
        // テンプレートID
        formData.append("AlbumTemplateId", String(album.albumTemplateId));
        // 一般公開
        formData.append("Open", String(album.open));
        // グループ公開
        formData.append("OpenGroup", String(album.openGroup));
        // フォロワー公開
        formData.append("OpenFollower", String(album.openFollower));
        // 特定ユーザ公開
        formData.append("OpenIndividual", String(album.openIndividual));

        // グループ公開の場合
        if (album.openGroup === 1) {
            if (album.lstOpenGroupIdList == null) {
                return null;
            }
            const lstGroupId = album.lstOpenGroupIdList.join(",");
            formData.append("GroupIdList", lstGroupId);
        }
        // 特定ユーザ公開の場合
        if (album.openIndividual === 1) {
            if (album.lstOpenUserIdList == null) {
                return null;
            }
            const lstUserId = album.lstOpenUserIdList.join(",");
            formData.append("UserIdList", lstUserId);
        }

        let url = UrlConstants.URL_SAVE_ALBUM;
        url = url.replace(":id", String(loginUser.id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumSaveResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * アルバム順序変更
     * @param loginUser
     * @param album_id_list
     * @param album_order_list
     * @returns
     */
    static async reorder(
        loginUser: UserModel,
        album_id_list: string[],
        album_order_list: string[]
    ) {
        if (loginUser.id == null || loginUser.bearerToken == null) {
            return null;
        }

        const lstAlbumId = album_id_list.join(",");
        const lstAlbumOrder = album_order_list.join(",");
        let formData = new FormData();
        formData.append("AlbumIdList", lstAlbumId);
        formData.append("AlbumOrderList", lstAlbumOrder);

        let url = UrlConstants.URL_SAVE_ALBUM_ORDER;
        url = url.replace(":id", String(loginUser.id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumReorderResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * アルバム順序変更(グループ)
     * @param loginUser
     * @param album_id_list
     * @param album_order_list
     * @returns
     */
    static async reorderGroup(
        loginUser: UserModel,
        album_group_id_list: string[],
        album_group_order_list: string[]
    ) {
        if (loginUser.id == null || loginUser.bearerToken == null) {
            return null;
        }

        const lstAlbumGroupId = album_group_id_list.join(",");
        const lstAlbumGroupOrder = album_group_order_list.join(",");
        let formData = new FormData();
        formData.append("AlbumGroupIdList", lstAlbumGroupId);
        formData.append("AlbumGroupOrderList", lstAlbumGroupOrder);

        let url = UrlConstants.URL_SAVE_ALBUM_GROUP_ORDER;
        url = url.replace(":id", String(loginUser.id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumReorderResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * アルバム順序変更(ユーザー)
     * @param loginUser
     * @param album_id_list
     * @param album_order_list
     * @returns
     */
    static async reorderUser(
        loginUser: UserModel,
        album_user_id_list: string[],
        album_user_order_list: string[]
    ) {
        if (loginUser.id == null || loginUser.bearerToken == null) {
            return null;
        }

        const lstAlbumUserId = album_user_id_list.join(",");
        const lstAlbumUserOrder = album_user_order_list.join(",");
        let formData = new FormData();
        formData.append("AlbumUserIdList", lstAlbumUserId);
        formData.append("AlbumUserOrderList", lstAlbumUserOrder);

        let url = UrlConstants.URL_SAVE_ALBUM_USER_ORDER;
        url = url.replace(":id", String(loginUser.id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumReorderResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * アルバムテンプレートリスト取得
     * @returns
     */
    static async getAlbumTemplateList() {
        const url = UrlConstants.URL_GET_ALBUM_TEMPLATE_LIST;
        let result = null;
        try {
            const res = await axios.get(url, { withCredentials: true });
            if (res != null) {
                result = new AlbumTemplateResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * アルバム取得
     * @returns
     */
    static async getAlbum(loginUser: UserModel, album_id: string) {
        if (loginUser.id == null || loginUser.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.URL_ALBUM;
        url = url.replace(":id", String(loginUser.id));
        url = url.replace(":album_id", album_id);
        let result = null;
        try {
            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * アルバム削除
     * @param loginUser
     * @param album_id
     */
    static async deleteAlbum(loginUser: UserModel, album_id: number) {
        if (
            loginUser.id == null ||
            loginUser.bearerToken == null ||
            album_id == null
        ) {
            return null;
        }
        let url = UrlConstants.URL_DELETE_ALBUM;
        url = url.replace(":id", String(loginUser.id));

        const formData = new FormData();
        formData.append("AlbumId", String(album_id));

        let result = null;
        try {
            const res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new AlbumDeleteResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * ギャラリーリスト取得
     * @returns
     */
    static async getGalleryList(loginUser: UserModel, album_id: string) {
        if (loginUser.id == null || loginUser.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.URL_USER_ALBUM_GALLERY_LIST;
        url = url.replace(":user_id", String(loginUser.id));
        url = url.replace(":album_id", album_id);
        let result = null;
        try {
            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new GalleryListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * ギャラリー追加
     * @param loginUser
     * @param gallery
     * @returns
     */
    static async appendGallery(loginUser: UserModel, gallery: GalleryModel) {
        if (
            loginUser.id == null ||
            loginUser.bearerToken == null ||
            gallery.albumId == null ||
            gallery.orderNo == null ||
            gallery.fileName == null ||
            gallery.file == null
        ) {
            return null;
        }
        let url = UrlConstants.URL_CREATE_GALLERY;
        url = url.replace(":user_id", String(loginUser.id));
        url = url.replace(":album_id", String(gallery.albumId));

        const formData = new FormData();
        formData.append("Order", String(gallery.orderNo));
        formData.append("FileName", gallery.fileName);
        formData.append("file", gallery.file);

        let result = null;
        try {
            const res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new GalleryUploadResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * ギャラリー削除
     * @param loginUser
     * @param gallery
     * @returns
     */
    static async deleteGallery(loginUser: UserModel, gallery: GalleryModel) {
        if (
            loginUser.id == null ||
            loginUser.bearerToken == null ||
            gallery.albumId == null ||
            gallery.id == null
        ) {
            return null;
        }
        let url = UrlConstants.URL_DELETE_GALLERY;
        url = url.replace(":user_id", String(loginUser.id));
        url = url.replace(":album_id", String(gallery.albumId));

        const formData = new FormData();
        formData.append("GalleryId", String(gallery.id));

        let result = null;
        try {
            const res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new GalleryDeleteResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * ギャラリー順序保存
     * @param loginUser
     * @param album_id
     * @param gallery_id_list
     * @param gallery_order_list
     * @returns
     */
    static async saveGalleryOrder(
        loginUser: UserModel,
        album_id: number,
        gallery_id_list: string[],
        gallery_order_list: string[]
    ) {
        if (loginUser.id == null || loginUser.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.URL_SAVE_GALLERY_ORDER;
        url = url.replace(":user_id", String(loginUser.id));
        url = url.replace(":album_id", String(album_id));

        const formData = new FormData();
        formData.append("GalleryIdList", gallery_id_list.join(","));
        formData.append("GalleryOrderList", gallery_order_list.join(","));

        let result = null;
        try {
            const res = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${loginUser.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new GallerySaveOrderResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
