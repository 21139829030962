import { CountryModel } from "models/Models";

export class CountryListResponse {
    rtnCd?: number | null = null;
    lstCountry?: CountryModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstCountry
        if (obj.hasOwnProperty("CountryList")) {
            if (this.lstCountry == null) {
                this.lstCountry = [];
            }
            for (let i in obj.CountryList) {
                let country = new CountryModel(obj.CountryList[i]);
                this.lstCountry.push(country);
            }
        }
    }
}
