import axios from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { UserModel } from "models/Models";
import {
    UnreadTalkResponse,
    TalkMessageReadResponse,
    TalkMessageListResponse,
    TalkMessageSendResponse,
    TalkMessageDeleteResponse,
} from "api/responses/Responses";

export class TalkMessageRequest {
    /**
     * トークメッセージリスト取得
     * @param user
     * @param partner_id
     * @param index
     * @returns
     */
    static async getTalkMessageList(
        user: UserModel,
        partner_id: number,
        index: number
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.V2_URL_TALK_MESSAGES;
        url = url.replace(":user_id", String(user.id));
        url = url + "?index=" + String(index);
        url = url + "&partner_id=" + String(partner_id);
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new TalkMessageListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * トークメッセージ送信
     * @param user
     * @param partner_id
     * @param message
     * @param file_list
     * @returns
     */
    static async send(
        user: UserModel,
        partner_id: number,
        message: string,
        file_list: File[] | undefined
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_SEND;
        url = url.replace(":user_id", String(user.id));
        let formData = new FormData();
        formData.append("sender_id", String(user.id));
        formData.append("receiver_id", String(partner_id));
        formData.append("message", message);
        if (file_list != null && file_list.length > 0) {
            file_list.forEach((file) => {
                formData.append("attachedImageFiles", file);
            });
        }

        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new TalkMessageSendResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * 未読件数取得
     * @param user
     * @returns
     */
    static async getUnreadCount(user: UserModel) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_UNREAD_MESSAGE_COUNT;
        url = url.replace(":user_id", String(user.id));
        let result = null;
        try {
            let res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
                withCredentials: true,
            });
            if (res != null) {
                result = new UnreadTalkResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * 既読セット
     * @param user
     * @param sender_id
     * @param talk_message_id
     * @returns
     */
    static async read(
        user: UserModel,
        sender_id: number,
        talk_message_id: number
    ) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_READ;
        url = url.replace(":user_id", String(user.id));
        let formData = new FormData();
        formData.append("sender_id", String(sender_id));
        formData.append("talk_message_id", String(talk_message_id));

        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new TalkMessageReadResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * トークメッセージ削除
     * @param user
     * @param talk_message_id
     * @returns
     */
    static async delete(user: UserModel, talk_message_id: number) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return;
        }
        let url = UrlConstants.URL_TALK_DELETE_MESSAGE;
        url = url.replace(":user_id", String(user.id));
        let formData = new FormData();
        formData.append("talk_message_id", String(talk_message_id));

        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            if (res != null) {
                result = new TalkMessageDeleteResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
