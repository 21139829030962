import { createSelector } from "reselect";

const rtcInfoSelector = (state) => state.rtcInfo;

export const getRtcInfo = createSelector(
    [rtcInfoSelector],
    (state) => state
);
export const getRtcSenderId = createSelector(
    [rtcInfoSelector],
    (state) => state.senderId
);
export const getRtcReceiverId = createSelector(
    [rtcInfoSelector],
    (state) => state.receiverId
);
export const getRtcSenderTalkType = createSelector(
    [rtcInfoSelector],
    (state) => state.senderTalkType
);
export const getRtcReceiverTalkType = createSelector(
    [rtcInfoSelector],
    (state) => state.receiverTalkType
);
