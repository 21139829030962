import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import {
    HistoryAction,
    UnreadCountAction,
    SelectedItemAction,
    WorkingItemAction,
} from "redux/Actions";
import {
    getUserListHistory,
    getUnreadCountMail,
    getUnreadCountTalk,
} from "redux/Selectors";
import { UserModel, HistoryUserList } from "models/Models";
import { AppConstants } from "constants/Constants";
import Utility from "utils/Utility";
import "styles/components/Footer.scss";

type Props = {
    loginUser: UserModel | null;
};

export const Footer: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== Footer");
        /***** 定数、変数 */
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const location = useLocation();
        const tmpUnreadMailCount = useSelector((state) => getUnreadCountMail(state));
        const unreadMailCount = tmpUnreadMailCount != null
                ? tmpUnreadMailCount
                : 0;
        const tmpUnreadTalkCount = useSelector((state) => getUnreadCountTalk(state));
        const unreadTalkCount = tmpUnreadTalkCount != null
                ? tmpUnreadTalkCount
                : 0;
        const userListHistory = useSelector((state) => getUserListHistory(state) as HistoryUserList);

        /***** useState */
        const [selectedFooterMenu, setSelectedFooterMenu] =
            React.useState<number>(AppConstants.FOOTER_MENU_NONE);
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            if (location.pathname == null ||
                location.pathname === "/" ||
                location.pathname.startsWith("/login") ||
                location.pathname.startsWith("/register") ||
                location.pathname.startsWith("/maintenance") ||
                location.pathname.startsWith("/report")
            ) {
                setSelectedFooterMenu(AppConstants.FOOTER_MENU_NONE);
            } else {
                if (location.pathname.startsWith("/users")) {
                    setSelectedFooterMenu(AppConstants.FOOTER_MENU_USERS);
                } else if (location.pathname.startsWith("/groups")) {
                    setSelectedFooterMenu(AppConstants.FOOTER_MENU_GROUPS);
                } else if (location.pathname.startsWith("/board")) {
                    setSelectedFooterMenu(AppConstants.FOOTER_MENU_MESSAGE_BOARD);
                } else if (location.pathname.startsWith("/talk")) {
                    setSelectedFooterMenu(AppConstants.FOOTER_MENU_TALK);
                } else if (location.pathname.startsWith("/mail")) {
                    setSelectedFooterMenu(AppConstants.FOOTER_MENU_MAIL);
                } else if (location.pathname.startsWith("/mypage")) {
                    setSelectedFooterMenu(AppConstants.FOOTER_MENU_MYPAGE);
                }
            }
        }, [location.pathname]);

        /**
         * メール未読件数取得
         * @returns
         */
        async function getUnreadMailCount() {
            const unreadCount = await Utility.getUnreadMailCount(
                props.loginUser
            );
            dispatch(UnreadCountAction({ mail: unreadCount }));
        }

        /**
         * ユーザリスト選択時
         */
        const onClickUserList = async () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            if (userListHistory != null) {
                userListHistory.scrollPos = 0;
                dispatch(HistoryAction({ userListHistory: userListHistory }));
            }
            setSelectedFooterMenu(AppConstants.FOOTER_MENU_USERS);
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearReceivedMailListHistory();
            clearSentMailListHistory();
            navigate("/users");
        };
        /**
         * グループリスト選択時
         */
        const onClickGroupList = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            setSelectedFooterMenu(AppConstants.FOOTER_MENU_GROUPS);
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/groups");
        };
        /**
         * 掲示板選択時
         */
        const onClickMessageBoard = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            setSelectedFooterMenu(AppConstants.FOOTER_MENU_MESSAGE_BOARD);
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/board");
        };
        /**
         * 会話選択時
         */
        const onClickConversation = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            setSelectedFooterMenu(AppConstants.FOOTER_MENU_TALK);
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/talk");
        };
        /**
         * メール選択時
         */
        const onClickMail = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            getUnreadMailCount();
            setSelectedFooterMenu(AppConstants.FOOTER_MENU_MAIL);
            clearSelectedItem();
            clearWorkingItem();
            clearUserListHistory();
            if (window.location.pathname.includes("/mail")) {
                window.setTimeout(() => {
                    navigate("/mail", {state: {
                        refreshTime: new Date().getTime(),
                    }});
                });
            } else {
                window.setTimeout(() => {
                    navigate("/mail");
                });
            }
        };
        /**
         * マイページ選択時
         */
        const onClickMyPage = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            setSelectedFooterMenu(AppConstants.FOOTER_MENU_MYPAGE);
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/mypage");
        };

        function clearSelectedItem() {
            dispatch(
                SelectedItemAction({
                    userId: 0,
                    user: null,
                    group: null,
                    parentMail: null,
                    childMail: null,
                    talkUser: null,
                    album: null,
                    lstUser: null,
                    lstGroup: null,
                    albumName: null,
                    albumPublicSetting: null,
                    albumTemplateId: null,
                })
            );
        }
        function clearWorkingItem() {
            dispatch(
                WorkingItemAction({
                    register: null,
                    album: null,
                    mail: null,
                    profile: null,
                })
            );
        }
        function clearHistory() {
            clearUserListHistory();
            clearReceivedMailListHistory();
            clearSentMailListHistory();
        }
        function clearUserListHistory() {
            dispatch(HistoryAction({ userListHistory: null }));
        }
        function clearReceivedMailListHistory() {
            dispatch(HistoryAction({ receivedMailListHistory: null }));
        }
        function clearSentMailListHistory() {
            dispatch(HistoryAction({ sentMailListHistory: null }));
        }

        return (
            <div className="Footer">
                <div className="dummy-for-sp" />
                <ul className="footer-menu-list">
                    {/* ユーザ */}
                    <li className="footer-menu-item" onClick={onClickUserList}>
                        <img
                            className="img-footer-menu"
                            src={
                                selectedFooterMenu ===
                                AppConstants.FOOTER_MENU_USERS
                                    ? "/images/menu/menu_userlist_on.png"
                                    : "/images/menu/menu_userlist_off.png"
                            }
                            alt="User List Button"
                        />
                        <Box
                            component="div"
                            sx={{
                                lineHeight: "1.0",
                                color:
                                    selectedFooterMenu ===
                                    AppConstants.FOOTER_MENU_USERS
                                        ? "#2222ff"
                                        : "#ffffff",
                            }}
                        >
                            <FormattedMessage id={"MENU_USERS"} />
                        </Box>
                    </li>
                    {/* グループ */}
                    <li className="footer-menu-item" onClick={onClickGroupList}>
                        <img
                            className="img-footer-menu"
                            src={
                                selectedFooterMenu ===
                                AppConstants.FOOTER_MENU_GROUPS
                                    ? "/images/menu/menu_grouplist_on.png"
                                    : "/images/menu/menu_grouplist_off.png"
                            }
                            alt="Group List Button"
                        />
                        <div
                            style={{
                                lineHeight: "1.0",
                                color:
                                    selectedFooterMenu ===
                                    AppConstants.FOOTER_MENU_GROUPS
                                        ? "#2222ff"
                                        : "#ffffff",
                            }}
                        >
                            <FormattedMessage id={"MENU_GROUPS"} />
                        </div>
                    </li>
                    {/* 掲示板 */}
                    <li
                        className="footer-menu-item"
                        onClick={onClickMessageBoard}
                    >
                        <img
                            className="img-footer-menu"
                            src={
                                selectedFooterMenu ===
                                AppConstants.FOOTER_MENU_MESSAGE_BOARD
                                    ? "/images/menu/menu_message_board_on.png"
                                    : "/images/menu/menu_message_board_off.png"
                            }
                            alt="Board Button"
                        />
                        <div
                            style={{
                                lineHeight: "1.0",
                                color:
                                    selectedFooterMenu ===
                                    AppConstants.FOOTER_MENU_MESSAGE_BOARD
                                        ? "#2222ff"
                                        : "#ffffff",
                            }}
                        >
                            <FormattedMessage id={"MENU_BULLETIN_BOARD"} />
                        </div>
                    </li>
                    {/* 会話 */}
                    <li
                        className="footer-menu-item"
                        onClick={onClickConversation}
                    >
                        <img
                            className="img-footer-menu"
                            src={
                                selectedFooterMenu ===
                                AppConstants.FOOTER_MENU_TALK
                                    ? "/images/menu/menu_talk_on.png"
                                    : "/images/menu/menu_talk_off.png"
                            }
                            alt="Chat Button"
                        />
                        <div
                            style={{
                                lineHeight: "1.0",
                                color:
                                    selectedFooterMenu ===
                                    AppConstants.FOOTER_MENU_TALK
                                        ? "#2222ff"
                                        : "#ffffff",
                            }}
                        >
                            <FormattedMessage id={"MENU_TALK"} />
                        </div>
                        <div
                            className="unread-badge-talk"
                            style={{
                                display:
                                    unreadTalkCount === 0 ? "none" : "flex",
                            }}
                        >
                            {unreadTalkCount}
                        </div>
                    </li>
                    {/* メール */}
                    <li className="footer-menu-item" onClick={onClickMail}>
                        <img
                            className="img-footer-menu"
                            src={
                                selectedFooterMenu ===
                                AppConstants.FOOTER_MENU_MAIL
                                    ? "/images/menu/menu_mail_on.png"
                                    : "/images/menu/menu_mail_off.png"
                            }
                            alt="Mail Button"
                        />
                        <div
                            style={{
                                lineHeight: "1.0",
                                color:
                                    selectedFooterMenu ===
                                    AppConstants.FOOTER_MENU_MAIL
                                        ? "#2222ff"
                                        : "#ffffff",
                            }}
                        >
                            <FormattedMessage id={"MENU_MAIL"} />
                        </div>
                        <div
                            className="unread-badge-mail"
                            style={{
                                display:
                                    unreadMailCount === 0 ? "none" : "flex",
                            }}
                        >
                            {unreadMailCount}
                        </div>
                    </li>
                    {/* マイページ */}
                    <li className="footer-menu-item" onClick={onClickMyPage}>
                        <img
                            className="img-footer-menu"
                            src={
                                selectedFooterMenu ===
                                AppConstants.FOOTER_MENU_MYPAGE
                                    ? "/images/menu/menu_mypage_on.png"
                                    : "/images/menu/menu_mypage_off.png"
                            }
                            alt="MyPage Button"
                        />
                        <div
                            style={{
                                lineHeight: "1.0",
                                color:
                                    selectedFooterMenu ===
                                    AppConstants.FOOTER_MENU_MYPAGE
                                        ? "#2222ff"
                                        : "#ffffff",
                            }}
                        >
                            <FormattedMessage id={"MENU_MYPAGE"} />
                        </div>
                    </li>
                </ul>
                <Dialog
                    className="dialog"
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent className="dialog-content2">
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"msg_login_needed"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevLoginUser = prevProps.loginUser;
        const nextLoginUser = nextProps.loginUser;
        if (prevLoginUser !== nextLoginUser) {
            return false;
        }
        return true;
    }
);
