export const READ_SIZE = "READ_SIZE";

export const ReadSizeAction = (readSizeState) => {
    let payload = {};
    if (Object.keys(readSizeState).indexOf("USER_LIST") > -1) {
        payload["USER_LIST"] = readSizeState.USER_LIST;
    }
    if (Object.keys(readSizeState).indexOf("GROUP_LIST") > -1) {
        payload["GROUP_LIST"] = readSizeState.GROUP_LIST;
    }
    if (Object.keys(readSizeState).indexOf("MESSAGEBOARD_LIST") > -1) {
        payload["MESSAGEBOARD_LIST"] = readSizeState.MESSAGEBOARD_LIST;
    }
    if (Object.keys(readSizeState).indexOf("PARENT_MAIL_LIST") > -1) {
        payload["PARENT_MAIL_LIST"] = readSizeState.PARENT_MAIL_LIST;
    }
    if (Object.keys(readSizeState).indexOf("GROUP_MEMBER_LIST") > -1) {
        payload["GROUP_MEMBER_LIST"] = readSizeState.GROUP_MEMBER_LIST;
    }
    if (Object.keys(readSizeState).indexOf("GROUP_CHAT_LIST") > -1) {
        payload["GROUP_CHAT_LIST"] = readSizeState.GROUP_CHAT_LIST;
    }
    if (Object.keys(readSizeState).indexOf("TALK_USER_LIST") > -1) {
        payload["TALK_USER_LIST"] = readSizeState.TALK_USER_LIST;
    }
    if (Object.keys(readSizeState).indexOf("TALK_MESSAGE_LIST") > -1) {
        payload["TALK_MESSAGE_LIST"] = readSizeState.TALK_MESSAGE_LIST;
    }
    if (Object.keys(readSizeState).indexOf("TWEET_LIST") > -1) {
        payload["TWEET_LIST"] = readSizeState.TWEET_LIST;
    }
    return {
        type: READ_SIZE,
        payload: payload,
    };
};
