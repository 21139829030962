import { CategoryModel } from "models/Models";

export class CategoryListResponse {
    rtnCd?: number | null = null;
    lstCategory?: CategoryModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstCategory
        if (obj.hasOwnProperty("CategoryList")) {
            if (this.lstCategory == null) {
                this.lstCategory = [];
            }
            for (let i in obj.CategoryList) {
                let category = new CategoryModel(obj.CategoryList[i]);
                this.lstCategory.push(category);
            }
        }
    }
}
