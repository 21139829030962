export class GroupChatMemberData {
    id: number | null = null;
    groupId: number | null = null;
    name: string | null = null;
    gender: number | null = null;
    tag: string | null = null;
    attribute: number | null = null;

    selected: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // groupId
        if (obj.hasOwnProperty("groupId")) {
            this.groupId = obj.groupId;
        } else if (obj.hasOwnProperty("GroupId")) {
            this.groupId = obj.GroupId;
        }
        // name
        if (obj.hasOwnProperty("name")) {
            this.name = obj.name;
        } else if (obj.hasOwnProperty("Name")) {
            this.name = obj.Name;
        }
        // gender
        if (obj.hasOwnProperty("gender")) {
            this.gender = obj.gender;
        } else if (obj.hasOwnProperty("Gender")) {
            this.gender = obj.Gender;
        }
        // tag
        if (obj.hasOwnProperty("tag")) {
            this.tag = obj.tag;
        } else if (obj.hasOwnProperty("Tag")) {
            this.tag = obj.Tag;
        }
        // attribute
        if (obj.hasOwnProperty("attribute")) {
            this.attribute = obj.attribute;
        } else if (obj.hasOwnProperty("Attribute")) {
            this.attribute = obj.Attribute;
        }
    }
}
