import React from "react";
import { useNavigate } from "react-router-dom";
// import Draggable from "react-draggable";
import {
    SkyWayStreamFactory,
    LocalAudioStream,
    LocalVideoStream,
    LocalStream,
    RemoteAudioStream,
    RemoteVideoStream,
} from "@skyway-sdk/core";
import {
    SkyWayContext,
    SkyWayRoom,
    RoomPublication,
    LocalP2PRoomMember,
    P2PRoom,
} from "@skyway-sdk/room";
import {
    useInteractJS,
} from "hooks/InteractHooks";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
} from "@mui/material";
// import { EventEmitter, EventSubscription } from "fbemitter";
import { UserModel, TalkUserModel, TalkPermissionModel, TalkHistoryModel } from "models/Models";
import {
    getEmitter,
} from "redux/Selectors";
import {
    NetworkAction,
} from "redux/Actions";

import {
    OkDialog,
} from "components/Components";
import Utility from "utils/Utility";
import {
    UserRequest,
    TalkPermissionRequest,
} from "api/requests/Requests";
import { AppServer } from "socket/AppServer";
import { DbConstants, UrlConstants } from "constants/Constants";
import { TALK_TYPE, TALK_STATUS } from "constants/Enum";
import { UserTalkHandler } from "handlers/handler";
import { TalkHistoryRequest } from "api/requests/TalkHistoryRequest";
import { pushDataLayer } from "gtm/gtm"
import CallingImage from "assets/images/calling_white.png";
import CallingGif from "assets/images/calling.gif";
import CallEnd from "assets/images/call_end.svg";

import "styles/components/UserTalkComponent.scss";

type Props = {
    loginUser: UserModel;
    appServer: AppServer;
    talkUser: TalkUserModel;
    pathname: string;
    talkStarted: () => void;
};

const _UserTalkComponent = React.forwardRef<UserTalkHandler, Props>(
    (props, ref) => {
        Utility.log("@@@@@ UserTalkComponent IN");
        React.useImperativeHandle(ref, () => ({
            setTalkType,
            onReceivedResponseForConfirmConnectStatus,
            onReceivedResponseOfTalkRequest,
            onReceivedRejectTalkResponse,
            onReceivedPartnerTalkingResponse,
            onReceivedStartTalk,
            onReceivedTalkFailureNotification,
        }));

        /***** 定数、変数 */
        // const MAX_WAITING_SECONDS = 40000;
        const MAX_WAITING_SECONDS = 40;
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        // セッションストレージ
        const sessSenderTalkStarted = window.sessionStorage.getItem("SENDER_TALK_STARTED");
        const sessSenderId = window.sessionStorage.getItem("SENDER_ID");
        const sessReceiverId = window.sessionStorage.getItem("RECEIVER_ID");
        const sessSenderTalkType = window.sessionStorage.getItem("SENDER_TALK_TYPE");
        const sessReceiverTalkType = window.sessionStorage.getItem("RECEIVER_TALK_TYPE");
        // EventEmitter
        const emitter = useSelector((state) => getEmitter(state));
        // InteractJS
        const interact = useInteractJS();
    
        /***** useRef */
        const audioDisabled = React.useRef<boolean>(false);
        // // EventEmitterの購読
        // const lstSubscription = React.useRef<EventSubscription[]>();
        // トーク相手
        const refPartner = React.useRef<UserModel>();
        // トークステータス
        const refTalkStatus = React.useRef<TALK_STATUS>(TALK_STATUS.NONE);
        // 通話タイプ
        const refUserTalkType = React.useRef<TALK_TYPE>(TALK_TYPE.NONE);
        const refPartnerTalkType = React.useRef<TALK_TYPE>(TALK_TYPE.NONE);
        // ブロックされているかどうか
        const refIsBlocked = React.useRef<boolean>();
        // 自分が発信者かどうか
        const refIsSender = React.useRef<boolean>(false);
        // SkyWayトークン
        const refSkyWayToken = React.useRef<string>();
        // VIDEO
        const refVideoElementMe = React.useRef<HTMLVideoElement>(null);
        const refVideoElementPartner = React.useRef<HTMLVideoElement>(null);
        // AUDIO
        const refAudioElementMe = React.useRef<HTMLAudioElement>(null);
        const refAudioElementPartner = React.useRef<HTMLAudioElement>(null);
        // トークスクリーン要素
        const refTalkScreenElement = React.useRef<HTMLDivElement>(null);
        // ルーム参照
        const refRoom = React.useRef<P2PRoom>();
        // ルームメンバー(自分)参照
        const refRoomMemberMe = React.useRef<LocalP2PRoomMember>();
        // PublicationId参照
        const refPublicationAudio = React.useRef<RoomPublication<LocalAudioStream>>();
        const refPublicationVideo = React.useRef<RoomPublication<LocalVideoStream>>();
        // メディアストリーム
        const refMediaStream = React.useRef<MediaStream>();
        // SkyWayストリーム
        const refAudioStream = React.useRef<LocalAudioStream>();
        const refVideoStream = React.useRef<LocalVideoStream>();
        // タイマー
        const refTimerId = React.useRef<number>(0);
        // トーク開始インターバルID
        const refIntervalId = React.useRef<number>(0);
        // トーク履歴
        const refTalkHistory = React.useRef<TalkHistoryModel>();

        /***** useState */
        const [partner, setPartner] = React.useState<UserModel>();
        const [talkStatus, setTalkStatus] = React.useState<TALK_STATUS>(
            TALK_STATUS.NONE
        );
        const [talkTypeMe, setTalkTypeMe] = React.useState<TALK_TYPE>(TALK_TYPE.NONE);
        const [talkTypePartner, setTalkTypePartner] = React.useState<TALK_TYPE>(TALK_TYPE.NONE);
        // トーク開始実行フラグ
        const [executeTalk, setExecuteTalk] = React.useState<boolean>(false);
        // ダイアログメッセージ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>("");

        /**
         * トーク履歴更新
         */
        const updateTalkHistory = React.useCallback(async() => {
            if (refTalkHistory == null || refTalkHistory.current == null || refTalkHistory.current.id == null) {
                return;
            }
            const result = await TalkHistoryRequest.update(props.loginUser, refTalkHistory.current);
        }, [props.loginUser]);

        /**
         * 通話終了処理
         */
        const terminateRtc = React.useCallback(async(needToSendTalkFailureNotification: boolean) => {
            Utility.log("terminateRtc IN " + needToSendTalkFailureNotification)
            if (refTimerId != null && refTimerId.current != null && refTimerId.current !== 0) {
                window.clearTimeout(refTimerId.current);
            }
            if (refIntervalId != null && refIntervalId.current != null && refIntervalId.current !== 0) {
                window.clearTimeout(refTimerId.current);
            }
            if (refTalkHistory != null && 
                refTalkHistory.current != null && 
                refTalkHistory.current.id != null &&
                refTalkHistory.current.startTime != null &&
                refIsSender.current === true
            ) {
                refTalkHistory.current.endTime = Math.floor(new Date().getTime()/1000);
                refTalkHistory.current.talkTime = refTalkHistory.current.endTime - refTalkHistory.current.startTime;
                updateTalkHistory();
            }
            // stream解放
            if (refAudioStream != null && refAudioStream.current != null) {
                refAudioStream.current.detach();
                refAudioStream.current.release();
            }
            if (refVideoStream != null && refVideoStream.current != null) {
                refVideoStream.current.detach();
                refVideoStream.current.release();
            }
            // 離脱
            if (refRoom != null && refRoom.current != null) {
                if (refRoomMemberMe != null && refRoomMemberMe.current != null) {
                    try {
                        if (refRoomMemberMe.current.state === "joined") {
                            await refRoom.current.leave(refRoomMemberMe.current);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
            if (refRoomMemberMe != null && refRoomMemberMe.current != null) {
                if (refPublicationAudio != null && refPublicationAudio.current != null) {
                    try {
                        const subscriptions = refPublicationAudio.current.subscriptions;
                        for (let i=0; i<subscriptions.length; i++) {
                            const subscription = subscriptions[i];
                            await subscription.cancel();
                        }
                        // await refRoomMemberMe.current.unpublish(refPublicationAudio.current);
                    } catch (e) {
                        console.error(e);
                    }
                }
                if (refPublicationVideo != null && refPublicationVideo.current != null) {
                    try {
                        const subscriptions = refPublicationVideo.current.subscriptions;
                        for (let i=0; i<subscriptions.length; i++) {
                            const subscription = subscriptions[i];
                            await subscription.cancel();
                        }
                        // await refRoomMemberMe.current.unpublish(refPublicationVideo.current);
                    } catch (e) {
                        console.error(e);
                    }
                }
                if (refRoomMemberMe.current.subscriptions != null) {
                    for (let i=0; i<refRoomMemberMe.current.subscriptions.length; i++) {
                        const subscription = refRoomMemberMe.current.subscriptions[i];
                        try {
                            await refRoomMemberMe.current.unsubscribe(subscription.id);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
                try {
                    if (refRoomMemberMe.current.state === "joined") {
                        await refRoomMemberMe.current.leave();
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            if (refMediaStream != null && refMediaStream.current != null) {
                const audioTrack = refMediaStream.current.getAudioTracks()[0];
                const videoTrack = refMediaStream.current.getVideoTracks()[0];
                if (audioTrack != null) {
                    audioTrack.stop();
                    refMediaStream.current.removeTrack(audioTrack);
                }
                if (videoTrack != null) {
                    videoTrack.stop();
                    refMediaStream.current.removeTrack(videoTrack);
                }
            }
            removeSessionStorage();

            refUserTalkType.current = TALK_TYPE.NONE;
            setTalkTypeMe(refUserTalkType.current);
            refPartnerTalkType.current = TALK_TYPE.NONE;
            setTalkTypePartner(refPartnerTalkType.current);
            refTalkStatus.current = TALK_STATUS.NONE;
            setTalkStatus(refTalkStatus.current);

            if (needToSendTalkFailureNotification) {
                if (props.loginUser.id != null && 
                    refPartner != null && 
                    refPartner.current != null &&
                    refPartner.current.id != null
                ) {
                    props.appServer.sendTalkFailureNotification(
                        props.loginUser.id,
                        refPartner.current.id
                    );
                }
            }
            Utility.log("terminateRtc Finished!")
        }, [
            props.loginUser,
            props.appServer,
            updateTalkHistory,
        ]);

        /**
         * トーク部屋にJOIN
         * @returns 
         */
        const joinSkyWayRoom = React.useCallback(async(): Promise<boolean> => {
            try {
                if (props.loginUser.name == null) {
                    return false;
                }
                if (refPartner == null || refPartner.current == null || refPartner.current.id == null) {
                    return false;
                }
                if (refSkyWayToken == null || refSkyWayToken.current == null) {
                    return false;
                }
                let roomName =
                    String(props.loginUser.id) +
                    "_" +
                    String(refPartner.current.id);
                if (!refIsSender.current) {
                    roomName =
                    String(refPartner.current.id) +
                    "_" +
                    String(props.loginUser.id);
                }
    
                const context = await SkyWayContext.Create(refSkyWayToken.current);
                refRoom.current = await SkyWayRoom.FindOrCreate(context, {
                    type: "p2p",
                    name: roomName,
                });
                // const person: PersonInit = {
                //     name: props.loginUser.name
                // }
                // refRoomMemberMe.current = await refRoom.current.join(person);
                refRoomMemberMe.current = await refRoom.current.join();
                refRoomMemberMe.current.onPublicationSubscribed.add((e) => {
                    const subscription = e.subscription;
                    const subscriber = subscription.subscriber
                    const publisher = subscription.publication.publisher;
                    if (refIsSender.current === true &&
                        refTalkHistory != null &&
                        refTalkHistory.current != null
                    ) {
                        refTalkHistory.current.startTime = Math.floor(new Date().getTime()/1000);
                        updateTalkHistory();
                    }
                    Utility.log("subscriber:" + subscriber.name + " publisher:" + publisher.name);
                });

                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        }, [
            props.loginUser,
            updateTalkHistory,
        ]);
        
        /**
         * トークストリーム作成
         * @returns 
         */
        const createAndAttachTalkStream = React.useCallback(async(): Promise<boolean> => {
            Utility.log("createAndAttachTalkStream IN");
            if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                    Utility.log("createAndAttachTalkStream return false 1");
                    return false;
                }
            } else if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                    Utility.log("createAndAttachTalkStream return false 2");
                    return false;
                }
            }
            Utility.log("createAndAttachTalkStream 1");
            try {
                // 自分のカメラ・マイクに接続
                if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                    Utility.log("before createMicrophoneAudioAndCameraStream");
                    const { audio, video } = await SkyWayStreamFactory.createMicrophoneAudioAndCameraStream({
                        video: { height: 640, width: 360, frameRate: 15 },
                    });
                    Utility.log("after createMicrophoneAudioAndCameraStream");
                    refAudioStream.current = audio;
                    refVideoStream.current = video;
                    if (refVideoElementMe == null || refVideoElementMe.current == null) {
                        Utility.log("createAndAttachTalkStream return false 3");
                        return false;
                    }
                    video.attach(refVideoElementMe.current);
                    await refVideoElementMe.current.play();
                } else {
                    Utility.log("before createMicrophoneAudioStream");
                    const audio = await SkyWayStreamFactory.createMicrophoneAudioStream();
                    Utility.log("after createMicrophoneAudioStream");
                    if (audio == null) {
                        Utility.log("createAndAttachTalkStream return false 4");
                        return false;
                    }
                    refAudioStream.current = audio;
                    if (refAudioElementMe == null || refAudioElementMe.current == null) {
                        Utility.log("createAndAttachTalkStream return false 5");
                        return false;
                    }
                    audio.attach(refAudioElementMe.current as HTMLAudioElement);
                    // await refAudioElementMe.current.play();
                }
                if (props.loginUser.id == null) {
                    Utility.log("createAndAttachTalkStream return false 6");
                    return false;
                }
                if (refPartner == null || refPartner.current == null || refPartner.current.id == null) {
                    Utility.log("createAndAttachTalkStream return false 7");
                    return false;
                }
                if (refSkyWayToken == null || refSkyWayToken.current == null) {
                    Utility.log("createAndAttachTalkStream return false 8");
                    return false;
                }

                return true;
            } catch (error) {
                alert(error)
                return false;
            }
        }, [props.loginUser]);

        /**
         * トーク履歴作成
         */
        const createTalkHistory = React.useCallback(async() => {
            if (refTalkHistory == null || refTalkHistory.current == null) {
                return;
            }
            const result = await TalkHistoryRequest.create(props.loginUser, refTalkHistory.current);
            if (result != null && result.talkHistory != null && result.talkHistory.id != null) {
                refTalkHistory.current.id = result.talkHistory.id;
            }
        }, [props.loginUser]);

        /**
         * 配信終了
         */
        const finishCasting = React.useCallback(async() => {
            await terminateRtc(true);
        }, [terminateRtc]);

        /**
         * 配信開始
         * @returns 
         */
        const startCasting = React.useCallback(async(): Promise<boolean> => {
            Utility.log("startCasting IN")
            if (refPartner == null || refPartner.current == null) {
                return false;
            }
            if (refRoomMemberMe == null || refRoomMemberMe.current == null) {
                return false;
            }
            try {
                if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                    if ((!audioDisabled.current && (refAudioStream == null || refAudioStream.current == null)) ||
                        refVideoStream == null || refVideoStream.current == null ||
                        refVideoElementMe == null || refVideoElementMe.current == null) {
                            return false;
                    }
                    refPublicationVideo.current = await refRoomMemberMe.current.publish(refVideoStream.current);
                    refPublicationVideo.current.onSubscribed.add((e1) => {
                        const subscription = e1.subscription;
                        const {removeListener, disposer} = subscription.subscriber.onLeft.add(async () => {
                            Utility.log(">>>> subscription.subscriber.onLeft IN")
                            await terminateRtc(false);
                            Utility.log(">>>> subscription.subscriber.onLeft OUT")
                        });
                        Utility.log("refPublicationVideo.current.onSubscribed IN")
                        Utility.log("subscriber:" + e1.subscription.subscriber.name);
                        Utility.log("refPublicationVideo.current.onSubscribed OUT")
                    })
                    if (!audioDisabled.current) {
                        if (refAudioStream == null || refAudioStream.current == null) {
                            return false;
                        }
                        refPublicationAudio.current = await refRoomMemberMe.current.publish(refAudioStream.current);
                    }
                    await refVideoElementMe.current.play();
                } else {
                    if (refAudioStream == null || refAudioStream.current == null ||
                        refAudioElementMe == null || refAudioElementMe.current == null)
                    {
                        if (refAudioStream == null || refAudioStream.current == null)
                        {
                            Utility.log("refAudioStream is null")
                        }
                        if (refAudioElementMe == null || refAudioElementMe.current == null)
                        {
                            Utility.log("refAudioElementMe is null")
                        }
                        return false;
                    }
                    refPublicationAudio.current = await refRoomMemberMe.current.publish(refAudioStream.current);
                    refPublicationAudio.current.onSubscribed.add((e1) => {
                        const subscription = e1.subscription;
                        const {removeListener, disposer} = subscription.subscriber.onLeft.add(async () => {
                            Utility.log(">>>> subscription.subscriber.onLeft IN")
                            await terminateRtc(false);
                            Utility.log(">>>> subscription.subscriber.onLeft OUT")
                        });
                        if (refTalkHistory != null && refTalkHistory.current != null) {
                            refTalkHistory.current.startTime = Math.floor(new Date().getTime()/1000);
                        }
                        Utility.log("refPublicationVideo.current.onSubscribed IN")
                        Utility.log("subscriber:" + e1.subscription.subscriber.name);
                        Utility.log("refPublicationVideo.current.onSubscribed OUT")
                    })
                }
                refTalkStatus.current = TALK_STATUS.UNDER_TALK;
                setTalkStatus(refTalkStatus.current);
    
                return true;
            } catch (e) {
                Utility.log(e);
                finishCasting();
                return false;
            }
        }, [
            finishCasting,
            terminateRtc,
        ]);

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * SkyWayトークン取得
         * @returns
         */
        const fetchSkyWayToken = React.useCallback(async(): Promise<boolean> => {
            Utility.log("fetchSkyWayToken IN");
            if (
                props.loginUser == null ||
                props.loginUser.id == null ||
                props.loginUser.bearerToken == null ||
                refPartner == null ||
                refPartner.current == null ||
                refPartner.current.id == null
            ) {
                return false;
            }
            let channelName =
                String(props.loginUser.id) +
                "_" +
                String(refPartner.current.id);
            if (!refIsSender.current) {
                channelName =
                String(refPartner.current.id) +
                "_" +
                String(props.loginUser.id);
            }
            const result = await UserRequest.getSkyWayToken(
                props.loginUser,
                refPartner.current.id,
                channelName
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return false;
            }

            if (result.skywayToken == null) {
                return false;
            }
            refSkyWayToken.current = result.skywayToken;

            return true;
        }, [
            dispatch,
            navigate,
            props.loginUser,
        ]);

        /**
         * トーク開始通知送信
         * @returns 
         */
        const sendTalkStartNotification = React.useCallback((): boolean => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return false;
            }
            if (refPartner == null || refPartner.current == null || refPartner.current.id == null) {
                return false;
            }
            // トーク開始通知を相手に送る
            props.appServer.sendTalkStartNotification(
                props.loginUser.id,
                refPartner.current.id,
                refUserTalkType.current,
                refPartnerTalkType.current
            );
            setTalkStatus(TALK_STATUS.UNDER_TALK);
            return true;
        }, [
            props.loginUser,
            props.appServer,
            refPartner,
            refUserTalkType,
            refPartnerTalkType,
        ]);

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * トーク開始
         */
        const startTalk = React.useCallback(async(): Promise<boolean> => {
            Utility.log("startTalk IN")
            if (refPartner == null || refPartner.current == null) {
                return false;
            }
            Utility.log("startTalk 1")
            Utility.log("talk type me:" + refUserTalkType.current)
            Utility.log("talk type partner:" + refPartnerTalkType.current)
            refTalkHistory.current = new TalkHistoryModel();
            if (refIsSender) {
                refTalkHistory.current.senderId = props.loginUser.id;
                refTalkHistory.current.receiverId = refPartner.current.id;
                refTalkHistory.current.senderTalkType = refUserTalkType.current;
                refTalkHistory.current.receiverTalkType = refPartnerTalkType.current;
            } else {
                refTalkHistory.current.senderId = refPartner.current.id;
                refTalkHistory.current.receiverId = props.loginUser.id;
                refTalkHistory.current.senderTalkType = refPartnerTalkType.current;
                refTalkHistory.current.receiverTalkType = refUserTalkType.current;
            }

            let success = false;
            if (refSkyWayToken == null || refSkyWayToken.current == null) {
                success = await fetchSkyWayToken();
                Utility.log("fetchSkyWayToken success:" + success)
                if (!success) {
                    setTalkStatus(TALK_STATUS.NONE);
                    refTalkStatus.current = TALK_STATUS.NONE;
                    if (refIsSender.current === true) {
                        createTalkHistory();
                    }
                    return false;
                }
            }
            refTalkHistory.current.fetchSkywayToken = 1;
            Utility.log("startTalk 2")
            success = await createAndAttachTalkStream();
            Utility.log("createAndAttachTalkStream success:" + success);
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
                return false;
            }
            refTalkHistory.current.createAndAttachTalkStream = 1;
            Utility.log("startTalk 3")

            success = await joinSkyWayRoom();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
            return false;
            }
            refTalkHistory.current.joinSkywayRoom = 1;
            Utility.log("startTalk 4")

            success = await startCasting();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
                return false;
            }
            refTalkHistory.current.startCasting = 1;
            Utility.log("startTalk 5")

            success = await subscribeTalk();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
                return false;
            }
            if (refIsSender.current) {
                window.sessionStorage.setItem("rtcSenderId", String(props.loginUser.id));
                window.sessionStorage.setItem("rtcReceiverId", String(refPartner.current.id));
                window.sessionStorage.setItem("rtcSenderName", props.loginUser.name == null ? "" : props.loginUser.name);
                window.sessionStorage.setItem("rtcReceiverName", refPartner.current.name == null ? "" : refPartner.current.name);
                window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
            } else {
                window.sessionStorage.setItem("rtcSenderId", String(refPartner.current.id));
                window.sessionStorage.setItem("rtcReceiverId", String(props.loginUser.id));
                window.sessionStorage.setItem("rtcSenderName", refPartner.current.name == null ? "" : refPartner.current.name);
                window.sessionStorage.setItem("rtcReceiverName", props.loginUser.name == null ? "" : props.loginUser.name);
                window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
            }
            window.sessionStorage.setItem("rtcTalkStatus", String(refTalkStatus.current));

            refTalkHistory.current.subscribeTalk = 1;
            if (refIsSender.current === true) {
                createTalkHistory();
            }
            refTalkStatus.current = TALK_STATUS.UNDER_TALK;
            setTalkStatus(refTalkStatus.current);
            Utility.log("startTalk 6")

            if (refIsSender.current) {
                window.sessionStorage.setItem("rtcSenderId", String(props.loginUser.id));
                window.sessionStorage.setItem("rtcReceiverId", String(refPartner.current.id));
                window.sessionStorage.setItem("rtcSenderName", props.loginUser.name == null ? "" : props.loginUser.name);
                window.sessionStorage.setItem("rtcReceiverName", refPartner.current.name == null ? "" : refPartner.current.name);
                window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
                sendTalkStartNotification();
            } else {
                window.sessionStorage.setItem("rtcSenderId", String(refPartner.current.id));
                window.sessionStorage.setItem("rtcReceiverId", String(props.loginUser.id));
                window.sessionStorage.setItem("rtcSenderName", refPartner.current.name == null ? "" : refPartner.current.name);
                window.sessionStorage.setItem("rtcReceiverName", props.loginUser.name == null ? "" : props.loginUser.name);
                window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
            }
            window.sessionStorage.setItem("rtcTalkStatus", String(refTalkStatus.current));

            return true;
        }, [
            props.loginUser,
            joinSkyWayRoom,
            createAndAttachTalkStream,
            createTalkHistory,
            fetchSkyWayToken,
            sendTalkStartNotification,
            startCasting,
        ]);


        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "ユーザトーク",
                path: window.location.pathname,
            });

            // デストラクタ
            return () => {
                if (refTimerId != null && refTimerId.current != null && refTimerId.current !== 0) {
                    window.clearTimeout(refTimerId.current);
                }
                removeSessionStorage();
                terminateRtc(true);
            };
        }, [terminateRtc]);
        React.useEffect(() => {
            if (interact == null) {
                return;
            }
            interact.enable();
        }, [interact]);

        React.useEffect(() => {
            if (props.talkUser == null || props.talkUser.partner == null) {
                return;
            }
            // 既にパートナーがセットされている場合は、トークパートナーが変更されたということなので、ターミネート処理を行う
            if (partner != null) {
                terminateRtc(false);
            }
            // パートナーセット
            setPartner(props.talkUser.partner);
            refPartner.current = props.talkUser.partner;
        }, [
            props.talkUser,
            partner,
            terminateRtc,
        ]);
        React.useEffect(() => {
            if (!props.pathname.includes("/talk/call")) {
                return;
            }
            if (partner == null) {
                return;
            }
            if (sessSenderTalkStarted !== "1" ||
            sessReceiverId !== String(props.loginUser.id) ||
            sessReceiverTalkType == null ||
            sessReceiverTalkType === "0" ||
            sessSenderTalkType == null ||
            sessSenderTalkType === "0") {
                
                Utility.log("return")
                // トーク要求者はここでリターン
                return;
            }

            // これ以降はトーク要求受信者用処理
            (async () => {
                refIsSender.current = false;
                
                // 自分のトークタイプをセット
                if (sessReceiverTalkType === "1") {
                    refUserTalkType.current = TALK_TYPE.VOICE;
                } else if (sessReceiverTalkType === "2") {
                    refUserTalkType.current = TALK_TYPE.VIDEO;
                }
                setTalkTypeMe(refUserTalkType.current);

                // パートナーのトークタイプをセット
                if (sessSenderTalkType === "1") {
                    refPartnerTalkType.current = TALK_TYPE.VOICE;
                } else if (sessSenderTalkType === "2") {
                    refPartnerTalkType.current = TALK_TYPE.VIDEO;
                }
                setTalkTypePartner(refPartnerTalkType.current);

                const partnerId = getPartnerIdFromPathname(props.pathname);
                if (refPartner != null && refPartner.current != null &&
                    refPartner.current.id === partnerId
                ) {
                    refTalkStatus.current = TALK_STATUS.UNDER_TALK;
                    setTalkStatus(refTalkStatus.current);
                    let counter = 0;
                    refIntervalId.current =  window.setInterval(() => {
                        if (counter > 15) {
                            if (refIntervalId != null && refIntervalId.current != null) {
                                window.clearInterval(refIntervalId.current);
                                terminateRtc(true);
                                setTalkStatus(TALK_STATUS.NONE);
                                return;
                            }
                        }
                        if (isElementsPrepared()) {
                            window.clearInterval(refIntervalId.current);
                            (async () => {
                                const success = await startTalk();
                                if (!success) {
                                    terminateRtc(true);
                                    return;
                                }
                                props.talkStarted();
                            })();
                        }
                        counter++;
                    }, 1000);
                }
            })();
            return () => {
                if (refTimerId != null && refTimerId.current != null && refTimerId.current !== 0) {
                    window.clearTimeout(refTimerId.current);
                }
                const partnerIdPathname = getPartnerIdFromPathname(props.pathname);
                const senderIdSession = window.sessionStorage.getItem("SENDER_ID");
                const receiverIdSession = window.sessionStorage.getItem("RECEIVER_ID");
                if (props.loginUser.id === senderIdSession) {
                    if (receiverIdSession === String(partnerIdPathname)) {
                        removeSessionStorage();
                    }
                } else if (props.loginUser.id === receiverIdSession) {
                    if (senderIdSession === String(partnerIdPathname)) {
                        removeSessionStorage();
                    }
                }
            }
        }, [
            props,
            partner,
            sessReceiverId,
            sessReceiverTalkType,
            sessSenderTalkStarted,
            sessSenderTalkType,
            startTalk,
            terminateRtc,
        ]);

        function removeSessionStorage() {
            Utility.log("removeSessionStorage IN")
            window.sessionStorage.removeItem("rtcSenderId");
            window.sessionStorage.removeItem("rtcReceiverId");
            window.sessionStorage.removeItem("rtcSenderName");
            window.sessionStorage.removeItem("rtcReceiverName");
            window.sessionStorage.removeItem("rtcSenderTalkType");
            window.sessionStorage.removeItem("rtcReceiverTalkType");
            window.sessionStorage.removeItem("rtcTalkStatus");
            window.sessionStorage.removeItem("SENDER_TALK_STARTED");
            window.sessionStorage.removeItem("SENDER_ID");
            window.sessionStorage.removeItem("RECEIVER_ID");
            window.sessionStorage.removeItem("SENDER_TALK_TYPE");
            window.sessionStorage.removeItem("RECEIVER_TALK_TYPE");
        }
        /**
         * 部屋を去った時
         */
        // async function sendLeftRoomNotification() {
        //     if (props.loginUser.id == null ||
        //         refPartner == null ||
        //         refPartner.current == null ||
        //         refPartner.current.id == null
        //     ) {
        //         return;
        //     }
        //     props.appServer.sendLeftRoomNotification(
        //         props.loginUser.id,
        //         refPartner.current.id
        //     );
        // }

        function setTalkType(talkType: TALK_TYPE) {
            refUserTalkType.current = talkType
            setTalkTypeMe(talkType);
        }

        /**
         * 音声通話クリック時
         */
        async function onClickVoiceCall() {
            Utility.log("onClickVoiceCall IN");
            setTalkStatus(TALK_STATUS.UNDER_TALK);
        }
        /**
         * 映像通話クリック時
         */
        async function onClickVideoCall() {
            Utility.log("onClickVideoCall IN");
            setTalkStatus(TALK_STATUS.UNDER_TALK);
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * トーク要求の発信
         * @returns
         */
        async function sendTalkRequest() {
            if (
                props.loginUser.id == null ||
                refPartner == null ||
                refPartner.current == null ||
                refPartner.current.id == null
            ) {
                return;
            }
            setTalkStatus(TALK_STATUS.SENT_REQUEST_CALL);
            refTalkStatus.current = TALK_STATUS.SENT_REQUEST_CALL;
            props.appServer.sendTalkCallRequest(
                props.loginUser.id,
                refPartner.current.id,
                refUserTalkType.current
            );
            refTimerId.current = window.setTimeout(stopRequestCall, MAX_WAITING_SECONDS * 1000);
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * トーク要求後の応答有無チェック
         */
        function stopRequestCall() {
            if (refTalkStatus.current === TALK_STATUS.SENT_REQUEST_CALL) {
                refTalkStatus.current = TALK_STATUS.NONE;
                setTalkStatus(TALK_STATUS.NONE);
                sendCancelTalkRequest();
                window.setTimeout(() => {
                    navigate(-1);
                });

                const message = intl.formatMessage({
                    id: "msg_no_response",
                });
                setDialogMessage(message);
                setOpenDialog(true);
            }
        }

        /**
         * トーク要求をキャンセル
         */
        function sendCancelTalkRequest() {
            if (refPartner == null || refPartner.current == null) {
                return;
            }
            if (props.loginUser.id != null && refPartner.current.id != null) {
                props.appServer.sendCancelTalkRequest(
                    props.loginUser.id,
                    refPartner.current.id
                );
            }
        }

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * 接続状況確認の返答受信時
         * @param obj
         */
        async function onReceivedResponseForConfirmConnectStatus(obj: any) {
            Utility.log("onReceivedResponseForConfirmConnectStatus IN");
            Utility.log("talkType:" + refUserTalkType.current);

            refIsSender.current = true;
            const success = await fetchSkyWayToken();
            if (!success) {
                return;
            }
            sendTalkRequest();

            // const senderId = obj.sender_id;
            // const receiverId = obj.receiver_id;
            // const connected = obj.connected;
            // if (senderId == null || receiverId == null || connected == null) {
            //     return;
            // }
            // if (!connected) {
            //     const message = intl.formatMessage({
            //         id: "partner_is_not_online",
            //     });
            //     setDialogMessage(message);
            //     setOpenDialog(true);
            //     return;
            // }
            // const result = await UserRequest.getUser(parseInt(receiverId));
            // let deviceType: number | null = null;
            // if (result != null && result.user != null) {
            //     deviceType = result.user.deviceType;
            // }
            // if (deviceType !== DbConstants.DEVICE_TYPE_BROWSER) {
            //     const message = intl.formatMessage({
            //         id: "err_talk_call_device_type",
            //     });
            //     setDialogMessage(message);
            //     setOpenDialog(true);
            //     return;
            // }
        }
        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * トーク要求に対する返信の受信
         * @param obj
         * @returns
         */
        async function onReceivedResponseOfTalkRequest(partnerTalkType: TALK_TYPE) {
            Utility.log("UserTalkComponent onReceivedResponseOfTalkRequest IN");
            if (refTimerId != null && refTimerId.current != null) {
                window.clearTimeout(refTimerId.current);
            }
            if (refPartner == null || refPartner.current == null) {
                return;
            }
            if (partnerTalkType === TALK_TYPE.NONE) {
                return;
            }
            refPartnerTalkType.current = partnerTalkType
            setTalkTypePartner(partnerTalkType);
            let counter = 0;
            setTalkStatus(TALK_STATUS.UNDER_TALK);
            refIntervalId.current =  window.setInterval(() => {
                if (counter > 10) {
                    if (refIntervalId != null && refIntervalId.current != null) {
                        window.clearInterval(refIntervalId.current);
                        terminateRtc(true);
                        setTalkStatus(TALK_STATUS.NONE);
                        return;
                    }
                }
                if (isElementsPrepared()) {
                    window.clearInterval(refIntervalId.current);
                    (async () => {
                        // トーク要求者のトーク開始
                        const success = await startTalk();
                        if (!success) {
                            setTalkStatus(TALK_STATUS.NONE);
                            terminateRtc(true);
                            return;
                        }
                        props.talkStarted();
                    })();
                }
                counter++;
            }, 1000);

            return;
        }

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * トーク要求発信者がトーク開始した通知の受信時
         * @param obj
         * @returns
         */
        async function onReceivedStartTalk(
            senderTalkType: TALK_TYPE,
            receiverTalkType: TALK_TYPE
        ) {
            Utility.log("UserTalkComponent onReceivedStartTalk");

            refIsSender.current = false;
            refUserTalkType.current = receiverTalkType;
            refPartnerTalkType.current = senderTalkType;

            setTalkTypePartner(refPartnerTalkType.current);
            setTalkTypeMe(refUserTalkType.current);

            // Skywayトークン取得
            const success = await fetchSkyWayToken();
            if (!success) {
                return;
            }
            
            refTalkStatus.current = TALK_STATUS.UNDER_TALK;
            setTalkStatus(refTalkStatus.current);
            refIntervalId.current = window.setInterval(() => {
                if (isElementsPrepared()) {
                    window.clearInterval(refIntervalId.current);
                    (async () => {
                        const success = await startTalk();
                        if (!success) {
                            terminateRtc(true);
                        }
                    })();
                }
            }, 1000);
        }

        /**
         * パートナーのトーク要求拒絶応答の受信時
         */
        function onReceivedRejectTalkResponse(partnerId: number) {
            if (partner != null && partner.id === partnerId) {
                terminateRtc(false);
            }
        }
        function onReceivedPartnerTalkingResponse(partnerId: number) {
            if (partner != null && partner.id === partnerId) {
                onClickCloseTalkScreen();
            }
        }

        /**
         * パートナーがトーク開始失敗した通知の受信時
         */
        function onReceivedTalkFailureNotification() {
            terminateRtc(false);
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * ダイアログメッセージ表示
         * @param message
         */
        // function showRequestCallResponseMessage(message: string) {
        //     setDialogMessage(message);
        //     setOpenDialog(true);
        // }

        function getPartnerIdFromPathname(pathname: string): number {
            let strPartnerId = pathname.replace("/talk/call/", "");
            strPartnerId = strPartnerId.replace("/talk/chat/", "");
            try {
                return parseInt(strPartnerId);
            } catch (error) {
                return 0;
            }
        }

        function isElementsPrepared(): boolean {
            if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                if (refVideoElementMe == null || refVideoElementMe.current == null) {
                    return false;
                }

                if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                    if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                        return false;
                    }
                } else if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                    if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if (refUserTalkType.current === TALK_TYPE.VOICE) {
                if (refAudioElementMe == null || refAudioElementMe.current == null) {
                    return false;
                }

                if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                    if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                        return false;
                    }
                } else if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                    if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
            return true;
        }


        /**
         * 性別によるクラス名取得
         * @param user
         * @returns
         */
        function getGenderClassName(user: UserModel): string {
            const gender = user.gender;
            if (gender === DbConstants.GENDER_MALE) {
                return "male";
            } else if (gender === DbConstants.GENDER_FEMALE) {
                return "female";
            } else if (gender === DbConstants.GENDER_OTHER) {
                return "other";
            } else {
                return "";
            }
        }


        /**
         * 配信停止
         */
        async function stopCasting(): Promise<boolean> {
            Utility.log(">>>>> stopCasting IN")
            if (refRoomMemberMe == null || refRoomMemberMe.current == null) {
                return false;
            }
            try {
                if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                    if (refVideoElementMe == null || refVideoElementMe.current == null ||
                        refPublicationVideo == null || refPublicationVideo.current == null ||
                        (!audioDisabled.current && (refPublicationAudio == null || refPublicationAudio.current == null))
                    ) {
                        return false;
                    }
                    await refRoomMemberMe.current.unpublish(refPublicationVideo.current);
                    if (!audioDisabled.current) {
                        if (refPublicationAudio == null || refPublicationAudio.current == null) {
                            return false;
                        }
                        await refRoomMemberMe.current.unpublish(refPublicationAudio.current);
                    }
                    refVideoElementMe.current.pause();
                } else {
                    if (refPublicationAudio == null || refPublicationAudio.current == null ||
                        refAudioElementMe == null || refAudioElementMe.current == null) {
                            return false;
                    }
                    await refRoomMemberMe.current.unpublish(refPublicationAudio.current);
                    refAudioElementMe.current.pause();
                    if (refVideoElementMe != null && refVideoElementMe.current != null) {
                    }
                }
                refTalkStatus.current = TALK_STATUS.PAUSE_TALK;
                setTalkStatus(refTalkStatus.current);

                return true;
            } catch (error) {
                Utility.log(error);
                finishCasting();
                return false;
            }
        }

        /**
         * トーク購読
         * @returns 
         */
        async function subscribeTalk(): Promise<boolean> {
            Utility.log(">>>>> subscribeTalk IN")
            if (refRoom == null || refRoom.current == null) {
                return false;
            }
            const subscribeAndAttach = async (publication: RoomPublication<LocalStream>) => {
                try {
                    if (refRoomMemberMe == null || refRoomMemberMe.current == null) {
                        return;
                    }
                    if (publication.publisher.id === refRoomMemberMe.current.id) {
                        return;
                    }
                    const { stream } = await refRoomMemberMe.current.subscribe(publication.id);
                    if (stream == null) {
                        return;
                    }
                    if (refPartnerTalkType == null || refPartnerTalkType.current == null) {
                        return;
                    }
                    if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                        if ((stream as RemoteVideoStream).track.kind === "video") {
                            if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                                return;
                            }
                            try {
                                (stream as RemoteVideoStream).attach(refVideoElementPartner.current);
                            } catch (error) {
                                console.error(error);
                                return;
                            }
                        }
                        else if ((stream as RemoteVideoStream).track.kind === "audio") {
                            if (audioDisabled.current) {
                                return;
                            }
                            if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                                return;
                            }
                            (stream as RemoteVideoStream).attach(refVideoElementPartner.current);
                            await refVideoElementPartner.current.play();
                        }
                    } else
                    if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                        if ((stream as RemoteAudioStream).track.kind === "audio") {
                            if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                                return;
                            }
                            (stream as RemoteVideoStream).attach(refAudioElementPartner.current);
                            await refAudioElementPartner.current.play();
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            } 

            refRoom.current.publications.forEach(subscribeAndAttach);
            refRoom.current.onStreamPublished.add((e) => subscribeAndAttach(e.publication));

            return true;
        }

        function onClickCloseTalkScreen() {
            if (partner != null && partner.id != null) {
                refTalkStatus.current = TALK_STATUS.NONE;
                setTalkStatus(TALK_STATUS.NONE);
                sendCancelTalkRequest();
                window.setTimeout(() => {
                    navigate(-1);
                });
            }
        }

        return (
            <Box
                className="component UserTalkComponent"
                sx={{
                    position: "relative",
                    margin: "auto",
                }}
            >
                {
                    // 呼び出し中画面
                }
                {partner != null && 
                talkStatus !== TALK_STATUS.UNDER_TALK &&
                talkStatus !== TALK_STATUS.PAUSE_TALK && (
                    <>
                        <div className="calling-area">
                            <div
                                className="btn-close"
                                onClick={onClickCloseTalkScreen}
                            >
                                <span />
                                <span />
                            </div>
                            <div className="partner-thumbnail-area">
                                <img
                                    className="partner-thumbnail"
                                    src={
                                        partner.iconName != null &&
                                        partner.iconName.length > 0
                                            ? UrlConstants.URL_S3_USER_ICON +
                                              partner.iconName
                                            : "/images/no_image.png"
                                    }
                                    alt=""
                                />
                                <div
                                    className={`${getGenderClassName(
                                        partner
                                    )} partner-name`}
                                >
                                    {partner.name}
                                </div>
                            </div>
                            <div className="connecting-image-area">
                                <img
                                    className="calling-image"
                                    src={
                                        talkStatus ===
                                        TALK_STATUS.SENT_REQUEST_CALL
                                            ? CallingGif
                                            : CallingImage
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="user-icon-area">
                                <img
                                    className="user-thumbnail"
                                    src={
                                        props.loginUser.iconName != null &&
                                        props.loginUser.iconName.length > 0
                                            ? UrlConstants.URL_S3_USER_ICON +
                                            props.loginUser.iconName
                                            : "/images/no_image.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="button-area">
                                <img 
                                    className="call-end-icon"
                                    src={CallEnd}
                                    onClick={onClickCloseTalkScreen}
                                    alt=""
                                />
                            </div>
                        </div>
                        <OkDialog
                            open={openDialog}
                            title={intl.formatMessage({
                                id: "dlg_title_message",
                            })}
                            message={dialogMessage}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            onOk={() => {
                                setOpenDialog(false);
                            }}
                        />
                    </>
                )}
                {partner != null && (talkStatus === TALK_STATUS.UNDER_TALK || talkStatus === TALK_STATUS.PAUSE_TALK) && (
                    <div ref={refTalkScreenElement} className="talk-screen">
                        <div className="operation-area">
                            {talkStatus === TALK_STATUS.UNDER_TALK && (
                                <>
                                    <label className="talk-label">
                                        <FormattedMessage id={"talking"} />
                                    </label>
                                    <Button
                                        className="btn-stop-casting"
                                        onClick={stopCasting}
                                        color="primary"
                                    >
                                        <FormattedMessage id={"btn_stop_casting"} />
                                    </Button>
                                </>
                            )}
                            {talkStatus === TALK_STATUS.PAUSE_TALK && (
                                <Button
                                    className="btn-start-casting"
                                    onClick={startCasting}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_start_casting"} />
                                </Button>
                            )}
                            <Button
                                className="btn-finish-casting"
                                onClick={finishCasting}
                                color="primary"
                            >
                                <FormattedMessage id={"btn_exit"} />
                            </Button>
                        </div>
                        <div className="screen-area">
                            <div 
                                ref={interact.ref}
                                className="my-area"
                                style={{
                                    ...interact.style,
                                }}
                            >
                                {talkStatus === TALK_STATUS.PAUSE_TALK && (
                                    <div className="pause-display" />
                                )}
                                {talkTypeMe === TALK_TYPE.VIDEO && (
                                    <video 
                                        ref={refVideoElementMe} 
                                        id="local-video-me" 
                                        muted={false} 
                                        autoPlay 
                                        playsInline
                                    ></video>
                                )}
                                {talkTypeMe === TALK_TYPE.VOICE && (
                                    <>
                                        <audio 
                                            ref={refAudioElementMe} 
                                            id="local-audio-me" 
                                            autoPlay={false}
                                            playsInline
                                        ></audio>
                                        <img
                                            className="my-thumbnail"
                                            src={
                                                props.loginUser.iconName != null &&
                                                props.loginUser.iconName.length > 0
                                                    ? UrlConstants.URL_S3_USER_ICON +
                                                    props.loginUser.iconName
                                                    : "/images/no_image.png"
                                            }
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                            <div className={talkTypePartner === TALK_TYPE.VIDEO ? "video partner-area" : "voice partner-area"}>
                                {talkTypePartner === TALK_TYPE.VIDEO && (
                                    <video 
                                        ref={refVideoElementPartner} 
                                        id="local-video-partner" 
                                        controls 
                                        muted={false} 
                                        autoPlay={false}
                                    ></video>
                                )}
                                {talkTypePartner === TALK_TYPE.VOICE && (
                                    <>
                                        <audio 
                                            ref={refAudioElementPartner} 
                                            id="local-audio-partner" 
                                            controls 
                                            muted={false} 
                                            autoPlay={true}
                                        ></audio>
                                        <img
                                            className="partner-thumbnail"
                                            src={
                                                partner.iconName != null &&
                                                partner.iconName.length > 0
                                                    ? UrlConstants.URL_S3_USER_ICON +
                                                    partner.iconName
                                                    : "/images/no_image.png"
                                            }
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Box>
        );
    },
);

export const UserTalkComponent = React.memo(
    _UserTalkComponent,
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.appServer !== nextProps.appServer) {
            return false;
        }
        if (prevProps.talkUser !== nextProps.talkUser) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser != null) {
            if (prevProps.talkUser.userId !== nextProps.talkUser.userId) {
                return false;
            }
            if (prevProps.talkUser.partnerId !== nextProps.talkUser.partnerId) {
                return false;
            }
            if (prevProps.talkUser.partner !== nextProps.talkUser.partner) {
                return false;
            }
            if (prevProps.talkUser.partner != null && nextProps.talkUser.partner != null) {
                const prevPartner = prevProps.talkUser.partner;
                const nextPartner = nextProps.talkUser.partner;
                if (prevPartner.id !== nextPartner.id) {
                    return false;
                }
            }
        }
        if (prevProps.pathname !== nextProps.pathname) {
            return false;
        }
        if (prevProps.talkStarted !== nextProps.talkStarted) {
            return false;
        }
        return true;
    }
);
