export const SHOOT = "SHOOT";

export const ShootAction = (processState) => {
    return {
        type: SHOOT,
        payload: {
            photoId: processState.photoId,
            videoId: processState.videoId,
        },
    };
};
