import axios, { AxiosResponse } from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { GoogleAuthenticationResponse } from "api/responses/Responses";

export class VerifyGoogleCredentialRequest {
    /**
     * Google Credential 検証
     * @returns
     */
    static async verifyCoogleCredential(clientId: string, credential: string) {
        let url = UrlConstants.URL_VERIFY_GOOGLE_CREDENTIAL;
        const formData = new FormData();
        formData.append("ClientId", clientId);
        formData.append("Credential", credential);

        let result = null;
        try {
            let res: AxiosResponse | null = null;
            res = await axios.put(url, formData, {
                withCredentials: true,
            });
            if (res != null) {
                result = new GoogleAuthenticationResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
