import { UserModel } from "./UserModel";

export class AlbumUserModel {
    id: number | null = null;
    albumId: number | null = null;
    userId: number | null = null;
    user: UserModel | null = null;
    orderNo: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // albumId
        if (obj.hasOwnProperty("albumId")) {
            this.albumId = obj.albumId;
        } else if (obj.hasOwnProperty("AlbumId")) {
            this.albumId = obj.AlbumId;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // user
        if (obj.hasOwnProperty("user")) {
            this.user = new UserModel(obj.user);
        } else if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // orderNo
        if (obj.hasOwnProperty("orderNo")) {
            this.orderNo = obj.orderNo;
        } else if (obj.hasOwnProperty("OrderNo")) {
            this.orderNo = obj.OrderNo;
        }
    }
}
