import { GroupChatMemberData } from "./GroupChatMemberData";

export class GroupChatMessageData {
    isHeader: boolean = false;
    id: number | null = null;
    groupId: number | null = null;
    senderId: number | null = null;
    sender: GroupChatMemberData | null = null;
    message: string | null = null;
    existsAttachedFiles: number = 0;
    invalid: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    selfReturn: boolean = false;
    enter: boolean = false;
    leave: boolean = false;
    displayTime: string | null = null;
    displayDate: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // isHeader
        if (obj.hasOwnProperty("isHeader")) {
            this.isHeader = obj.isHeader;
        } else if (obj.hasOwnProperty("IsHeader")) {
            this.isHeader = obj.IsHeader;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // groupId
        if (obj.hasOwnProperty("groupId")) {
            this.groupId = obj.groupId;
        } else if (obj.hasOwnProperty("GroupId")) {
            this.groupId = obj.GroupId;
        }
        // senderId
        if (obj.hasOwnProperty("senderId")) {
            this.senderId = obj.senderId;
        } else if (obj.hasOwnProperty("SenderId")) {
            this.senderId = obj.SenderId;
        }
        // sender
        if (obj.hasOwnProperty("sender")) {
            this.sender = new GroupChatMemberData(obj.sender);
        } else if (obj.hasOwnProperty("Sender")) {
            this.sender = new GroupChatMemberData(obj.Sender);
        }
        // message
        if (obj.hasOwnProperty("message")) {
            this.message = obj.message;
        } else if (obj.hasOwnProperty("Message")) {
            this.message = obj.Message;
        }
        // existsAttachedFiles
        if (obj.hasOwnProperty("existsAttachedFiles")) {
            this.existsAttachedFiles = obj.existsAttachedFiles;
        } else if (obj.hasOwnProperty("ExistsAttachedFiles")) {
            this.existsAttachedFiles = obj.ExistsAttachedFiles;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // selfReturn
        if (obj.hasOwnProperty("selfReturn")) {
            this.selfReturn = obj.selfReturn;
        } else if (obj.hasOwnProperty("SelfReturn")) {
            this.selfReturn = obj.SelfReturn;
        }
        // enter
        if (obj.hasOwnProperty("enter")) {
            this.enter = obj.enter;
        } else if (obj.hasOwnProperty("Enter")) {
            this.enter = obj.Enter;
        }
        // leave
        if (obj.hasOwnProperty("leave")) {
            this.leave = obj.leave;
        } else if (obj.hasOwnProperty("Leave")) {
            this.leave = obj.Leave;
        }
        // displayTime
        if (obj.hasOwnProperty("displayTime")) {
            this.displayTime = obj.displayTime;
        } else if (obj.hasOwnProperty("DisplayTime")) {
            this.displayTime = obj.DisplayTime;
        }
        // displayDate
        if (obj.hasOwnProperty("displayDate")) {
            this.displayDate = obj.displayDate;
        } else if (obj.hasOwnProperty("DisplayDate")) {
            this.displayDate = obj.DisplayDate;
        }
    }
}
