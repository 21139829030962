export const COUNTRY_LIST = "COUNTRY_LIST";

export const CountryListAction = (countryListState) => {
    let payload = {};
    if (Object.keys(countryListState).indexOf("list") > -1) {
        payload["list"] = countryListState.list;
    }
    return {
        type: COUNTRY_LIST,
        payload: payload,
    };
};
