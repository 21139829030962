import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Divider, Button } from "@mui/material";
import { UserModel, MailModel, ParentMailModel } from "models/Models";
import { AppConstants } from "constants/Constants";
import { AttachedFileListComponent } from "components/Components";
import Utility from "utils/Utility";
import "styles/components/listItems/SentChildMailListItem.scss";

type Props = {
    loginUser: UserModel | null;
    parentMail: ParentMailModel | undefined;
    mail: MailModel;
    sender: UserModel | undefined;
    receiver: UserModel | undefined;
    timeDifference: number;
    langCd: string;
    onDelete: () => void;
};

export const SentChildMailListItem: React.FC<Props> = React.memo((props) => {
    // Utility.log("===== SentChildMailListItem IN");
    /***** 定数、変数 */

    /***** useState */
    const [createdAt, setCreatedAt] = React.useState<string>("");
    // const [read, setRead] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (
            props.mail != null &&
            props.mail.createdAt != null &&
            props.timeDifference != null &&
            props.langCd != null
        ) {
            const unixTime = new Date(props.mail.createdAt).getTime() / 1000;
            const wkCreatedAt = Utility.getFormattedDateTime2(
                unixTime,
                props.timeDifference,
                props.langCd
            );
            setCreatedAt(wkCreatedAt);
        }
    }, [
        props.langCd,
        props.mail,
        props.timeDifference,
    ]);

    /**
     * メールメッセージ組み立て
     * @returns
     */
    function getMailMessage(): string {
        if (props.mail == null || props.mail.message == null) {
            return "";
        }
        let message = Utility.escape(props.mail.message).replaceAll(
            "\n",
            "<br />"
        );
        const linkInfo = props.mail.linkInfo2;
        if (linkInfo == null) {
            return message;
        } else {
            const userId = linkInfo.userId;
            const userName = linkInfo.userName;
            const groupId = linkInfo.groupId;
            const groupName = linkInfo.groupName;
            const urlList = linkInfo.urlList;

            // ユーザ名置換
            if (
                userName != null &&
                userName.length > 0 &&
                userId != null &&
                userId > 0
            ) {
                message = message.replaceAll(
                    "[" + userName + "]",
                    `[<a target="_blank" href="/users/012-39111508-${userId}/profile">${userName}</a>]`
                );
            }
            // グループ名置換
            if (
                groupName != null &&
                groupName.length > 0 &&
                groupId != null &&
                groupId > 0
            ) {
                message = message.replaceAll(
                    "[" + groupName + "]",
                    `[<a target="_blank" href="/groups/${groupId}">${groupName}</a>]`
                );
            }
            // URL置換
            if (urlList != null && urlList.length > 0) {
                const lstUrl = urlList.split(",");
                for (let i = 0; i < lstUrl.length; i++) {
                    let url = lstUrl[i];
                    message = message.replaceAll(
                        url,
                        `<a href="${url}" target="_blank">${url}</a>`
                    );
                }
            }
            return message;
        }
    }
    /**
     * レンダー
     */
    return (
        <Box
            className={props.loginUser != null &&
                    props.loginUser.id === props.mail.senderId ? "component SentChildMailListItem login" : "component SentChildMailListItem"}
        >
            {props.loginUser != null &&
                props.parentMail != null &&
                props.mail.sender != null &&
                props.mail.receiver != null && (
                    <Box className="wrapper">
                        {
                            // 受信者名、作成日時
                        }
                        <Box className="row-1">
                            <Box className="receiver">
                                <span>To:&nbsp;</span>
                                {(props.mail.receiver.id === 1 ||
                                    props.loginUser.id ===
                                        props.mail.receiverId) && (
                                    <span>{props.mail.receiver.name}</span>
                                )}
                                {props.mail.receiver.id !== 1 &&
                                    props.loginUser.id !==
                                        props.mail.receiverId && (
                                        <a
                                            href={`/users/012-39111508-${props.mail.receiver.id}/profile`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {props.mail.receiver.name}
                                        </a>
                                    )}
                            </Box>
                            <Box className="date">
                                {createdAt}
                            </Box>
                        </Box>
                        {
                            // 送信者名
                        }
                        <Box className="row-2">
                            From:{" " + props.mail.sender.name}
                        </Box>
                        {
                            // タイトル
                        }
                        <Box className="row-3">
                            {props.mail.title}
                        </Box>
                        <Divider className="divider" />
                        {
                            // 本文
                        }
                        <Box className="row-4"
                            dangerouslySetInnerHTML={{
                                __html: getMailMessage(),
                            }}
                        ></Box>
                        {
                            // 添付ファイル
                        }
                        {props.mail.existsAttachedFiles === 1 &&
                            props.mail.lstAttachedFile != null && (
                                <AttachedFileListComponent
                                    loginUser={props.loginUser}
                                    lstAttachedFile={
                                        props.mail.lstAttachedFile
                                    }
                                />
                            )}
                        {
                            // ボタンエリア
                        }
                        <Box className="button-area">
                            {props.loginUser.id === props.mail.senderId && (
                                <Button className="btn-delete"
                                    variant="text"
                                    onClick={props.onDelete}
                                >
                                    <FormattedMessage id={"btn_delete"} />
                                </Button>
                            )}
                            {props.loginUser.id === props.mail.senderId &&
                                props.mail.read !== 1 && (
                                    <Button className="btn-unread"
                                        variant="text"
                                        disabled={true}
                                    >
                                        <FormattedMessage id={"unread"} />
                                    </Button>
                                )}
                        </Box>
                    </Box>
                )}
        </Box>
    );
},
(prevProps: Props, nextProps: Props) => {
    if (prevProps.loginUser !== nextProps.loginUser) {
        return false;
    }
    if (prevProps.parentMail !== nextProps.parentMail) {
        return false;
    }
    if (prevProps.parentMail != null && nextProps.parentMail != null) {
        if (prevProps.parentMail.id !== nextProps.parentMail.id) {
            return false;
        }
    }
    if (prevProps.mail !== nextProps.mail) {
        return false;
    }
    if (prevProps.mail != null && nextProps.mail != null) {
        if (prevProps.mail.id !== nextProps.mail.id) {
            return false;
        }
    }
    if (prevProps.sender != null && nextProps.sender != null) {
        if (prevProps.sender.id !== nextProps.sender.id) {
            return false;
        }
    }
    if (prevProps.receiver != null && nextProps.receiver != null) {
        if (prevProps.receiver.id !== nextProps.receiver.id) {
            return false;
        }
    }
    if (prevProps.langCd !== nextProps.langCd) {
        return false;
    }
    if (prevProps.onDelete !== nextProps.onDelete) {
        return false;
    }

    return true;
});
