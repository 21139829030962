import { GroupModel, JoinGroupModel } from "models/Models";

export class GroupListResponse {
    rtnCd: number | null = null;
    hasNext: boolean = false;
    lstGroup: GroupModel[] | null = null;
    lstJoinGroup: JoinGroupModel[] | null = null;
    readSize: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // hasNext
        if (obj.hasOwnProperty("IsNext")) {
            this.hasNext = obj.IsNext;
        }
        // lstGroup
        if (obj.hasOwnProperty("GroupList")) {
            if (this.lstGroup == null) {
                this.lstGroup = [];
            }
            for (let i in obj.GroupList) {
                let group = new GroupModel(obj.GroupList[i]);
                this.lstGroup.push(group);
            }
        }
        // lstJoinGroup
        if (obj.hasOwnProperty("JoinedGroupList")) {
            if (this.lstJoinGroup == null) {
                this.lstJoinGroup = [];
            }
            for (let i in obj.JoinedGroupList) {
                let joinGroup = new JoinGroupModel(obj.JoinedGroupList[i]);
                this.lstJoinGroup.push(joinGroup);
            }
        }
        if (obj.hasOwnProperty("ReadSize")) {
            this.readSize = obj.ReadSize;
        }
    }
}
