import React from "react";
import { CountryModel } from "models/Models";
import CheckIcon from "@mui/icons-material/Check";
import "styles/components/listItems/CountryListItem.scss";

type Props = {
    country: CountryModel;
    selected: boolean;
    langCode: string;
};

export const CountryListItem: React.FC<Props> = React.memo((props) => {
    // Utility.log("===== CountryListItem");

    /***** useState */
    const [countryName, setCountryName] = React.useState<string>();

    /***** useEffect */
    React.useEffect(() => {
        if (props.country == null || props.langCode == null) {
            return;
        }
        switch (props.langCode) {
            case "ja":
                if (props.country.nameJa != null) {
                    setCountryName(props.country.nameJa);
                }
                break;
            case "cn":
                if (props.country.nameCn != null) {
                    setCountryName(props.country.nameCn);
                }
                break;
            case "tw":
                if (props.country.nameTw != null) {
                    setCountryName(props.country.nameTw);
                }
                break;
            case "es":
                if (props.country.nameEs != null) {
                    setCountryName(props.country.nameEs);
                }
                break;
            case "de":
                if (props.country.nameDe != null) {
                    setCountryName(props.country.nameDe);
                }
                break;
            case "it":
                if (props.country.nameIt != null) {
                    setCountryName(props.country.nameIt);
                }
                break;
            case "ru":
                if (props.country.nameRu != null) {
                    setCountryName(props.country.nameRu);
                }
                break;
            default:
                if (props.country.nameEn != null) {
                    setCountryName(props.country.nameEn);
                }
                break;
        }
    }, [props.langCode, props.country]);
    
    /**
     * レンダー
     */
    return (
        <div
            className={
                props.selected
                    ? "CountryListItem selected"
                    : "CountryListItem"
            }
        >
            {countryName && (
                <>
                    <div className="flag-area">
                        {props.country.code != null && (
                            <img
                                src={`/images/flag/${props.country.code.toUpperCase()}.png`}
                                alt="Country Flag"
                            />
                        )}
                    </div>
                    <div className="name-area">{countryName}</div>
                    <div className="check-area">
                        {props.selected && <CheckIcon class-name="img-check" />}
                    </div>
                </>
            )}
        </div>
    );
},
(prevProps: Props, nextProps: Props) => {
    if (prevProps.country !== nextProps.country) {
        return false;
    }
    if (prevProps.country != null && nextProps.country != null) {
        if (prevProps.country.id !== nextProps.country.id) {
            return false;
        }
    }
    if (prevProps.selected !== nextProps.selected) {
        return false;
    }
    if (prevProps.langCode !== nextProps.langCode) {
        return false;
    }

    return true;
});
