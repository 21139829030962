import { GroupChatMessageData } from "models/GroupChatMessageData";

export class GroupChatHistoryResponse {
    rtnCd: number | null = null;
    hasNext: boolean | null = null;
    lstHistory: GroupChatMessageData[] | null = null;
    limitUnixTime: number | null = null;
    prevLimitUnixTime: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // hasNext
        if (obj.hasOwnProperty("IsNext")) {
            this.hasNext = obj.IsNext;
        }
        // lstHistory
        if (obj.hasOwnProperty("GroupChatMessageList")) {
            if (this.lstHistory == null) {
                this.lstHistory = [];
            }
            for (let i in obj.GroupChatMessageList) {
                let history = new GroupChatMessageData(
                    obj.GroupChatMessageList[i]
                );
                this.lstHistory.push(history);
            }
        }
        // limitUnixTime
        if (obj.hasOwnProperty("LIMIT_UNIXTIME")) {
            this.limitUnixTime = obj.LIMIT_UNIXTIME;
        }
        // prevLimitUnixTime
        if (obj.hasOwnProperty("PREV_LIMIT_UNIXTIME")) {
            this.prevLimitUnixTime = obj.PREV_LIMIT_UNIXTIME;
        }
    }
}
