import { AlbumModel } from "models/Models";

export class AlbumResponse {
    rtnCd?: number | null = null;
    album?: AlbumModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // album
        if (obj.hasOwnProperty("Album")) {
            this.album = new AlbumModel(obj.Album);
        }
    }
}
