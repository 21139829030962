import React from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    TextField,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { EventEmitter, EventSubscription } from "fbemitter";

import { TalkUserModel, TalkMessageModel, UserModel, TalkPermissionModel } from "models/Models";
import { TalkUserListItem } from "components/Components";
import {
    UserAction,
    UnreadCountAction,
    NetworkAction,
} from "redux/Actions";
import { getEmitter, getFollowUserList, getBlockUserList } from "redux/Selectors";
import { TalkUserRequest, TalkPermissionRequest } from "api/requests/Requests";
import { AppConstants, USER_LIST_TYPE } from "constants/Constants";
import { TalkUserListHandler } from "handlers/handler";
import { AppServer } from "socket/AppServer";
import { follow, block } from "functions/Functions";
import Search from "@mui/icons-material/Search";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"

type Props = {
    loginUser: UserModel;
    selectedTalkUser: TalkUserModel | undefined;
    langCd: string;
    timeDifference: number;
    onSelectTalkUser: (talkUser: TalkUserModel | undefined, transfer?: boolean) => void;
};

const _TalkUserListComponent = React.forwardRef<TalkUserListHandler, Props>(
    (props, ref) => {
        Utility.log("@@@@@ TalkUserListComponent");
        // React.useImperativeHandle(ref, () => ({
            // letDataInitialized,
            // setSearchWordFromParent,
            // setModeFromParent,
            // fetchTalkUserList,
            // searchTalkUser,
            // onClickTalkUser,
        // }));
        /***** 定数、変数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const dispatch = useDispatch();
        const href = window.location.href;
        // EventEmitter
        const emitter = useSelector((state) => getEmitter(state));
        let lstFollowUser = useSelector((state) => getFollowUserList(state));
        let lstBlockUser = useSelector((state) => getBlockUserList(state));

        /***** useRef */
        // Appサーバー
        const appServer = React.useRef<AppServer>(AppServer.instance);
        // EventEmitterの購読
        const lstSubscription = React.useRef<EventSubscription[]>();
        // モード
        const mode = React.useRef<USER_LIST_TYPE>(USER_LIST_TYPE.LIST);
        // 処理中フラグ
        const isUnderProcess = React.useRef<boolean>(false);
        // 読み込みサイズ
        const readSize = React.useRef<number>(100);
        // 読み込みインデックス
        const currentIndex = React.useRef<number>(0);
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読込中フラグ
        const nowFetching = React.useRef<boolean>(false);
        // タイマーID
        const timeoutId = React.useRef<number>(0);
        // 選択トークユーザ参照
        const refSelectedTalkUser = React.useRef<TalkUserModel>();
        // トークユーザリスト参照(登録したEventListener内処理のため)
        const refTalkUserList = React.useRef<TalkUserModel[]>();
        // お気に入りトークユーザリスト参照(登録したEventListener内処理のため)
        const refFavoriteTalkUserList = React.useRef<TalkUserModel[]>();
        // トーク許可リスト参照(登録したEventListener内処理のため)
        const refTalkPermissionList = React.useRef<TalkPermissionModel[]>();
        // スクロール領域エレメントref
        const refUserListArea = React.useRef<HTMLDivElement>();
        // 受信トークユーザ
        const refReceiveTalkUser = React.useRef<TalkUserModel>();
        // 処理中フラグ
        const refIsUnderProcess = React.useRef<boolean>(false);
        // 初期読み込みフラグ
        const refLoadedInitialData = React.useRef<boolean>(false);
        // ユーザ選択処理中フラグ
        const refUnderSelectUser = React.useRef<boolean>(false);
        // 検索処理中フラグ
        const refUnderSearch = React.useRef<boolean>(false);
        const refUseEffectEntered1 = React.useRef<boolean>(false);
        const needFetchTalkUserList = React.useRef<boolean>(false);
        const needSetTalkUser = React.useRef<boolean>(false);

        /***** useState */
        // 検索ユーザ名
        const [userName, setUserName] = React.useState<string>("");
        // 選択されたトークユーザ
        // const [selectedTalkUser, setSelectedTalkUser] = React.useState<TalkUserModel>();
        // 選択されたトークユーザのトーク許可ステータス
        const [selectedPartnerTalkPermission, setSlectedPartnerTalkPermission] = React.useState<boolean>(false);
        // トークユーザリスト
        const [lstTalkUser, setTalkUserList] =
            React.useState<TalkUserModel[]>();
        // お気に入りトークユーザリスト
        const [lstFavoriteTalkUser, setFavoriteTalkUserList] =
            React.useState<TalkUserModel[]>();
        // // トーク許可リスト
        // const [lstTalkPermission, setTalkPermissionList] =
            React.useState<TalkPermissionModel[]>();
        // メッセージ受信時のハイライト
        const [showHighLight, setShowHighLight] =
            React.useState<boolean>(false);
        // ダイアログメッセージ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        const [dialogMessage, setDialogMessage] = React.useState<string>();

        /***** useEffect */
        React.useEffect(() => {
            Utility.log("TalkUserListComponent useEffect 1");
            pushDataLayer({
                event: 'page_view',
                screen: "トークユーザ一覧",
                path: window.location.pathname,
            });
            
            refUseEffectEntered1.current = true;
            needFetchTalkUserList.current = true;

            // EventEmitterを登録
            registerEmitterListener();
            return () => {
                removeEmitterListener();
            };
        }, []);

        React.useEffect(() => {
            Utility.log("TalkUserListComponent useEffect 21");
            refSelectedTalkUser.current = props.selectedTalkUser;
        }, [props.selectedTalkUser])

        React.useEffect(() => {
            Utility.log("TalkUserListComponent useEffect 31");
            (async() => {
                const pathname = window.location.pathname;
                if (pathname.includes("/talk/chat") ||
                    pathname.includes("/talk/call"))
                {
                    needSetTalkUser.current = true;
                    let partnerId = 0;
                    if (pathname.includes("/chat/")) {
                        partnerId = parseInt(pathname.replace("/talk/chat/", ""));
                    } else if (pathname.includes("/call/")) {
                        partnerId = parseInt(pathname.replace("/talk/call/", ""));
                    }
                }
                if (refUnderSearch.current) {
                    needFetchTalkUserList.current = true;
                }
                const url = new URL(window.location.href);
                const params = url.searchParams;
                const searchWord = params.get("s") == null ? "" : params.get("s");
                // 前画面の検索ワードとクエリパラメータが不一致の場合
                if (userName !== searchWord) {
                    setUserName(searchWord == null ? "" : searchWord)
                    needFetchTalkUserList.current = true;
                }
                await init(searchWord);
                refUseEffectEntered1.current = false;
                needFetchTalkUserList.current = false;
                needSetTalkUser.current = false;
                refUnderSearch.current = false;
            })();
        }, [href])

        /**
         * 初期処理
         */
        async function init(_searchWord?: string | null) {
            const pathname = window.location.pathname;
            const url = new URL(window.location.href);
            const params = url.searchParams;
            let searchWord = params.get("s");
            if (_searchWord != null) {
                searchWord = _searchWord;
            }

            if (needFetchTalkUserList.current) {
                if (searchWord == null || searchWord.trim().length === 0) {
                    setUserName("");
                    await fetchTalkUserList(0);
                } else {
                    setUserName(searchWord);
                    await searchTalkUser(0, searchWord);
                }
            }
            if (needSetTalkUser.current) {
                let partnerId = 0;
                if (pathname.includes("/chat/")) {
                    partnerId = parseInt(pathname.replace("/talk/chat/", ""));
                } else if (pathname.includes("/talk/")) {
                    partnerId = parseInt(pathname.replace("/talk/call/", ""));
                }
                if (partnerId === 0) {
                    return;
                }

                let talkUser = getTalkUserInList(partnerId);
                if (talkUser == null) {
                    talkUser = await fetchTalkUser(partnerId);
                }
                if (talkUser == null) {
                    return;
                }
                props.onSelectTalkUser(talkUser, false);
            }
        }
        
        /**
         * EventEmitterのイベント登録
         * @returns
         */
        function registerEmitterListener() {
            if (emitter == null) {
                return;
            }
            const s1 = emitter.addListener(
                "RECEIVED_NEW_MESSAGE",
                (talkMessage: TalkMessageModel) => {
                    onReceiveTalkMessage(talkMessage);
                }
            );
            const s2 = emitter.addListener(
                "DELETE_TALK_MESSAGE",
                onReceiveDeleteTalkMessage
            );
            const s3 = emitter.addListener(
                "READ_TALK_MESSAGE",
                onReceiveReadTalkMessage
            );
            const s4 = emitter.addListener(
                "CHANGED_TALK_PERMISSION",
                onReceivedTalkPermissionChange
            );
            lstSubscription.current = [s1, s2, s3, s4];
        }
        /**
         * EventEmitterのイベント解除
         */
        function removeEmitterListener() {
            if (lstSubscription != null && lstSubscription.current != null) {
                for (let i = 0; i < lstSubscription.current.length; i++) {
                    lstSubscription.current[i].remove();
                }
            }
            if (emitter != null) {
                emitter.removeAllListeners("RECEIVED_NEW_MESSAGE");
                emitter.removeAllListeners("DELETE_TALK_MESSAGE");
                emitter.removeAllListeners("READ_TALK_MESSAGE");
                emitter.removeAllListeners("CHANGED_TALK_PERMISSION");
            }
        }

        /**
         * スクロール時
         * @param event
         * @returns
         */
        async function onScroll(event: any) {
            if (timeoutId.current !== 0) {
                return;
            }
            timeoutId.current = window.setTimeout(async function () {
                const el = refUserListArea.current;
                if (el == null) {
                    timeoutId.current = 0;
                    return;
                }
                const scrollHeight = el.scrollHeight;
                const scrollY = getScrollPosition();
                const windowHeight = window.innerHeight;
                if (scrollHeight - 50 < scrollY + windowHeight) {
                    if (!hasNext) {
                        return;
                    }
                    if (nowFetching.current) {
                        return;
                    }
                    nowFetching.current = true;

                    let index = currentIndex.current + readSize.current;
                    if (mode.current === USER_LIST_TYPE.LIST) {
                        await fetchTalkUserList(index);
                    } else {
                        await searchTalkUser(index, userName);
                    }

                    nowFetching.current = false;
                }
                timeoutId.current = 0;
            }, 100);
        }

        /**
         * 検索ボタン押下時
         */
        async function onClickSearch() {
            const pathname = window.location.pathname;
            const wkUserName = userName.trim();
            const url = new URL(window.location.href);
            const params = url.searchParams;
            const searchWord = params.get("s");
            
            if (wkUserName === searchWord) {
                return;
            }

            refUnderSearch.current = true;
            if (wkUserName == null || wkUserName.length === 0) {
                mode.current = USER_LIST_TYPE.LIST;
                navigate(pathname);
            } else {
                mode.current = USER_LIST_TYPE.SEARCH;
                navigate(pathname + "?s=" + encodeURI(wkUserName));
            }
        }

        /**
         * トークユーザ選択時
         * @param user
         */
        async function onClickTalkUser(talkUser: TalkUserModel) {
            const partner = talkUser.partner;
            if (partner == null) {
                return;
            }
            if (props.selectedTalkUser != null && 
                props.selectedTalkUser.userId == talkUser.userId && 
                props.selectedTalkUser.partnerId == talkUser.partnerId) {
                return
            }
            const isTalkAvailable = Utility.isChatAvailable(partner);
            if (!isTalkAvailable) {
                const message = intl.formatMessage({
                    id: "msg_talk_unavailable",
                });
                if (message == null) {
                    return;
                }
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }

            if (refUnderSelectUser.current) {
                return;
            }
            const search = window.location.search;
            navigate(`/talk/chat/${talkUser.partnerId}${search}`);
        }

        /**
         * トークメッセージ受信
         * @param talkMessage
         */
        async function onReceiveTalkMessage(talkMessage: TalkMessageModel) {
            const senderId = talkMessage.senderId;
            if (senderId == null) {
                return;
            }
            if (senderId === props.loginUser.id) {
                return;
            }
            const tmpFavoriteTalkUserList = refFavoriteTalkUserList.current;
            const tmpTalkUserList = refTalkUserList.current;
            let talkUser: TalkUserModel | null = null;
            if (tmpFavoriteTalkUserList != null) {
                for (let i = 0; i < tmpFavoriteTalkUserList.length; i++) {
                    const wkTalkUser = tmpFavoriteTalkUserList[i];
                    if (wkTalkUser.partnerId === senderId) {
                        talkUser = wkTalkUser;
                        break;
                    }
                }
            }
            if (talkUser != null) {
                if (talkUser.partnerId != null) {
                    incrementUnreadCount(talkUser.partnerId);
                }
                showHighLightOnReceive(talkUser);
                return;
            }
            if (tmpTalkUserList != null) {
                for (let i = 0; i < tmpTalkUserList.length; i++) {
                    const wkTalkUser = tmpTalkUserList[i];
                    if (wkTalkUser.partnerId === senderId) {
                        talkUser = wkTalkUser;
                        break;
                    }
                }
            }
            if (talkUser != null) {
                if (talkUser.partnerId != null) {
                    incrementUnreadCount(talkUser.partnerId);
                }
                showHighLightOnReceive(talkUser);
                return;
            }
            // 表示されてるトークユーザリスト内に存在しない場合、トークユーザレコードをfetchし、表示されてるトークユーザリストにprependする
            const newTalkUser: TalkUserModel | null = await fetchTalkUser(
                senderId
            );
            if (newTalkUser != null) {
                setTalkUserList((prevTalkUserList) => {
                    if (prevTalkUserList == null) {
                        refTalkUserList.current = [newTalkUser];
                        return [newTalkUser];
                    } else {
                        const newList = [newTalkUser, ...prevTalkUserList];
                        refTalkUserList.current = newList;
                        return newList;
                    }
                });
                showHighLightOnReceive(newTalkUser);
            }
        }
        /**
         * トークメッセージ削除通知受信
         * @param talkMessage
         */
        function onReceiveDeleteTalkMessage(talkMessage: TalkMessageModel) {
            if (talkMessage.read === 1) {
                return;
            }
            const senderId = talkMessage.senderId;
            if (senderId == null) {
                return;
            }
            decrementUnreadCount(senderId);
        }
        /**
         * トークメッセージ既読通知受信
         * @param talkMessage
         */
        function onReceiveReadTalkMessage(talkMessage: TalkMessageModel) {
            if (talkMessage.selfReturn == null || !talkMessage.selfReturn) {
                return;
            }
            const senderId = talkMessage.senderId;
            if (senderId == null) {
                return;
            }
            decrementUnreadCount(senderId);
        }

        /**
         * トーク許可変更の通知受信
         * @param obj
         */
        function onReceivedTalkPermissionChange(obj: any) {
            const receiverId = obj.receiver_id;
            if (receiverId == null || props.loginUser.id !== receiverId) {
                return;
            }
            const senderId = obj.sender_id;
            if (senderId == null) {
                return;
            }
            const permitted = obj.permitted;
            if (permitted == null) {
                return;
            }
            const talkUser = getTalkUserInList(senderId);
            if (talkUser == null) {
                return;
            }
            talkUser.pertnerPermitted = permitted;
            setTalkUserList((prev) => {
                if (prev == null) {
                    return prev;
                } else {
                    return [...prev];
                }
            });
            setFavoriteTalkUserList((prev) => {
                if (prev == null) {
                    return prev;
                } else {
                    return [...prev];
                }
            });
            if (refSelectedTalkUser != null && refSelectedTalkUser.current != null) {
                refSelectedTalkUser.current.pertnerPermitted = permitted;
                props.onSelectTalkUser(talkUser, false);
            }
        }

        /**
         * スクロール位置取得
         * @returns
         */
        const getScrollPosition = (): number => {
            if (refUserListArea != null && refUserListArea.current != null) {
                return Math.max(refUserListArea.current.scrollTop);
            } else {
                return 0;
            }
        };

        /**
         * トークメッセージ受信時にハイライト表示対象のユーザかどうかを判定
         * @param talkUser
         * @returns
         */
        function needToShowHightLight(talkUser: TalkUserModel): boolean {
            if (!showHighLight) {
                return false;
            }
            if (
                refReceiveTalkUser == null ||
                refReceiveTalkUser.current == null
            ) {
                return false;
            }
            if (refReceiveTalkUser.current.partnerId !== talkUser.partnerId) {
                return false;
            }
            if (
                props.selectedTalkUser != null &&
                props.selectedTalkUser.partner != null &&
                refReceiveTalkUser.current.partnerId ===
                props.selectedTalkUser.partner.id
            ) {
                return false;
            }
            return true;
        }

        /**
         * トーク許可表示更新
         * @param partnerId 
         * @param permitted 
         * @returns 
         */
        function setPermissionStatus(partnerId: number, permitted: boolean) {
            Utility.log("-------------------------------------------")
            Utility.log("setPermissionStatus IN permitted:" + permitted);
            Utility.log("-------------------------------------------")
            let changedValue = false;
            if (props.selectedTalkUser != null) {
                if (props.selectedTalkUser.partnerId === partnerId) {
                    props.selectedTalkUser.talkPermitted = permitted;
                }
            }
            if (lstFavoriteTalkUser != null) {
                const newFavoriteTalkUserList = lstFavoriteTalkUser.map((talkUser: TalkUserModel) => {
                    if (talkUser.partnerId === partnerId) {
                        talkUser.talkPermitted = permitted;
                        Utility.log("partner name:" + talkUser.partner?.name);
                        changedValue = true;
                    }
                    return talkUser;
                })
                if (changedValue) {
                    Utility.log("setPermissionStatus 1");
                    refFavoriteTalkUserList.current = newFavoriteTalkUserList;
                    setFavoriteTalkUserList(newFavoriteTalkUserList);
                    return;
                }
            }
            if (lstTalkUser != null) {
                const newTalkUserList = lstTalkUser.map((talkUser: TalkUserModel) => {
                    if (talkUser.partnerId === partnerId) {
                        Utility.log("name:" + talkUser.partner?.name)
                        talkUser.talkPermitted = permitted;
                        changedValue = true;
                    }
                    return talkUser;
                })
                if (changedValue) {
                    Utility.log("setPermissionStatus 2");
                    refTalkUserList.current = newTalkUserList;
                    setTalkUserList(newTalkUserList);
                    return;
                }
            }
        }
        /**
         * メッセージ受信時のハイライト表示設定
         * @param talkUser
         */
        function showHighLightOnReceive(talkUser: TalkUserModel) {
            window.setTimeout(() => {
                refReceiveTalkUser.current = undefined;
                setShowHighLight((prevValue) => {
                    return false;
                });
            }, 1000);
            refReceiveTalkUser.current = talkUser;
            setShowHighLight((prevValue) => {
                return true;
            });
        }
        /**
         * 未読カウントをインクリメント
         * @param partnerId
         */
        function incrementUnreadCount(partnerId: number) {
            let changed = false;
            if (
                refTalkUserList != null &&
                refTalkUserList.current != null &&
                refTalkUserList.current.length > 0
            ) {
                const newList = refTalkUserList.current.map((talkUser) => {
                    if (talkUser.partnerId === partnerId) {
                        changed = true;
                        if (talkUser.unreadCount == null) {
                            talkUser.unreadCount = 1;
                        } else {
                            talkUser.unreadCount = talkUser.unreadCount + 1;
                        }
                    }
                    return talkUser;
                });
                if (changed) {
                    setTalkUserList(() => {
                        return newList;
                    });
                    refTalkUserList.current = newList;
                }
            }
            if (
                !changed &&
                refFavoriteTalkUserList != null &&
                refFavoriteTalkUserList.current != null &&
                refFavoriteTalkUserList.current.length > 0
            ) {
                const newList = refFavoriteTalkUserList.current.map(
                    (talkUser) => {
                        if (talkUser.partnerId === partnerId) {
                            changed = true;
                            if (talkUser.unreadCount == null) {
                                talkUser.unreadCount = 1;
                            } else {
                                talkUser.unreadCount = talkUser.unreadCount + 1;
                            }
                        }
                        return talkUser;
                    }
                );
                if (changed) {
                    setFavoriteTalkUserList(() => {
                        return newList;
                    });
                    refFavoriteTalkUserList.current = newList;
                }
            }
        }
        /**
         * 未読カウントをデクリメント
         * @param partnerId
         */
        function decrementUnreadCount(partnerId: number) {
            let changed = false;
            if (
                refTalkUserList != null &&
                refTalkUserList.current != null &&
                refTalkUserList.current.length > 0
            ) {
                const newList = refTalkUserList.current.map((talkUser) => {
                    if (talkUser.partnerId === partnerId) {
                        changed = true;
                        if (
                            talkUser.unreadCount != null &&
                            talkUser.unreadCount > 0
                        ) {
                            talkUser.unreadCount = talkUser.unreadCount - 1;
                        }
                    }
                    return talkUser;
                });
                if (changed) {
                    setTalkUserList(() => {
                        return newList;
                    });
                    refTalkUserList.current = newList;
                }
            }
            if (
                !changed &&
                refFavoriteTalkUserList != null &&
                refFavoriteTalkUserList.current != null &&
                refFavoriteTalkUserList.current.length > 0
            ) {
                const newList = refFavoriteTalkUserList.current.map(
                    (talkUser) => {
                        if (talkUser.partnerId === partnerId) {
                            changed = true;
                            if (
                                talkUser.unreadCount != null &&
                                talkUser.unreadCount > 0
                            ) {
                                talkUser.unreadCount = talkUser.unreadCount - 1;
                            }
                        }
                        return talkUser;
                    }
                );
                if (changed) {
                    setFavoriteTalkUserList(() => {
                        return newList;
                    });
                    refFavoriteTalkUserList.current = newList;
                }
            }
        }

        function isFollowing(partner: UserModel) {
            return Utility.isFollowing(lstFollowUser, partner.id)
        }
        function didPermitTalk(partner: UserModel | null | undefined): boolean {
            if (refTalkPermissionList == null || refTalkPermissionList.current == null) {
                return false;
            }
            if (partner == null || partner.id == null) {
                return false;
            }
            for (let i=0; i<refTalkPermissionList.current.length; i++) {
                const talkPermission = refTalkPermissionList.current[i];
                if (talkPermission.partnerId === partner.id) {
                    if (talkPermission.permitted) {
                        return true;
                    }
                }
            }
            return false;
        }

        /**
         * リストの中からトークユーザを取得
         * @param partnerId 
         * @returns 
         */
        function getTalkUserInList(partnerId: number): TalkUserModel | null {
            if (refTalkUserList != null && refTalkUserList.current != null) {
                for (let i=0; i<refTalkUserList.current.length; i++) {
                    const talkUser = refTalkUserList.current[i];
                    if (talkUser.partnerId === partnerId) {
                        return talkUser;
                    }
                }
            }
            if (refFavoriteTalkUserList != null && refFavoriteTalkUserList.current != null) {
                for (let i=0; i<refFavoriteTalkUserList.current.length; i++) {
                    const talkUser = refFavoriteTalkUserList.current[i];
                    if (talkUser.partnerId === partnerId) {
                        return talkUser;
                    }
                }
            }
            return null;
        }
        /**
         * トークユーザーリスト取得
         * @param index
         * @returns
         */
        async function fetchTalkUserList(
            index: number
        ): Promise<TalkUserModel[] | null> {
            if (props.loginUser == null) {
                return null;
            }
            if (index > 0) {
                if (!hasNext.current) {
                    return null;
                }
            }
            if (isUnderProcess.current) {
                return null;
            }
            isUnderProcess.current = true;
            if (index === 0) {
                currentIndex.current = 0;
                hasNext.current = true;
                refTalkUserList.current = undefined;
                setTalkUserList(undefined);
                refFavoriteTalkUserList.current = undefined;
                setFavoriteTalkUserList(undefined);
            }
            // リクエスト実行
            const result = await TalkUserRequest.getTalkUserList(
                props.loginUser,
                index
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            currentIndex.current = index;
            if (result.hasNext != null) {
                hasNext.current = result.hasNext;
            }
            let wkFavoriteTalkUserList: TalkUserModel[] | undefined
            // 初回取得時
            if (index === 0) {
                // 初回取得時は許可リストを取得する
                if (result.lstTalkPermission != null) {
                    refTalkPermissionList.current = result.lstTalkPermission as TalkPermissionModel[];
                    // パートナーが選択されてる場合
                    if (props.selectedTalkUser != null) {
                        // 選択パートナーに対してトーク許可ステータス設定
                        if (didPermitTalk(props.selectedTalkUser.partner)) {
                            props.selectedTalkUser.talkPermitted = true;
                        } else {
                            props.selectedTalkUser.talkPermitted = false;
                        }
                    }
                }
                // お気に入りトークユーザリストをセットする
                if (result.lstFavoriteTalkUser != null) {
                    wkFavoriteTalkUserList = result.lstFavoriteTalkUser;
                    for (let i = 0; i < wkFavoriteTalkUserList.length; i++) {
                        const talkUser = wkFavoriteTalkUserList[i];
                        const partner = talkUser.partner;
                        // フォローステータスをセット
                        if (partner != null) {
                            partner.following = true;
                            talkUser.partner = partner;
                        }
                        if (props.selectedTalkUser != null) {
                            if (talkUser.userId === props.selectedTalkUser.userId &&
                                talkUser.partnerId === props.selectedTalkUser.partnerId) {
                                props.selectedTalkUser.partner = partner;
                            }
                        }
                        // トーク許可ステータスをセット
                        if (didPermitTalk(partner)) {
                            talkUser.talkPermitted = true;
                        } else {
                            talkUser.talkPermitted = false;
                        }
                    }
                    refFavoriteTalkUserList.current = wkFavoriteTalkUserList;
                    setFavoriteTalkUserList(refFavoriteTalkUserList.current);
                }
            }
            let wkTalkUserList: TalkUserModel[] | null = result.lstTalkUser;
            if (wkTalkUserList != null) {
                // ブロックユーザの場合は削除する
                if (lstBlockUser != null) {
                    wkTalkUserList = wkTalkUserList.filter((talkUser: TalkUserModel) => {
                        const blocked = Utility.isBlocked(lstBlockUser, talkUser.partnerId);
                        if (blocked) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                }
                // フォロー中ユーザリストの中にトークユーザがいれば削除する
                if (wkFavoriteTalkUserList != null) {
                    wkFavoriteTalkUserList = wkFavoriteTalkUserList.filter((favoriteTalkUser: TalkUserModel) => {
                        if (wkTalkUserList != null) {
                            for (let i=0; i<wkTalkUserList.length; i++) {
                                const talkUser = wkTalkUserList[i];
                                if (favoriteTalkUser.partnerId === talkUser.partnerId) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    })
                    refFavoriteTalkUserList.current = wkFavoriteTalkUserList;
                    setFavoriteTalkUserList(refFavoriteTalkUserList.current);
                }
                
                for (let i=0; i<wkTalkUserList.length; i++) {
                    const talkUser = wkTalkUserList[i];
                    const partner = talkUser.partner;
                    if (partner != null) {
                        // トークユーザリストの中にフォロー中ユーザがいればフラグをたてる
                        if (isFollowing(partner)) {
                            partner.following = true;
                        } else {
                            partner.following = false;
                        }
                        // トーク許可ステータスをセット
                        if (didPermitTalk(partner)) {
                            talkUser.talkPermitted = true;
                        } else {
                            talkUser.talkPermitted = false;
                        }
                    }
                }
                // Utility.log("before setTalkUserList")
                if (refTalkUserList.current != null) {
                    wkTalkUserList = refTalkUserList.current.concat(wkTalkUserList)
                }
                setTalkUserList(wkTalkUserList);
                refTalkUserList.current = wkTalkUserList;
            }
            if (result.readSize != null) {
                readSize.current = result.readSize;
            }
            isUnderProcess.current = false;
            let rtnList: TalkUserModel[] | null = null;
            if (wkTalkUserList != null) {
                rtnList = wkTalkUserList;
            }
            if (wkFavoriteTalkUserList != null) {
                if (rtnList == null) {
                    rtnList = [];
                }
                rtnList = rtnList.concat(wkFavoriteTalkUserList);
            }

            return rtnList;
        }

        /**
         * トークユーザ検索実行
         * @param index
         * @returns
         */
        async function searchTalkUser(
            index: number,
            _userName: string | null = null
        ): Promise<TalkUserModel[] | null> {
            if (
                props.loginUser == null ||
                _userName == null ||
                _userName.length === 0
            ) {
                return null;
            }
            if (index > 0) {
                if (!hasNext.current) {
                    return null;
                }
            }
            if (isUnderProcess.current) {
                return null;
            }
            isUnderProcess.current = true;
            if (index === 0) {
                currentIndex.current = 0;
                hasNext.current = true;
                refTalkUserList.current = undefined;
                setTalkUserList(undefined);
                refFavoriteTalkUserList.current = undefined;
                setFavoriteTalkUserList(undefined);
            }
            // 検索実行
            const result = await TalkUserRequest.searchTalkUser(
                props.loginUser,
                _userName == null ? userName : _userName,
                index
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                isUnderProcess.current = false;
                return null;
            }
            currentIndex.current = index;
            if (result.hasNext != null) {
                hasNext.current = result.hasNext;
            }
            // 初回取得時は許可リストを取得する
            if (index === 0) {
                if (result.lstTalkPermission != null) {
                    refTalkPermissionList.current = result.lstTalkPermission as TalkPermissionModel[];
                    // パートナーが選択されてる場合
                    if (props.selectedTalkUser != null) {
                        // トーク許可設定
                        if (didPermitTalk(props.selectedTalkUser.partner)) {
                            props.selectedTalkUser.talkPermitted = true;
                        } else {
                            props.selectedTalkUser.talkPermitted = false;
                        }
                    }
                }
            }
            
            let wkTalkUserList = result.lstTalkUser;
            if (wkTalkUserList != null) {
                // ブロックユーザの場合は削除する
                if (lstBlockUser != null) {
                    wkTalkUserList = wkTalkUserList.filter((talkUser: TalkUserModel) => {
                        const blocked = Utility.isBlocked(lstBlockUser, talkUser.partnerId);
                        if (blocked) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                }

                if (wkTalkUserList != null) {
                    if (lstFollowUser != null) {
                        for (let i = 0; i < wkTalkUserList.length; i++) {
                            const talkUser = wkTalkUserList[i]
                            const partner = talkUser.partner;
                            if (partner == null) {
                                continue;
                            }
                            // フォローセット
                            if (isFollowing(partner)) {
                                partner.following = true;
                            } else {
                                partner.following = false;
                            }
                            // トーク許可ステータスをセット
                            if (didPermitTalk(partner)) {
                                talkUser.talkPermitted = true;
                            } else {
                                talkUser.talkPermitted = false;
                            }
                        }
                    }
                }
                if (refTalkUserList.current != null) {
                    wkTalkUserList = refTalkUserList.current.concat(wkTalkUserList)
                }
                setTalkUserList((prevList) => {
                    if (wkTalkUserList == null) {
                        return undefined;
                    } else {
                        return wkTalkUserList;
                    }
                });
                refTalkUserList.current = wkTalkUserList;
                // setTalkUserList((prevTalkUserList) => {
                //     if (wkTalkUserList == null) {
                //         refTalkUserList.current = prevTalkUserList;
                //         return prevTalkUserList;
                //     } else {
                //         if (prevTalkUserList == null || index === 0) {
                //             refTalkUserList.current = wkTalkUserList;
                //             return wkTalkUserList;
                //         } else {
                //             const newList = prevTalkUserList.concat(wkTalkUserList);
                //             refTalkUserList.current = newList;
                //             return newList;
                //         }
                //     }
                // });
            }
            if (result.readSize != null) {
                readSize.current = result.readSize;
            }
            // if (index === 0 && props.selectedTalkUser != null) {
            //     addSelectedUserToUserList();
            // }
            isUnderProcess.current = false;
            return wkTalkUserList;
        }

        /**
         * トークユーザ取得
         * @returns
         */
        async function fetchTalkUser(
            targetId: number
        ): Promise<TalkUserModel | null> {
            if (props.loginUser == null) {
                return null;
            }
            // リクエスト実行
            const result = await TalkUserRequest.getTalkUser(
                props.loginUser,
                targetId
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }

            if (result.rtnCd == null || result.rtnCd < 0 || result.talkUser == null) {
                return null;
            }
            if (result.talkUser.userId === 0 || result.talkUser.partnerId === 0) {
                return null;
            }
            return result.talkUser;
        }

        /**
         * フォロー
         * @param target 
         */
        async function onFollow(target: UserModel) {
            const rtnCd = await follow(props.loginUser, target);
            if (rtnCd === 0) {
                if (lstFollowUser == null) {
                    lstFollowUser = [target];
                } else {
                    lstFollowUser.push(target);
                }
                dispatch(
                    UserAction({
                        lstFollowUser: lstFollowUser,
                    })
                );
                if (lstTalkUser != null) {
                    const wkTalkUserList = lstTalkUser.map((talkUser: TalkUserModel) => {
                        const partner = talkUser.partner;
                        if (partner != null) {
                            if (partner.id === target.id) {
                                partner.following = true;
                            }
                        }
                        return talkUser;
                    })
                    setTalkUserList(wkTalkUserList);
                }
                if (props.selectedTalkUser != null && props.selectedTalkUser.partner != null) {
                    const partner = props.selectedTalkUser.partner;
                    if (partner.id === target.id) {
                        partner.following = true;
                        Utility.log("onSelectTalkUser 10")
                        props.onSelectTalkUser(props.selectedTalkUser);
                    }
                }
            }
        }
        
        /**
         * ブロック
         * @param user
         */
        async function onBlock(target: UserModel) {
            const rtnCd = await block(props.loginUser, target);
            if (rtnCd === 0) {
                // メール未読件数取得
                const p1 = Utility.getUnreadMailCount(props.loginUser);
                // トーク未読件数取得
                const p2 = Utility.getUnreadTalkCount(props.loginUser);
                const [unreadMailCount, unreadTalkCount] =
                    await Promise.all([p1, p2]);
                dispatch(
                    UnreadCountAction({
                        mail:
                            unreadMailCount != null
                                ? unreadMailCount
                                : 0,
                        talk:
                            unreadTalkCount != null
                                ? unreadTalkCount
                                : 0,
                    })
                );
                if (lstBlockUser == null) {
                    lstBlockUser = [target];
                } else {
                    lstBlockUser.push(target);
                }
                if (lstTalkUser != null) {
                    const wkTalkUserList = lstTalkUser.filter((talkUser: TalkUserModel) => {
                        const partner = talkUser.partner;
                        if (partner != null) {
                            if (partner.id === target.id) {
                                return false;
                            }
                        }
                        return true;
                    })
                    setTalkUserList(wkTalkUserList);
                }
                if (props.selectedTalkUser != null) {
                    if (props.selectedTalkUser.partnerId === target.id) {
                        props.onSelectTalkUser(undefined, false);
                    }
                }
                dispatch(
                    UserAction({
                        lstBlockUser: lstBlockUser,
                    })
                );
            }
        }

        /**
         * トーク許可更新
         * @param partner 
         * @param permitted 
         * @returns 
         */
        async function onChangePermission(partner: UserModel, permitted: boolean) {
            Utility.log("onChangePermission IN permitted:" + permitted);
            if (props.loginUser.id == null) {
                return;
            }
            if (partner.id == null) {
                return;
            }
            if (refIsUnderProcess.current) {
                return;
            }
            refIsUnderProcess.current = true;
            try {
                const result = await TalkPermissionRequest.updatePermission(
                    props.loginUser,
                    partner,
                    permitted ? 1 : 0
                )
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }

                if (result.rtnCd == null || result.rtnCd < 0) {
                    return;
                }
                Utility.log("result:" + JSON.stringify(result))
                setPermissionStatus(partner.id, permitted);
                appServer.current.sendTalkPermissionNotification(
                    props.loginUser.id,
                    partner.id,
                    permitted
                );
            } catch (error) {
                Utility.log(error);
            } finally {
                refIsUnderProcess.current = false;
            }
        }

        /**
         * 選択パートナーのトーク許可情報取得
         */
        // async function fetchPartnerTalkPermission(target: UserModel): Promise<TalkPermissionModel | null> {
        //     const result = await TalkPermissionRequest.getPermission(
        //         props.loginUser,
        //         target
        //     )
        //     if (result == null) {
        //         if (window.navigator.onLine) {
        //             navigate("/maintenance");
        //         } else {
        //             dispatch(NetworkAction({connected: false}));
        //         }
        //         return null;
        //     }

        //     if (result.rtnCd == null || result.rtnCd < 0) {
        //         return null;
        //     }
        //     Utility.log("result:" + JSON.stringify(result.talkPermission))
        //     return result.talkPermission;
        // }

        /**
         * レンダリング
         */
        return (
            <>
                <style>
                    {`@keyframes onReceiveTalkMessage {
                    0% {
                        background-color: transparent;
                    }
                    100% {
                        background-color: #cc88ff;
                    }
                }`}
                </style>
                <Box
                    className="component TalkUserListComponent"
                    sx={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        margin: "auto",
                        paddingTop: "10px",
                        backgroundColor: AppConstants.BG_COLOR_APP,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <SearchInputArea
                        intl={intl}
                        userName={userName}
                        onChangeUserName={(userName) => {
                            setUserName(() => {
                                return userName;
                            });
                        }}
                        onClickSearch={onClickSearch}
                    />
                    {props.selectedTalkUser != null && props.selectedTalkUser.partner != null && (
                        <Box
                            sx={{
                                position: "relative",
                                padding: "5px 10px",
                                marginTop: "5px",
                                backgroundColor: "#00cc88",
                            }}
                        >
                            <TalkUserListItem
                                talkUser={props.selectedTalkUser}
                                selected={true}
                                permitted={props.selectedTalkUser.talkPermitted}
                                unreadCount={
                                    props.selectedTalkUser.unreadCount ?? 0
                                }
                                langCd={props.langCd}
                                timeDifference={props.timeDifference}
                                onFollow={() => {
                                    if (props.selectedTalkUser != null && props.selectedTalkUser.partner != null) {
                                        onFollow(props.selectedTalkUser.partner);
                                    }
                                }}
                                onBlock={() => {
                                    if (props.selectedTalkUser != null && props.selectedTalkUser.partner != null) {
                                        onBlock(props.selectedTalkUser.partner);
                                    }
                                }}
                                onChangePermission={(permitted) => {
                                    if (props.selectedTalkUser != null && props.selectedTalkUser.partner != null) {
                                        onChangePermission(props.selectedTalkUser.partner, permitted);
                                    }
                                }}
                            />
                        </Box>                    
                    )}
                    <TalkUserListArea
                        langCd={props.langCd}
                        timeDifference={props.timeDifference}
                        lstTalkUser={lstTalkUser}
                        lstFavoriteTalkUser={lstFavoriteTalkUser}
                        refUserListArea={refUserListArea}
                        selectedTalkUser={props.selectedTalkUser}
                        onScroll={onScroll}
                        needToShowHightLight={needToShowHightLight}
                        onClickTalkUser={onClickTalkUser}
                        onFollow={onFollow}
                        onBlock={onBlock}
                        onChangePermission={(partner, permitted) => {
                            onChangePermission(partner as UserModel, permitted);
                        }}
/>
                    <Dialog
                        className="dialog"
                        open={openDialog}
                        onClose={() => {
                            setOpenDialog(false);
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle
                            id="alert-dialog-title"
                            className="dialog-title"
                        >
                            <FormattedMessage id={"dlg_title_message"} />
                        </DialogTitle>
                        <DialogContent className="dialog-content2">
                            <DialogContentText id="alert-dialog-description">
                                {dialogMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className="dialog-button"
                                onClick={() => {
                                    setOpenDialog(false);
                                }}
                                color="primary"
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
        );
    }
);

type SearchInputAreaProps = {
    intl: IntlShape;
    userName: string;
    onChangeUserName: (name: string) => void;
    onClickSearch: () => void;
};
const SearchInputArea: React.FC<SearchInputAreaProps> = ({
    intl,
    userName,
    onChangeUserName,
    onClickSearch,
}) => {
    return (
        <Box
            className="SearchInputArea"
            component="div"
            sx={{
                flexGrow: 0,
                flexShrink: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "45px",
                padding: "0 20px",
                boxSizing: "border-box",
            }}
        >
            {
                // 検索ユーザ名
            }
            <TextField
                fullWidth
                sx={{
                    flexGrow: 1,
                    flexShrink: 1,
                    backgroundColor: "#ffffff",
                    height: "100%",
                    padding: 0,
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-input": {
                        height: "100%",
                        padding: "0 10px",
                        backgroundColor: "#ffffff",
                        borderRadius: "4px",
                    },
                }}
                InputProps={{
                    style: {
                        padding: 0,
                        height: "100%",
                    },
                }}
                placeholder={intl.formatMessage({
                    id: "user_name",
                })}
                type="text"
                value={userName}
                onChange={(event) => onChangeUserName(event.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onClickSearch();
                    }
                }}
            />
            {
                // 検索ボタン
            }
            <IconButton
                sx={{
                    backgroundColor: AppConstants.COLOR_PRIMARY,
                    fontSize: AppConstants.FONT_SIZE_BUTTON,
                    color: "#ffffff",
                    marginLeft: "5px",
                    "&:hover": {
                        backgroundColor: AppConstants.COLOR_PRIMARY,
                    },
                }}
                onClick={onClickSearch}
            >
                <Search />
            </IconButton>
        </Box>
    );
};

type TalkUserListAreaProps = {
    langCd: string;
    timeDifference: number;
    lstTalkUser: TalkUserModel[] | undefined;
    lstFavoriteTalkUser: TalkUserModel[] | undefined;
    selectedTalkUser: TalkUserModel | undefined;
    refUserListArea: React.MutableRefObject<HTMLDivElement | undefined>;
    onScroll: (event: any) => void;
    needToShowHightLight: (talkUser: TalkUserModel) => boolean;
    onClickTalkUser: (talkUser: TalkUserModel) => void;
    onFollow: (target: UserModel) => void;
    onBlock: (target: UserModel) => void;
    onChangePermission: (target: UserModel, permitted: boolean) => void;
};
/**
 * トークユーザ一覧表示エリア
 */
const TalkUserListArea: React.FC<TalkUserListAreaProps> = ({
    langCd,
    timeDifference,
    lstTalkUser,
    lstFavoriteTalkUser,
    refUserListArea,
    selectedTalkUser,
    onScroll,
    needToShowHightLight,
    onClickTalkUser,
    onFollow,
    onBlock,
    onChangePermission,
}) => {
    return (
        <Box
            ref={refUserListArea}
            className="TalkUserListArea"
            sx={{
                flexGrow: 1,
                flexShrink: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                backgroundColor: AppConstants.BG_COLOR_APP,
                boxSizing: "border-box",
                paddingBottom: `${AppConstants.AD_AREA_BOTTOM_HEIGHT}px`,
            }}
            onScroll={onScroll}
        >
            {
                // トークユーザリスト
            }
            {lstTalkUser != null &&
                lstTalkUser.map((talkUser: TalkUserModel, index: number) => {
                    // Utility.log("talkUser:" + JSON.stringify(talkUser));
                    return (
                        <React.Fragment
                            key={
                                String(talkUser.userId) +
                                "_" +
                                String(talkUser.partnerId)
                            }
                        >
                            {!talkUser.hidden &&
                                talkUser.partner != null &&
                                talkUser.partner.id != null &&
                                talkUser.partner.id > 0 &&
                                talkUser.partner.name != null &&
                                talkUser.partner.name.length > 0 && (
                                    <>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                padding: "5px 10px",
                                                backgroundColor: "transparent",
                                                "&:hover": {
                                                    backgroundColor: "#AAAADD",
                                                },
                                                animation: needToShowHightLight(
                                                    talkUser
                                                )
                                                    ? "onReceiveTalkMessage 0.3s infinite"
                                                    : "none",
                                            }}
                                        >
                                            <TalkUserListItem
                                                talkUser={talkUser}
                                                selected={false}
                                                permitted={talkUser.talkPermitted}
                                                unreadCount={
                                                    talkUser.unreadCount ?? 0
                                                }
                                                langCd={langCd}
                                                timeDifference={timeDifference}
                                                onClickUser={() => {
                                                    onClickTalkUser(talkUser);
                                                }}
                                                onFollow={() => {
                                                    onFollow(talkUser.partner as UserModel);
                                                }}
                                                onBlock={() => {
                                                    onBlock(talkUser.partner as UserModel);
                                                }}
                                                onChangePermission={(permitted) => {
                                                    onChangePermission(talkUser.partner as UserModel, permitted);
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                        </React.Fragment>
                    );
                })}
            {
                // お気に入りトークユーザリスト
            }
            {lstFavoriteTalkUser != null &&
                lstFavoriteTalkUser.map(
                    (talkUser: TalkUserModel, index: number) => {
                        // Utility.log("favoriteTalkUser:" + JSON.stringify(talkUser));
                        return (
                            <React.Fragment
                                key={
                                    String(talkUser.userId) +
                                    "_" +
                                    String(talkUser.partnerId)
                                }
                            >
                                {!talkUser.hidden &&
                                    talkUser.partner != null &&
                                    talkUser.partner.id != null &&
                                    talkUser.partner.id > 0 &&
                                    talkUser.partner.name != null &&
                                    talkUser.partner.name.length > 0 && (
                                        <>
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    padding: "5px 10px",
                                                    backgroundColor: "transparent",
                                                    "&:hover": {
                                                        backgroundColor: "#AAAADD",
                                                    },
                                                }}
                                            >
                                                <TalkUserListItem
                                                    talkUser={talkUser}
                                                    selected={false}
                                                    permitted={talkUser.talkPermitted}
                                                    unreadCount={
                                                        talkUser.unreadCount ??
                                                        0
                                                    }
                                                    langCd={langCd}
                                                    timeDifference={
                                                        timeDifference
                                                    }
                                                    onClickUser={() => {
                                                        onClickTalkUser(
                                                            talkUser
                                                        );
                                                    }}
                                                    onFollow={() => {
                                                        onFollow(talkUser.partner as UserModel);
                                                    }}
                                                    onBlock={() => {
                                                        onBlock(talkUser.partner as UserModel);
                                                    }}
                                                    onChangePermission={(permitted) => {
                                                        onChangePermission(talkUser.partner as UserModel, permitted);
                                                    }}
                                                />
                                            </Box>
                                        </>
                                    )}
                            </React.Fragment>
                        );
                    }
                )}
        </Box>
    );
};

export const TalkUserListComponent = React.memo(
    _TalkUserListComponent,
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }
        if (prevProps.selectedTalkUser !== nextProps.selectedTalkUser) {
            return false;
        }
        if (prevProps.selectedTalkUser != null && nextProps.selectedTalkUser != null) {
            if (prevProps.selectedTalkUser.partner != null && nextProps.selectedTalkUser.partner != null) {
                if (prevProps.selectedTalkUser.partner.following !== nextProps.selectedTalkUser.partner.following) {
                    return false;
                }
            }
            if (prevProps.selectedTalkUser.talkPermitted !== nextProps.selectedTalkUser.talkPermitted) {
                return false;
            }
            if (prevProps.selectedTalkUser.pertnerPermitted !== nextProps.selectedTalkUser.pertnerPermitted) {
                return false;
            }
        }
        if (prevProps.onSelectTalkUser !== nextProps.onSelectTalkUser) {
            return false;
        }
        return true;
    }
);
