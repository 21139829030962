import React from "react";
import {
    loadGoogleAuthenticationScript,
    unloadGoogleAuthenticationScript,
} from "functions/GoogleAuthenticationFunction";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Box,
    FormControl,
    OutlinedInput,
    Button,
    Alert,
    Select,
    MenuItem,
    useMediaQuery,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { SelectChangeEvent } from "@mui/material/Select";
import Utility from "utils/Utility";
import { DbConstants, AppConstants } from "constants/Constants";
import { TagModel, CustomTagModel, RegisterData } from "models/Models";
import { NavBar, ProfileDialog, SelectTagDialog } from "components/Components";
import {
    CountryCodeRequest,
    UserRequest,
    VerifyGoogleCredentialRequest,
} from "api/requests/Requests";
import { NetworkAction, ProcessAction, UserAction, WorkingItemAction } from "redux/Actions";
import {
    getWorkingRegister,
    getWorkingProfile,
    getUserTagList,
    getUserCustomTagList,
    getLanguageCode,
} from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/register/Register.scss";

const Register: React.FC = () => {
    // Utility.log("@@@@@ Register");
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
    let wkSelectedTagList = useSelector((state) => getUserTagList(state));
    let wkSelectedCustomTagList = useSelector((state) => getUserCustomTagList(state));
    let wkProfileHtml = useSelector((state) => getWorkingProfile(state));
    const langCd = useSelector((state) => getLanguageCode(state));
    const workingRegisterData = useSelector((state) => getWorkingRegister(state));

    /***** useRef */
    const container = React.useRef<HTMLDivElement>();
    const isUnderProcessing = React.useRef<boolean>(false);
    const retainEdittedData = React.useRef<boolean>(false);

    /***** useState */
    const [email, setEmail] = React.useState<string>("");
    const [confirmEmail, setConfirmEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [userName, setUserName] = React.useState<string>("");
    const [gender, setGender] = React.useState<number>(DbConstants.GENDER_NONE);
    const [age, setAge] = React.useState<number>(DbConstants.AGE_NONE);
    const [attribute, setAttribute] = React.useState<number>(
        DbConstants.ATTRIBUTE_NONE
    );
    const [region, setRegion] = React.useState<string>("");
    const [lstSelectedTag, setSelectedTagList] = React.useState<TagModel[]>([]);
    const [lstSelectedCustomTag, setSelectedCustomTagList] = React.useState<
        CustomTagModel[]
    >([]);
    const [profImageFile, setProfImageFile] = React.useState<File>();
    const [previewImagePath, setPreviewImagePath] = React.useState<string>("");
    const [profileHtml, setProfileHtml] = React.useState<string>();

    const [errMessage, setErrMessage] = React.useState<string>("");
    const [btnRegisterLabel, setBtnRegisterLabel] = React.useState<string>("");
    const [btnRegisterDisabled, setBtnRegisterDisabled] =
        React.useState<boolean>(false);
    const [openDialogTag, setOpenDialogTag] = React.useState<boolean>(false);
    const [openDialogProfile, setOpenDialogProfile] =
        React.useState<boolean>(false);
    const [registerWithGoogle, setRegisterWithGoogle] =
        React.useState<boolean>(false);
    const [googleClientId, setGoogleClientId] = React.useState<string>();
    const [googleCredential, setGoogleCredential] = React.useState<string>();
    const [countryCode, setCountryCode] = React.useState<string>();
    const [over18, setOver18] = React.useState<boolean>(false);

    const onDrop = React.useCallback(async (files: File[]) => {
        // Do something with the files
        const buffer = await files[0].arrayBuffer();
        const blob = new File([buffer], files[0].name, { type: files[0].type });
        setProfImageFile(blob);
        const dataUrl = URL.createObjectURL(blob);
        // setProfImageFile(files[0]);
        // const dataUrl = URL.createObjectURL(files[0]);
        setPreviewImagePath(dataUrl);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/*": [".jpeg", ".png"],
        },
        multiple: false,
    });

    const scrollToTop = React.useCallback(() => {
        if (container.current != null) {
            container.current.scrollTo({ top: 0 });
        }
    }, []);
    /**
     * 新規登録ボタンのラベル設定
     * @param labelId
     */
    const setRegisterButtonLabel = React.useCallback((labelId: string) => {
        const btnLabel = intl.formatMessage({
            id: labelId,
        });
        setBtnRegisterLabel(btnLabel);
    }, [intl]);


    /**
     * useEffect
     */
    React.useEffect(() => {
        pushDataLayer({
            event: 'page_view',
            screen: "新規登録",
            path: window.location.pathname,
        });
        // 国コード取得
        const country = localStorage.getItem("country");
        if (country === "JP") {
            setCountryCode("JP");
            setBtnRegisterDisabled(true);
        } else {
            (async() => {
                const result = await CountryCodeRequest.getCountryCode();
                if (result == null || result.country !== "JP") {
                    return;
                }
                setCountryCode(result.country);
                setBtnRegisterDisabled(true);
                localStorage.setItem("country", result.country);
            })();
        }
    }, []);
    React.useEffect(() => {
        retainEdittedData.current = false;
        setRegisterButtonLabel("btn_register");
        if (workingRegisterData != null) {
            if (workingRegisterData.email != null) {
                setEmail(workingRegisterData.email);
            }
            if (workingRegisterData.confirmEmail != null) {
                setConfirmEmail(workingRegisterData.confirmEmail);
            }
            if (workingRegisterData.password != null) {
                setPassword(workingRegisterData.password);
            }
            if (workingRegisterData.confirmPassword != null) {
                setConfirmPassword(workingRegisterData.confirmPassword);
            }
            if (workingRegisterData.userName != null) {
                setUserName(workingRegisterData.userName);
            }
            if (workingRegisterData.gender != null) {
                setGender(workingRegisterData.gender);
            }
            if (workingRegisterData.age != null) {
                setAge(workingRegisterData.age);
            }
            if (workingRegisterData.attribute != null) {
                setAttribute(workingRegisterData.attribute);
            }
            if (workingRegisterData.region != null) {
                setRegion(workingRegisterData.region);
            }
            if (workingRegisterData.profileImage != null) {
                setProfImageFile(workingRegisterData.profileImage);
            }
            if (workingRegisterData.previewImagePath != null) {
                setPreviewImagePath(workingRegisterData.previewImagePath);
            }
        }
        window.onGoogleAuthenticationResult = (response: any) => {
            var clientId = "";
            if (response.hasOwnProperty("client_id")) {
                clientId = response.client_id;
            } else if (response.hasOwnProperty("clientId")) {
                clientId = response.clientId;
            }
            if (clientId !== AppConstants.GOOGLE_OAUTH_CLIENT_ID) {
                return -1;
            }
            var credential = "";
            if (response.hasOwnProperty("credential")) {
                credential = response.credential;
            }
            if (credential === "") {
                return -1;
            }
            verifyGoogleCredential(clientId, credential);

            return 0;
        };
        loadGoogleAuthenticationScript();
        return () => {
            unloadGoogleAuthenticationScript();
            if (
                retainEdittedData == null ||
                retainEdittedData.current == null ||
                !retainEdittedData.current
            ) {
                dispatch(
                    WorkingItemAction({
                        register: null,
                        profile: null,
                    })
                );
                dispatch(
                    UserAction({
                        lstTag: null,
                        lstCustomTag: null,
                    })
                );
            }
        };
    }, []);
    React.useEffect(() => {
        if (langCd == null) {
            return;
        }
        setRegisterButtonLabel("btn_register");
    }, [
        langCd,
        setRegisterButtonLabel,
    ]);
    React.useEffect(() => {
        if (wkSelectedTagList != null && wkSelectedTagList.length > 0) {
            setSelectedTagList(wkSelectedTagList);
        } else {
            setSelectedTagList([]);
        }
    }, [wkSelectedTagList]);
    React.useEffect(() => {
        if (
            wkSelectedCustomTagList != null &&
            wkSelectedCustomTagList.length > 0
        ) {
            setSelectedCustomTagList(wkSelectedCustomTagList);
        } else {
            setSelectedCustomTagList([]);
        }
    }, [wkSelectedCustomTagList]);
    React.useEffect(() => {
        if (wkProfileHtml == null) {
            setProfileHtml("");
        } else {
            setProfileHtml(wkProfileHtml);
        }
    }, [wkProfileHtml]);

    async function verifyGoogleCredential(
        clientId: string,
        credential: string
    ) {
        try {
            const result =
                await VerifyGoogleCredentialRequest.verifyCoogleCredential(
                    clientId,
                    credential
                );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd !== 0) {
                throw new Error();
            }
            if (result.email == null) {
                throw new Error();
            }
            setRegisterWithGoogle(true);
            setGoogleClientId(clientId);
            setGoogleCredential(credential);
            setEmail(result.email);
            if (langCd != null) {
                if (result.givenName != null) {
                    if (langCd === "ja") {
                        if (result.familyName != null) {
                            setUserName(result.familyName + result.givenName);
                        } else {
                            setUserName(result.givenName);
                        }
                    } else {
                        setUserName(result.givenName);
                    }
                }
            }
        } catch (error) {
            const message = intl.formatMessage({
                id: "err_google_authentication_failed",
            });
            setErrMessage(message);
        }
    }

    /**
     * タグダイアログ表示
     */
    const handleOpenTag = () => {
        if (isSizeXS) {
            retainEdittedData.current = true;
            const data = buildRegisterData();
            dispatch(
                WorkingItemAction({
                    register: data,
                })
            );
            navigate("/select/tag");
        } else {
            setOpenDialogTag(true);
        }
    };
    /**
     * プロフィールダイアログ表示
     */
    const onClickProfile = () => {
        dispatch(
            WorkingItemAction({
                profile: profileHtml,
            })
        );
        if (isSizeXS) {
            retainEdittedData.current = true;
            const data = buildRegisterData();
            dispatch(
                WorkingItemAction({
                    register: data,
                })
            );
            navigate("/profile");
        } else {
            setOpenDialogProfile(true);
        }
    };
    /**
     * プロフィールダイアログ閉じる
     */
    const handleCloseProfile = () => {
        setOpenDialogProfile(false);
    };
    /**
     * プロフィールダイアログで「OK」押下時
     */
    function onOkProfile(_profileHtml: string) {
        setOpenDialogProfile(false);
    }
    /**
     * プロフィールダイアログで「キャンセル」押下時
     */
    function onCancelProfile() {
        setOpenDialogProfile(false);
    }

    /**
     * バリデーション
     */
    function validate(): boolean {
        let valid = false;
        try {
            // Eメール
            if (email.length === 0) {
                throw new Error("err_empty_email");
            }
            if (!registerWithGoogle) {
                // Eメール(確認)
                if (email !== confirmEmail) {
                    throw new Error("err_mismatch_email");
                }
                // パスワード
                if (password.length === 0) {
                    throw new Error("err_empty_password");
                }
                // パスワード(確認)
                if (password !== confirmPassword) {
                    throw new Error("err_mismatch_password");
                }
            }
            // ユーザ名
            if (userName.length === 0) {
                throw new Error("err_empty_user_name");
            }
            // 性別
            if (gender === DbConstants.GENDER_NONE) {
                throw new Error("err_gender_not_selected");
            }
            // 年代
            if (age === DbConstants.AGE_NONE) {
                throw new Error("err_age_not_selected");
            }
            // 属性
            if (attribute === DbConstants.ATTRIBUTE_NONE) {
                throw new Error("err_attribute_not_selected");
            }
            setErrMessage("");
            valid = true;
        } catch (e) {
            if (e instanceof Error) {
                const errMessage = intl.formatMessage({
                    id: e.message,
                });
                setErrMessage(errMessage);
                scrollToTop();
                // goToScrollTop();
            }
        } finally {
            return valid;
        }
    }

    /**
     * スクロールをトップへ移動
     */
    // function goToScrollTop() {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    // }
    function buildRegisterData(): RegisterData {
        let wkLangCd = langCd;
        if (wkLangCd == null) {
            wkLangCd = Utility.getLanguageCode();
        }
        const data = new RegisterData();
        if (registerWithGoogle) {
            data.authType = DbConstants.AUTH_TYPE_GOOGLE;
        } else {
            data.authType = DbConstants.AUTH_TYPE_APP;
        }
        data.countryCode = "99";
        data.languageCode = wkLangCd;
        data.email = email;
        if (!registerWithGoogle) {
            data.confirmEmail = confirmEmail;
            data.password = password;
            data.confirmPassword = confirmPassword;
        }
        data.userName = userName;
        data.gender = gender;
        data.age = age;
        data.attribute = attribute;
        data.region = region;
        if (lstSelectedTag.length > 0 || lstSelectedCustomTag.length > 0) {
            const lstSelectedTagId: string[] = [];
            lstSelectedTag.forEach((tag) => {
                if (tag.id != null) {
                    lstSelectedTagId.push(String(tag.id));
                }
            });
            data.lstTag = lstSelectedTagId;

            if (lstSelectedCustomTag.length > 0) {
                const lstSelectedCustomTagName: string[] = [];
                lstSelectedCustomTag.forEach((customTag) => {
                    if (customTag.name != null) {
                        lstSelectedCustomTagName.push(customTag.name);
                    }
                });
                data.lstCustomTag = lstSelectedCustomTagName;
            }
        }
        if (previewImagePath.length > 0 && profImageFile != null) {
            data.previewImagePath = previewImagePath;
            data.profileImage = profImageFile;
        }
        if (profileHtml != null && profileHtml.length > 0) {
            data.profile = profileHtml;
        }
        return data;
    }
    /**
     * 新規登録ボタンクリック時
     * @returns
     */
    function onClickRegister() {
        if (validate()) {
            executeRegister();
        }
    }
    /**
     * 新規登録実行
     * @returns
     */
    async function executeRegister() {
        if (isUnderProcessing.current) {
            return;
        }
        isUnderProcessing.current = true;

        setBtnRegisterDisabled(true);
        setRegisterButtonLabel("msg_processing");
        dispatch(
            ProcessAction({ processing: true, message: "msg_processing" })
        );
        const data = buildRegisterData();
        try {
            const actionKey = await Utility.getActionKey();
            if (actionKey == null || actionKey.length === 0) {
                throw new Error();
            }
            data.actionKey = actionKey;
            let result = await UserRequest.register(data);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                    throw new Error();
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                if (result.rtnCd === AppConstants.ERROR_DUPLICATE_EMAIL) {
                    const errMessage = intl.formatMessage({
                        id: "err_email_already_registered",
                    });
                    throw new Error(errMessage);
                } else if (
                    result.rtnCd === AppConstants.ERROR_DUPLICATE_USER_NAME
                ) {
                    const errMessage = intl.formatMessage({
                        id: "err_duplicate_user_name",
                    });
                    throw new Error(errMessage);
                }
            }
            const loginUser = result.user;
            if (loginUser != null) {
                dispatch(
                    WorkingItemAction({
                        register: null,
                        album: null,
                        mail: null,
                        profile: null,
                    })
                );
                setTimeout(function () {
                    window.location.href = "/";
                });
            }
        } catch (e) {
            let errMessage: string | null = null;
            if (
                e instanceof Error &&
                e.message != null &&
                e.message.length > 0
            ) {
                errMessage = e.message;
            }
            if (errMessage == null) {
                errMessage = intl.formatMessage({
                    id: "err_failure_signup",
                });
            }
            setErrMessage(errMessage);
            scrollToTop();
            // goToScrollTop();
        } finally {
            setBtnRegisterDisabled(false);
            setRegisterButtonLabel("btn_register");
            dispatch(ProcessAction({ processing: false, message: "" }));
            isUnderProcessing.current = false;
        }
    }

    const onChangeOver18 = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setOver18(event.target.checked);
        if (event.target.checked) {
            setBtnRegisterDisabled(false);
        } else {
            setBtnRegisterDisabled(true);
        }
    }, []);

    // function onClickShootPhoto() {
    //     navigate("/shoot/photo", {state: {
    //         shootType: SHOOT_TYPE.THUMBNAIL
    //     }});
    // }

    return (
        <Box className="pageWrapper Register" ref={container}>
            {
                // ページタイトル
            }
            <NavBar
                showBack={false}
                title={intl.formatMessage({ id: "title_register" })}
            />

            {
                // エラーメッセージエリア
            }
            {errMessage.length > 0 && (
                <Alert
                    severity="error"
                    onClose={() => {
                        setErrMessage("");
                    }}
                >
                    {errMessage}
                </Alert>
            )}
            <Box
                component="h1"
                className="page-title"
                display={{
                    xs: "none",
                    sm: "block",
                }}
            >
                <FormattedMessage id={"title_register"} />
            </Box>

            {
                // 入力エリア
            }
            <Box component="form">
                {
                    // Google認証
                }
                <div id="google-oauth-area" className="google-oauth-area">
                    <div
                        id="g_id_onload"
                        data-client_id="1024662147490-65t4ro788gqhscbm6trrgt0u6vap2nuj.apps.googleusercontent.com"
                        data-context="signup"
                        data-ux_mode="popup"
                        data-callback="onGoogleAuthenticationResult"
                        data-auto_prompt="false"
                    ></div>
                    <div
                        className="g_id_signin"
                        data-type="standard"
                        data-shape="rectangular"
                        data-theme="filled_blue"
                        data-text="signup_with"
                        data-size="large"
                        data-logo_alignment="left"
                    ></div>
                </div>
                {
                    // メールアドレス
                }
                <FormControl className="entry-item-area" variant="standard">
                    <div className="item-label-wrapper">
                        <label htmlFor="txtEmail">
                            <FormattedMessage id={"email_address"} />
                        </label>
                        <span style={{ color: "#ff0000" }}>&#8251;</span>
                    </div>
                    <OutlinedInput
                        className="entry-field"
                        fullWidth
                        id="txtEmail"
                        label=""
                        type="email"
                        inputProps={{
                            maxLength: 50,
                        }}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        disabled={registerWithGoogle}
                    />
                </FormControl>
                {!registerWithGoogle && (
                    <>
                        {
                            // メールアドレス(確認用)
                        }
                        <FormControl
                            className="entry-item-area"
                            variant="standard"
                        >
                            <div className="item-label-wrapper">
                                <label htmlFor="txtEmailConfirm">
                                    <FormattedMessage
                                        id={"email_address_confirmation"}
                                    />
                                </label>
                                <span style={{ color: "#ff0000" }}>
                                    &#8251;
                                </span>
                            </div>
                            <OutlinedInput
                                className="entry-field"
                                fullWidth
                                id="txtEmailConfirm"
                                label=""
                                type="email"
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={confirmEmail}
                                onChange={(e) => {
                                    setConfirmEmail(e.target.value);
                                }}
                            />
                        </FormControl>
                        {
                            // パスワード
                        }
                        <FormControl
                            className="entry-item-area"
                            variant="standard"
                        >
                            <div className="item-label-wrapper">
                                <label htmlFor="txtPassword">
                                    <FormattedMessage id={"password"} />
                                </label>
                                <span style={{ color: "#ff0000" }}>
                                    &#8251;
                                </span>
                            </div>
                            <OutlinedInput
                                className="entry-field"
                                fullWidth
                                id="txtPassword"
                                label=""
                                type="password"
                                inputProps={{
                                    maxLength: 300,
                                }}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </FormControl>
                        {
                            // パスワード(確認用)
                        }
                        <FormControl
                            className="entry-item-area"
                            variant="standard"
                        >
                            <div className="item-label-wrapper">
                                <label htmlFor="txtPasswordConfirm">
                                    <FormattedMessage id={"password_confirm"} />
                                </label>
                                <span style={{ color: "#ff0000" }}>
                                    &#8251;
                                </span>
                            </div>
                            <OutlinedInput
                                className="entry-field"
                                fullWidth
                                id="txtPasswordConfirm"
                                label=""
                                type="password"
                                inputProps={{
                                    maxLength: 300,
                                }}
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                        </FormControl>
                    </>
                )}
                {
                    // ユーザ名
                }
                <FormControl className="entry-item-area" variant="standard">
                    <div className="item-label-wrapper">
                        <label htmlFor="txtUserName">
                            <FormattedMessage id={"user_name"} />
                        </label>
                        <span style={{ color: "#ff0000" }}>&#8251;</span>
                    </div>
                    <OutlinedInput
                        className="entry-field"
                        fullWidth
                        id="txtUserName"
                        label=""
                        type="text"
                        inputProps={{
                            maxLength: 40,
                        }}
                        value={userName}
                        onChange={(e) => {
                            setUserName(e.target.value);
                        }}
                    />
                </FormControl>
                {
                    // 性別
                }
                <FormControl className="entry-item-area" variant="standard">
                    <div className="item-label-wrapper">
                        <label htmlFor="selectGender">
                            <FormattedMessage id={"sex"} />
                        </label>
                        <span style={{ color: "#ff0000" }}>&#8251;</span>
                    </div>
                    <Select
                        className="entry-select"
                        value={`${gender}`}
                        id="selectGender"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        onChange={(e: SelectChangeEvent) => {
                            setGender(parseInt(e.target.value));
                        }}
                    >
                        <MenuItem value={`${DbConstants.GENDER_NONE}`}>
                            {intl.formatMessage({
                                id: "no_selection",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.GENDER_MALE}`}>
                            {intl.formatMessage({
                                id: Utility.getGenderLabelKey(
                                    DbConstants.GENDER_MALE
                                ),
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.GENDER_FEMALE}`}>
                            {intl.formatMessage({
                                id: Utility.getGenderLabelKey(
                                    DbConstants.GENDER_FEMALE
                                ),
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.GENDER_OTHER}`}>
                            {intl.formatMessage({
                                id: Utility.getGenderLabelKey(
                                    DbConstants.GENDER_OTHER
                                ),
                            })}
                        </MenuItem>
                    </Select>
                </FormControl>
                {
                    // 年代
                }
                <FormControl className="entry-item-area" variant="standard">
                    <div className="item-label-wrapper">
                        <label htmlFor="selectAge">
                            <FormattedMessage id={"age"} />
                        </label>
                        <span style={{ color: "#ff0000" }}>&#8251;</span>
                    </div>
                    <Select
                        className="entry-select"
                        value={`${age}`}
                        id="selectAge"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        onChange={(e: SelectChangeEvent) => {
                            setAge(parseInt(e.target.value));
                        }}
                    >
                        <MenuItem value={`${DbConstants.AGE_NONE}`}>
                            {intl.formatMessage({
                                id: "no_selection",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.AGE_10}`}>
                            {intl.formatMessage({
                                id: "age_10",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.AGE_20}`}>
                            {intl.formatMessage({
                                id: "age_20",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.AGE_30}`}>
                            {intl.formatMessage({
                                id: "age_30",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.AGE_40}`}>
                            {intl.formatMessage({
                                id: "age_40",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.AGE_50}`}>
                            {intl.formatMessage({
                                id: "age_50",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.AGE_60}`}>
                            {intl.formatMessage({
                                id: "age_60",
                            })}
                        </MenuItem>
                    </Select>
                </FormControl>
                {
                    // 属性
                }
                <FormControl className="entry-item-area" variant="standard">
                    <div className="item-label-wrapper">
                        <label htmlFor="selectAttribute">
                            <FormattedMessage id={"attribute"} />
                        </label>
                        <span style={{ color: "#ff0000" }}>&#8251;</span>
                    </div>
                    <Select
                        className="entry-select"
                        value={`${attribute}`}
                        id="selectAttribute"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        onChange={(e: SelectChangeEvent) => {
                            setAttribute(parseInt(e.target.value));
                        }}
                    >
                        <MenuItem value={`${DbConstants.ATTRIBUTE_NONE}`}>
                            {intl.formatMessage({
                                id: "no_selection",
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.ATTRIBUTE_SADO}`}>
                            {intl.formatMessage({
                                id: Utility.getAttributeLabelKey2(
                                    gender,
                                    DbConstants.ATTRIBUTE_SADO
                                ),
                            })}
                        </MenuItem>
                        <MenuItem value={`${DbConstants.ATTRIBUTE_MASO}`}>
                            {intl.formatMessage({
                                id: Utility.getAttributeLabelKey2(
                                    gender,
                                    DbConstants.ATTRIBUTE_MASO
                                ),
                            })}
                        </MenuItem>
                    </Select>
                </FormControl>
                {
                    // お住まい
                }
                <FormControl className="entry-item-area" variant="standard">
                    <label htmlFor="txtRegion">
                        <FormattedMessage id={"region"} />
                    </label>
                    <OutlinedInput
                        className="entry-field"
                        fullWidth
                        id="txtRegion"
                        label=""
                        type="text"
                        inputProps={{
                            maxLength: 30,
                        }}
                        value={region}
                        onChange={(e) => {
                            setRegion(e.target.value);
                        }}
                    />
                </FormControl>
                {
                    // タグ
                }
                <FormControl className="entry-item-area" variant="standard">
                    <label htmlFor="txtTagList">
                        <FormattedMessage id={"interests"} />
                    </label>
                    <div className="entry-tag-wrapper" onClick={handleOpenTag}>
                        {lstSelectedCustomTag != null &&
                            lstSelectedCustomTag.map((customTag, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            DbConstants.getCssGenderLabel(
                                                gender
                                            ) + " tag-item"
                                        }
                                    >
                                        {customTag.name}
                                    </div>
                                );
                            })}
                        {lstSelectedTag != null &&
                            lstSelectedTag.map((tag) => {
                                return (
                                    <div
                                        key={tag.id}
                                        className={
                                            DbConstants.getCssGenderLabel(
                                                gender
                                            ) + " tag-item"
                                        }
                                    >
                                        {langCd === "ja"
                                            ? tag.name
                                            : tag.nameEn}
                                    </div>
                                );
                            })}
                    </div>
                    <SelectTagDialog
                        loginUser={null}
                        openDialog={openDialogTag}
                        onClose={() => {
                            setOpenDialogTag(false);
                        }}
                        onCancel={() => {
                            setOpenDialogTag(false);
                        }}
                        onOk={() => {
                            setOpenDialogTag(false);
                        }}
                    />
                </FormControl>
                {
                    // プロフィール画像
                }
                <FormControl className="entry-item-area profile-picture" variant="standard">
                    <div className="item-label-wrapper">
                        <label htmlFor="txtProfImage">
                            <FormattedMessage id={"profile_picture"} />
                        </label>
                        {/* <img 
                            className="camera-icon" 
                            src={PhotoIcon} 
                            alt=""
                            onClick={onClickShootPhoto}
                        /> */}
                    </div>
                    <div className="entry-profile-picture-wrapper">
                        <div
                            {...getRootProps({ className: "dropzone" })}
                            style={{ width: "100%", height: "100%" }}
                        >
                            <input {...getInputProps()} />
                            <div className="picture-area-wrapper">
                                <p>
                                    <FormattedMessage id="guide_profile_picture_drag_and_drop" />
                                </p>
                                {previewImagePath != null &&
                                    previewImagePath.length > 0 && (
                                        <img
                                            src={previewImagePath}
                                            alt=""
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </FormControl>
                {
                    // プロフィール
                }
                <FormControl className="entry-item-area" variant="standard">
                    <label htmlFor="txtProfile">
                        <FormattedMessage id={"profile"} />
                    </label>
                    <div
                        className="entry-profile-wrapper"
                        dangerouslySetInnerHTML={{
                            __html: profileHtml == null ? "" : profileHtml,
                        }}
                        onClick={onClickProfile}
                    />
                    <ProfileDialog
                        openDialog={openDialogProfile}
                        onClose={handleCloseProfile}
                        onCancel={onCancelProfile}
                        onOk={(profileHtml, profile) =>
                            onOkProfile(profileHtml)
                        }
                    />
                </FormControl>
                {
                    // 日本国内の場合は１８歳以上の確認
                }
                <FormControlLabel 
                    control={
                        <Checkbox 
                            checked={over18} 
                            color="secondary"
                            onChange={onChangeOver18}
                        />
                    } 
                    label="１８歳以上"
                />

                {
                    // ボタンエリア
                }
                <div className="button-area">
                    <Button
                        className={
                            btnRegisterDisabled ? "app-button button disabled" : "app-button button"
                        }
                        variant="text"
                        disabled={btnRegisterDisabled}
                        onClick={onClickRegister}
                    >
                        {btnRegisterLabel}
                    </Button>
                </div>
            </Box>
        </Box>
    );
};

export default Register;
