import { AlbumModel } from "models/Models";

export class UserAlbumListResponse {
    rtnCd?: number | null = null;
    lstAlbum?: AlbumModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstAlbum
        if (obj.hasOwnProperty("AlbumList")) {
            if (this.lstAlbum == null) {
                this.lstAlbum = [];
            }
            for (let i in obj.AlbumList) {
                let album = new AlbumModel(obj.AlbumList[i]);
                this.lstAlbum.push(album);
            }
        }
    }
}
