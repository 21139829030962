export class LinkInfo2Model {
    type: number | null = null;
    messageBoardId: number | null = null;
    userId: number | null = null;
    userName: string | null = null;
    groupId: number | null = null;
    groupName: string | null = null;
    urlList: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // type
        if (obj.hasOwnProperty("type")) {
            this.type = obj.type;
        } else if (obj.hasOwnProperty("Type")) {
            this.type = obj.Type;
        }
        // messageBoardId
        if (obj.hasOwnProperty("messageBoardId")) {
            this.messageBoardId = obj.messageBoardId;
        } else if (obj.hasOwnProperty("MessageBoardId")) {
            this.messageBoardId = obj.MessageBoardId;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // userName
        if (obj.hasOwnProperty("userName")) {
            this.userName = obj.userName;
        } else if (obj.hasOwnProperty("UserName")) {
            this.userName = obj.UserName;
        }
        // groupId
        if (obj.hasOwnProperty("groupId")) {
            this.groupId = obj.groupId;
        } else if (obj.hasOwnProperty("GroupId")) {
            this.groupId = obj.GroupId;
        }
        // groupName
        if (obj.hasOwnProperty("groupName")) {
            this.groupName = obj.groupName;
        } else if (obj.hasOwnProperty("GroupName")) {
            this.groupName = obj.GroupName;
        }
        // urlList
        if (obj.hasOwnProperty("url")) {
            this.urlList = obj.url;
        } else if (obj.hasOwnProperty("Url")) {
            this.urlList = obj.Url;
        }
    }
}
