import * as Actions from "./actions";
import initialState from "../store/initialState";

export const HistoryReducer = (
    state = initialState.history,
    action
) => {
    switch (action.type) {
        case Actions.HISTORY:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
