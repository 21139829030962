import { UserModel } from "models/Models";

export class UserSaveResponse {
    rtnCd?: number | null = null;
    user?: UserModel | null = null;
    bearerToken?: string | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // user
        if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // bearerToken
        if (obj.hasOwnProperty("Token")) {
            this.bearerToken = obj.Token;
        }
    }
}
