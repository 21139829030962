import { TalkUserModel } from "models/Models";

export class TalkUserResponse {
    rtnCd: number | null = null;
    exist: boolean | null = null;
    talkUser: TalkUserModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // exist
        if (obj.hasOwnProperty("EXIST")) {
            this.exist = obj.EXIST;
        }
        // talkUser
        if (obj.hasOwnProperty("TALK_USER")) {
            this.talkUser = new TalkUserModel(obj.TALK_USER);
        }
    }
}
