import { ResponseModel } from "models/Models";

export class ResponsePostResponse {
    rtnCd?: number | null = null;
    response?: ResponseModel | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // response
        if (obj.hasOwnProperty("Response")) {
            this.response = new ResponseModel(obj.Response);
        }
    }
}
