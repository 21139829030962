import React from "react";
import { Box, Divider } from "@mui/material";
import { UserModel, ParentMailModel } from "models/Models";
import { AppConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";

type Props = {
    loginUser: UserModel;
    parentMail: ParentMailModel;
    read: boolean;
    selected: boolean;
    langCd: string;
    timeDifference: number;
    onClick: (parentMail: ParentMailModel) => void;
};

export const ReceivedParentMailListItem: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== ReceivedParentMailListItem");
        /***** 定数、変数 */

        /***** useState */
        const [title, setTitle] = React.useState<string>("");
        const [sender, setSender] = React.useState<UserModel>();
        const [receiver, setReceiver] = React.useState<UserModel>();
        const [updatedAt, setUpdatedAt] = React.useState<string>("");

        React.useEffect(() => {
            function reflectData() {
                if (
                    props.loginUser != null &&
                    props.parentMail != null &&
                    props.parentMail.sender != null &&
                    props.parentMail.receiver != null
                ) {
                    if (props.loginUser.id === props.parentMail.receiverId) {
                        // 送信者セット
                        setSender(props.parentMail.sender);
                        // 受信者セット
                        setReceiver(props.parentMail.receiver);
                        // タイトルセット
                        if (props.parentMail.title != null) {
                            if (
                                props.parentMail.reply != null &&
                                props.parentMail.reply === 1
                            ) {
                                setTitle(
                                    AppConstants.MAIL_REPLY + props.parentMail.title
                                );
                            } else {
                                setTitle(props.parentMail.title);
                            }
                        }
                        // 更新日時セット
                        if (props.parentMail.updatedAtReceiverReceive != null) {
                            const unixTime =
                                new Date(
                                    props.parentMail.updatedAtReceiverReceive
                                ).getTime() / 1000;
                            const updatedAt = Utility.getFormattedDateTime(
                                unixTime,
                                props.timeDifference,
                                props.langCd
                            );
                            setUpdatedAt(updatedAt);
                        }
                    } else {
                        // 送信者セット
                        setSender(props.parentMail.receiver);
                        // 受信者セット
                        setReceiver(props.parentMail.sender);
                        // タイトルセット
                        if (props.parentMail.title != null) {
                            setTitle(
                                AppConstants.MAIL_REPLY + props.parentMail.title
                            );
                        }
                        // 更新日時セット
                        if (props.parentMail.updatedAtSenderReceive != null) {
                            const unixTime =
                                new Date(
                                    props.parentMail.updatedAtSenderReceive
                                ).getTime() / 1000;
                            const updatedAt = Utility.getFormattedDateTime(
                                unixTime,
                                props.timeDifference,
                                props.langCd
                            );
                            setUpdatedAt(updatedAt);
                        }
                    }
                }
            }
            reflectData();
        }, [
            props.loginUser, 
            props.timeDifference,
            props.parentMail,
            props.langCd
        ]);
        // React.useEffect(() => {
        //     reflectData();
        // }, [props.loginUser, props.parentMail]);


        /**
         * レンダー
         */
        return (
            <Box
                sx={{
                    width: "100%",
                    cursor: "pointer",
                    backgroundColor: {
                        xs: props.read
                            ? "#ffffff"
                            : AppConstants.BG_COLOR_UNREAD,
                        sm: props.read
                            ? props.selected
                                ? "#eeeeee"
                                : "#ffffff"
                            : props.selected
                            ? AppConstants.BG_COLOR_UNREAD_SELECTED
                            : AppConstants.BG_COLOR_UNREAD,
                    },
                }}
                onClick={() => {
                    props.onClick(props.parentMail);
                
                }}
            >
                {sender != null && receiver != null && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                minHeight: {
                                    xs: "50px",
                                    sm: "60px",
                                },
                                padding: {
                                    xs: "5px 10px 0 10px",
                                    sm: "5px 10px",
                                },
                            }}
                        >
                            {
                                // プロフィール画像
                            }
                            <Box
                                component="img"
                                sx={{
                                    objectFit: "cover",
                                    height: {
                                        xs: "40px",
                                        sm: "50px",
                                    },
                                    width: {
                                        xs: "40px",
                                        sm: "50px",
                                    },
                                    borderRadius: "50%",
                                }}
                                src={
                                    sender.iconName != null &&
                                    sender.iconName.length > 0
                                        ? UrlConstants.URL_S3_USER_ICON +
                                          sender.iconName
                                        : "/images/no_image.png"
                                }
                            ></Box>
                            {
                                // 親メール内容
                            }
                            <Box
                                component="div"
                                sx={{
                                    width: "100%",
                                    height: "90%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    padding: {
                                        xs: "5px 0 5px 5px",
                                        sm: "5px",
                                    },
                                    minWidth: 0,
                                }}
                            >
                                {
                                    // 送信者名
                                }
                                <Box
                                    component="div"
                                    sx={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {"From:" + sender.name}
                                </Box>
                                {
                                    // タイトル
                                }
                                <Box
                                    component="div"
                                    sx={{
                                        width: "100%",
                                        fontSize: "1.0rem",
                                        fontWeight: "bold",
                                        textAlign: "left",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {title}
                                </Box>
                                {
                                    // 日時
                                }
                                <Box
                                    component="div"
                                    sx={{
                                        width: "100%",
                                        textAlign: "right",
                                        color: AppConstants.COLOR_GRAY_DATE,
                                        fontSize: "0.75rem",
                                    }}
                                >
                                    {updatedAt}
                                </Box>
                            </Box>
                        </Box>
                        <Divider
                            sx={{
                                margin: {
                                    xs: "5px 10px 0 10px",
                                    sm: "0",
                                },
                                backgroundColor: "#000000",
                            }}
                        />
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.parentMail !== nextProps.parentMail) {
            return false;
        }
        if (prevProps.parentMail != null && nextProps.parentMail != null) {
            if (prevProps.parentMail.id !== nextProps.parentMail.id) {
                return false;
            }
        }
        if (prevProps.read !== nextProps.read) {
            return false;
        }
        if (prevProps.selected !== nextProps.selected) {
            return false;
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }
        if (prevProps.onClick !== nextProps.onClick) {
            return false;
        }

        return true;
    }
);
