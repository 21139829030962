import { TalkUserModel } from "models/Models";

export class FavoriteTalkUserListResponse {
    rtnCd: number | null = null;
    lstFavoriteTalkUser: TalkUserModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstFavoriteTalkUser
        if (obj.hasOwnProperty("FavoriteTalkUserList")) {
            if (this.lstFavoriteTalkUser == null) {
                this.lstFavoriteTalkUser = [];
            }
            for (let i in obj.FavoriteTalkUserList) {
                let favoriteTalkUser = new TalkUserModel(
                    obj.FavoriteTalkUserList[i]
                );
                this.lstFavoriteTalkUser.push(favoriteTalkUser);
            }
        }
    }
}
