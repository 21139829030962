export const ALBUM_TEMPLATE = "ALBUM_TEMPLATE";

export const AlbumTemplateAction = (albumTemplateState) => {
    let payload = {};
    if (Object.keys(albumTemplateState).indexOf("list") > -1) {
        payload["list"] = albumTemplateState.list;
    }
    return {
        type: ALBUM_TEMPLATE,
        payload: payload,
    };
};
