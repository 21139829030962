import { CustomTagModel, TagModel } from "models/Models";

export class TagListResponse {
    rtnCd?: number | null = null;
    lstTag?: TagModel[] | null = null;
    lstCustomTag?: CustomTagModel[] | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // rtnCd
        if (obj.hasOwnProperty("RTN_CD")) {
            this.rtnCd = obj.RTN_CD;
        }
        // lstTag
        if (obj.hasOwnProperty("TagList")) {
            if (this.lstTag == null) {
                this.lstTag = [];
            }
            for (let i in obj.TagList) {
                let tag = new TagModel(obj.TagList[i]);
                this.lstTag.push(tag);
            }
        }
        // lstCustomTag
        if (obj.hasOwnProperty("CustomTagList")) {
            if (this.lstCustomTag == null) {
                this.lstCustomTag = [];
            }
            for (let i in obj.CustomTagList) {
                let customTag = new CustomTagModel(obj.CustomTagList[i]);
                this.lstCustomTag.push(customTag);
            }
        }
    }
}
