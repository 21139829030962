export const LANGUAGE = "LANGUAGE";

export const LanguageAction = (languageState) => {
    return {
        type: LANGUAGE,
        payload: {
            code: languageState.code,
        },
    };
};
