import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    OutlinedInput,
    TextField,
    Alert,
    FormControlLabel,
    RadioGroup,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
} from "@mui/material";
import { UserModel, GroupModel } from "models/Models";
import { NavBar } from "components/Components";
import { UserAction, ProcessAction, NetworkAction } from "redux/Actions";
import { GroupRequest } from "api/requests/Requests";
import { DbConstants } from "constants/Constants";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/group/GroupCreate.scss";

type Props = {
    loginUser: UserModel | null;
};

const GroupCreate: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupCreate");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const GROUP_PUBLIC_SETTING_PUBLIC = "1";
        const GROUP_PUBLIC_SETTING_PRIVATE = "2";
        const GROUP_JOIN_SYSTEM_AUTO_JOIN = "1";
        const GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL = "2";

        /***** useRef */
        const processing = React.useRef(false);

        /***** useState */
        const [groupName, setGroupName] = React.useState<string>();
        const [publicSetting, setPublicSetting] = React.useState<string>(
            GROUP_PUBLIC_SETTING_PUBLIC
        );
        const [joinSystem, setJoinSystem] = React.useState<string>(
            GROUP_JOIN_SYSTEM_AUTO_JOIN
        );
        const [groupProfile, setGroupProfile] = React.useState<string>();
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        const [errMessage, setErrMessage] = React.useState<string>("");

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループ作成",
                path: window.location.pathname,
            })            
        }, []);

        /**
         * グループ作成ボタン押下時
         */
        function onClickCreate() {
            if (validate()) {
                setOpenConfirmDialog(true);
            }
        }

        /**
         * グループ作成
         */
        async function executeCreateGroup() {
            setOpenConfirmDialog(false);
            if (props.loginUser == null) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const group = new GroupModel();
            group.countryCd = "99";
            group.languageCd = Utility.getLanguageCode();
            if (groupName != null) {
                group.name = groupName;
            }
            switch (publicSetting) {
                case GROUP_PUBLIC_SETTING_PUBLIC:
                    group.open = DbConstants.GROUP_PUBLIC;
                    break;
                case GROUP_PUBLIC_SETTING_PRIVATE:
                    group.open = DbConstants.GROUP_PRIVATE;
                    break;
                default:
                    processing.current = false;
                    return;
            }
            switch (joinSystem) {
                case GROUP_JOIN_SYSTEM_AUTO_JOIN:
                    group.autoJoin = DbConstants.GROUP_AUTO_JOIN;
                    break;
                case GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL:
                    group.autoJoin = DbConstants.GROUP_INDIVIDUAL_APPROVAL;
                    break;
                default:
                    processing.current = false;
                    return;
            }
            dispatch(
                ProcessAction({ processing: true, message: "msg_processing" })
            );

            if (groupProfile != null) {
                group.profile = groupProfile;
            }
            const result = await GroupRequest.create(props.loginUser, group);
            dispatch(ProcessAction({ processing: false, message: "" }));

            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setErrMessage(intl.formatMessage({ id: "err_create_group" }));
                processing.current = false;
                return;
            }
            if (result.lstOwnGroup != null) {
                dispatch(
                    UserAction({
                        lstOwnGroup: result.lstOwnGroup,
                    })
                );
            }
            navigate(-1);
        }

        /**
         * バリデーション
         * @returns
         */
        function validate(): boolean {
            if (groupName == null) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_group_name",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (publicSetting == null || publicSetting.length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_not_selected",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (joinSystem == null || joinSystem.length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_not_selected",
                });
                setErrMessage(errMessage);
                return false;
            }
            setErrMessage("");
            return true;
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper GroupCreate">
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "title_group_create" })}
                />

                {
                    // エラーメッセージエリア
                }
                {errMessage != null && errMessage.length > 0 && (
                    <Alert
                        className="error-message"
                        severity="error"
                        onClose={() => {
                            setErrMessage("");
                        }}
                    >
                        {errMessage}
                    </Alert>
                )}
                {
                    // ガイド文言
                }
                <Box
                    component="h1"
                    className="page-title"
                    display={{
                        xs: "none",
                        sm: "block",
                    }}
                >
                    <FormattedMessage id={"title_group_create2"} />
                </Box>
                <div className="contents-wrapper">
                    <div className="info-area">
                        {
                            // グループ名
                        }
                        <div className="group-name-area">
                            <label className="label">
                                <FormattedMessage id={"group_name"} />
                            </label>
                            <OutlinedInput
                                className="group-name-value"
                                fullWidth
                                label=""
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={groupName}
                                onChange={(e) => {
                                    setGroupName(e.target.value);
                                }}
                            />
                        </div>
                        <Divider className="divider" />
                        {
                            // 公開設定
                        }
                        <div className="public-setting-area">
                            <label className="label">
                                <FormattedMessage id={"group_public_setting"} />
                            </label>
                            <div className="value-area">
                                <RadioGroup
                                    className="radio-group"
                                    name="rdo-public-setting"
                                    value={publicSetting}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setPublicSetting(e.target.value);
                                    }}
                                >
                                    {
                                        // 公開
                                    }
                                    <FormControlLabel
                                        className="radio-item"
                                        control={
                                            <Radio
                                                value={
                                                    GROUP_PUBLIC_SETTING_PUBLIC
                                                }
                                            />
                                        }
                                        label={intl.formatMessage({
                                            id: "group_public",
                                        })}
                                    />
                                    {
                                        // 非公開
                                    }
                                    <FormControlLabel
                                        className="radio-item"
                                        control={
                                            <Radio
                                                value={
                                                    GROUP_PUBLIC_SETTING_PRIVATE
                                                }
                                            />
                                        }
                                        label={intl.formatMessage({
                                            id: "group_private",
                                        })}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <Divider className="divider" />
                        {
                            // 加入方式
                        }
                        <div className="join-system-area">
                            <label className="label">
                                <FormattedMessage id={"group_join_system"} />
                            </label>
                            <div className="value-area">
                                <RadioGroup
                                    className="radio-group"
                                    name="rdo-join-system"
                                    value={joinSystem}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setJoinSystem(e.target.value);
                                    }}
                                >
                                    {
                                        // 自動承認制
                                    }
                                    <FormControlLabel
                                        className="radio-item"
                                        control={
                                            <Radio
                                                value={
                                                    GROUP_JOIN_SYSTEM_AUTO_JOIN
                                                }
                                            />
                                        }
                                        label={intl.formatMessage({
                                            id: "join_auto_approval",
                                        })}
                                    />
                                    {
                                        // 個別承認制
                                    }
                                    <FormControlLabel
                                        className="radio-item"
                                        control={
                                            <Radio
                                                value={
                                                    GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL
                                                }
                                            />
                                        }
                                        label={intl.formatMessage({
                                            id: "join_individual_approval",
                                        })}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <Divider className="divider" />
                        {
                            // グループプロフィール
                        }
                        <div className="profile-area">
                            <div className="label">
                                <FormattedMessage id={"group_profile"} />
                            </div>
                            <TextField
                                fullWidth
                                variant="outlined"
                                sx={{
                                    backgroundColor: "#ffffff",
                                    fontSize: "1rem",
                                    borderRadius: "4px",
                                    "& .MuiInputBase-root": {
                                        padding: "5px",
                                    },
                                }}
                                label=""
                                type="text"
                                multiline={true}
                                maxRows={30}
                                minRows={8}
                                inputProps={{
                                    maxLength: 500,
                                }}
                                value={groupProfile}
                                onChange={(e) => {
                                    setGroupProfile(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    {
                        // ボタンエリア
                    }
                    {props.loginUser != null && (
                        <div className="button-area">
                            {
                                // グループ作成ボタン
                            }
                            <Button
                                className="app-button button"
                                variant="text"
                                onClick={onClickCreate}
                            >
                                <FormattedMessage id={"btn_group_create"} />
                            </Button>
                        </div>
                    )}
                </div>
                <Dialog
                    className="dialog"
                    open={openConfirmDialog}
                    onClose={() => {
                        setOpenConfirmDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        className="dialog-title"
                        id="alert-dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent className="dialog-content2">
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"confirm_group_create"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            className="dialog-button"
                            onClick={executeCreateGroup} 
                            color="primary">
                            <FormattedMessage id={"btn_yes2"} />
                        </Button>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenConfirmDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_cancel"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);
export default GroupCreate;
